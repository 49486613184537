import { StyleSheet, ImageBackground } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import { H2, Txt } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Buttons";

const SubscriptionRequiredModal = ({
  isOpen,
  setIsOpen,
  navigation,
  fromCommunity,
}) => {
  const { t } = useTranslation();
  const { isMobile } = DimensionsHook();

  const closeHandler = () => {
    setIsOpen(false);
    if (fromCommunity) {
      navigation.navigate("Home");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 55 }}
            >
              <H2
                style={{
                  textAlign: "center",
                  lineHeight: isMobile ? 32 : 40,
                }}
              >
                {t("need_subscription_modal.title")}
              </H2>

              <Txt
                style={{
                  marginTop: 30,
                  marginHorizontal: 10,
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {t("need_subscription_modal.text4")}
              </Txt>

              <PrimaryButton
                onPress={() => {
                  setIsOpen(false);
                  navigation.navigate("AccountPayment");
                }}
                style={{ marginVertical: 20 }}
              >
                {t("need_subscription_modal.button")}
              </PrimaryButton>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default SubscriptionRequiredModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 800,
    maxHeight: 5000,
  },
});
