import { AlertDialog, Button } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import Spinner from "../Spinner";
import { BoldTxt, H6, Txt } from "../TextsComponents";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { View } from "react-native";
import DimensionsHook from "../../hooks/DimensionsHook";
import { Ionicons } from "@expo/vector-icons";

const DeleteCommentModal = ({ isOpen, setIsOpen, comment_id, getData }) => {
  const { t } = useTranslation();

  const [loader, setLoader] = useState(false);
  const { isMobile } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState(null);

  const cancelRef = React.useRef(null);

  const onClose = () => {
    setLoader(false);
    setIsOpen(false);
    setErrorMessage(null);
  };

  const deletePostHandler = async () => {
    if (!loader) {
      const TOKEN = await AsyncStorage.getItem("access_token");
      setLoader(true);
      setErrorMessage(null);

      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      const url = `${process.env.COMMUNITY_COMMENT}?comment_id=${comment_id}`;
      axios
        .delete(url, config)
        .then(() => {
          setTimeout(() => {
            getData(false, true);
            setIsOpen(false);
          }, 400);
        })
        .catch((err) => {
          setErrorMessage(t("unsubscribe.error_connexion"));
          setLoader(false);
        });
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialog.Content
        style={{
          width: "95%",
          maxWidth: 400,
        }}
      >
        <AlertDialog.CloseButton focusable={false} />

        <AlertDialog.Header>
          <BoldTxt style={{ fontSize: 16 }}>
            {t("community.delete_comment")}
          </BoldTxt>
        </AlertDialog.Header>
        <AlertDialog.Body style={{ padding: isMobile ? 5 : 20 }}>
          <Txt
            style={{
              fontSize: 14,
              color: colors.red1,
              textAlign: "center",
            }}
          >
            {errorMessage ? errorMessage : " "}
          </Txt>
          <View style={{ alignSelf: "center" }}>
            <Ionicons name="trash-outline" size={50} color={colors.blue3} />
          </View>

          <H6
            style={{
              fontSize: 16,
              marginTop: 10,
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            {t("community.delete_comment_text")}
          </H6>

          <Button.Group
            focusable={false}
            alignSelf="center"
            style={{ marginBottom: 10 }}
            space={2}
          >
            <Button
              focusable={false}
              style={{
                backgroundColor: colors.green2,
                color: colors.white,
                height: 40,
              }}
              onPress={onClose}
              ref={cancelRef}
            >
              <Txt style={{ fontSize: 14, color: colors.white }}>
                {t("delete_account_modal.cancel")}
              </Txt>
            </Button>
            <Button
              focusable={false}
              style={{
                backgroundColor: colors.blue3,
                color: colors.white,
                width: 140,
                height: 40,
              }}
              onPress={deletePostHandler}
            >
              {loader ? (
                <Spinner color={colors.white} />
              ) : (
                <Txt
                  style={{
                    fontSize: 14,
                    color: colors.white,
                  }}
                >
                  {t("delete_account_modal.delete")}
                </Txt>
              )}
            </Button>
          </Button.Group>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default DeleteCommentModal;
