import { StyleSheet, Image, TouchableOpacity, Keyboard } from "react-native";
import React, { useState } from "react";
import { AlertDialog, View } from "native-base";
import { Input } from "../../components/Inputs";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import { useSelector } from "react-redux";
import { Avatar } from "../../assets/svg/Icons";
import DimensionsHook from "../../hooks/DimensionsHook";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Spinner from "../../components/Spinner";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

const AddComment = ({ comment, setComment, post_id, getData }) => {
  const { t } = useTranslation();
  const { image } = useSelector((state) => state.userData);
  const { isDesktop, isTablet } = DimensionsHook();
  const [submitLoader, setSubmitLoader] = useState(false);

  const submitHandler = async () => {
    if (!submitLoader) {
      Keyboard.dismiss();
      setSubmitLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");

      axios
        .post(process.env.COMMUNITY_COMMENT, {
          access_token: TOKEN,
          comment,
          post_id,
        })
        .then(() => {
          setSubmitLoader(false);
          setComment("");
          getData(false, true);
        })
        .catch((err) => {
          console.log(err);
          setSubmitLoader(false);
        });
    }
  };

  return (
    <View
      style={{
        width: "100%",
        marginTop: 10,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: "#e5e7eb",
        flexDirection: "row",
        paddingTop: 10,
        paddingBottom: 15,
      }}
    >
      {(isTablet || isDesktop) && (
        <View>
          {image ? (
            <View
              style={[
                styles.image,
                {
                  width: isDesktop ? 40 : 30,
                  height: isDesktop ? 40 : 30,
                },
              ]}
            >
              <Image
                source={{
                  uri: process.env.BASE_URL + "/" + image,
                }}
                style={{ ...StyleSheet.absoluteFillObject }}
              />
            </View>
          ) : (
            <View
              style={[
                styles.image,
                {
                  padding: 10,
                  width: isDesktop ? 40 : 30,
                  height: isDesktop ? 40 : 30,
                },
              ]}
            >
              <Avatar />
            </View>
          )}
        </View>
      )}
      <View style={{ flex: 1 }}>
        <Input
          bg="transparent"
          multiline
          numberOfLines={3}
          placeholder={t("placeholders.add_comment")}
          value={comment}
          onChangeText={(val) => setComment(val)}
        />
      </View>
      <TouchableOpacity
        onPress={submitHandler}
        style={{
          backgroundColor: "transparent",
          alignSelf: "flex-end",
          padding: 5,
        }}
      >
        {submitLoader ? (
          <View style={{ width: isDesktop ? 40 : 32, marginVertical: 10 }}>
            <Spinner color={colors.green2} />
          </View>
        ) : (
          <MaterialCommunityIcons
            name="send-circle"
            size={isDesktop ? 40 : 32}
            color={colors.green2}
          />
        )}
      </TouchableOpacity>
    </View>
  );
};

export default AddComment;

const styles = StyleSheet.create({
  image: {
    borderRadius: 25,
    overflow: "hidden",
    backgroundColor: colors.grayBackground,
    marginRight: 5,
    marginTop: 8,
  },
});
