import { AlertDialog, Button, Radio } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import Spinner from "../Spinner";
import { BoldTxt, H6, Txt } from "../TextsComponents";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { View } from "react-native";
import DimensionsHook from "../../hooks/DimensionsHook";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const ReportCommentModal = ({ isOpen, setIsOpen, comment_id }) => {
  const { t } = useTranslation();

  const reportReasons = [
    { label: t("community.reasons.reason_1"), value: "reason_1" },
    {
      label: t("community.reasons.reason_2"),
      value: "reason_2",
    },
    {
      label: t("community.reasons.reason_3"),
      value: "reason_3",
    },
    {
      label: t("community.reasons.reason_4"),
      value: "reason_4",
    },
    {
      label: t("community.reasons.reason_5"),
      value: "reason_5",
    },
  ];

  const [loader, setLoader] = useState(false);
  const { isMobile } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState(null);
  const [reason, setReason] = useState("");

  const cancelRef = React.useRef(null);

  const onClose = () => {
    setLoader(false);
    setIsOpen(false);
    setReason("");

    setErrorMessage(null);
  };

  const reportCommentHandler = async () => {
    if (!loader) {
      setLoader(true);
      setErrorMessage(null);
      const TOKEN = await AsyncStorage.getItem("access_token");

      const url = `${process.env.COMMUNITY_COMMENT_REPORT}?comment_id=${comment_id}`;
      axios
        .post(url, { access_token: TOKEN, reason })
        .then(() => {
          setTimeout(() => {
            setIsOpen(false);
          }, 400);
        })
        .catch((err) => {
          setErrorMessage(t("unsubscribe.error_connexion"));
          setLoader(false);
        });
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialog.Content
        style={{
          width: "95%",
          maxWidth: 500,
        }}
      >
        <AlertDialog.CloseButton focusable={false} />

        <AlertDialog.Header>
          <BoldTxt style={{ fontSize: 16 }}>{t("community.report")}</BoldTxt>
        </AlertDialog.Header>
        <AlertDialog.Body style={{ padding: isMobile ? 5 : 20 }}>
          <Txt
            style={{
              fontSize: 14,
              color: colors.red1,
              textAlign: "center",
            }}
          >
            {errorMessage ? errorMessage : " "}
          </Txt>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <MaterialCommunityIcons
              name="flag-variant"
              size={24}
              color={colors.blue3}
            />

            <BoldTxt
              style={{
                fontSize: 17,
                marginLeft: 3,
              }}
            >
              {t("community.report_question")}
            </BoldTxt>
          </View>
          {/** radio buttons */}
          <View style={{ marginHorizontal: 10 }}>
            <Radio.Group
              name="reason options"
              accessibilityLabel="reasons"
              value={reason}
              onChange={(nextValue) => {
                setReason(nextValue);
              }}
            >
              {reportReasons.map((reason) => {
                return (
                  <Radio
                    size="sm"
                    mt="2"
                    colorScheme="emerald"
                    color={colors.blue3}
                    key={reason.value}
                    value={reason.value}
                  >
                    {reason.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </View>
          <Button.Group
            focusable={false}
            alignSelf="center"
            style={{ marginBottom: 10, marginTop: 30 }}
            space={2}
          >
            <Button
              focusable={false}
              style={{
                backgroundColor: colors.green2,
                color: colors.white,
                height: 40,
              }}
              onPress={onClose}
              ref={cancelRef}
            >
              <Txt style={{ fontSize: 14, color: colors.white }}>
                {t("delete_account_modal.cancel")}
              </Txt>
            </Button>
            <Button
              disabled={reason == ""}
              focusable={false}
              style={{
                backgroundColor: colors.blue3,
                color: colors.white,
                width: 140,
                height: 40,
              }}
              onPress={reportCommentHandler}
            >
              {loader ? (
                <Spinner color={colors.white} />
              ) : (
                <Txt
                  style={{
                    fontSize: 14,
                    color: colors.white,
                  }}
                >
                  {t("community.report")}
                </Txt>
              )}
            </Button>
          </Button.Group>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default ReportCommentModal;
