import { Snackbar } from "react-native-paper";
import { colors } from "../styles/GlobalStyle";
import { Txt } from "./TextsComponents";

const AlertBar = ({ isOpen, setIsOpen, text, variant }) => {
  const dismissBar = () => setIsOpen(false);

  return (
    <Snackbar
      style={{
        backgroundColor: variant === "danger" ? colors.red1 : colors.blue3,
      }}
      visible={isOpen}
      duration={4000}
      onDismiss={dismissBar}
      action={{
        labelStyle: { color: colors.white },
        label: "Ok",
        onPress: () => {
          dismissBar();
        },
      }}
    >
      <Txt color={colors.white}>{text}</Txt>
    </Snackbar>
  );
};

export default AlertBar;
