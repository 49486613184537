import { View } from "react-native";
import React, { useState } from "react";
import UseCard from "./UseCards";

import { ImgDormir } from "../../../../assets/svg/space";
import { ImgJouer } from "../../../../assets/svg/space";
import { ImgParler } from "../../../../assets/svg/space";
import { ImgSeRelaxer } from "../../../../assets/svg/space";
import { useTranslation } from "react-i18next";
import DimensionsHook from "../../../../hooks/DimensionsHook";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import VideoMovement from "../../../../components/videos/VideoMovement";
import SubscriptionRequiredModal from "../../../../components/modals/SubscriptionRequiredModal";
import { useSelector } from "react-redux";
import ChatEnterModal from "../../../../components/modals/ChatEnterModal";

const GreenCards = ({ setSoonModal, navigation }) => {
  const { subscription, lifetime_subscription, company } = useSelector(
    (state) => state.userData
  );
  const { t, i18n } = useTranslation();
  const { width } = DimensionsHook();
  const [loaderMindfullness, setLoaderMindfullness] = useState(false);
  const [loaderSleep, setLoaderSleep] = useState(false);
  const [data, setData] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showNeedSubscriptionModal, setShowNeedSubscriptionModal] =
    useState(false);
  const [loaderTime, setLoaderTime] = useState(false);
  const [times, setTimes] = useState([]);
  const [showChatEnterModal, setShowChatEnterModal] = useState(false);

  const CustomHeight = width <= 790 ? 74 : width <= 1300 ? 108 : 108;
  const SMWidth = width <= 790 ? "100%" : width <= 1300 ? "49%" : "49%";
  const Mr = width >= 1300 ? 10 : 0;
  const BoxB1A = {
    flexDirection: width <= 790 ? "column" : "row",
    width: width <= 790 ? "100%" : width <= 1300 ? "100%" : "80%",
    justifyContent: "space-evenly",
    alignSelf: "center",
    alignItems: "center",
    marginLeft: Mr,
  };

  const showMinfullnessWorkshopHandler = async () => {
    if (!subscription && !lifetime_subscription && !company) {
      setShowNeedSubscriptionModal(true);
    } else {
      setLoaderMindfullness(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(
          `${process.env.WORKSHOPS_ENDPOINT}/random?type=conscience&language=${i18n.language}`,
          config
        )
        .then((res) => {
          setData(res.data);
          setLoaderMindfullness(false);
          setShowVideoModal(true);
        })
        .catch((err) => {
          setLoaderMindfullness(false);
          setSoonModal(true);
        });
    }
  };

  const showSleepWorkshopHandler = async () => {
    if (!subscription && !lifetime_subscription && !company) {
      setShowNeedSubscriptionModal(true);
    } else {
      setLoaderSleep(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(
          `${process.env.WORKSHOPS_ENDPOINT}/fun-workshop?subtype=sleep&language=${i18n.language}`,
          config
        )
        .then((res) => {
          setData(res.data);
          setLoaderSleep(false);
          setShowVideoModal(true);
        })
        .catch((err) => {
          setLoaderSleep(false);
          setSoonModal(true);
        });
    }
  };

  const getChatTimes = async () => {
    setLoaderTime(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(process.env.GET_CHAT_TIME, config)
      .then((res) => {
        setTimes(res.data);
        setLoaderTime(false);
        setShowChatEnterModal(true);
      })
      .catch((err) => {
        console.log(err);
        setLoaderTime(false);
      });
  };

  return (
    <View style={BoxB1A}>
      <View
        style={{ flexDirection: "column", width: SMWidth, marginRight: Mr }}
      >
        {/** parler */}
        <UseCard
          title={t("my_space.green1")}
          Img={ImgParler}
          loader={loaderTime}
          CustomHeight={CustomHeight}
          onPress={() => {
            // CASE USER FREEMIUM
            if (!subscription && !lifetime_subscription && !company) {
              setShowNeedSubscriptionModal(true);
            } else {
              //setSoonModal(true);
              getChatTimes();
            }
          }}
        />
        <UseCard
          title={t("my_space.green2")}
          Img={ImgJouer}
          CustomHeight={CustomHeight}
          onPress={() => {
            // CASE USER FREEMIUM
            if (!subscription && !lifetime_subscription && !company) {
              setShowNeedSubscriptionModal(true);
            } else {
              setSoonModal(true);
            }
          }}
        />
      </View>

      <View style={{ flexDirection: "column", width: SMWidth }}>
        <UseCard
          title={t("my_space.green3")}
          Img={ImgSeRelaxer}
          CustomHeight={CustomHeight}
          loader={loaderMindfullness}
          onPress={showMinfullnessWorkshopHandler}
        />
        <UseCard
          title={t("my_space.green4")}
          Img={ImgDormir}
          CustomHeight={CustomHeight}
          onPress={showSleepWorkshopHandler}
          loader={loaderSleep}
        />
      </View>
      {data && (
        <VideoMovement
          isOpen={showVideoModal}
          setIsOpen={setShowVideoModal}
          data={data}
        />
      )}
      {/** need subscription modal */}
      <SubscriptionRequiredModal
        isOpen={showNeedSubscriptionModal}
        setIsOpen={setShowNeedSubscriptionModal}
        navigation={navigation}
      />
      {/** chat enter modal */}
      <ChatEnterModal
        isOpen={showChatEnterModal}
        setIsOpen={setShowChatEnterModal}
        navigation={navigation}
        times={times}
      />
    </View>
  );
};

export default GreenCards;
