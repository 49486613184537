import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import HeaderComponent from "../../components/HeaderComponent";
import NavigationMenu from "../../components/myaccount/NavigationMenu";
import { H3 } from "../../components/TextsComponents";
import GeneralScreen from "../../components/myaccount/GeneralScreen";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";

const AccountGeneral = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { isDesktop } = DimensionsHook();
  const scrollRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const scrollToTop = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };
  useEffect(() => {
    setTimeout(() => {
      const isVisible = route.params && route.params.show ? true : false;
      setIsOpen(isVisible);
      setLoader(false);
    }, 200);
  }, [route]);

  if (loader) {
    return (
      <View style={styles.container}>
        {isDesktop ? (
          <HeaderComponent myaccount={true} navigation={navigation} />
        ) : (
          <BackHeader
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            navigation={navigation}
            myaccount={true}
            active="AccountGeneral"
          />
        )}
        <ImageBackground
          source={require("../../assets/background.png")}
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <Spinner size="large" />
        </ImageBackground>
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView
          ref={scrollRef}
          showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          {isDesktop ? (
            <HeaderComponent myaccount={true} navigation={navigation} />
          ) : (
            <BackHeader
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              navigation={navigation}
              myaccount={true}
              active="AccountGeneral"
            />
          )}
          <View style={styles.contentContainer}>
            {isDesktop && (
              <H3 style={{ marginVertical: 10, marginLeft: 10 }}>
                {t("my_account")}
              </H3>
            )}

            <View
              style={{
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              {isDesktop ? (
                <View style={{ width: 300 }}>
                  <NavigationMenu
                    navigation={navigation}
                    active="AccountGeneral"
                  />
                </View>
              ) : (
                <></>
              )}
              {/** general screen */}
              <View
                style={[
                  styles.screenContainer,
                  {
                    backgroundColor: isDesktop ? colors.white : "transparent",
                    paddingHorizontal: isDesktop ? 20 : 10,
                    marginHorizontal: isDesktop ? 20 : 0,
                    paddingVertical: isDesktop ? 20 : 0,
                    marginBottom: 30,
                  },
                ]}
              >
                <GeneralScreen
                  scrollToTop={scrollToTop}
                  navigation={navigation}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default AccountGeneral;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  contentContainer: { marginHorizontal: "2%", marginTop: 10 },
  screenContainer: {
    flex: 1,
    borderRadius: 20,
  },
});
