import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useState } from "react";
import { Modal, useToast } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { SmallBoldTxt, Txt } from "../../components/TextsComponents";
import { Input } from "../../components/Inputs";
import { PrimaryButton } from "../../components/Buttons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { AntDesign, Ionicons } from "@expo/vector-icons";

const ParrainageModal = ({ isOpen, setIsOpen, scrollToTop }) => {
  const toast = useToast();
  const { isMobile, isDesktop } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [parrainEmail, setParrainEmail] = useState("");
  const { firstname, lastname } = useSelector((state) => state.userData);
  const [errorEmail, setErrorEmail] = useState(false);
  const { t } = useTranslation();
  const closeHandler = () => {
    setIsOpen(false);
    setErrorMessage("");
    setParrainEmail("");
  };

  const submitHandler = async () => {
    if (!loader) {
      setErrorMessage("");
      setLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const url = process.env.PARRAINAGE_ENDPOINT;
      axios
        .post(url, {
          access_token: TOKEN,
          firstname,
          lastname,
          parrainEmail,
        })
        .then(() => {
          setLoader(false);
          scrollToTop();
          closeHandler();
          toast.show({
            placement: "top",
            render: () => {
              return (
                <View
                  style={[
                    styles.toastContainer,
                    { marginTop: isDesktop ? 30 : 20 },
                  ]}
                >
                  <Ionicons
                    name="checkmark-circle"
                    size={24}
                    color={colors.white}
                    style={{ marginRight: 5 }}
                  />
                  <Txt color={colors.white}>{t("parrainage.success")}</Txt>
                  <TouchableOpacity
                    style={{ marginLeft: 20 }}
                    onPress={() => toast.closeAll()}
                  >
                    <AntDesign name="close" size={24} color={colors.white} />
                  </TouchableOpacity>
                </View>
              );
            },
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            setErrorMessage(t("parrainage.error_email"));
            setLoader(false);
          } else if (err.response.status === 403) {
            setErrorMessage(t("parrainage.error_email_used"));
            setLoader(false);
          } else if (err.response.status === 409) {
            setErrorMessage(t("parrainage.error_parrain_used"));
            setLoader(false);
          } else {
            setErrorMessage(t("parrainage.error_connexion"));
            setLoader(false);
          }
        });
    }
  };

  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body
          style={{
            marginTop: 20,
            marginHorizontal: isMobile ? 0 : 20,
          }}
        >
          <Modal.CloseButton
            focusable={false}
            style={{ marginRight: isMobile ? 0 : 10 }}
          />
          <SmallBoldTxt
            style={{
              color: colors.red1,
              textAlign: "center",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            {errorMessage ? errorMessage : " "}
          </SmallBoldTxt>
          <Txt style={{ marginBottom: 20, fontSize: 15, textAlign: "justify" }}>
            {t("parrainage.text1")}
          </Txt>
          <Input
            value={parrainEmail}
            placeholder={t("parrainage.placeholder")}
            error={errorEmail}
            onChangeText={(val) => {
              // CHECK IF THE EMAIL IS VALID
              setParrainEmail(val);
              const re = /\S+@\S+\.\S+/;
              const isValid = re.test(val);
              setErrorEmail(!isValid);
            }}
          />

          <PrimaryButton
            disabled={errorEmail}
            onPress={submitHandler}
            style={{ marginBottom: 20, marginTop: 35 }}
          >
            {loader ? <Spinner color={colors.white} /> : t("parrainage.button")}
          </PrimaryButton>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default ParrainageModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "90%",
    maxWidth: 600,
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 20,
  },
});
