import { Pressable, StyleSheet } from "react-native";
import React, { useEffect, useState } from "react";
import { Box, PresenceTransition, View } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import { BoldTxt, H6, Txt } from "../../components/TextsComponents";
import { Image } from "react-native";
import { t } from "i18next";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Avatar } from "../../assets/svg/Icons";
import { Surface } from "react-native-paper";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import Comments from "./Comments";
import axios from "axios";

const Post = ({ post, trail_title }) => {
  const { i18n } = useTranslation();
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [numberOfComments, setNumberOfComments] = useState("");
  if (i18n.language == "fr") {
    moment.locale(i18n.language);
  }

  const getNumberComments = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(
        `${process.env.COMMUNITY_VALID_COMMENT_NUMBER}?post_id=${post._id}`,
        config
      )
      .then((res) => {
        setNumberOfComments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNumberComments();
  }, []);

  return (
    <Box
      w="100%"
      mt="5"
      space={8}
      bg="#ffffff60"
      borderWidth={0}
      style={{ width: "100%" }}
    >
      <Surface style={styles.postContainer}>
        <>
          <View style={styles.topContainer}>
            <View style={styles.row}>
              {/** image */}

              {post.user_data[0].image ? (
                <View style={styles.image}>
                  <Image
                    source={{
                      uri: process.env.BASE_URL + "/" + post.user_data[0].image,
                    }}
                    style={{ ...StyleSheet.absoluteFillObject }}
                  />
                </View>
              ) : (
                <View style={[styles.image, { padding: 10 }]}>
                  <Avatar />
                </View>
              )}

              {/** posted by */}
              <View style={styles.postedBy}>
                <Txt style={{ fontSize: 15 }}>
                  {t("community.published_by")}{" "}
                  <BoldTxt style={{ fontSize: 15 }}>
                    {post.user_data[0].pseudo}
                  </BoldTxt>
                </Txt>
                <Txt style={{ fontSize: 13 }} color={colors.grayLabel}>
                  {moment(post.createdAt).fromNow()}
                </Txt>
              </View>
            </View>
            {/** season  */}
            <View
              style={{
                flexDirection: "column",
                alignItems: "flex-end",
                flex: 1,
              }}
            >
              <BoldTxt
                style={{ fontSize: 12.5, marginTop: 5, textAlign: "right" }}
              >
                {trail_title}
              </BoldTxt>
              <BoldTxt
                style={{
                  fontSize: 12,
                  color: colors.grayLabel,
                  marginTop: 3,
                  textAlign: "right",
                }}
              >
                {post.season.label ? post.season.label : "Season -"}
              </BoldTxt>
            </View>
          </View>
          {/** text */}
          <View style={styles.textsContainer}>
            {/** closed open chip */}
            <View style={styles.chipsContainer}>
              {post.closed ? (
                <View style={[styles.chip, { backgroundColor: colors.red1 }]}>
                  <Ionicons
                    name="lock-closed-outline"
                    size={15}
                    color="white"
                  />
                  <View style={{ width: 3 }} />
                  <Txt style={{ fontSize: 13, color: colors.white }}>
                    {t("community.closed")}
                  </Txt>
                </View>
              ) : (
                <View style={[styles.chip, { backgroundColor: colors.green2 }]}>
                  <Ionicons name="lock-open-outline" size={15} color="white" />
                  <View style={{ width: 3 }} />
                  <Txt style={{ fontSize: 13, color: colors.white }}>
                    {t("community.open")}
                  </Txt>
                </View>
              )}
            </View>
            {/** title + text */}
            <H6 style={{ fontSize: 16 }} numberOfLines={2}>
              {post.title}
            </H6>
            <Txt style={{ marginTop: 20, fontSize: 15 }}>{post.text}</Txt>
          </View>
          {/** comment button or close button */}
          {
            <Pressable
              onPress={() => setShowCommentSection(!showCommentSection)}
              style={styles.bottomContainer}
            >
              <MaterialCommunityIcons
                name={
                  showCommentSection
                    ? "arrow-up-drop-circle-outline"
                    : "message-text-outline"
                }
                size={20}
                color={colors.grayLabel}
              />
              <Txt
                style={{ fontSize: 13, marginLeft: 5, marginTop: -3 }}
                color={colors.grayLabel}
              >
                {t("community.comments")} ({numberOfComments})
              </Txt>
            </Pressable>
          }
          {/** comment section */}
          {showCommentSection && (
            <PresenceTransition
              visible={showCommentSection}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 300,
                },
              }}
            >
              <View
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  overflow: "hidden",
                }}
              >
                {/** post comments */}
                <Comments post={post} />
              </View>
            </PresenceTransition>
          )}
        </>
      </Surface>
    </Box>
  );
};

export default Post;

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  postContainer: {
    padding: 10,
    elevation: 2,
    borderColor: "#e5e7eb",
    borderWidth: 1,
    borderRadius: 10,
  },
  row: { flexDirection: "row", alignItems: "center" },
  image: {
    width: 50,
    height: 50,
    borderRadius: 25,
    overflow: "hidden",
    backgroundColor: colors.grayBackground,
  },
  postedBy: {
    marginRight: 8,
    marginLeft: 8,
  },
  textsContainer: {
    width: "100%",
    justifyContent: "center",
    marginTop: 10,
  },
  bottomContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
    marginTop: 10,

    paddingVertical: 5,
  },
  chipsContainer: {
    flexDirection: "row",
    marginBottom: 10,
    marginTop: 0,
  },
  chip: {
    width: 165,
    height: 30,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
});
