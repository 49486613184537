import { StyleSheet, Image, ImageBackground } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../../styles/GlobalStyle";
import { H2, Txt } from "../../TextsComponents";
import DimensionsHook from "../../../hooks/DimensionsHook";

import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../Buttons";

const NoAccessModal = ({ isOpen, setIsOpen, navigation }) => {
  const { t } = useTranslation();
  const { width, isMobile } = DimensionsHook();
  const isDesktop = width > 1000;
  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              justifyContent="center"
              alignItems="center"
              style={{ marginHorizontal: 30, marginTop: 20 }}
            >
              <H2
                style={{
                  textAlign: "center",
                  lineHeight: isMobile ? 32 : 40,
                  marginTop: 30,
                }}
              >
                {t("need_subscription_modal.title")}
              </H2>

              <Txt
                style={{
                  marginTop: 30,
                  marginBottom: isDesktop ? 20 : 10,
                  textAlign: "center",
                }}
              >
                {t("need_subscription_modal.text2")}
              </Txt>

              <PrimaryButton
                onPress={() => {
                  setIsOpen(false);

                  navigation.navigate("MyAccount", {
                    screen: "AccountPayment",
                  });
                }}
                style={{ marginVertical: 20 }}
              >
                {t("need_subscription_modal.button")}
              </PrimaryButton>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default NoAccessModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 800,
    maxHeight: 5000,
  },
});
