import { StyleSheet, View } from "react-native";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, PresenceTransition, Row, Skeleton, VStack } from "native-base";
import DimensionsHook from "../../hooks/DimensionsHook";
import { SearchComponent } from "../../components/Inputs";
import Post from "./Post";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { H6 } from "../../components/TextsComponents";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { colors } from "../../styles/GlobalStyle";
import { useIsFocused } from "@react-navigation/native";
import AddPost from "./AddPost";

const LoaderComponent = () => {
  return (
    <VStack
      w="100%"
      mt="5"
      p="4"
      borderWidth="1"
      space={8}
      overflow="hidden"
      rounded="md"
      _dark={{
        borderColor: "coolGray.500",
      }}
      _light={{
        borderColor: "coolGray.200",
      }}
    >
      <Row alignItems="center" flex={1}>
        <Skeleton style={{ width: 50, height: 50, borderRadius: 25 }} />
        <View style={{ flex: 1 }}>
          <Skeleton style={styles.skelton1} />
          <Skeleton style={styles.skelton2} />
        </View>
      </Row>
      <Skeleton
        style={{ width: "100%", height: 15, borderRadius: 20, marginTop: -10 }}
      />
      <Skeleton.Text style={{ marginTop: -10 }} />

      <Skeleton
        style={{ width: 50, height: 20, marginTop: -10 }}
        rounded="2xl"
        alignSelf="flex-end"
      />
    </VStack>
  );
};

const Posts = ({ trail_code, scrollToTop, seasons, trail_title }) => {
  const { t, i18n } = useTranslation();
  const { isMobile } = DimensionsHook();
  const [loader, setLoader] = useState(true);
  const [posts, setPosts] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastResult, setLastResult] = useState(false);
  const [fetchingMoreLoader, setFetchingMoreLoader] = useState(false);

  const [showAddPost, setShowAddPost] = useState(false);

  const searchHandler = async (searchText) => {
    setLoader(true);
    setLastResult(false);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    axios
      .get(
        `${process.env.COMMUNITY_POSTS}?pageNumber=${1}&language=${
          i18n.language
        }&trail_code=${trail_code}&keyword=${searchText}`,
        config
      )
      .then((res) => {
        setTimeout(() => {
          setPosts(res.data.posts);

          if (res.data.page == res.data.pages) {
            setLastResult(true);
          } else {
            setCurrentPage(2);
          }

          setLoader(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = async (loadingMore, isFirstData) => {
    setLastResult(false);
    if (loadingMore) {
      setFetchingMoreLoader(true);
    } else {
      setLoader(true);
    }
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    console.log(
      `${process.env.COMMUNITY_POSTS}?pageNumber=${
        isFirstData ? 1 : currentPage
      }&language=${i18n.language}&trail_code=${trail_code}&keyword=${keyword}`
    );
    axios
      .get(
        `${process.env.COMMUNITY_POSTS}?pageNumber=${
          isFirstData ? 1 : currentPage
        }&language=${
          i18n.language
        }&trail_code=${trail_code}&keyword=${keyword}`,
        config
      )
      .then((res) => {
        setTimeout(() => {
          setPosts(
            isFirstData ? res.data.posts : [...posts, ...res.data.posts]
          );

          if (res.data.page == res.data.pages) {
            setLastResult(true);
          } else {
            setCurrentPage(isFirstData ? 2 : currentPage + 1);
          }
          if (loadingMore) {
            setFetchingMoreLoader(false);
          } else {
            setLoader(false);
          }
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isFocused) {
      getData(false, true);
    }
  }, [isFocused]);

  if (loader) {
    return (
      <Box>
        <View
          style={{
            width: "100%",
            flexDirection: isMobile ? "column-reverse" : "row",
            alignItems: "center",
            justifyContent: isMobile ? "center" : "space-between",
          }}
        >
          <View
            style={{
              width: isMobile ? "100%" : 300,
              marginTop: isMobile ? 10 : 0,
            }}
          >
            {!showAddPost && (
              <SearchComponent
                placeholder={t("placeholders.search")}
                value={keyword}
                setValue={setKeyword}
                searchHandler={searchHandler}
              />
            )}
          </View>

          <PrimaryButton
            onPress={() => setShowAddPost(!showAddPost)}
            width={isMobile ? "100%" : 250}
          >
            {showAddPost ? "Publications" : t("community.new_post")}
          </PrimaryButton>
        </View>
        {/** case new post pressed */}
        {showAddPost ? (
          <PresenceTransition
            visible={showAddPost}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 300,
              },
            }}
          >
            <View
              style={{
                width: "100%",
                backgroundColor: "transparent",
                overflow: "hidden",
              }}
            >
              <AddPost
                setShowAddPost={setShowAddPost}
                trail_code={trail_code}
                seasons={seasons}
              />
            </View>
          </PresenceTransition>
        ) : (
          <>
            <LoaderComponent />
            <LoaderComponent />
            <LoaderComponent />
          </>
        )}
      </Box>
    );
  }

  if (posts.length == 0) {
    return (
      <Box>
        <View
          style={{
            width: "100%",
            flexDirection: isMobile ? "column-reverse" : "row",
            alignItems: "center",
            justifyContent: isMobile ? "center" : "space-between",
          }}
        >
          <View
            style={{
              width: isMobile ? "100%" : 300,
              marginTop: isMobile ? 10 : 0,
            }}
          >
            {!showAddPost && (
              <SearchComponent
                placeholder={t("placeholders.search")}
                value={keyword}
                setValue={setKeyword}
                searchHandler={searchHandler}
              />
            )}
          </View>

          <PrimaryButton
            onPress={() => setShowAddPost(!showAddPost)}
            width={isMobile ? "100%" : 250}
          >
            {showAddPost ? "Publications" : t("community.new_post")}
          </PrimaryButton>
        </View>
        {/** case new post pressed */}
        {showAddPost ? (
          <PresenceTransition
            visible={showAddPost}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 300,
              },
            }}
          >
            <View
              style={{
                width: "100%",
                backgroundColor: "transparent",
                overflow: "hidden",
              }}
            >
              <AddPost
                setShowAddPost={setShowAddPost}
                trail_code={trail_code}
                seasons={seasons}
              />
            </View>
          </PresenceTransition>
        ) : (
          <View style={styles.noPostsContainer}>
            <H6 style={{ fontSize: 18 }} color={colors.grayLabel}>
              {t("community.no_post")}
            </H6>
          </View>
        )}
      </Box>
    );
  }

  return (
    <View>
      <View
        style={{
          width: "100%",
          flexDirection: isMobile ? "column-reverse" : "row",
          alignItems: "center",
          justifyContent: isMobile ? "center" : "space-between",
          flexWrap: "wrap",
        }}
      >
        <View
          style={{
            width: isMobile ? "100%" : 300,
            marginTop: isMobile ? 10 : 0,
          }}
        >
          {!showAddPost && (
            <SearchComponent
              placeholder={t("placeholders.search")}
              value={keyword}
              setValue={setKeyword}
              searchHandler={searchHandler}
            />
          )}
        </View>

        <PrimaryButton
          onPress={() => setShowAddPost(!showAddPost)}
          width={isMobile ? "100%" : 250}
        >
          {showAddPost ? "Publications" : t("community.new_post")}
        </PrimaryButton>
      </View>
      {/** case new post pressed */}
      {showAddPost ? (
        <PresenceTransition
          visible={showAddPost}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 300,
            },
          }}
        >
          <View
            style={{
              width: "100%",
              backgroundColor: "transparent",
              overflow: "hidden",
            }}
          >
            <AddPost
              setShowAddPost={setShowAddPost}
              trail_code={trail_code}
              seasons={seasons}
            />
          </View>
        </PresenceTransition>
      ) : (
        <View>
          {posts.map((post) => {
            return (
              <View key={post._id} style={{ width: "100%" }}>
                <Post post={post} trail_title={trail_title} />
              </View>
            );
          })}
        </View>
      )}
      {/** loading More */}
      {!lastResult && !fetchingMoreLoader && (
        <SecondaryButton
          onPress={() => getData(true)}
          style={{
            width: "100%",
            marginVertical: 20,
            maxWidth: 400,
            alignSelf: "center",
          }}
        >
          {t("community.load_more")}
        </SecondaryButton>
      )}
      {fetchingMoreLoader && (
        <Box>
          <LoaderComponent />
          <LoaderComponent />
          <LoaderComponent />
        </Box>
      )}
    </View>
  );
};

export default Posts;

const styles = StyleSheet.create({
  skelton1: {
    width: "75%",
    marginLeft: 8,
    height: 12,
    maxWidth: 300,
    borderRadius: 10,
  },
  skelton2: {
    width: "50%",
    marginLeft: 8,
    height: 10,
    marginTop: 3,
    maxWidth: 200,
    borderRadius: 10,
  },
  noPostsContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 100,
  },
});
