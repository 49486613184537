import {
  Platform,
  ScrollView,
  StyleSheet,
  Image,
  View,
  TouchableOpacity,
} from "react-native";
import React, { useRef, useState, useEffect } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import { colors } from "../../styles/GlobalStyle";
import { H5, Txt } from "../TextsComponents";
import { LinearGradient } from "expo-linear-gradient";
import { useHover } from "react-native-web-hooks";
import { PlayIcon } from "../../assets/svg/Icons";
import { useSelector } from "react-redux";
import { EpisodesBorder } from "../../assets/svg/VideoIcons";
import axios from "axios";
import LoaderEffect from "../LoaderEffect";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import FirstVisitModal from "../modals/FirstVisitModal";
import ChooseVideoModal from "../modals/ChooseVideoModal";
import ChallengeModal from "../modals/ChallengeModal";
import FinishEpisodeModal from "../modals/FinishEpisodeModal";
import GreenModal from "../modals/GreenModal";
import BuyTrailModal from "../modals/EpisodesModal/BuyTrailModal";
import NeedSubscriptionModal from "../modals/EpisodesModal/NeedSubscriptionModal";
import MaxEpisodesWatchedModal from "../modals/EpisodesModal/MaxEpisodesWatchedModal";
import NoAccessModal from "../modals/EpisodesModal/NoAccessModal";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import TrailEpisodeVideo from "../videos/TrailEpisodeVideo";
import VideoMovement from "../videos/VideoMovement";

const LastDivider = () => {
  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: colors.white,
        minWidth: 120,
        height: 5,
        flex: 1,
        opacity: 0.5,
      }}
    />
  );
};

const UpperSection = ({
  item,
  selectedSeason,
  sequenceEpisodes,
  setIsOpenFirstVisit,
  isOpenFirstVisit,
  setShowPrealableVideoModal,
  lastWatchedEpisode,
  navigation,
}) => {
  const { width, isBigScreen, isTablet, isDesktop } = DimensionsHook();
  const { i18n, t } = useTranslation();
  // Scrollview for episodes ref
  const scrollViewRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [episodes, setEpisodes] = useState([]);
  const watched_episodes = useSelector((state) => state.watchedEpisodes);
  const { trails } = useSelector((state) => state.userActivity);
  const [showTrailVideoModal, setShowTrailVideoModal] = useState(false);
  const [showMovementVideoModal, setShowMovementVideoModal] = useState(false);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [isOpenChooseModal, setIsOpenChooseModal] = useState(false);
  const [isOpenChallenge, setIsOpenChallenge] = useState(false);
  const [isOpenFinishEpisode, setIsOpenFinishEpisode] = useState(false);
  const [isOpenGreenModal, setIsOpenGreenModal] = useState(false);
  const [isOpenMovementGreenModal, setIsOpenMovementGreenModal] =
    useState(false);
  const [isOpenBuyTrailModal, setIsOpenBuyTrailModal] = useState(false);
  const [isOpenNeedSubscriptionModal, setIssOpenNeedSubscriptionModal] =
    useState(false);
  const [isOpenMaxEpisodesModal, setIsOpenMaxEpisodesModal] = useState(false);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const getEpisodes = async () => {
    if (selectedSeason.season_code) {
      setLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(
          `${process.env.EPISODES_ENDPOINT}/${selectedSeason.season_code}?language=${i18n.language}`,
          config
        )
        .then((res) => {
          setEpisodes(res.data);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getEpisodes();
  }, [selectedSeason.season_code]);

  const EpisodeItem = ({ ep, index }) => {
    const hoverRef = useRef(null);
    const isHovered = useHover(hoverRef);

    const indexOfEpisode = sequenceEpisodes.indexOf(ep.episode_code);
    const previousEpisode = sequenceEpisodes[indexOfEpisode - 1];
    // FOR EXAMPLE TRAIL FREE || TRAIL IS UNLOCKED WE CAN WATCH START ANY SEASON
    const isFirstEpisodeAccessible =
      ep.episode_code.includes("E1") &&
      (item.free || trails.includes(item.trail_code));

    const accessible = ep.episode_code.includes("S1-E1")
      ? true
      : isFirstEpisodeAccessible
      ? true
      : watched_episodes.includes(previousEpisode)
      ? true
      : false;
    const isTheLastEpisode =
      sequenceEpisodes[sequenceEpisodes.length - 1] == ep.episode_code;
    const isWatched = watched_episodes.includes(ep.episode_code);

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: 5,
            height: 120,
            backgroundColor: "transparent",
            flexDirection: "row",
            width: 120,
            opacity: accessible ? 1 : 0.5,
          }}
        >
          <EpisodesBorder />
        </View>
        {/** episode circle button */}
        <TouchableOpacity
          disabled={!accessible}
          onPress={() => {
            setSelectedEpisode({
              ...ep,
              number: index + 1,
              isTheLastEpisode,
              trail_code: item.trail_code,
              trail_free: item.free,
              daily_limit: item.daily_limit,
              poster_link: item.poster_link,
            });
            // if it s the first season first episode then show prealable first episode modal
            if (ep.episode_code.includes("S1-E1")) {
              setIsOpenFirstVisit(true);
            } else {
              if (isWatched) {
                setIsOpenChooseModal(true);
              } else {
                setIsOpenChallenge(true);
              }
            }
          }}
          ref={hoverRef}
          style={[
            styles.episodeButton,
            {
              borderColor: !accessible
                ? colors.white
                : isHovered
                ? colors.blue3
                : colors.white,
              backgroundColor: !accessible
                ? "transparent"
                : isHovered
                ? colors.blue3
                : isWatched
                ? colors.white
                : "transparent",
              opacity: accessible ? 1 : 0.5,
            },
          ]}
        >
          <H5
            color={
              !accessible
                ? colors.white
                : isHovered
                ? colors.green2
                : isWatched
                ? colors.blue3
                : colors.white
            }
          >
            Ép. {index + 1}
          </H5>
          {ep.duration && (
            <Txt
              style={{ fontSize: 12 }}
              color={
                !accessible
                  ? colors.white
                  : isHovered
                  ? colors.green2
                  : isWatched
                  ? colors.blue3
                  : colors.white
              }
            >
              <MaterialCommunityIcons
                name="clock-fast"
                size={12}
                color={
                  !accessible
                    ? colors.white
                    : isHovered
                    ? colors.green2
                    : isWatched
                    ? colors.blue3
                    : colors.white
                }
              />
              {` ${ep.duration} min`}
            </Txt>
          )}
          <View
            style={[
              styles.playIconContainer,
              {
                backgroundColor: !accessible
                  ? colors.white
                  : isHovered
                  ? colors.green2
                  : isWatched
                  ? colors.green2
                  : colors.white,
              },
            ]}
          >
            <PlayIcon
              color={
                !accessible
                  ? colors.green2
                  : isHovered
                  ? colors.blue3
                  : isWatched
                  ? colors.white
                  : colors.green2
              }
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  if (loader) {
    return (
      <LoaderEffect
        width={width}
        height={isBigScreen ? 400 : isDesktop ? 400 : isTablet ? 300 : 250}
      />
    );
  }

  return (
    <View
      style={{
        height: isBigScreen ? 400 : isDesktop ? 400 : isTablet ? 300 : 250,
        width: "100%",
        backgroundColor: colors.blue3,
        justifyContent: "center",
      }}
    >
      <Image
        source={{ uri: selectedSeason.poster_link }}
        style={styles.image}
      />
      <LinearGradient
        colors={["#1B496500", "#1B496566"]}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      />

      <ScrollView
        bounces={false}
        ref={scrollViewRef}
        onLayout={() => {
          if (selectedSeason.episodes.includes(lastWatchedEpisode)) {
            scrollViewRef.current.scrollTo({
              x:
                (selectedSeason.episodes.indexOf(lastWatchedEpisode) + 1) * 240,
              y: 0,
              animated: true,
            });
          }
        }}
        horizontal
        contentContainerStyle={{
          alignItems: "center",
          minWidth: "100%",
        }}
        showsHorizontalScrollIndicator={Platform.OS === "web" ? true : false}
      >
        {episodes.map((ep, index) => {
          return <EpisodeItem key={ep.episode_code} ep={ep} index={index} />;
        })}
        <LastDivider />
      </ScrollView>

      {/** choose watch episode / watch movement */}
      {selectedEpisode && (
        <ChooseVideoModal
          selectedEpisode={selectedEpisode}
          isOpen={isOpenChooseModal}
          setSelectedWorkshop={setSelectedWorkshop}
          setIsOpen={setIsOpenChooseModal}
          setShowTrailVideoModal={setShowTrailVideoModal}
          setShowMovementVideoModal={setShowMovementVideoModal}
          setIsOpenBuyTrailModal={setIsOpenBuyTrailModal}
          setIssOpenNeedSubscriptionModal={setIssOpenNeedSubscriptionModal}
          setIsOpenMaxEpisodesModal={setIsOpenMaxEpisodesModal}
          setShowNoAccessModal={setShowNoAccessModal}
        />
      )}
      {/** show trail video modal */}
      {selectedEpisode && (
        <TrailEpisodeVideo
          isOpen={showTrailVideoModal}
          setIsOpen={setShowTrailVideoModal}
          data={selectedEpisode}
          setIsOpenChooseModal={setIsOpenChooseModal}
          setIsOpenGreenModal={setIsOpenGreenModal}
        />
      )}
      {/*
<VideoModal 
        isOpen={showTrailVideoModal}
        setIsOpen={setShowTrailVideoModal}
        setOpenMovement={setShowMovementVideoModal}
        item={selectedEpisode}
        setIsOpenChooseModal={setIsOpenChooseModal}
        setIsOpenGreenModal={setIsOpenGreenModal}
      />
    */}

      {/** show movement video */}
      {selectedWorkshop && (
        <VideoMovement
          isOpen={showMovementVideoModal}
          setIsOpen={setShowMovementVideoModal}
          data={selectedWorkshop}
        />
      )}
      {/*selectedWorkshop &&  (
        <MovementVideoModal
          setIsOpenMovementGreenModal={setIsOpenMovementGreenModal}
          title={""}
          isOpen={showMovementVideoModal}
          setIsOpen={setShowMovementVideoModal}
          item={{
            link:
              selectedWorkshop.link.endsWith(".m3u8") && Platform.OS == "web"
                ? selectedWorkshop.link.replace(
                    "playlist.m3u8",
                    "play_480p.mp4"
                  )
                : selectedWorkshop.link,
            poster: selectedWorkshop.poster_link,
          }}
        /> 
        )*/}
      {/** modal if finish trail */}
      <GreenModal
        isOpen={isOpenGreenModal}
        setIsOpen={setIsOpenGreenModal}
        title={t("modals.title1")}
        text={t("modals.text1")}
      />
      {/** modal if finish movement */}
      <GreenModal
        isOpen={isOpenMovementGreenModal}
        setIsOpen={setIsOpenMovementGreenModal}
        title={t("modals.title1")}
        text={t("modals.text3")}
      />
      {/** T4_1 */}

      {selectedEpisode && (
        <FirstVisitModal
          isOpen={isOpenFirstVisit}
          setIsOpen={setIsOpenFirstVisit}
          selectedEpisode={selectedEpisode}
          setShowFirstVideo={setShowTrailVideoModal}
          setShowPrealableVideo={setShowPrealableVideoModal}
        />
      )}
      {/** show challenge popup */}
      {selectedEpisode && isOpenChallenge && (
        <ChallengeModal
          isOpen={isOpenChallenge}
          setIsOpen={setIsOpenChallenge}
          sequenceEpisodes={sequenceEpisodes}
          selectedEpisode={selectedEpisode}
          setIsOpenChooseModal={setIsOpenChooseModal}
        />
      )}
      {selectedEpisode && (
        <FinishEpisodeModal
          isOpen={isOpenFinishEpisode}
          setIsOpen={setIsOpenFinishEpisode}
          item={selectedEpisode}
        />
      )}

      {/**  modal if not allowed to unlock trail */}
      <BuyTrailModal
        isOpen={isOpenBuyTrailModal}
        setIsOpen={setIsOpenBuyTrailModal}
        navigation={navigation}
      />
      {/** modal if not allowed to unlock trail and user is freemium -> can't buy trail */}
      <NeedSubscriptionModal
        isOpen={isOpenNeedSubscriptionModal}
        setIsOpen={setIssOpenNeedSubscriptionModal}
        navigation={navigation}
      />
      {/** modal if watched already 2 episodes */}
      <MaxEpisodesWatchedModal
        isOpen={isOpenMaxEpisodesModal}
        setIsOpen={setIsOpenMaxEpisodesModal}
        navigation={navigation}
      />
      {/** freemium no access to season 2 modal */}
      <NoAccessModal
        isOpen={showNoAccessModal}
        setIsOpen={setShowNoAccessModal}
        navigation={navigation}
      />
    </View>
  );
};

export default UpperSection;

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    position: "absolute",
  },
  episodeButton: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 120,
    height: 120,
    borderRadius: 60,
    borderWidth: 3,
  },
  playIconContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginTop: 10,
    padding: 10,

    alignItems: "center",
    justifyContent: "center",
  },
});
