import {
  ImageBackground,
  StyleSheet,
  ScrollView,
  View,
  Platform,
} from "react-native";
import React from "react";
import { colors } from "../styles/GlobalStyle";
import DimensionsHook from "../hooks/DimensionsHook";
import { BoldTxt, H1, H2 } from "../components/TextsComponents";
import { MaterialIcons } from "@expo/vector-icons";
import { PrimaryButton } from "../components/Buttons";
import { useTranslation } from "react-i18next";

const NoConnection = ({ navigation }) => {
  const { t } = useTranslation();
  const { height } = DimensionsHook();
  const submitHandler = () => {
    navigation.replace("Loader");
  };
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView
          style={{ flex: 1 }}
          showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          <View
            style={[
              styles.box,
              {
                minHeight: height,
              },
            ]}
          >
            <MaterialIcons
              name="wifi-off"
              size={100}
              color={colors.grayLabel}
            />
            <H1 style={{ textAlign: "center" }}>Oops !</H1>
            <H2 style={{ textAlign: "center" }}>{t("no_connexion.text1")}</H2>
            <BoldTxt style={{ textAlign: "center", marginVertical: 20 }}>
              {t("no_connexion.text2")}
            </BoldTxt>
            <PrimaryButton onPress={submitHandler}>
              {t("no_connexion.button")}
            </PrimaryButton>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default NoConnection;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  box: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 15,
  },
});
