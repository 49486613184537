import React, { useState, useEffect } from "react";
import { FlatList, Platform, View } from "react-native";
import SwiperItemMySpace from "../../../components/swiper/SwiperItemMySpace";
import axios from "axios";
import LoaderItem from "../../../components/swiper/LoaderItem";
import DimensionsHook from "../../../hooks/DimensionsHook";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { H7 } from "../../../components/TextsComponents";
import { PrimaryButton } from "../../../components/Buttons";
import { colors } from "../../../styles/GlobalStyle";

const FavorisSwiper = ({ type, navigation, showStateBar }) => {
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const userActivity = useSelector((state) => state.userActivity);
  const { isMobile, width } = DimensionsHook();
  const { t, i18n } = useTranslation();
  const getData = async () => {
    if (Data.length === 0) {
      setLoader(true);
    }
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const endpoint =
      type == "Trail"
        ? process.env.USER_FAVOURITE_TRAILS
        : process.env.USER_FAVOURITE_WORKSHOPS;
    const Response = await axios.get(
      endpoint + `?language=${i18n.language}`,
      config
    );

    setData(Response.data.slice(0, 4));
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const variableToRerender =
    type == "Trail"
      ? userActivity.favourite_trails
      : userActivity.favourite_workshops;
  useEffect(() => {
    getData();
  }, [variableToRerender]);

  // 3 Loader item to show
  if (loader) {
    // console.log("isDesktop", isDesktop);
    return width >= 1300 ? (
      // Check if the user is in the main screen
      <FlatList
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        horizontal={false}
        numColumns={2}
        style={{ paddingHorizontal: 10 }}
        data={[0, 1, 2]}
        renderItem={() => <LoaderItem SwiperItem={true} />}
        keyExtractor={(item) => item.toString()}
      />
    ) : (
      <FlatList
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        horizontal={true}
        numColumns={1}
        style={{ paddingHorizontal: isMobile ? 10 : 20 }}
        data={[0, 1]}
        keyExtractor={(item) => item.toString()}
        renderItem={() => <LoaderItem />}
      />
    );
  }

  if (Data.length === 0) {
    return (
      <View
        style={{
          height: width <= 790 ? 150 : 260,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <H7
          style={{
            color: colors.grayLabel,
          }}
        >
          {t("my_space.no_favorite")}
        </H7>
        <PrimaryButton
          onPress={() => {
            if (type == "Trail") {
              navigation.navigate("Trails");
            } else {
              navigation.navigate("Workshops");
            }
          }}
          style={{ marginTop: 20 }}
        >
          {t("my_space.button2")}
        </PrimaryButton>
      </View>
    );
  }

  // FLATLIST WITH DATA
  // add CONDITION FOR FLAT LIST WITH 2 COL

  if (width >= 1300 && Data) {
    return (
      <FlatList
        keyExtractor={(item) =>
          type == "Trail" ? item.trail_code : item.workshop_code
        }
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        horizontal={false}
        numColumns={2}
        style={{ height: "100%", width: "100%", paddingHorizontal: 10 }}
        data={Data}
        renderItem={(props) => (
          <SwiperItemMySpace
            {...props}
            type={type}
            navigation={navigation}
            SwiperItem={true}
            showStateBar={showStateBar === true ? true : false}
          />
        )}
      />
    );
  } else {
    return (
      <FlatList
        keyExtractor={(item) =>
          type == "Trail" ? item.trail_code : item.workshop_code
        }
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        horizontal={true}
        numColumns={1}
        style={{ width: "100%", paddingHorizontal: 10 }}
        data={Data.slice(0, 4)}
        renderItem={(props) => (
          <SwiperItemMySpace
            showStateBar={showStateBar === true ? true : false}
            {...props}
            type={type}
            navigation={navigation}
          />
        )}
      />
    );
  }
};

export default FavorisSwiper;
