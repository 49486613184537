import { colors } from "../styles/GlobalStyle";
import { Spinner as S } from "native-base";
// size = small || large
const Spinner = ({ color, size }) => {
  return (
    <S size={size == "large" ? "lg" : "sm"} color={color || colors.blue3} />
  );
};

export default Spinner;

{
  /**
<ActivityIndicator
    
      animating={true}
      color={color || colors.blue3}
      size={size || "small"}
    />
*/
}
