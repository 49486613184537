import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useState, useEffect } from "react";
import { TextInput } from "react-native-paper";
import { Input } from "../Inputs";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { PrimaryButton } from "../Buttons";
import { H1, H6, SmallBoldTxt, Txt } from "../TextsComponents";
import Spinner from "../Spinner";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { AntDesign } from "@expo/vector-icons";
import { useToast } from "native-base";
import { t } from "i18next";
import { PasswordIcon } from "../../assets/svg/MyAccountIcons";
import ChangeEmail from "./AccountDetails/ChangeEmail";
import ChangePhone from "./AccountDetails/ChangePhone";
import DeleteAccountModal from "../modals/DeleteAccountModal";
import { useSelector } from "react-redux";

const AccountDetailsScreen = ({ navigation, scrollToTop }) => {
  const { width } = DimensionsHook();
  const isDesktop = width > 1000;
  const { provider } = useSelector((state) => state.userData);

  // current password
  const [currentPassword, setCurrentPassword] = useState("");
  const [isCurrentPasswordSecure, setCurrentPasswordIsSecure] = useState(true);
  // new password
  const [newPassword, setNewPassword] = useState("");
  const [isNewPasswordSecure, setNewPasswordIsSecure] = useState(true);
  // confirm password
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmPasswordSecure, setConfirmPasswordIsSecure] = useState(true);
  // Loader and error message
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // delete account modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toast = useToast();

  const submitHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      setErrorMessage("");
      scrollToTop();

      if (currentPassword.length < 8) {
        setSubmitLoader(false);
        setErrorMessage(t("account_details_screen.error_password"));
        scrollToTop();
        return;
      }

      const passwordTest = currentPassword.length >= 8;

      if (passwordTest === false) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_password"));
        scrollToTop();
        return;
      }
      if (confirmPassword != newPassword) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_confirm"));
        scrollToTop();
        return;
      }
      // API CALL TO CHANGE PASSWORD
      setTimeout(async () => {
        setSubmitLoader(false);

        const TOKEN = await AsyncStorage.getItem("access_token");
        axios
          .post(process.env.USER_CHANGE_PASSWORD, {
            access_token: TOKEN,
            password: currentPassword,
            newPassword,
          })
          .then(async (res) => {
            setConfirmPassword("");
            setCurrentPassword("");
            setNewPassword("");

            scrollToTop();
            await toast.show({
              placement: isDesktop ? "top" : "bottom",
              render: () => {
                return (
                  <View style={styles.toastContainer}>
                    <Txt
                      style={{ paddingLeft: isDesktop ? 5 : 0 }}
                      color={colors.white}
                    >
                      {t("account_details_screen.success_change_password")}
                    </Txt>
                    <TouchableOpacity
                      style={{ paddingHorizontal: 5 }}
                      onPress={() => toast.closeAll()}
                    >
                      <AntDesign name="close" size={16} color={colors.white} />
                    </TouchableOpacity>
                  </View>
                );
              },
            });
            setSubmitLoader(false);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              setSubmitLoader(false);
              setErrorMessage(t("account_details_screen.error_wrong_password"));
              scrollToTop();
            } else {
              setErrorMessage(t("signin.error_connexion"));
              setSubmitLoader(false);
              scrollToTop();
            }
          });
      }, 1000);
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      setCurrentPassword("");
    });

    return unsubscribe;
  }, [navigation]);

  const hidePassword =
    provider === "google" || provider === "apple" ? true : false;

  return (
    <View style={{ marginBottom: 20 }}>
      <H1>{t("account_navigation.account")}</H1>
      <View style={[styles.divider]} />
      {/** error message */}

      <SmallBoldTxt
        style={{
          color: colors.red1,
          textAlign: "center",
          marginBottom: 10,
        }}
      >
        {errorMessage.length > 0 ? errorMessage : " "}
      </SmallBoldTxt>
      {/** change email */}
      <ChangeEmail
        scrollToTop={scrollToTop}
        setErrorMessage={setErrorMessage}
      />
      {/** phone number settings*/}
      <ChangePhone
        scrollToTop={scrollToTop}
        setErrorMessage={setErrorMessage}
      />
      {/** change password section */}
      {!hidePassword && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <PasswordIcon />
          <H6 style={{ marginBottom: -4, marginLeft: 5 }}>
            {t("account_details_screen.password")}
          </H6>
        </View>
      )}

      <View style={styles.container}>
        {/** hidden input to detect autocomplete */}
        <View style={{ display: "none" }}>
          <Input
            mode="outlined"
            disableAutoComplete
            secureTextEntry={true}
            value=""
            onChangeText={(val) => {}}
          />
        </View>
        {/** currentPassword */}
        {!hidePassword && (
          <View style={{ width: "100%" }}>
            <View style={{ width: isDesktop ? "49%" : "100%" }}>
              <Input
                mode="outlined"
                disableAutoComplete
                style={{ marginTop: 10 }}
                placeholder={t("account_details_screen.current_password")}
                secureTextEntry={isCurrentPasswordSecure}
                value={currentPassword}
                onChangeText={(val) => setCurrentPassword(val)}
                right={
                  isCurrentPasswordSecure ? (
                    <TextInput.Icon
                      forceTextInputFocus={false}
                      onPress={() =>
                        setCurrentPasswordIsSecure(!isCurrentPasswordSecure)
                      }
                      name={"eye"}
                      size={24}
                      color={colors.blue3}
                    />
                  ) : (
                    <TextInput.Icon
                      forceTextInputFocus={false}
                      onPress={() =>
                        setCurrentPasswordIsSecure(!isCurrentPasswordSecure)
                      }
                      name="eye-off"
                      size={24}
                      color={colors.blue3}
                    />
                  )
                }
              />
            </View>
            {/** newPassword */}
            <View style={{ width: isDesktop ? "49%" : "100%" }}>
              <Input
                mode="outlined"
                style={{ marginTop: 10 }}
                placeholder={t("account_details_screen.new_password")}
                secureTextEntry={isNewPasswordSecure}
                value={newPassword}
                onChangeText={(val) => setNewPassword(val)}
                right={
                  isNewPasswordSecure ? (
                    <TextInput.Icon
                      forceTextInputFocus={false}
                      onPress={() =>
                        setNewPasswordIsSecure(!isNewPasswordSecure)
                      }
                      name={"eye"}
                      size={24}
                      color={colors.blue3}
                    />
                  ) : (
                    <TextInput.Icon
                      forceTextInputFocus={false}
                      onPress={() =>
                        setNewPasswordIsSecure(!isNewPasswordSecure)
                      }
                      name="eye-off"
                      size={24}
                      color={colors.blue3}
                    />
                  )
                }
              />
            </View>
            {/** confirm password */}
            <View style={{ width: isDesktop ? "49%" : "100%" }}>
              <Input
                mode="outlined"
                style={{ marginTop: 10 }}
                placeholder={t("account_details_screen.confirm_password")}
                secureTextEntry={isConfirmPasswordSecure}
                value={confirmPassword}
                onChangeText={(val) => setConfirmPassword(val)}
                right={
                  isConfirmPasswordSecure ? (
                    <TextInput.Icon
                      forceTextInputFocus={false}
                      onPress={() =>
                        setConfirmPasswordIsSecure(!isConfirmPasswordSecure)
                      }
                      name={"eye"}
                      size={24}
                      color={colors.blue3}
                    />
                  ) : (
                    <TextInput.Icon
                      forceTextInputFocus={false}
                      onPress={() =>
                        setConfirmPasswordIsSecure(!isConfirmPasswordSecure)
                      }
                      name="eye-off"
                      size={24}
                      color={colors.blue3}
                    />
                  )
                }
              />
            </View>
          </View>
        )}
      </View>
      {/** button*/}
      {!hidePassword && (
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: isDesktop ? "flex-end" : "center",
            marginTop: 20,
          }}
        >
          <PrimaryButton width={200} onPress={submitHandler}>
            {submitLoader ? (
              <Spinner
                color={submitLoader ? colors.white : colors.blue3}
                size="small"
              />
            ) : (
              t("account_details_screen.submit_password")
            )}
          </PrimaryButton>
        </View>
      )}

      <View
        style={{
          flexDirection: "row",

          alignItems: "center",
          justifyContent: isDesktop ? "flex-end" : "center",
        }}
      >
        <PrimaryButton
          onPress={() => setShowDeleteModal(true)}
          style={{
            backgroundColor: colors.red1,
            width: isDesktop ? 300 : "100%",
            marginTop: 20,
            marginBottom: 40,
          }}
        >
          {t("account_details_screen.delete")}
        </PrimaryButton>
      </View>

      {/** delete modal */}
      <DeleteAccountModal
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        navigation={navigation}
      />
    </View>
  );
};

export default AccountDetailsScreen;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  divider: {
    height: 1,
    width: "100%",
    alignSelf: "center",
    backgroundColor: colors.grayBorder,
    marginVertical: 20,
    marginTop: 10,
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    borderRadius: 20,
    paddingHorizontal: 15,
    marginTop: 30,
  },
});
