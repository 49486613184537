import React, { useEffect, useState } from "react";
import { colors } from "../../styles/GlobalStyle";
import HeaderComponent from "../../components/HeaderComponent";
import { useTranslation } from "react-i18next";
import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import DimensionsHook from "../../hooks/DimensionsHook";
import { BoldTxt, H1, H2, H3, H5, Txt } from "../../components/TextsComponents";
import { useSelector } from "react-redux";
import CommunityConditionsModal from "../../components/modals/communityConditionsModal";
import { Entypo, MaterialCommunityIcons } from "@expo/vector-icons";
import Spinner from "../../components/Spinner";
import { PrimaryButton } from "../../components/Buttons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Image } from "native-base";
import { LinearGradient } from "expo-linear-gradient";
import SubscriptionRequiredModal from "../../components/modals/SubscriptionRequiredModal";

const CommunityGeneral = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const { isDesktop, isBigScreen } = DimensionsHook();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [showSubscriptionRequired, setShowSubscriptionRequired] =
    useState(false);
  const {
    community_visit,
    banned,
    subscription,
    lifetime_subscription,
    company,
  } = useSelector((state) => state.userData);
  // modal to sign "CHARTE DE MODÉRATION ET DE BONNE CONDUITE SUR LES COMMUNAUTÉS LISL UP"
  const [showModal, setShowModal] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const getCurrentTrails = async () => {
    setLoader(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.CURRENT_TRAILS}?language=${i18n.language}`, config)
      .then((res) => {
        setData(res.data);

        setTimeout(() => {
          setLoader(false);
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      // if freemium
      if (!subscription && !lifetime_subscription && !company) {
        setShowSubscriptionRequired(true);
      } else {
        // if user banned
        if (banned) {
          setIsBanned(true);
        } else {
          // if first visit
          if (community_visit) {
            // console.log("conditions accepted by user");
            getCurrentTrails();
          } else {
            setShowModal(true);
          }
        }
      }
    });
    return unsubscribe;
  }, [
    navigation,
    community_visit,
    banned,
    subscription,
    lifetime_subscription,
    company,
  ]);

  if (isBanned) {
    return (
      <View style={styles.container}>
        <ImageBackground
          source={require("../../assets/background.png")}
          style={{ flex: 1 }}
        >
          <HeaderComponent
            name={t("tab_navigation.community")}
            navigation={navigation}
          />
          <View
            style={{
              width: "100%",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Entypo name="block" size={100} color={colors.grayLabel} />

            <H2 style={{ textAlign: "center" }}>
              {t("community.banned_title")}
            </H2>
            <BoldTxt style={{ textAlign: "center", marginTop: 20 }}>
              {t("community.banned_text1")}
            </BoldTxt>
            <Txt
              style={{
                textAlign: "center",
                marginBottom: 20,
                marginTop: 10,
                marginHorizontal: 15,
              }}
            >
              {t("community.banned_text2")}
              {"\n"}contact@lislup.com .
            </Txt>
            <PrimaryButton onPress={() => navigation.goBack()}>
              {t("back")}
            </PrimaryButton>
          </View>
        </ImageBackground>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web"}>
          <HeaderComponent
            name={t("tab_navigation.community")}
            navigation={navigation}
          />

          {/** show content only if accept conditions */}
          {community_visit ? (
            <View
              style={{
                paddingTop: 10,
                paddingBottom: 30,
                marginHorizontal: isDesktop ? "2.5%" : 10,
              }}
            >
              <H3 style={{ marginTop: 10, marginBottom: 20 }}>
                {t("community_navigation.general")}
              </H3>

              <View
                style={{
                  flexDirection: isDesktop ? "row" : "column",
                  alignItems: isDesktop ? "flex-start" : "stretch",
                  flex: 1,
                  flexWrap: "wrap",
                }}
              >
                <View
                  style={[
                    styles.leftSection,
                    {
                      width: isBigScreen ? 400 : isDesktop ? 280 : "100%",
                      marginBottom: isDesktop ? 0 : 20,
                    },
                  ]}
                >
                  <View style={{ marginBottom: 15 }}>
                    <MaterialCommunityIcons
                      name="information-outline"
                      size={40}
                      color={colors.blue3}
                    />
                  </View>
                  {/** community tuto text */}
                  <Txt
                    fontSize={13}
                    color={colors.blue3}
                    style={{ textAlign: "center" }}
                  >
                    {t("community.tuto1")}
                  </Txt>
                  <Txt
                    color={colors.blue3}
                    fontSize={13}
                    style={{ textAlign: "center", marginTop: 15 }}
                  >
                    {t("community.tuto2")}
                  </Txt>
                  <Txt
                    color={colors.blue3}
                    fontSize={13}
                    style={{ textAlign: "center", marginTop: 15 }}
                  >
                    {t("community.tuto3")}
                  </Txt>
                  <Txt
                    color={colors.blue3}
                    fontSize={13}
                    style={{ textAlign: "center", marginTop: 15 }}
                  >
                    {t("community.tuto4")}
                  </Txt>
                </View>
                {loader ? (
                  <View
                    style={[
                      styles.loaderContainer,
                      {
                        marginLeft: isBigScreen ? 30 : isDesktop ? 20 : 0,
                      },
                    ]}
                  >
                    <Spinner size="large" />
                  </View>
                ) : // case no trails
                data.length == 0 ? (
                  <View
                    style={[
                      styles.loaderContainer,
                      {
                        marginLeft: isBigScreen ? 30 : isDesktop ? 20 : 0,
                      },
                    ]}
                  >
                    <Txt
                      color={colors.blue3}
                      fontSize={13}
                      style={{ textAlign: "center", marginTop: 15 }}
                    >
                      {t("community.text1")}
                    </Txt>
                    <Txt
                      color={colors.blue3}
                      fontSize={13}
                      style={{ textAlign: "center", marginTop: 5 }}
                    >
                      {t("community.text2")}
                    </Txt>
                    <PrimaryButton
                      style={{ marginTop: 15 }}
                      onPress={() => navigation.navigate("Trails")}
                    >
                      {t("community.button1")}
                    </PrimaryButton>
                  </View>
                ) : (
                  /** community list render */
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: colors.white,
                      marginLeft: isBigScreen ? 30 : isDesktop ? 20 : 0,
                      marginHorizontal: isDesktop ? 20 : 0,
                      borderRadius: 20,
                      paddingVertical: 20,
                      paddingHorizontal: isDesktop ? 20 : 10,
                    }}
                  >
                    <Txt>{t("community.text3")}</Txt>

                    <View
                      style={{
                        marginTop: 20,
                      }}
                    >
                      {data.map((trail) => {
                        return (
                          <View
                            style={styles.trailContainer}
                            key={trail.trail_code}
                          >
                            {/** title and button container */}
                            <View
                              style={{
                                width: "70%",
                                paddingVertical: 20,

                                paddingHorizontal: isDesktop ? 20 : 5,
                                justifyContent: "center",
                              }}
                            >
                              <H5 style={{ fontSize: isDesktop ? 22 : 15 }}>
                                {trail.title}
                              </H5>
                              <PrimaryButton
                                onPress={() =>
                                  navigation.navigate("Community", trail)
                                }
                                style={{
                                  width: isDesktop ? 160 : "90%",
                                  marginTop: 10,
                                }}
                              >
                                {t("community.button2")}
                              </PrimaryButton>
                            </View>
                            {/** img container */}
                            <View style={{ flex: 1 }}>
                              <Image
                                source={{ uri: trail.poster_link }}
                                style={{
                                  ...StyleSheet.absoluteFillObject,
                                  resizeMode: "cover",
                                }}
                              />
                              <LinearGradient
                                colors={["#1B496500", "#1B496566"]}
                                style={{
                                  ...StyleSheet.absoluteFillObject,
                                  position: "absolute",
                                }}
                              />
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                )}
              </View>
            </View>
          ) : (
            <></>
          )}
        </ScrollView>
        {/** need subscription modal */}
        <SubscriptionRequiredModal
          fromCommunity
          isOpen={showSubscriptionRequired}
          setIsOpen={setShowSubscriptionRequired}
          navigation={navigation}
        />
        {/** charte modal to accept if first visit  */}
        <CommunityConditionsModal
          navigation={navigation}
          isOpen={showModal}
          setIsOpen={setShowModal}
          getCurrentTrails={getCurrentTrails}
        />
      </ImageBackground>
    </View>
  );
};

export default CommunityGeneral;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },

  loaderContainer: {
    flex: 1,
    alignSelf: "stretch",
    alignItems: "center",
    minHeight: 200,
    justifyContent: "center",
    padding: 20,
    backgroundColor: "#ffffff80",
    borderRadius: 20,
  },
  leftSection: {
    backgroundColor: "#ffffff80",
    padding: 20,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  trailContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: colors.beige,
    borderRadius: 20,
    overflow: "hidden",
    marginBottom: 10,
    alignItems: "stretch",
  },
});
