import { StyleSheet, View, Platform, Linking } from "react-native";
import React, { useState, useEffect } from "react";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { H1, H2, Txt } from "../../TextsComponents";
import { colors } from "../../../styles/GlobalStyle";
import { PrimaryButton } from "../../Buttons";
import axios from "axios";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";
import { useTranslation } from "react-i18next";
import NumberOfTrails from "./NumberOfTrails";
import InvoiceItem from "./InvoiceItem";

const LifePlan = ({ navigation, lifetimePlan }) => {
  const { width } = DimensionsHook();
  const isDesktop = width > 1000;
  const [loaderInvoice, setLoaderInvoice] = useState(false);
  const { lifetime_payment, customer, lifetime_gifted } = useSelector(
    (state) => state.userData
  );
  const { t, i18n } = useTranslation();
  const downloadInvoiceLifeTimePlanHandler = async () => {
    if (!loaderInvoice) {
      const url = process.env.PAYMENT_INTENT_ENDPOINT;

      setLoaderInvoice(true);
      axios
        .get(`${url}?paymentId=${lifetime_payment}`)
        .then((res) => {
          setTimeout(() => {
            const invoiceUrl = res.data.charges.data[0].receipt_url;
            if (Platform.OS == "web") {
              setLoaderInvoice(false);
              window.open(invoiceUrl, "_blank");
            } else {
              setLoaderInvoice(false);
              Linking.openURL(invoiceUrl);
            }
          }, 500);
        })
        .catch((err) => setLoaderInvoice(false));
    }
  };

  const [loader, setLoader] = useState(true);
  // data = invoices
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoader(true);
    const invoiceUrl = process.env.USER_INVOICES;
    axios.get(`${invoiceUrl}?customerId=${customer}`).then((InvoicesRes) => {
      setData(InvoicesRes.data);
      /*   console.log("invoices :", res.data);*/

      setTimeout(() => {
        setLoader(false);
      }, 2000);
    });
  }, []);

  return (
    <View>
      <View
        style={{
          marginTop: isDesktop ? 0 : 20,
        }}
      >
        <H1>
          {t("plans.your_subscription")} -{" "}
          {i18n.language == "fr"
            ? lifetimePlan.title_fr
            : lifetimePlan.title_en}
        </H1>

        <Txt style={{ marginVertical: 15 }}>
          {i18n.language == "fr"
            ? lifetimePlan.description_fr
            : lifetimePlan.description_en}
        </Txt>
      </View>
      {/** buttons */}
      {lifetime_gifted ? (
        <></>
      ) : (
        <View
          style={{
            width: "100%",
            flexDirection: isDesktop ? "row-reverse" : "column",
            alignItems: isDesktop ? "center" : "center",
            justifyContent: isDesktop ? "flex-start" : "center",
            marginTop: 10,
          }}
        >
          {/** download lifetime invoice */}
          {lifetime_payment !== "sub_lifetime" && (
            <PrimaryButton
              onPress={downloadInvoiceLifeTimePlanHandler}
              style={{ width: !isDesktop ? "100%" : 250 }}
            >
              {loaderInvoice ? (
                <Spinner color={colors.white} />
              ) : (
                t("plans.download_invoice")
              )}
            </PrimaryButton>
          )}
        </View>
      )}

      <View style={styles.divider} />
      {/** num of allowed trails */}
      <NumberOfTrails navigation={navigation} />
      {/** historique factures */}
      {data.length > 0 && (
        <View style={{ marginTop: 20 }}>
          <H2>{t("plans.invoices")}</H2>
          {data.map((invoice) => {
            return <InvoiceItem key={invoice._id} invoice={invoice} />;
          })}
        </View>
      )}
    </View>
  );
};

export default LifePlan;

const styles = StyleSheet.create({
  badge: {
    backgroundColor: colors.green2,
    paddingHorizontal: 15,
    borderRadius: 100,
    paddingVertical: 5,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginTop: 20,
  },
});
