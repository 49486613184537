import { StyleSheet, View, TouchableOpacity } from "react-native";
import React, { useEffect, useState } from "react";
import { SmallArrow } from "../../../assets/svg/Icons";
import { Txt, H2 } from "../../TextsComponents";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { QuestionDropDown } from "../../Inputs";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import LoaderEffect from "../../LoaderEffect";
import { colors } from "../../../styles/GlobalStyle";
import { PrimaryButton } from "../../Buttons";

const HelpList = ({ setState, categorieList, category, navigation }) => {
  const { isDesktop } = DimensionsHook();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const getData = async () => {
    setLoader(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = process.env.FAQ_ENDPOINT;
    axios
      .get(
        `${url}?language=${i18n.language}&category=${category.value}`,
        config
      )
      .then((res) => {
        setData(res.data);
        setLoader(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 1000);
  }, [category]);

  //all subcategory options (except if trail name)
  const options = [
    { label: t("contact_screen.sub_objects.billing"), value: "billing" },
    { label: t("contact_screen.sub_objects.payment"), value: "payment" },
    {
      label: t("contact_screen.sub_objects.dysfunction"),
      value: "dysfunction",
    },
    { label: t("contact_screen.sub_objects.suggestion"), value: "suggestion" },
    { label: t("placeholders.all_trails"), value: "all_trails" },
    {
      label: t("workshop_types.label"),
      value: "all_workshops",
    },
    {
      label: t("workshop_types.conscience"),
      value: "conscience",
    },
    {
      label: t("workshop_types.corps"),
      value: "body",
    },
    {
      label: t("workshop_types.plaisir"),
      value: "fun",
    },
    {
      label: t("workshop_types.rituels"),
      value: "rituals",
    },
    {
      label: t("workshop_types.science"),
      value: "science",
    },
    {
      label: t("workshop_types.temoignages"),
      value: "testimony",
    },
    {
      label: t("workshop_types.outils"),
      value: "tools",
    },
    {
      label: t("workshop_types.coaching"),
      value: "coaching",
    },
  ];

  const getSubCategoryValue = (val) => {
    const result = options.find((option) => option.value == val);
    if (result) {
      return result.label;
    } else {
      return val;
    }
  };

  return (
    <View style={{ marginTop: isDesktop ? 0 : 20 }}>
      <TouchableOpacity
        style={{
          flexDirection: "row",
          alignItems: "center",
          position: isDesktop ? "absolute" : "relative",
          top: isDesktop ? -65 : 0,
        }}
        onPress={() => setState(categorieList[0])}
      >
        <SmallArrow />

        <Txt
          style={{
            marginLeft: 8,
          }}
        >
          {t("help_screen.back")}
        </Txt>
      </TouchableOpacity>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: isDesktop ? 0 : 20,
        }}
      >
        <View style={{ width: 24, height: 24, marginRight: 5, marginTop: -5 }}>
          {category.icon(colors.blue3)}
        </View>
        <H2>{category.label}</H2>
      </View>

      {/** use question dropdown */}
      {loader ? (
        <View>
          {["0", "1", "2"].map((i) => {
            return (
              <View key={i} style={styles.loaderContainer}>
                <LoaderEffect width="100%" height={100} />
              </View>
            );
          })}
        </View>
      ) : (
        <View>
          {data.map((faq) => {
            const val = faq.subCategory || "";
            const subCategory = getSubCategoryValue(val);
            return (
              <QuestionDropDown
                key={faq._id}
                question={faq.question}
                subCategory={subCategory}
                answer={faq.answer}
                height={100}
              />
            );
          })}
          {/** text to contact */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Txt style={{ marginVertical: 20 }}>{t("help_screen.text1")}</Txt>
            <PrimaryButton
              onPress={() => navigation.navigate("AccountContact")}
              style={{ marginVertical: 20 }}
            >
              {t("help_screen.contact")}
            </PrimaryButton>
          </View>
        </View>
      )}
    </View>
  );
};

export default HelpList;

const styles = StyleSheet.create({
  loaderContainer: {
    width: "100%",
    marginTop: 20,
    borderRadius: 10,
    overflow: "hidden",
  },
});
