import {
  ImageBackground,
  StyleSheet,
  ScrollView,
  View,
  Platform,
  Image,
} from "react-native";
import React, { useState } from "react";
import { colors } from "../../styles/GlobalStyle";
import { PrimaryButton } from "../../components/Buttons";
import DimensionsHook from "../../hooks/DimensionsHook";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { resetUser } from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ValidationEmail from "./ValidationEmail";
import { H2, SmallBoldTxt, SmallTxt } from "../../components/TextsComponents";
import { FontAwesome5 } from "@expo/vector-icons";
import ValidationPhone from "./ValidationPhone";
import Spinner from "../../components/Spinner";
import axios from "axios";

const Validation = ({ navigation }) => {
  const { isDesktop } = DimensionsHook();
  const { t } = useTranslation();
  const [sendEmailLoader, setSendEmailLoader] = useState(false);
  const [validationMethod, setValidationMethod] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [sendSmsLoader, setSendSmsLoader] = useState(false);
  const { phoneCode, phoneNumber, email, company } = useSelector(
    (state) => state.userData
  );
  const phone = phoneCode.replace("+", "") + phoneNumber;
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    await AsyncStorage.removeItem("access_token");
    dispatch(resetUser());

    navigation.reset({
      index: 0,
      routes: [{ name: "Loader" }],
    });
  };

  const sendVerificationEmail = async () => {
    if (!sendEmailLoader || !sendSmsLoader) {
      setSendEmailLoader(true);
      setErrorMessage("");

      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.USER_SEND_CODE, {
          access_token: TOKEN,
        })
        .then(() => {
          setSendEmailLoader(false);
          setValidationMethod("email");
        })
        .catch((err) => {
          setSendEmailLoader(false);
          setErrorMessage(t("validation.error_connexion"));
        });
    }
  };

  const sendVerificationSms = async () => {
    if (!sendSmsLoader || !sendEmailLoader) {
      setSendSmsLoader(true);
      setErrorMessage("");

      const TOKEN = await AsyncStorage.getItem("access_token");

      axios
        .post(process.env.USER_SEND_VALIDATE_SMS, {
          access_token: TOKEN,
        })
        .then(() => {
          setTimeout(() => {
            setSendSmsLoader(false);
            setValidationMethod("phone");
          }, 1000);
        })
        .catch((err) => {
          setSendSmsLoader(false);
          setErrorMessage(t("validation.error_connexion"));
        });
    }
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView
          style={{ flex: 1 }}
          showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          <View
            style={{
              marginHorizontal: "2.5%",

              marginBottom: 40,
            }}
          >
            {/** logout handler */}
            <View style={{ alignSelf: "flex-end", marginVertical: 20 }}>
              <PrimaryButton onPress={logoutHandler}>
                <MaterialCommunityIcons
                  name="logout"
                  size={24}
                  color={colors.white}
                />
              </PrimaryButton>
            </View>

            <View style={{ width: "100%", maxWidth: 700, alignSelf: "center" }}>
              {/* choose validation method */}
              {validationMethod == null ? (
                <View>
                  <H2 style={{ textAlign: "center" }}>
                    Choisir une méthode de validation du compte
                  </H2>
                  {/** validation via email */}
                  <SmallBoldTxt
                    style={{
                      color: colors.red1,
                      textAlign: "center",
                      marginTop: 10,
                      marginBottom: -10,
                    }}
                  >
                    {errorMessage.length > 0 ? errorMessage : " "}
                  </SmallBoldTxt>
                  <View style={styles.choiceContainer}>
                    <View style={styles.row}>
                      <MaterialCommunityIcons
                        name="email-fast"
                        size={30}
                        color={colors.blue3}
                      />

                      <View style={styles.textContainer}>
                        <SmallBoldTxt
                          style={{ fontSize: 15, marginVertical: 5 }}
                        >
                          {t("validation.mail_title")}
                        </SmallBoldTxt>
                        <SmallTxt>
                          {t("validation.mail_text")}{" "}
                          <SmallBoldTxt>{email}</SmallBoldTxt>
                        </SmallTxt>
                      </View>
                    </View>
                    <View style={styles.buttonContainer}>
                      <PrimaryButton
                        width={200}
                        onPress={sendVerificationEmail}
                      >
                        {sendEmailLoader ? (
                          <Spinner
                            color={
                              sendEmailLoader ? colors.white : colors.blue3
                            }
                            size="small"
                          />
                        ) : (
                          t("validation.mail_button")
                        )}
                      </PrimaryButton>
                    </View>
                  </View>
                  {/** validation via sms */}
                  {phone.length > 5 && !company && (
                    <View style={styles.choiceContainer}>
                      <View style={styles.row}>
                        <FontAwesome5
                          name="sms"
                          size={30}
                          color={colors.blue3}
                        />
                        <View style={styles.textContainer}>
                          <SmallBoldTxt
                            style={{ fontSize: 15, marginVertical: 5 }}
                          >
                            {t("validation.sms_title")}
                          </SmallBoldTxt>
                          <SmallTxt>
                            {t("validation.sms_text")}{" "}
                            <SmallBoldTxt>
                              {phoneCode + phoneNumber}
                            </SmallBoldTxt>
                          </SmallTxt>
                        </View>
                      </View>
                      <View style={styles.buttonContainer}>
                        <PrimaryButton
                          width={200}
                          onPress={sendVerificationSms}
                        >
                          {sendSmsLoader ? (
                            <Spinner
                              color={
                                sendSmsLoader ? colors.white : colors.blue3
                              }
                              size="small"
                            />
                          ) : (
                            t("validation.sms_button")
                          )}
                        </PrimaryButton>
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <View>
                  {/** cases email || phone */}
                  {validationMethod == "email" ? (
                    <ValidationEmail
                      navigation={navigation}
                      setValidationMethod={setValidationMethod}
                    />
                  ) : (
                    <ValidationPhone
                      navigation={navigation}
                      setValidationMethod={setValidationMethod}
                    />
                  )}
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
      {isDesktop && (
        <Image
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
          }}
          style={styles.image}
        />
      )}
    </View>
  );
};

export default Validation;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  image: {
    position: "absolute",
    bottom: 0,
    right: 20,
    height: 230,
    width: 140,
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 20,
  },

  choiceContainer: {
    padding: 20,
    borderRadius: 20,
    width: "100%",
    backgroundColor: colors.white,
    marginTop: 30,
  },

  row: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  textContainer: { marginLeft: 10, width: "100%" },
  buttonContainer: { flexDirection: "row-reverse", marginTop: 20 },
});
