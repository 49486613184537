import { View, StyleSheet, ScrollView, ImageBackground } from "react-native";
import React from "react";
import { Txt, H6 } from "../components/TextsComponents";
import BackHeader from "../components/BackHeader";
import { colors } from "../styles/GlobalStyle";
import DimensionsHook from "../hooks/DimensionsHook";
import { SecondaryButton } from "../components/Buttons";
import { CheckCircle } from "../assets/svg/space";
import { useTranslation } from "react-i18next";

const Message = ({ navigation }) => {
  const { t } = useTranslation();
  const width = DimensionsHook();
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <View style={{ flex: 1 }}>
          <BackHeader
            isOpen={false}
            setIsOpen={() => {}}
            navigation={navigation}
          />
          <ScrollView>
            <View style={styles.section}>
              <View style={styles.image}>
                <CheckCircle />
              </View>
              <View style={styles.Text}>
                {true && (
                  <>
                    <H6 style={{ textAlign: "center" }}>
                      {t("message.text1")}
                    </H6>
                    <Txt style={{ textAlign: "center", marginTop: 20 }}>
                      {t("message.text2")}
                    </Txt>
                  </>
                )}
              </View>

              <SecondaryButton
                onPress={() => {
                  //  navigation.goBack()
                  navigation.navigate("Coaching");
                }}
                style={{
                  width: width > 800 ? "22%" : "48%",
                  marginVertical: 40,
                }}
              >
                {t("message.button")}
              </SecondaryButton>
            </View>
          </ScrollView>
        </View>
      </ImageBackground>
    </View>
  );
};

export default Message;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  image: {
    width: 60,
    height: 60,
  },
  section: {
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    position: "relative",
    width: "95%",
    maxWidth: 800,
  },
  Text: {
    marginTop: 30,
  },
  Image: {
    height: 304,
    width: 184,
    resizeMode: "contain",
    position: "absolute",
    right: 10,
    bottom: 61,
  },
});
