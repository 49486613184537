import { StyleSheet, View, TouchableWithoutFeedback } from "react-native";
import React, { useEffect, useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import { BoldTxt, H1, LightTxt, Txt } from "../TextsComponents";
import { DropDown } from "../Inputs";
import { colors } from "../../styles/GlobalStyle";
import { PrimaryButton } from "../Buttons";
import { useTranslation } from "react-i18next";
import GreenModal from "../modals/GreenModal";
import Constants from "expo-constants";
import { Switch } from "native-base";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch, useSelector } from "react-redux";
import { toggleNotification } from "../../redux/Actions";

const SettingsScreen = ({ navigation }) => {
  const version = Constants.manifest.version;

  const { notification } = useSelector((state) => state.userData);
  const { isDesktop, isMobile } = DimensionsHook();
  const [isOpenGreenModal, setIsOpenGreenModal] = useState(false);
  const [acceptEmailNotification, setAcceptEmailNotification] =
    useState(notification);
  const [toggleLoader, setToggleLoader] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languages = [
    { label: "Français", value: "fr" },
    { label: "English", value: "en" },
  ];

  const [language, setLanguage] = useState(
    languages.find((lng) => lng.value === i18n.language)
  );
  const [showLanguage, setShowLanguage] = useState(false);

  const logoutHandler = () => {
    setIsOpenGreenModal(true);
  };

  const toggleEmailNotification = async () => {
    if (!toggleLoader) {
      setToggleLoader(true);
      setAcceptEmailNotification(!acceptEmailNotification);

      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .put(process.env.USER_UPDATE, {
          access_token: TOKEN,
          notification: !acceptEmailNotification,
        })
        .then((res) => {
          dispatch(toggleNotification(!acceptEmailNotification));
          setToggleLoader(false);
        })
        .catch((err) => {
          setToggleLoader(false);
          console.log(err);
        });
    }
  };

  return (
    <TouchableWithoutFeedback onPress={() => setShowLanguage(false)}>
      <View style={{ marginTop: isDesktop ? 0 : 20, flex: 1 }}>
        <H1>{t("setting_screen.settings")}</H1>
        {/** language */}
        <View
          style={{
            marginVertical: 20,
            marginBottom: 30,
            flexDirection: !isMobile ? "row" : "column",
            alignItems: !isMobile ? "center" : "flex-start",
            justifyContent: !isMobile ? "space-between" : "flex-start",
          }}
        >
          <BoldTxt style={{ marginBottom: isMobile ? 20 : 0, fontSize: 15 }}>
            {t("setting_screen.your_language")}
          </BoldTxt>
          <View
            style={{
              width: isMobile ? "90%" : 300,
              alignSelf: "center",
            }}
          >
            <DropDown
              show={showLanguage}
              language={true}
              setting={true}
              navigation={navigation}
              setShow={() => setShowLanguage(!showLanguage)}
              value={language}
              setValue={setLanguage}
              options={languages}
              placeholder={language}
              height={64}
              smallLabel={t("setting_screen.language")}
            />
          </View>
        </View>
        <View style={{ zIndex: -1 }}>
          <View style={styles.divider} />
          {/** logout */}
          <View
            style={{
              marginVertical: 20,
              flexDirection: !isMobile ? "row" : "column",
              alignItems: !isMobile ? "center" : "flex-start",
              justifyContent: !isMobile ? "space-between" : "flex-start",
            }}
          >
            <BoldTxt
              style={{
                marginBottom: isMobile ? 20 : 0,
                marginRight: 10,
                fontSize: 15,
              }}
            >
              {t("setting_screen.logout")}
            </BoldTxt>
            <PrimaryButton
              whiteText
              style={{ backgroundColor: colors.red1 }}
              onPress={logoutHandler}
            >
              {t("setting_screen.logout_button")}
            </PrimaryButton>
          </View>
          <View style={styles.divider} />
          {/** notification  */}
          <View
            style={{
              marginVertical: 20,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <BoldTxt
              style={{
                flex: 1,

                fontSize: 15,
              }}
            >
              {t("setting_screen.notification_mail")}
            </BoldTxt>
            <Switch
              style={{ marginLeft: 15 }}
              isChecked={acceptEmailNotification}
              onToggle={toggleEmailNotification}
              w="10%"
              size="lg"
              offTrackColor={colors.grayLabel}
              onTrackColor={colors.green2}
              onThumbColor={colors.green3}
              offThumbColor={colors.green0}
            />
          </View>
          <View>
            <Txt style={{ fontSize: 15 }}>
              {t("setting_screen.notification_mail1")}
            </Txt>
            <Txt style={{ fontSize: 15, marginTop: 10 }}>
              {t("setting_screen.notification_mail2")}
            </Txt>
            <Txt style={{ fontSize: 15, marginTop: 10 }}>
              {t("setting_screen.notification_mail3")}
            </Txt>
            <Txt style={{ fontSize: 15, marginTop: 5 }}>
              {" • "}
              {t("setting_screen.notification_mail4")}
            </Txt>
            <Txt style={{ fontSize: 15 }}>
              {" • "}
              {t("setting_screen.notification_mail5")}
            </Txt>
            <Txt style={{ fontSize: 15, marginTop: 10 }}>
              {t("setting_screen.notification_mail6")}
            </Txt>
          </View>
        </View>

        <GreenModal
          logout={true}
          navigation={navigation}
          isOpen={isOpenGreenModal}
          setIsOpen={setIsOpenGreenModal}
          title={t("modals.title2")}
          text={t("modals.text4")}
        />
        <View
          style={{
            alignSelf: "center",
            marginBottom: isMobile ? 10 : 20,
            marginTop: 40,
          }}
        >
          <Txt style={{ fontSize: 14, color: colors.grayLabel }}>
            LISL UP - Version {version}
          </Txt>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default SettingsScreen;

const styles = StyleSheet.create({
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginBottom: 20,
  },
});
