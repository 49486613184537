import React, { useState, useEffect, useRef } from "react";
import { CheckBox } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { TextInput } from "react-native-paper";
import { colors } from "../styles/GlobalStyle";
import { TextInput as ReactInput } from "react-native";
import { Select } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import OutsidePressHandler from "react-native-outside-press";

import {
  Pressable,
  View,
  Platform,
  StyleSheet,
  Animated,
  Easing,
  ScrollView,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { BoldTxt, SmallLightTxt, Txt } from "./TextsComponents";
import { TouchableRipple } from "react-native-paper";
import { PresenceTransition } from "native-base";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export const Input = ({
  placeholder,
  outlineColor,
  activeOutlineColor,
  placeholderTextColor,
  style,
  value,
  onChangeText,
  secureTextEntry,
  right,
  smallLabel,
  disabled,
  multiline,
  numberOfLines,
  big,
  onBlur,
  onFocus,
  error,
  textContentType,
  kayboardType,
  bg,
  onSubmitEditing,
}) => {
  return (
    <View>
      <TextInput
        onSubmitEditing={onSubmitEditing || null}
        onFocus={onFocus || null}
        textContentType={textContentType || "none"}
        onBlur={onBlur || null}
        multiline={multiline || false}
        numberOfLines={numberOfLines || 1}
        right={right || null}
        disabled={disabled || false}
        placeholder={placeholder}
        value={value}
        keyboardType={kayboardType || "web-search"}
        secureTextEntry={secureTextEntry || false}
        onChangeText={(val) => onChangeText(val)}
        mode="outlined"
        outlineColor={outlineColor || colors.grayBorder}
        activeOutlineColor={activeOutlineColor || colors.green2}
        error={error}
        placeholderTextColor={placeholderTextColor || colors.grayBorder}
        theme={{
          colors: { text: colors.blue3 },
        }}
        style={{
          backgroundColor: bg ? bg : "#fff",
          justifyContent: big ? "flex-end" : "center",
          color: colors.blue3,
          fontSize: 16,
          paddingTop: smallLabel ? 8 : 0,
          ...style,
        }}
      />
      {smallLabel ? (
        <Txt
          style={{
            position: "absolute",
            top: 12,
            left: 15,
            fontSize: 12,
          }}
          color={colors.grayLabel}
        >
          {smallLabel}
        </Txt>
      ) : null}
    </View>
  );
};

export const NumericInput = ({
  placeholder,
  outlineColor,
  activeOutlineColor,
  placeholderTextColor,
  style,
  value,
  setValue,
  secureTextEntry,
  right,
  smallLabel,
  code,
  multiline,
  disabled,
  numberOfLines,
  maxLength,

  big,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <View style={{ marginTop: big ? 5 : 0 }}>
      <ReactInput
        onFocus={() => !disabled && setIsFocused(true)}
        onBlur={() => !disabled && setIsFocused(false)}
        maxLength={maxLength || 100}
        multiline={multiline || false}
        keyboardType="web-search"
        numberOfLines={numberOfLines || 1}
        right={right || null}
        editable={!disabled || false}
        autoComplete={Platform.OS === "web" ? "none" : "off"}
        placeholder={placeholder}
        value={value}
        secureTextEntry={secureTextEntry || false}
        onChangeText={(val) => setValue(val.replace(/[^0-9]/g, ""))}
        placeholderTextColor={placeholderTextColor || colors.grayBorder}
        style={[
          {
            backgroundColor: "#fff",
            justifyContent: "center",
            color: disabled ? colors.grayBorder : colors.blue3,
            paddingTop: smallLabel ? 8 : 0,
            height: big ? 65 : 58,
            borderWidth: isFocused ? 2 : 1,
            borderRadius: 5,
            paddingLeft: code ? 0 : 15,
            opacity: disabled ? 0.95 : 1,

            borderColor: isFocused
              ? activeOutlineColor || colors.green2
              : outlineColor || colors.grayBorder,
            fontFamily: "OxygenRegular",
            fontSize: 16,
            ...style,
          },
          Platform.OS === "web" ? { outline: "none" } : {},
        ]}
      />
      {smallLabel ? (
        <Txt
          style={{
            position: "absolute",
            top: 8,
            left: 15,
            fontSize: 12,
          }}
          color={colors.grayLabel}
        >
          {smallLabel}
        </Txt>
      ) : null}
    </View>
  );
};

export const RadioInput = ({ onPress, checked, title, disabled }) => {
  return disabled ? (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Ionicons
        name="ios-radio-button-off-outline"
        size={24}
        color={colors.grayBorder}
      />
      <Txt color={colors.grayBorder}>{title}</Txt>
    </View>
  ) : (
    <CheckBox
      checked={checked}
      title={title || <></>}
      containerStyle={{
        alignSelf: "flex-start",
        margin: 0,
        padding: 0,
        backgroundColor: "transparent",
        borderWidth: 0,
      }}
      checkedIcon={
        <Ionicons name="checkmark-circle" size={24} color={colors.green2} />
      }
      uncheckedIcon={
        <Ionicons
          name="ios-radio-button-off-outline"
          size={24}
          color={colors.grayBorder}
        />
      }
      onPress={onPress}
    />
  );
};

export const SearchComponent = ({
  value,
  setValue,
  style,
  outlineColor,
  activeOutlineColor,
  placeholderTextColor,
  searchHandler,
}) => {
  const resetHandler = () => {
    setValue("");
    searchHandler("");
  };
  const { t } = useTranslation();
  return (
    <TextInput
      placeholder={t("placeholders.search")}
      mode="outlined"
      autoComplete={Platform.OS === "web" ? "none" : "off"}
      outlineColor={outlineColor || colors.grayBorder}
      activeOutlineColor={activeOutlineColor || colors.green2}
      placeholderTextColor={placeholderTextColor || colors.grayBorder}
      onSubmitEditing={() => searchHandler(value)}
      value={value}
      style={{
        backgroundColor: "#fff",
        justifyContent: "center",
        color: colors.blue3,
        ...style,
      }}
      theme={{ colors: { text: colors.blue3 } }}
      right={
        <TextInput.Icon
          onPress={() => searchHandler(value)}
          name={() => <Ionicons name={"search"} size={24} />}
        />
      }
      left={
        value.length > 0 ? (
          <TextInput.Icon
            onPress={resetHandler}
            name={() => <Ionicons name={"close"} size={24} />}
          />
        ) : null
      }
      onChangeText={(val) => setValue(val)}
    />
  );
};

export const DropDown = ({
  show,
  setShow,
  value,
  setValue,
  options,
  height,
  placeholder,
  smallLabel,
  language,
  setting,
  navigation,
  disableFirstOption,
  setSelectedSeason,
  seasons,
  seasonDropDown,
  transparent,
}) => {
  const animation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animation, {
      toValue: show ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start();
  }, [show]);

  const onSelectOption = (option) => {
    setValue(option);
    setShow(false);
    if (seasonDropDown) {
      setSelectedSeason(seasons[option.value]);
    }
    if (language) {
      i18n.changeLanguage(option.value);
      AsyncStorage.setItem("lng", option.value);
    }
    if (setting) {
      navigation.reset({
        index: 0,
        routes: [{ name: "TabNavigation" }],
      });
    }
  };
  if (Platform.OS === "web") {
    return (
      <View style={{ width: "100%", position: "relative", zIndex: 1000 }}>
        {/*<OutsidePressHandler onOutsidePress={setShow}>*/}
        <Pressable
          onPress={setShow}
          style={[
            styles.button,
            {
              backgroundColor: transparent ? "transparent" : colors.white,
              borderColor: show ? colors.green2 : colors.grayBorder,
              borderBottomLeftRadius: show ? 0 : 5,
              borderBottomRightRadius: show ? 0 : 5,
              alignItems: smallLabel ? "flex-end" : "center",
              paddingBottom: smallLabel ? 11 : 0,
              height,
            },
          ]}
        >
          {smallLabel && (
            <Txt
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                fontSize: 12,
              }}
              color={colors.grayLabel}
            >
              {smallLabel}
            </Txt>
          )}
          <Txt
            color={
              value.value === "" && disableFirstOption
                ? colors.grayBorder
                : colors.blue3
            }
            numberOfLines={1}
            fontSize={14}
          >
            {value === "" ? placeholder : value.label}
          </Txt>
          <Animated.View
            style={{
              transform: [
                {
                  rotate: animation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["0deg", "180deg"],
                  }),
                },
              ],
            }}
          >
            <FontAwesome5
              name="chevron-down"
              size={14}
              color={colors.blue3}
              style={{ marginTop: 3 }}
            />
          </Animated.View>
        </Pressable>
        {/*</OutsidePressHandler>*/}
        {/** options */}

        <PresenceTransition
          visible={show}
          initial={{
            translateY: -5,
            opacity: 0,
          }}
          animate={{
            translateY: 0,
            opacity: 1,
            transition: {
              type: "timing",
              duration: 300,
              useNativeDriver: Platform.OS === "web" ? false : true,
            },
          }}
          style={[
            styles.optionsContainer,
            {
              backgroundColor: transparent ? "transparent" : colors.white,
              top: height,
            },
          ]}
        >
          <ScrollView nestedScrollEnabled={true}>
            {options.map((option) => {
              return (
                <TouchableRipple
                  disabled={disableFirstOption && option.value === ""}
                  rippleColor={colors.green3}
                  onPress={() => onSelectOption(option)}
                  key={option.value}
                  style={[
                    styles.optionButton,
                    {
                      backgroundColor:
                        option.value == value.value
                          ? colors.beige
                          : transparent
                          ? "transparent"
                          : colors.white,
                    },
                  ]}
                >
                  <Txt
                    fontSize={14}
                    color={
                      disableFirstOption && option.value === ""
                        ? colors.grayLabel
                        : colors.blue3
                    }
                    numberOfLines={1}
                  >
                    {option.label}
                  </Txt>
                </TouchableRipple>
              );
            })}
          </ScrollView>
        </PresenceTransition>
      </View>
    );
  }
  return (
    <View style={{ width: "100%" }}>
      <Select
        selectedValue={value.value}
        placeholder={value.label}
        borderWidth={1}
        color={
          value.value === "" && disableFirstOption
            ? colors.grayBorder
            : colors.blue3
        }
        dropdownIcon={
          <FontAwesome5
            name="chevron-down"
            size={14}
            color={colors.blue3}
            style={{ marginRight: 10 }}
          />
        }
        borderColor={colors.grayBorder}
        backgroundColor={colors.white}
        fontFamily="OxygenRegular"
        fontSize={15}
        _selectedItem={{
          bg: colors.green2,
          _text: { color: colors.white, fontFamily: "OxygenRegular" },
        }}
        height={`${height}px`}
        onValueChange={(itemValue) => {
          const selected = options.find((option) => option.value === itemValue);
          setValue(selected);
          if (seasonDropDown) {
            setSelectedSeason(seasons[selected.value]);
          }
          if (language) {
            i18n.changeLanguage(selected.value);
            AsyncStorage.setItem("lng", selected.value);
          }
        }}
      >
        {options.map((option) => {
          return (
            <Select.Item
              disabled={disableFirstOption && option.value === ""}
              key={option.value}
              _text={{
                fontFamily: "OxygenRegular",
                color:
                  disableFirstOption && option.value === ""
                    ? colors.grayLabel
                    : colors.blue3,
              }}
              label={option.label}
              value={option.value}
            />
          );
        })}
      </Select>
    </View>
  );
};

export const QuestionDropDown = ({ question, answer, height, subCategory }) => {
  const animation = useRef(new Animated.Value(0)).current;
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  useEffect(() => {
    Animated.timing(animation, {
      toValue: show ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start();
  }, [show]);
  console.log(subCategory);
  return (
    <View
      style={{
        width: "100%",
        position: "relative",
        zIndex: 1000,
        marginTop: 20,
      }}
    >
      <Pressable
        onPress={toggleShow}
        style={[
          styles.button,
          {
            borderColor: show ? colors.green2 : colors.grayBorder,
            borderBottomLeftRadius: show ? 0 : 5,
            borderBottomRightRadius: show ? 0 : 5,
            alignItems: "center",

            height,
          },
        ]}
      >
        {subCategory && (
          <View
            style={{
              position: "absolute",
              right: 5,
              top: 5,
            }}
          >
            <SmallLightTxt>{subCategory}</SmallLightTxt>
          </View>
        )}
        <Txt
          style={{ width: "92%", fontSize: 15 }}
          numberOfLines={2}
          color={show ? colors.green2 : colors.blue3}
        >
          {question}
        </Txt>
        <Animated.View
          style={{
            transform: [
              {
                rotate: animation.interpolate({
                  inputRange: [0, 1],
                  outputRange: ["0deg", "180deg"],
                }),
              },
            ],
          }}
        >
          <FontAwesome5
            name="chevron-down"
            size={14}
            color={show ? colors.green2 : colors.blue3}
            style={{ marginTop: 3 }}
          />
        </Animated.View>
      </Pressable>
      {/** options */}

      {show && (
        <PresenceTransition
          visible={show}
          initial={{
            translateY: -5,
            opacity: 0,
          }}
          animate={{
            translateY: 0,
            opacity: 1,

            transition: {
              type: "timing",
              duration: 300,
              useNativeDriver: Platform.OS === "web" ? false : true,
            },
          }}
          style={[styles.answerContainer]}
        >
          <BoldTxt style={{ marginBottom: 20 }}>{question}</BoldTxt>
          <Txt>{answer}</Txt>
        </PresenceTransition>
      )}
    </View>
  );
};

export const PhoneInput = ({
  phoneCode,
  setPhoneCode,
  phoneNumber,
  setPhoneNumber,
  codePlaceholder,
  placeholder,

  style,
  disabled,
  big,
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flex: 1,
        ...style,
      }}
    >
      {/** code phone */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: 100,
        }}
      >
        <View style={{ flex: 1 }}>
          <NumericInput
            big={big}
            disabled={disabled}
            maxLength={4}
            placeholder={codePlaceholder}
            value={phoneCode}
            setValue={setPhoneCode}
            style={{ paddingLeft: 25 }}
          />
        </View>
        <View style={{ position: "absolute", left: 10 }}>
          <Txt color={disabled ? colors.grayBorder : colors.blue3}>+</Txt>
        </View>
      </View>
      {/** number input */}
      <View style={{ flex: 1, marginLeft: 10 }}>
        <NumericInput
          big={big}
          disabled={disabled}
          maxLength={15}
          placeholder={placeholder}
          value={phoneNumber}
          setValue={setPhoneNumber}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  answerContainer: {
    backgroundColor: colors.white,
    width: "100%",
    borderWidth: 1,
    borderColor: colors.green2,
    borderTopWidth: 0,
    padding: 20,
  },
  optionsContainer: {
    position: "absolute",
    backgroundColor: colors.white,
    width: "100%",
    borderWidth: 1,
    borderColor: colors.green2,
    borderTopWidth: 0,
    maxHeight: 200,
  },
  optionButton: {
    width: "100%",
    height: 45,
    justifyContent: "center",
    borderBottomWidth: 1,
    borderColor: colors.beige,
    paddingLeft: 10,
  },
  button: {
    borderWidth: 1,
    width: "100%",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    height: 50,
    backgroundColor: colors.white,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    flexDirection: "row",

    justifyContent: "space-between",
  },
});
