import { FlatList, Platform, ScrollView, View } from "react-native";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { H7, H6 } from "../../../components/TextsComponents";
import { PrimaryButton } from "../../../components/Buttons";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { colors } from "../../../styles/GlobalStyle";
import SwiperItem from "../../../components/swiper/SwiperItem";
import { setUserData } from "../../../redux/Actions";
import Spinner from "../../../components/Spinner";

const FinishedSwiper = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const userActivity = useSelector((state) => state.userActivity);
  const dipatch = useDispatch();
  const [Data, setData] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const { isMobile, width, isDesktop } = DimensionsHook();

  const swiperContainerStyle = {
    backgroundColor: colors.white,
    width: "100%",
    alignSelf: "center",
    paddingTop: 15,
    paddingBottom: 5,
    borderRadius: 20,
    marginTop: 20,
    paddingLeft: isMobile ? 10 : 20,
    paddingRight: isMobile ? 0 : 20,
  };

  const resetFinishedContentHandler = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    if (!deleteLoader) {
      setDeleteLoader(true);
      const url = process.env.FINISHED;

      axios
        .delete(url, { data: { access_token: TOKEN } })
        .then(async () => {
          await dipatch(setUserData());
          getData();
          setDeleteLoader(false);
        })
        .catch((err) => {
          setDeleteLoader(false);
        });
    }
  };

  const getData = async () => {
    if (Data.length === 0) {
      setLoader(true);
    }
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = process.env.FINISHED;

    const Response = await axios.get(
      url + `?language=${i18n.language}`,
      config
    );

    setData([...Response.data.trails, ...Response.data.workshops]);

    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [userActivity]);

  if (loader) {
    return <View />;
  }
  if (Data.length === 0) {
    return (
      <View style={swiperContainerStyle}>
        <H6>{t("my_space.title4")}</H6>
        <View
          style={{
            height: width <= 790 ? 200 : 260,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <H7
            style={{
              color: colors.grayLabel,
              textAlign: "center",
              padding: width <= 790 ? 5 : 0,
            }}
          >
            {t("my_space.no_finished1")}
            {"\n"}
            {t("my_space.no_finished2")}
          </H7>
          <PrimaryButton
            onPress={() => navigation.navigate("Home")}
            style={{ marginTop: 20 }}
          >
            {t("my_space.button3")}
          </PrimaryButton>
        </View>
      </View>
    );
  }

  return (
    <View>
      <View style={swiperContainerStyle}>
        <H6>{t("my_space.title4")}</H6>
        <View style={{ marginTop: 15 }}>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={Platform.OS === "web"}
          >
            {Data.map((item) => {
              return (
                <View
                  key={item.trail_code ? item.trail_code : item.workshop_code}
                >
                  <SwiperItem
                    item={item}
                    type={item.trail_code ? "Trail" : "Atelier"}
                    navigation={navigation}
                  />
                </View>
              );
            })}
          </ScrollView>
        </View>
      </View>
      {/** reset finished button */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isDesktop ? "flex-end" : "center",
        }}
      >
        <PrimaryButton
          onPress={resetFinishedContentHandler}
          style={{
            backgroundColor: colors.red1,
            width: isDesktop ? 300 : "100%",
            marginTop: 20,
          }}
        >
          {deleteLoader ? (
            <Spinner
              color={deleteLoader ? colors.white : colors.blue3}
              size="small"
            />
          ) : (
            "Réinitialiser Historique"
          )}
        </PrimaryButton>
      </View>
    </View>
  );
};

export default FinishedSwiper;
