import { StyleSheet, View, Platform } from "react-native";
import React, { useState, useEffect } from "react";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Spinner from "../Spinner";
import { setUserActivity } from "../../redux/Actions";

import WebView from "react-native-webview";
import { Surface } from "react-native-paper";
import { H2 } from "../TextsComponents";
import { LislUp } from "../../assets/svg/Logo";

const VideoWorkshop = ({ data, setIsOpenGreenModal }) => {
  const dispatch = useDispatch();
  const { width } = DimensionsHook();
  const [url, setUrl] = useState("");
  const userActivity = useSelector((state) => state.userActivity);
  const WIDTH =
    width < 700 ? width * 0.98 : width < 1000 ? width * 0.95 : width * 0.6;

  const [finished, setFinished] = useState(
    userActivity.current_finished_workshops.includes(data.workshop_code)
  );

  const finishedHandler = async () => {
    setFinished(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    axios
      .post(process.env.FINISHED_WORKSHOPS, {
        access_token: TOKEN,
        workshop_code: data.workshop_code,
      })
      .then((res) => {
        dispatch(setUserActivity());
        console.log("added to finished");
      });
  };
  // set workshop base link
  const setBaseLink = () => {
    const l = data.link;
    const baseUrl = l.replace("playlist.m3u8", "");
    //+ "?preload=true";
    setUrl(baseUrl);
  };

  useEffect(() => {
    setBaseLink();
  }, []);

  if (url == "") {
    return (
      <View style={styles.loaderContainer}>
        <Spinner size="large" />
      </View>
    );
  }

  const onTimeUpdateHandler = (e) => {
    const { duration, currentTime } = e.target;

    // mark as finished if current time > 0.8 of duration and not marked before as finished
    if (currentTime / duration > 0.8 && !finished) {
      finishedHandler();
    }
  };
  const onEndedHandler = () => setIsOpenGreenModal(true);

  if (Platform.OS == "web") {
    return (
      <View style={[styles.container, { width: WIDTH }]}>
        <Surface elevation={10} style={styles.videoContainer}>
          {width > 800 && (
            <View style={styles.header}>
              <View style={{ width: 50, height: 50 }}>
                <LislUp color={colors.white} />
              </View>
              <H2
                fontSize={16}
                color={colors.white}
                style={{ textAlign: "center" }}
              >
                {data.title}
              </H2>
            </View>
          )}
          <video
            src={url + "play_480p.mp4"}
            autoPlay
            poster={url + "thumbnail.jpg"}
            preload="true"
            crossOrigin="anonymous"
            onEnded={onEndedHandler}
            onTimeUpdate={onTimeUpdateHandler}
            controls
            controlsList="nodownload"
            width={WIDTH}
          >
            {data.srt.map((srt, i) => {
              return (
                <track
                  default={i == 0 ? true : false}
                  key={srt.value}
                  kind="captions"
                  label={srt.label}
                  src={url + `captions/${srt.value.toUpperCase()}.vtt`}
                  srcLang={srt.value.toLowerCase()}
                ></track>
              );
            })}
          </video>
        </Surface>
      </View>
    );
  }

  return (
    <View style={{ flex: 1, color: colors.beige }}>
      <WebView
        onMessage={(event) => {
          const data = event.nativeEvent.data;
          if (data == "ended") {
            setIsOpenGreenModal(true);
          } else {
            const d = data.split("-");
            const duration = d[0];
            const current = d[1];
            // if not finished before and watched more than 80%

            if (current / duration > 0.8 && !finished) {
              finishedHandler();
            }
          }
        }}
        containerStyle={{
          margin: 0,
          padding: 0,
        }}
        allowsFullscreenVideo={true}
        scalesPageToFit={false}
        setBuiltInZoomControls={false}
        useWebView2={true}
        source={{
          html: `
          <head>
          <link href="http://vjs.zencdn.net/6.4.0/video-js.css" rel="stylesheet">
        

          </head>
          <body style="margin: 0 !important;padding: 0 !important;">
          <div style="margin:0px; padding:0px; width:100%; height:100%; display:flex; align-items:center; justify-content:center; background-color:#FBF6F3; ">
            <div style=" overflow:hidden;  margin:0 ; padding:0 ;  width:100%; ">
            <video
            id="my-player"
            class="video-js vjs-big-play-centered vjs-fill "
            style="background-color:transparent;"
            preload="true"
            ontimeupdate="onTimeUpdate(this)"
            onended="onEnded()"
            poster="${url + "thumbnail.jpg"}"
            crossorigin="anonymous"
            src="${url + "play_480p.mp4"}"
            controls
            allowfullscreen
            controlsList="nodownload"
            width="100%"
            
          >
          ${data.srt.map(
            (srt) => `"<track
          key="${srt.value}"
          kind="captions"
          label="${srt.label}"
          src="${url + `captions/${srt.value.toUpperCase()}.vtt`}"
          srcLang="${srt.value.toLowerCase()}"
        ></track>"`
          )}
         
          </video>
           
          
          </div>
          </body>
          <script src="http://vjs.zencdn.net/6.4.0/video.js"></script>
          <script>
          
          function onEnded() {
                window.ReactNativeWebView.postMessage("ended!")
          }
          function onTimeUpdate(e) {
            window.ReactNativeWebView.postMessage(e.duration + "-" + e.currentTime)
          }
          </script>
          `,
        }}
      />
    </View>
  );
};

export default VideoWorkshop;

const styles = StyleSheet.create({
  loaderContainer: { flex: 1, alignItems: "center", justifyContent: "center" },
  container: {
    flex: 1,
    alignSelf: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  videoContainer: {
    elevation: 15,
    marginTop: -15,
    backgroundColor: colors.blue3,
    cursor: "pointer",
  },
  header: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
