import { StyleSheet, View, Image, Pressable } from "react-native";
import React, { useRef, useEffect, useState } from "react";
import { BoldTxt, H6, H5, SmallTxt, Txt } from "../TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { LislUp } from "../../assets/svg/Logo";
import { LinearGradient } from "expo-linear-gradient";
import { PresenceTransition, useDisclose } from "native-base";
import RolloverSmall from "../rollover/RolloverSmall";
import { FavoriteIcon, LikeIcon, PlayIcon } from "../../assets/svg/Icons";
import { useHover } from "react-native-web-hooks";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setUserActivity } from "../../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  BoiteIcon,
  CoachingIcon,
  ConscienceIcon,
  CorpsIcon,
  PlaisirIcon,
  RituelsIcon,
  ScienceIcon,
  TemoignageIcon,
} from "../../assets/svg/WorkshopBadgeIcons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { CustomTooltip } from "../Tooltips";

const ResponsiveItem = ({ item, type, navigation, oneOnSmall }) => {
  const { isDesktop, isMobile, isTablet, isBigScreen, width } =
    DimensionsHook();
  const { isOpen, onOpen, onClose } = useDisclose();

  const hoverRef = useRef(null);
  const playRef = useRef(null);
  const likeRef = useRef(null);
  const favoriteRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const playHover = useHover(playRef);
  const likeHover = useHover(likeRef);
  const favoriteHover = useHover(favoriteRef);

  // get the category to render it
  const { t } = useTranslation();
  const Categories = [
    {
      label: t("workshop_types.label"),
      value: "",
    },
    {
      label: t("workshop_types.conscience"),
      value: "conscience",
      textColor: colors.blue3,
      backgroundColor: colors.green2,
      icon: () => <ConscienceIcon />,
    },
    {
      label: t("workshop_types.corps"),
      value: "body",
      textColor: colors.blue3,
      backgroundColor: colors.yellow,
      icon: () => <CorpsIcon />,
    },
    {
      label: t("workshop_types.plaisir"),
      value: "fun",
      textColor: colors.blue3,
      backgroundColor: colors.green3,
      icon: () => <PlaisirIcon />,
    },
    {
      label: t("workshop_types.rituels"),
      value: "rituals",
      textColor: colors.white,
      backgroundColor: colors.red1,
      icon: () => <RituelsIcon color="white" />,
    },
    {
      label: t("workshop_types.science"),
      value: "science",
      textColor: colors.white,
      backgroundColor: colors.blue3,
      icon: () => <ScienceIcon color="white" />,
    },
    {
      label: t("workshop_types.temoignages"),
      value: "testimony",
      textColor: colors.blue3,
      backgroundColor: colors.blue0,
      icon: () => <TemoignageIcon />,
    },
    {
      label: t("workshop_types.outils"),
      value: "tools",
      textColor: colors.blue3,
      backgroundColor: colors.blue2,
      icon: () => <BoiteIcon />,
    },
    {
      label: t("workshop_types.coaching"),
      value: "coaching",
      textColor: colors.blue3,
      backgroundColor: colors.red0,
      icon: () => <CoachingIcon />,
    },
  ];
  const category = Categories.find((cat) => cat.value === item.type) || null;

  // liked and favorite user lists
  let code = type == "Trail" ? item.trail_code : item.workshop_code;
  const userActivity = useSelector((state) => state.userActivity);
  const liked =
    type == "Trail" ? userActivity.liked_trails : userActivity.liked_workshops;
  const favourite =
    type == "Trail"
      ? userActivity.favourite_trails
      : userActivity.favourite_workshops;

  // like favorite states
  const [isLiked, setIsLiked] = useState(liked.includes(code));
  const [isFavorite, setIsFavorite] = useState(favourite.includes(code));
  // PRESS LIKE & FAVOURITE FUNCTIONS
  const dispatch = useDispatch();
  const likePressHandler = async () => {
    setIsLiked(!isLiked);
    const TOKEN = await AsyncStorage.getItem("access_token");
    if (type === "Trail") {
      axios
        .post(process.env.USER_LIKE_TRAILS, {
          access_token: TOKEN,
          trail_code: item.trail_code,
        })
        .then(() => dispatch(setUserActivity()));
    } else {
      axios
        .post(process.env.USER_LIKE_WORKSHOPS, {
          access_token: TOKEN,
          workshop_code: item.workshop_code,
        })
        .then(() => dispatch(setUserActivity()));
    }
  };
  const favoritePressHandler = async () => {
    setIsFavorite(!isFavorite);
    const TOKEN = await AsyncStorage.getItem("access_token");
    if (type === "Trail") {
      axios
        .post(process.env.USER_FAVOURITE_TRAILS, {
          access_token: TOKEN,
          trail_code: item.trail_code,
        })
        .then(() => dispatch(setUserActivity()));
    } else {
      axios
        .post(process.env.USER_FAVOURITE_WORKSHOPS, {
          access_token: TOKEN,
          workshop_code: item.workshop_code,
        })
        .then(() => dispatch(setUserActivity()));
    }
  };

  const playHandler = () => {
    if (type === "Trail") {
      navigation.navigate("Trail", item);
    } else {
      navigation.navigate("Workshop", item);
    }
  };

  const navigationHandler = () => {
    if (isMobile) {
      onOpen();
    } else {
      if (type === "Trail") {
        navigation.navigate("Trail", item);
      } else {
        navigation.navigate("Workshop", item);
      }
    }
  };

  useEffect(() => {
    // check if in liked | favorite
    if (liked.includes(code)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
    if (favourite.includes(code)) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [userActivity]);

  const itemContainer = {
    backgroundColor: colors.grayBackground,
    marginTop: 15,
    height: 240,
    borderRadius: 20,
    overflow: "hidden",
    width: isBigScreen
      ? (width * 0.95 - 15) * 0.25 - 20
      : isDesktop
      ? (width * 0.95 - 15) * 0.5 - 20
      : isTablet
      ? (width * 0.95 - 15) * 0.5 - 20
      : oneOnSmall
      ? width * 0.95
      : (width * 0.95 - 15) * 0.5 - 20,
    marginLeft: isMobile && oneOnSmall ? 0 : 15,
  };

  return (
    <Pressable
      onPress={navigationHandler}
      disabled={isHovered && !isMobile}
      ref={hoverRef}
      style={itemContainer}
    >
      <Image source={{ uri: item.poster_link }} style={styles.image} />
      <LinearGradient
        colors={["#1B496500", "#1B496566"]}
        style={styles.image}
      />

      <View style={styles.row}>
        <View style={styles.logoContainer}>
          <LislUp color={colors.white} />
        </View>

        {item.free ? (
          <View style={[styles.newButton, { backgroundColor: colors.green1 }]}>
            <Txt fontSize={13}>{t("trail.free")}</Txt>
          </View>
        ) : moment().diff(item.createdAt, "days") < 90 ? (
          <View style={styles.newButton}>
            <Txt fontSize={13}>{t("trail.new")}</Txt>
          </View>
        ) : (
          <></>
        )}
      </View>
      <View style={[styles.textsContainer, { bottom: category ? 10 : 20 }]}>
        {!isHovered && type == "Trail" && (
          <BoldTxt color={colors.white}>Trail</BoldTxt>
        )}

        {!isHovered && (
          <View style={{ marginBottom: category ? 5 : 0, width: "100%" }}>
            <H6
              style={{ textAlign: "center", marginHorizontal: 5 }}
              color={colors.white}
            >
              {item.title}
            </H6>
            {category && (
              <View
                style={[
                  styles.badge,

                  {
                    backgroundColor: category.backgroundColor,
                    marginTop: 5,
                    opacity: 0.9,
                  },
                ]}
              >
                <View style={{ width: 16, height: 16, marginRight: 8 }}>
                  {category.icon()}
                </View>

                <SmallTxt color={category.textColor}>
                  {t("appointment.workshop")} {category.label}
                </SmallTxt>
              </View>
            )}
          </View>
        )}
      </View>
      <PresenceTransition
        style={styles.rolloverContainer}
        visible={isHovered && !isMobile}
        initial={{
          translateY: -240,
        }}
        animate={{
          translateY: 0,
          transition: {
            duration: 300,
          },
        }}
      >
        <Pressable onPress={playHandler}>
          <View style={{ padding: 16, paddingTop: 30 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <CustomTooltip
                bg={colors.blue3}
                color={colors.white}
                label={t("tooltips.play")}
              >
                <Pressable
                  ref={playRef}
                  onPress={playHandler}
                  style={[
                    styles.playButton,
                    {
                      backgroundColor: playHover ? colors.blue3 : colors.green2,
                    },
                  ]}
                >
                  <PlayIcon />
                </Pressable>
              </CustomTooltip>
              {/** like button */}
              <CustomTooltip
                bg={colors.blue2}
                color={colors.white}
                label={t("tooltips.like")}
              >
                <Pressable
                  ref={likeRef}
                  onPress={likePressHandler}
                  style={[
                    styles.likeButton,
                    {
                      borderWidth: isLiked ? 0 : likeHover ? 0 : 1,
                      backgroundColor: isLiked
                        ? colors.blue2
                        : likeHover
                        ? colors.blue0
                        : "transparent",
                    },
                  ]}
                >
                  <LikeIcon
                    color={
                      isLiked
                        ? colors.white
                        : likeHover
                        ? colors.white
                        : colors.grayBorder
                    }
                  />
                </Pressable>
              </CustomTooltip>
              {/** favorite button */}
              <CustomTooltip
                label={
                  isFavorite
                    ? t("tooltips.remove_favoris")
                    : t("tooltips.add_favoris")
                }
                bg={colors.red1}
                color={colors.white}
              >
                <Pressable
                  ref={favoriteRef}
                  onPress={favoritePressHandler}
                  style={[
                    styles.favoriteButton,

                    {
                      borderWidth: isFavorite ? 0 : favoriteHover ? 0 : 1,
                      backgroundColor: isFavorite
                        ? colors.red1
                        : favoriteHover
                        ? colors.red0
                        : "transparent",
                    },
                  ]}
                >
                  <FavoriteIcon
                    color={
                      isFavorite
                        ? colors.white
                        : favoriteHover
                        ? colors.white
                        : colors.grayBorder
                    }
                  />
                </Pressable>
              </CustomTooltip>
            </View>

            <View style={{ height: 140 }}>
              <H5
                color={colors.white}
                numberOfLines={2}
                style={{ lineHeight: 26, marginVertical: 5 }}
              >
                {item.title}
              </H5>
              <SmallTxt color={colors.white} numberOfLines={3}>
                {item.description}
              </SmallTxt>
            </View>
          </View>
          {/** seasons number  || duration workshop  */}
          {type === "Trail" ? (
            <View style={styles.seasonNumberContainer}>
              {item.seasons.length > 0 ? (
                <BoldTxt style={{ fontSize: 13 }} color={colors.white}>
                  {item.seasons.length +
                    ` saison${item.seasons.length > 1 ? "s" : ""}`}
                </BoldTxt>
              ) : (
                <SmallTxt color={colors.white} style={{ textAlign: "center" }}>
                  {t("soon")}
                </SmallTxt>
              )}
            </View>
          ) : (
            <View
              style={[
                styles.seasonNumberContainer,
                { flexDirection: "row", alignItems: "center" },
              ]}
            >
              <MaterialCommunityIcons
                name="clock-fast"
                size={16}
                color={colors.white}
              />
              <BoldTxt
                style={{ fontSize: 13, marginLeft: 5 }}
                color={colors.white}
              >{`${item.duration} Min`}</BoldTxt>
            </View>
          )}
          <View style={styles.backgroundLogo}>
            <LislUp color={colors.white} />
          </View>
        </Pressable>
      </PresenceTransition>

      {isMobile && isOpen && (
        <RolloverSmall
          type={type}
          item={item}
          navigation={navigation}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Pressable>
  );
};

export default ResponsiveItem;

const styles = StyleSheet.create({
  logoContainer: {
    width: 35,
    height: 35,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
  },
  newButton: {
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    borderRadius: 100,
    height: 26,
    alignItems: "center",
    justifyContent: "center",
  },
  badge: {
    paddingHorizontal: 20,
    height: 30,
    width: "100%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    position: "absolute",
  },
  textsContainer: {
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    position: "absolute",
  },
  playButton: {
    width: 40,
    height: 40,
    backgroundColor: colors.green2,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 12,
  },
  likeButton: {
    width: 40,
    height: 40,
    borderWidth: 1,
    borderColor: colors.grayBorder,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    marginLeft: 6,
  },
  favoriteButton: {
    width: 40,
    height: 40,
    borderWidth: 1,
    borderColor: colors.grayBorder,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    marginLeft: 6,
  },
  rolloverContainer: {
    backgroundColor: "#00000090",
    position: "absolute",
    width: "100%",
    height: 240,
    top: 0,
    zIndex: 20,

    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    flexDirection: "column",
  },
  seasonNumberContainer: { position: "absolute", bottom: 10, left: 16 },
  backgroundLogo: {
    width: 200,
    height: 200,
    top: 20,
    opacity: 0.05,
    alignSelf: "center",
    position: "absolute",
    zIndex: -10,
  },
});
