import { StyleSheet, View } from "react-native";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { H7, H1, Txt } from "../../TextsComponents";
import { PrimaryButton } from "../../Buttons";
import { ScoreBook, ScoreGift } from "../../../assets/svg/Icons";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { colors } from "../../../styles/GlobalStyle";
import { MaterialIcons } from "@expo/vector-icons";
import SoonModal from "../../modals/SoonModal";
import BuyTrailsModal from "./modals/BuyTrailsModal";
import axios from "axios";
import Spinner from "../../Spinner";
import { useSelector } from "react-redux";
import NeedSubscriptionModal from "../../modals/EpisodesModal/NeedSubscriptionModal";
import AlertBar from "../../AlertBar";

const UpperSection = ({ navigation, showByTrail }) => {
  const { t } = useTranslation();
  const { isDesktop, width, isMobile } = DimensionsHook();
  const [showSoonModal, setShowSoonModal] = useState(false);
  const [showBuyTrailsModal, setShowBuyTrailsModal] = useState(false);
  const [showNeedSubscriptionModal, setNeedSubscriptionModal] = useState(false);

  const [trailProduct, setTrailProduct] = useState(null);
  const [loader, setLoader] = useState(false);
  const userData = useSelector((state) => state.userData);
  const getTrailProduct = () => {
    if (
      !userData.subscription &&
      !userData.lifetime_subscription &&
      !userData.company
    ) {
      setNeedSubscriptionModal(true);
    } else {
      const url = process.env.PRODUCT_DATA;
      if (!loader) {
        setLoader(true);
        axios
          .get(`${url}?type=trail`)
          .then((res) => {
            setLoader(false);
            setTrailProduct(res.data);
            setShowBuyTrailsModal(true);
          })
          .catch((err) => {
            setLoader(false);
          });
      }
    }
  };

  useEffect(() => {
    if (showByTrail) {
      getTrailProduct();
    } else {
      console.log("non");
    }
  }, [showByTrail]);

  const isBig = width > 1250;
  const cardStyle = {
    backgroundColor: isDesktop ? colors.beige : colors.white,
    width: isMobile ? "100%" : isBig ? "22.5%" : "46.25%",
    height: isMobile ? 220 : 280,
    marginLeft: isMobile ? 0 : "2.5%",
    paddingBottom: 20,
    paddingHorizontal: 8,
  };
  return (
    <View>
      <H1 style={{ marginTop: !isDesktop ? 20 : 0 }}>
        {t("buy_screen.upper.title")}
      </H1>

      <Txt style={{ marginBottom: 20 }}>{t("buy_screen.upper.text1")}</Txt>
      {/** cards */}
      <View style={styles.cardsContainer}>
        {/** card 1 - buy trail */}
        <View style={[styles.card, cardStyle]}>
          <View style={{ height: 80 }} />
          <View style={styles.circle}>
            <MaterialIcons name="video-label" size={26} color={colors.green2} />
          </View>
          <H7 style={{ textAlign: "center" }}>{t("buy_screen.upper.text2")}</H7>

          <PrimaryButton onPress={getTrailProduct} style={styles.button}>
            {loader ? (
              <Spinner color={colors.white} size="small" />
            ) : (
              t("buy_screen.upper.button")
            )}
          </PrimaryButton>
        </View>
        {/** card 2 - buy ebook */}
        <View style={[styles.card, cardStyle]}>
          <View style={styles.circle}>
            <ScoreBook />
          </View>
          <View style={{ height: 80 }} />
          <H7 style={{ textAlign: "center" }}>{t("buy_screen.upper.text3")}</H7>

          <PrimaryButton
            onPress={() => navigation.navigate("Books", { gift: false })}
            style={styles.button}
          >
            {t("buy_screen.upper.button")}
          </PrimaryButton>
        </View>
        {/** card 3 buy flash-coaching */}
        <View style={[styles.card, cardStyle]}>
          <View style={styles.circle}>
            <ScoreGift />
          </View>
          <View style={{ height: 80 }} />
          <H7 style={{ textAlign: "center" }}>{t("buy_screen.upper.text4")}</H7>

          <PrimaryButton
            onPress={() => navigation.navigate("Appointment", { gift: false })}
            style={styles.button}
          >
            {t("buy_screen.upper.button")}
          </PrimaryButton>
        </View>
        {/** card 4 - don association */}
        <View style={[styles.card, cardStyle]}>
          <View style={styles.circle}>
            <ScoreGift />
          </View>
          <View style={{ height: 80 }} />
          <H7 style={{ textAlign: "center" }}>{t("buy_screen.upper.text5")}</H7>

          <PrimaryButton
            onPress={() => setShowSoonModal(true)}
            style={styles.button}
          >
            {t("buy_screen.upper.button")}
          </PrimaryButton>
        </View>
      </View>
      <View style={styles.divider} />
      {/** soon modal */}
      <SoonModal
        don={true}
        isOpen={showSoonModal}
        setIsOpen={setShowSoonModal}
      />
      {/** buy trails modal */}
      {trailProduct && (
        <BuyTrailsModal
          trailProduct={trailProduct}
          isOpen={showBuyTrailsModal}
          setIsOpen={setShowBuyTrailsModal}
          navigation={navigation}
        />
      )}
      {/** need subscription modal */}
      <NeedSubscriptionModal
        isOpen={showNeedSubscriptionModal}
        setIsOpen={setNeedSubscriptionModal}
        navigation={navigation}
      />
    </View>
  );
};

export default UpperSection;

const styles = StyleSheet.create({
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginVertical: 20,
  },
  circle: {
    width: 150,
    height: 150,
    paddingBottom: 30,
    borderRadius: 75,
    backgroundColor: "#C9DCC525",
    position: "absolute",
    top: -75,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  card: {
    borderRadius: 20,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 20,
    overflow: "hidden",
  },
  cardsContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  button: { marginBottom: 20 },
});
