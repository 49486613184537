import Home from "../screens/Home";
import Espace from "../screens/Space";
import Trails from "../screens/Trails";
import Workshops from "../screens/Workshops";

import TabBarComponent from "../components/TabBarComponent";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommunityGeneral from "../screens/Community/CommunityGeneral";
import { ImageBackground } from "react-native";
import { View } from "native-base";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";
import axios from "axios";
//import Soon from "../screens/Community/Soon";

const Tab = createBottomTabNavigator();
const TabNavigation = ({ navigation, route }) => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userData);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (route.params == false || route.params == undefined) {
      const giftUrl = process.env.GIFT_DATA;
      axios.get(`${giftUrl}?email=${userData.email}`).then((res) => {
        if (res.data != null) {
          navigation.replace("GiftScreen", { gift: res.data });
        } else {
          setLoader(false);
        }
      });
    } else {
      setLoader(false);
    }
  }, [route]);

  if (loader) {
    return (
      <View style={{ flex: 1 }}>
        <ImageBackground
          source={require("../assets/background.png")}
          style={{ flex: 1 }}
        >
          <View flex={1} alignItems="center" justifyContent="center">
            <Spinner size="large" />
          </View>
        </ImageBackground>
      </View>
    );
  }
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        keyboardHidesTabBar: true,
      }}
      tabBar={(props) => <TabBarComponent {...props} />}
    >
      <Tab.Screen
        options={{
          tabBarLabel: t("tab_navigation.home"),
          title: t("tab_navigation.home"),
        }}
        name="Home"
        component={Home}
      />
      <Tab.Screen
        options={{
          tabBarLabel: t("tab_navigation.my_space"),
          title: t("tab_navigation.my_space"),
        }}
        name="Espace"
        component={Espace}
      />
      <Tab.Screen
        options={{
          tabBarLabel: t("tab_navigation.trails"),
          title: t("tab_navigation.trails"),
        }}
        name="Trails"
        component={Trails}
      />
      <Tab.Screen
        options={{
          tabBarLabel: t("tab_navigation.workshops"),
          title: t("tab_navigation.workshops"),
        }}
        name="Workshops"
        component={Workshops}
      />
      <Tab.Screen
        options={{
          tabBarLabel: t("tab_navigation.community"),
          title: t("tab_navigation.community"),
        }}
        name="Communaute"
        component={CommunityGeneral}
      />
    </Tab.Navigator>
  );
};

export default TabNavigation;
