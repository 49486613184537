import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import React, { useState } from "react";
import { Modal, useToast } from "native-base";
import { colors } from "../../../styles/GlobalStyle";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BoldTxt, SmallBoldTxt, Txt } from "../../TextsComponents";
import { PrimaryButton } from "../../Buttons";
import Spinner from "../../Spinner";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setUserData } from "../../../redux/Actions";
import { AntDesign } from "@expo/vector-icons";

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";

const ValidatePhone = ({ isOpen, setIsOpen }) => {
  const { isMobile, isDesktop } = DimensionsHook();
  const { phoneCode, phoneNumber } = useSelector((state) => state.userData);
  const phone = phoneCode.replace("+", "") + phoneNumber;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const ref = useBlurOnFulfill({ value, cellCount: 6 });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const toast = useToast();
  const submitHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      setErrorMessage("");
      const TOKEN = await AsyncStorage.getItem("access_token");
      if (value.length != 6 || isNaN(value)) {
        setErrorMessage(t("validation.error_code"));
        setSubmitLoader(false);
        return;
      }
      axios
        .post(process.env.USER_VALIDATE_NUMBER, {
          access_token: TOKEN,
          code: value,
          phone,
        })
        .then(() => {
          dispatch(setUserData());
          setSubmitLoader(false);
          setIsOpen(false);
          toast.show({
            placement: isDesktop ? "top" : "bottom",
            render: () => {
              return (
                <View style={styles.toastContainer}>
                  <Txt
                    style={{ paddingLeft: isDesktop ? 5 : 0 }}
                    color={colors.white}
                  >
                    {t("account_details_screen.success_validate_phone")}
                  </Txt>
                  <TouchableOpacity
                    style={{ paddingHorizontal: 5 }}
                    onPress={() => toast.closeAll()}
                  >
                    <AntDesign name="close" size={16} color={colors.white} />
                  </TouchableOpacity>
                </View>
              );
            },
          });
        })
        .catch((err) => {
          setSubmitLoader(false);
          if (err.response.status === 403) {
            setErrorMessage(t("validation.error_code"));
            return;
          }
          setErrorMessage(t("validation.error_connexion"));
        });
    }
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.CloseButton
          focusable={false}
          style={{ marginRight: isMobile ? 0 : 10 }}
        />
        <Modal.Body
          style={{
            marginTop: isMobile ? 40 : 60,
            marginHorizontal: isMobile ? 10 : 30,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/** error message */}
          <SmallBoldTxt
            style={{
              color: colors.red1,
              textAlign: "center",
              marginBottom: 10,
            }}
          >
            {errorMessage.length > 0 ? errorMessage : " "}
          </SmallBoldTxt>

          <Txt style={{ textAlign: "center" }}>
            {t("account_details_screen.text5")}
          </Txt>
          <BoldTxt
            style={{ textAlign: "center", marginTop: 15, marginBottom: 10 }}
          >
            {phoneCode + phoneNumber}
          </BoldTxt>

          {/** validation code input */}
          <View
            style={{
              width: "90%",
              maxWidth: 300,
              alignSelf: "center",
              marginTop: 20,
            }}
          >
            <CodeField
              ref={ref}
              {...props}
              // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
              value={value}
              onChangeText={setValue}
              cellCount={6}
              rootStyle={styles.codeFieldRoot}
              keyboardType="number-pad"
              useBlurOnFulfill
              textContentType="oneTimeCode"
              renderCell={({ index, symbol, isFocused }) => (
                <Text
                  key={index}
                  style={[
                    styles.cell,
                    isFocused && styles.focusCell,
                    symbol.length > 0 && styles.filledCell,
                  ]}
                  onLayout={getCellOnLayoutHandler(index)}
                >
                  {symbol || (isFocused ? <Cursor /> : null)}
                </Text>
              )}
            />
          </View>
          <PrimaryButton
            width={180}
            style={{ marginBottom: 20 }}
            onPress={submitHandler}
          >
            {submitLoader ? (
              <Spinner
                color={submitLoader ? colors.white : colors.blue3}
                size="small"
              />
            ) : (
              t("account_details_screen.confirm")
            )}
          </PrimaryButton>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default ValidatePhone;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "90%",
    maxWidth: 500,
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    borderRadius: 20,
    paddingHorizontal: 15,
    marginTop: 30,
  },
  codeFieldRoot: { marginBottom: 20 },
  cell: {
    width: 40,
    height: 40,
    lineHeight: 36,
    fontSize: 18,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: colors.grayBorder,
    textAlign: "center",
    color: colors.green3,
    fontFamily: "OxygenBold",
  },
  focusCell: {
    borderColor: colors.blue3,
    color: colors.blue3,
  },
  filledCell: {
    backgroundColor: colors.blue3,
    borderColor: colors.blue3,
    color: colors.white,
  },
});
