import { StyleSheet, View, ImageBackground, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import BackHeader from "../components/BackHeader";
import { colors } from "../styles/GlobalStyle";
import { useTranslation } from "react-i18next";
import GreenModal from "../components/modals/GreenModal";
import { useSelector } from "react-redux";
import NeedSubscriptionModal from "../components/modals/EpisodesModal/NeedSubscriptionModal";
import { activateKeepAwake } from "expo-keep-awake";
import VideoWorkshop from "../components/videos/VideoWorkshop";

const Workshop = ({ navigation, route }) => {
  let item = route.params;

  const userData = useSelector((state) => state.userData);
  const [showNeedSubscriptionModal, setNeedSubscriptionModal] = useState(false);
  const [isOpenGreenModal, setIsOpenGreenModal] = useState(false);
  const [noContent, setNoContent] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    // case workshop is not free
    if (!item.free) {
      // if user freemium => pop up need subscription
      if (
        !userData.subscription &&
        !userData.lifetime_subscription &&
        !userData.company
      ) {
        setNeedSubscriptionModal(true);
        // user is not freemium => show content
      } else {
        setNoContent(false);
      }
      // case workshop is free
    } else {
      setNoContent(false);
    }
    if (Platform.OS != "web") {
      activateKeepAwake();
    }
  }, [item]);
  if (noContent) {
    return (
      <View style={styles.container}>
        <ImageBackground
          source={require("../assets/background.png")}
          style={{ flex: 1 }}
        >
          <BackHeader
            isOpen={false}
            setIsOpen={() => {}}
            navigation={navigation}
          />
        </ImageBackground>
        {/** need subscription modal */}
        <NeedSubscriptionModal
          isOpen={showNeedSubscriptionModal}
          setIsOpen={setNeedSubscriptionModal}
          navigation={navigation}
          isWorkshop={true}
        />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <BackHeader
          isOpen={false}
          setIsOpen={() => {}}
          navigation={navigation}
        />
        <View
          style={{
            flex: 1,
          }}
        >
          {/** if link mp3 then WorkshopVideo (with expo av) else with VideoWorkshop (html rendered player) */}

          <VideoWorkshop
            setIsOpenGreenModal={setIsOpenGreenModal}
            data={item}
          />
        </View>
      </ImageBackground>
      {/** modal if finish workshop */}
      <GreenModal
        workshop={true}
        isOpen={isOpenGreenModal}
        setIsOpen={setIsOpenGreenModal}
        navigation={navigation}
        title={t("modals.title1")}
        text={t("modals.text2")}
      />
    </View>
  );
};

export default Workshop;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
});
