import {
  ImageBackground,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useState } from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { BoldTxt, H2, SmallBoldTxt, Txt } from "../TextsComponents";
import { DropDown, Input } from "../Inputs";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import axios from "axios";
import { useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Spinner from "../Spinner";
import { useTranslation } from "react-i18next";

const UnsubscribeModal = ({
  isOpen,
  setIsOpen,
  setUnSubsribeDoneModal,
  setSub,
}) => {
  const { t } = useTranslation();
  const { isMobile } = DimensionsHook();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { subscription, email } = useSelector((state) => state.userData);

  const reasonsOptions = [
    { label: t("unsubscribe.reasons.placeholder"), value: "" },
    {
      label: t("unsubscribe.reasons.will_back"),
      value: "will_back",
    },
    { label: t("unsubscribe.reasons.no_interest"), value: "no_interest" },
    { label: t("unsubscribe.reasons.other"), value: "other" },
  ];

  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState(reasonsOptions[0]);
  const [message, setMessage] = useState("");

  const closeHandler = () => {
    setIsOpen(false);
    setErrorMessage(null);
    setReason(reasonsOptions[0]);
    setMessage("");
    setShowReason(false);
  };

  const validateUnsubsribeHandler = async () => {
    if (!submitLoader) {
      setErrorMessage(null);
      setSubmitLoader(true);
      const unsubscribeUrl = process.env.UNSUBSCRIBE_ENDPOINT;
      const subscriptionUrl = process.env.SUBSCRIPTION_USER;
      // console.log(unsubscribeUrl, subscriptionUrl);
      if (reason.value == "") {
        setErrorMessage(t("unsubscribe.error_reason"));
        setSubmitLoader(false);
      } else {
        const TOKEN = await AsyncStorage.getItem("access_token");
        axios
          .post(unsubscribeUrl, {
            access_token: TOKEN,
            subscription,
            reason,
            message,
            email,
          })
          .then(() => {
            axios
              .get(`${subscriptionUrl}?subscriptionId=${subscription}`)
              .then((res) => {
                setSub(res.data);
                setIsOpen(false);
                setUnSubsribeDoneModal(true);
                setSubmitLoader(false);
              });
          })
          .catch((err) => {
            setErrorMessage(t("unsubscribe.error_connexion"));
            setSubmitLoader(false);
          });
      }
    }
  };

  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <TouchableWithoutFeedback onPress={() => setShowReason(false)}>
        <Modal.Content style={styles.container}>
          <Modal.Body>
            <ImageBackground
              source={require("../../assets/background.png")}
              style={{ flex: 1 }}
            >
              <Modal.CloseButton
                focusable={false}
                style={{ marginRight: isMobile ? 0 : 10 }}
              />
              <VStack
                style={{
                  marginTop: isMobile ? 20 : 30,
                  marginHorizontal: isMobile ? 0 : 30,
                }}
              >
                <H2>{t("unsubscribe.title")}</H2>
                <SmallBoldTxt
                  style={{
                    color: colors.red1,
                    textAlign: "center",

                    marginTop: 10,
                  }}
                >
                  {errorMessage ? errorMessage : " "}
                </SmallBoldTxt>
                <BoldTxt style={{ marginTop: 20, marginBottom: 10 }}>
                  {t("unsubscribe.text1")}
                </BoldTxt>
                <Txt>{t("unsubscribe.text2")}</Txt>
                {/** reason */}
                <View style={{ width: "100%", marginTop: 20, zIndex: 5 }}>
                  <DropDown
                    height={58}
                    placeholder=""
                    show={showReason}
                    setShow={() => setShowReason(!showReason)}
                    value={reason}
                    disableFirstOption={true}
                    setValue={setReason}
                    options={reasonsOptions}
                  />
                </View>
                {/** message input */}
                <View style={{ marginVertical: 20 }}>
                  <Input
                    placeholder={t("unsubscribe.your_message")}
                    multiline={true}
                    numberOfLines={6}
                    value={message}
                    onChangeText={(val) => setMessage(val)}
                  />
                </View>
                {/** buttons */}
                <View
                  style={{
                    flexDirection: isMobile ? "column" : "row-reverse",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <PrimaryButton
                    whiteText={true}
                    width={200}
                    onPress={validateUnsubsribeHandler}
                    style={{
                      marginLeft: isMobile ? 0 : 20,
                      backgroundColor: colors.red1,
                    }}
                  >
                    {submitLoader ? (
                      <Spinner color={colors.white} />
                    ) : (
                      t("unsubscribe.title")
                    )}
                  </PrimaryButton>
                  <SecondaryButton
                    onPress={closeHandler}
                    style={{ marginTop: isMobile ? 15 : 0 }}
                  >
                    {t("unsubscribe.undo")}
                  </SecondaryButton>
                </View>
              </VStack>
            </ImageBackground>
          </Modal.Body>
        </Modal.Content>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default UnsubscribeModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 1000,
    maxHeight: 5000,
  },
});
