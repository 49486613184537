import { Linking, Platform, StyleSheet } from "react-native";
import React, { useState } from "react";
import { Modal } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { SmallBoldTxt, Txt } from "../../components/TextsComponents";
import { Input } from "../../components/Inputs";
import { PrimaryButton } from "../../components/Buttons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";

const EmailModal = ({
  isOpen,
  setIsOpen,
  book,
  withPoints,
  giftFirstName,
  giftLastName,
  setGiftFirstName,
  setGiftLastName,
  giftMail,
  setGiftMail,
}) => {
  const { isMobile } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const { customer } = useSelector((state) => state.userData);
  const { t } = useTranslation();
  const closeHandler = () => {
    setIsOpen(false);
    setErrorMessage("");
    setGiftMail("");
    setGiftFirstName("");
    setGiftFirstName("");
  };

  const giftHandler = async () => {
    if (!loader) {
      setErrorMessage("");
      setLoader(true);
      if (giftFirstName < 2) {
        setErrorMessage(t("signup.error_to_surname"));
        setLoader(false);
        return;
      }
      if (giftLastName < 2) {
        setErrorMessage(t("signup.error_to_name"));
        setLoader(false);
        return;
      }
      const url = process.env.BUY_BOOK;
      const TOKEN = await AsyncStorage.getItem("access_token");
      const body = {
        access_token: TOKEN,
        customer,
        price: book.stripe,
        email: giftMail.trim().toLowerCase(),
        firstname: giftFirstName,
        lastname: giftLastName,
        withPoints,
        points: book.points,
      };

      axios
        .post(url, body)
        .then((res) => {
          closeHandler();
          const sessionUrl = res.data.session.url;
          if (Platform.OS == "web") {
            setLoader(false);
            window.open(sessionUrl, "_blank");
          } else {
            Linking.openURL(sessionUrl);
            setLoader(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setErrorMessage(t("signup.error_email"));
            setLoader(false);
          } else if (err.response.status === 403) {
            setErrorMessage(t("books.error_points"));
            setLoader(false);
          } else {
            setErrorMessage(t("signup.error_connexion"));
            setLoader(false);
          }
        });
    }
  };

  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.CloseButton
          focusable={false}
          style={{ marginRight: isMobile ? 0 : 10 }}
        />
        <Modal.Body
          style={{
            marginTop: isMobile ? 20 : 60,
            marginHorizontal: isMobile ? 10 : 30,
          }}
        >
          <SmallBoldTxt
            style={{
              color: colors.red1,
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            {errorMessage ? errorMessage : " "}
          </SmallBoldTxt>
          <Txt style={{ marginBottom: 20, fontSize: 14, textAlign: "justify" }}>
            {t("books.gift_email")}
          </Txt>
          {/** firstname */}
          <Input
            value={giftFirstName}
            placeholder={t("placeholders.to_firstname")}
            onChangeText={setGiftFirstName}
          />
          {/** lastname */}
          <Input
            value={giftLastName}
            placeholder={t("placeholders.to_lastname")}
            onChangeText={setGiftLastName}
          />
          {/** mail */}
          <Input
            value={giftMail}
            placeholder={t("placeholders.to_email")}
            onChangeText={setGiftMail}
          />

          <PrimaryButton
            onPress={giftHandler}
            style={{ marginBottom: 20, marginTop: 35 }}
          >
            {loader ? <Spinner color={colors.white} /> : t("books.pay")}
          </PrimaryButton>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default EmailModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "90%",
    maxWidth: 600,
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
});
