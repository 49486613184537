import {
  Pressable,
  StyleSheet,
  View,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { ArrowBack } from "../../assets/svg/Icons";
import { LinearGradient } from "expo-linear-gradient";

import Constants from "expo-constants";
import { LislUp } from "../../assets/svg/Logo";
import { Surface } from "react-native-paper";
import WebView from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import FinishEpisodeModal from "../modals/FinishEpisodeModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import {
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../../redux/Actions";

const TrailEpisodeVideo = ({
  isOpen,
  setIsOpen,
  data,
  setIsOpenGreenModal,
  setIsOpenChooseModal,
}) => {
  const dispatch = useDispatch();
  const { isMobile, width, height } = DimensionsHook();
  const vidRef = useRef(null);

  const watched_episodes = useSelector((state) => state.watchedEpisodes);
  const { current_trails, finished_trails, current_finished_trails } =
    useSelector((state) => state.userActivity);
  const [showFinishTrail, setShowFinishTrail] = useState(false);

  const [finished, setFinished] = useState(
    watched_episodes.includes(data.episode_code)
  );
  const [inCurrentTrails, setInCurrentTrails] = useState(
    current_trails.includes(data.trail_code)
  );
  const [finishModal, setFinishModal] = useState(false);

  const url = data.link.replace("playlist.m3u8", "");

  // close  video modal
  const closeHandler = () => {
    setIsOpen(false);
    setIsOpenChooseModal(true);
    if (Platform.OS == "web") {
      vidRef.current.pause();
    }
  };

  // add to current trails
  const addToCurrentTrails = async () => {
    setInCurrentTrails(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    axios
      .post(process.env.CURRENT_TRAILS, {
        access_token: TOKEN,
        trail_code: data.trail_code,
      })
      .then(() => {
        dispatch(setUserActivity());
      });
  };

  //finish video  handler
  const finishedHandler = async () => {
    setFinished(true);
    const TOKEN = await AsyncStorage.getItem("access_token");

    axios
      .post(process.env.USER_WATCHED_EPISODES, {
        access_token: TOKEN,
        episode_code: data.episode_code,
        last_episode: data.isTheLastEpisode,
        trail_code: data.trail_code,
        daily_limit: data.daily_limit,
      })
      .then(() => {
        if (data.isTheLastEpisode) {
          setShowFinishTrail(true);
          dispatch(setUserActivity());
        }
        dispatch(setUserData());
        dispatch(setWatchedEpisodes());
      });
  };

  const onTimeUpdateHandler = (e) => {
    const { duration, currentTime } = e.target;
    if (!inCurrentTrails && data.episode_code.includes("S1-E2")) {
      addToCurrentTrails();
    }
    // mark as finished if current time > 0.8 of duration and not marked before as finished
    if (currentTime / duration > 0.8 && !finished) {
      finishedHandler();
    }
  };
  const onEndedHandler = () => {
    setFinishModal(true);
    closeHandler();

    if (data.isTheLastEpisode && showFinishTrail) {
      setIsOpenGreenModal(showFinishTrail);
    }
  };

  useEffect(() => {
    const isFinished = watched_episodes.includes(data.episode_code);
    setFinished(isFinished);
    setInCurrentTrails(current_trails.includes(data.trail_code));
    setShowFinishTrail(!finished_trails.includes(data.trail_code));
  }, [data]);

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content
        style={[
          styles.modalContainer,
          {
            backgroundColor: isMobile ? colors.beige : colors.white,
            height: isMobile ? "100%" : "auto",
            paddingTop: Platform.OS == "ios" ? Constants.statusBarHeight : 0,
            borderRadius: isMobile ? 0 : 20,
            width: isMobile ? "100%" : "95%",
          },
        ]}
      >
        <ImageBackground
          source={require("../../assets/background.png")}
          style={{ flex: 1 }}
        >
          {isMobile ? (
            <View style={styles.backContainer}>
              <Pressable onPress={closeHandler} style={styles.backButton}>
                <View onPress={closeHandler} style={{ width: 24, height: 24 }}>
                  <ArrowBack />
                </View>
              </Pressable>
            </View>
          ) : (
            <Modal.CloseButton focusable={false} style={{ marginRight: 10 }} />
          )}
          <Modal.Body
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? height - 70 : "auto",
              marginTop: Platform.OS === "ios" ? -Constants.statusBarHeight : 0,
            }}
          >
            <View
              style={{
                width: isMobile ? width : "90%",
                borderRadius: !isMobile ? 20 : 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                overflow: "hidden",
                flex: 1,
                alignItems: "center",
                marginTop: isMobile ? 0 : 40,
                justifyContent: "center",
              }}
            >
              {Platform.OS == "web" ? (
                <Surface
                  elevation={15}
                  style={{
                    borderRadius: 20,
                    elevation: 15,
                    overflow: "hidden",
                    backgroundColor: colors.blue3,
                    cursor: "pointer",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <video
                    ref={vidRef}
                    autoPlay={false}
                    src={url + "play_480p.mp4"}
                    poster={url + "/thumbnail.jpg"}
                    onEnded={onEndedHandler}
                    onTimeUpdate={onTimeUpdateHandler}
                    preload="true"
                    crossOrigin="anonymous"
                    controls
                    controlsList="nodownload noremoteplayback"
                    width="100%"
                  >
                    {data.srt.map((srt, i) => {
                      return (
                        <track
                          key={srt.value}
                          default={i == 0 ? true : false}
                          kind="captions"
                          label={srt.label}
                          src={url + `captions/${srt.value.toUpperCase()}.vtt`}
                          srcLang={srt.value.toLowerCase()}
                        ></track>
                      );
                    })}
                  </video>
                  <LinearGradient
                    // Background Linear Gradient
                    colors={["rgba(0,0,0,0.8)", "rgba(0,0,0,0.2)"]}
                    style={{
                      position: "absolute",
                      width: 80,
                      height: 80,
                      top: -2,
                      right: -2,
                      padding: 10,
                      borderBottomLeftRadius: 20,
                    }}
                  >
                    <LislUp color={colors.white} />
                  </LinearGradient>
                </Surface>
              ) : (
                /** ANDROID || IOS CASES */
                <View style={{ width: "100%", flex: 1 }}>
                  <WebView
                    onMessage={(event) => {
                      const d = event.nativeEvent.data;
                      if (d == "ended") {
                        closeHandler();
                        setFinishModal(true);
                        if (data.isTheLastEpisode && showFinishTrail) {
                          setIsOpenGreenModal(showFinishTrail);
                        }
                      } else {
                        const splittedD = d.split("-");
                        const duration = splittedD[0];
                        const current = splittedD[1];

                        if (
                          !inCurrentTrails &&
                          data.episode_code.includes("S1-E2")
                        ) {
                          addToCurrentTrails();
                        }

                        // if not finished before and watched more than 80%
                        if (current / duration > 0.8 && !finished) {
                          finishedHandler();
                        }
                      }
                    }}
                    containerStyle={{
                      margin: 0,
                      padding: 0,
                    }}
                    allowsFullscreenVideo={true}
                    scalesPageToFit={false}
                    setBuiltInZoomControls={false}
                    useWebView2={true}
                    source={{
                      html: `
            <body style="margin:0 !important; padding:0 !important; background-color:#FBF6F3; ">
            <div style="margin:0px; padding:0px; width:100%; height:100%; display:flex; align-items:center; justify-content:center;  ">
              <div style="border-radius:10px; overflow:hidden;  margin:0 ; padding:0 ; margin-top:-20px; width:98%; ">
              <video
              preload="true"
              ontimeupdate="onTimeUpdate(this)"
              onended="onEnded()"
              poster="${url + "thumbnail.jpg"}"
              crossorigin="anonymous"
              src="${url + "play_480p.mp4"}"
              controls
              allowfullscreen
              controlsList="nodownload"
              width="100%" 
            > 
            ${data.srt.map(
              (srt) => `"<track
            key="${srt.value}"
            kind="captions"
            label="${srt.label}"
            src="${url + `captions/${srt.value.toUpperCase()}.vtt`}"
            srcLang="${srt.value.toLowerCase()}"
          ></track>"`
            )}
            </video>
            </div>
            </div>
            <script>
          function onEnded() {
                window.ReactNativeWebView.postMessage("ended")
          }
          function onTimeUpdate(e) {
            window.ReactNativeWebView.postMessage(e.duration + "-" + e.currentTime)
          }
          </script>
            </body>
            `,
                    }}
                  />
                </View>
              )}
            </View>
          </Modal.Body>
        </ImageBackground>
      </Modal.Content>
      {/*finish episode modal*/}
      <FinishEpisodeModal
        isOpen={finishModal}
        setIsOpen={setFinishModal}
        item={data}
      />
    </Modal>
  );
};

export default TrailEpisodeVideo;

const styles = StyleSheet.create({
  backContainer: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.beige,
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
  backButton: {
    height: 70,
    width: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B496515",
  },

  modalContainer: {
    maxWidth: 800,
    maxHeight: 5000,
  },
});
