import { ClipPath, Defs, G, Path, Rect, Svg } from "react-native-svg";

export const AppIcon = ({ color }) => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G clipPath="url(#clip0_765_3773)">
        <Path
          d="M17.5 1.01L7.5 1C6.4 1 5.5 1.9 5.5 3V21C5.5 22.1 6.4 23 7.5 23H17.5C18.6 23 19.5 22.1 19.5 21V3C19.5 1.9 18.6 1.01 17.5 1.01ZM17.5 19H7.5V5H17.5V19Z"
          fill={color || "#A0C460"}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_765_3773">
          <Rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export const PaymentIcon = ({ color }) => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M14.4492 3.82422L11.1719 0.546875C10.8203 0.195313 10.3438 -0.00390625 9.84766 -0.00390625H1.875C0.839844 8.03266e-10 0 0.839844 0 1.875V18.125C0 19.1602 0.839844 20 1.875 20H13.125C14.1602 20 15 19.1602 15 18.125V5.15234C15 4.65625 14.8008 4.17578 14.4492 3.82422ZM13.5664 4.71094C13.6484 4.79297 13.7031 4.89063 13.7305 5H10V1.26953C10.1094 1.29688 10.207 1.35156 10.2891 1.43359L13.5664 4.71094ZM13.125 18.75H1.875C1.53125 18.75 1.25 18.4688 1.25 18.125V1.875C1.25 1.53125 1.53125 1.25 1.875 1.25H8.75V5.3125C8.75 5.83203 9.16797 6.25 9.6875 6.25H13.75V18.125C13.75 18.4688 13.4688 18.75 13.125 18.75ZM11.25 9.21875V9.53125C11.25 9.78906 11.0391 10 10.7812 10H4.21875C3.96094 10 3.75 9.78906 3.75 9.53125V9.21875C3.75 8.96094 3.96094 8.75 4.21875 8.75H10.7812C11.0391 8.75 11.25 8.96094 11.25 9.21875ZM11.25 11.7188V12.0313C11.25 12.2891 11.0391 12.5 10.7812 12.5H4.21875C3.96094 12.5 3.75 12.2891 3.75 12.0313V11.7188C3.75 11.4609 3.96094 11.25 4.21875 11.25H10.7812C11.0391 11.25 11.25 11.4609 11.25 11.7188ZM11.25 14.2188V14.5313C11.25 14.7891 11.0391 15 10.7812 15H4.21875C3.96094 15 3.75 14.7891 3.75 14.5313V14.2188C3.75 13.9609 3.96094 13.75 4.21875 13.75H10.7812C11.0391 13.75 11.25 13.9609 11.25 14.2188Z"
        fill={color || "#A0C460"}
      />
    </Svg>
  );
};

export const TrailIcon = ({ color }) => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M24.8016 18.0742L13.5516 0.574219C13.3215 0.216406 12.9254 0 12.5 0C12.0746 0 11.6785 0.216406 11.4485 0.574219L0.198438 18.0742C0.0770335 18.2631 0.0087224 18.4811 0.000680416 18.7054C-0.00736157 18.9298 0.0451624 19.1522 0.152735 19.3492C0.260358 19.5462 0.419037 19.7106 0.61211 19.8251C0.805183 19.9396 1.02553 20 1.25 20H23.75C24.2074 20 24.6282 19.7504 24.8473 19.3488C24.9549 19.1519 25.0074 18.9295 24.9993 18.7053C24.9913 18.481 24.923 18.263 24.8016 18.0742ZM12.5 3.56172L15.8356 8.75H12.5L10 11.25L8.51329 9.76328L12.5 3.56172Z"
        fill={color || "#A0C460"}
      />
    </Svg>
  );
};

export const WorkshopIcon = ({ color }) => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M10.625 7.49652C9.9375 7.49652 9.375 8.05902 9.375 8.74652V11.8715C9.375 12.2168 9.09531 12.4965 8.75 12.4965C8.40469 12.4965 8.125 12.2168 8.125 11.8715V8.88128C8.125 8.20199 8.30937 7.5348 8.65977 6.95198L11.6969 1.88987C12.052 1.29769 11.8605 0.530107 11.2684 0.174638C10.7039 -0.164034 9.99023 0.00666894 9.61601 0.534404C9.6082 0.543388 9.5918 0.542607 9.58516 0.553154L5.00469 7.42425C4.59219 8.043 4.375 8.76214 4.375 9.50355V12.6375L0.854687 13.811C0.605769 13.8938 0.389246 14.0529 0.235834 14.2657C0.0824217 14.4785 -9.11274e-05 14.7342 7.55246e-08 14.9965V18.7465C7.55246e-08 19.1692 0.332813 19.9965 1.25 19.9965C1.35508 19.9965 1.46133 19.9832 1.56484 19.9563L8.56445 18.1352C10.5141 17.5778 11.875 15.7735 11.875 13.7465V8.74652C11.875 8.05902 11.3125 7.49652 10.625 7.49652ZM24.1453 13.8141L20.625 12.6407V9.50667C20.625 8.76527 20.4078 8.04613 19.9953 7.42738L15.4148 0.556669C15.4078 0.546122 15.3918 0.547294 15.384 0.537919C15.0098 0.0101846 14.2961 -0.160519 13.7316 0.178153C13.1395 0.533232 12.948 1.3012 13.3031 1.89339L16.3402 6.9555C16.6906 7.53831 16.875 8.2055 16.875 8.8848V11.875C16.875 12.2203 16.5953 12.5 16.25 12.5C15.9047 12.5 15.625 12.2203 15.625 11.875V8.75003C15.625 8.06253 15.0625 7.50003 14.375 7.50003C13.6875 7.50003 13.125 8.06253 13.125 8.75003V13.75C13.125 15.777 14.4859 17.5813 16.4355 18.1383L23.4352 19.9594C23.5391 19.9864 23.6453 19.9996 23.75 19.9996C24.6672 19.9996 25 19.1723 25 18.7496V14.9996C25 14.4618 24.6559 13.9844 24.1453 13.8141Z"
        fill={color || "#A0C460"}
      />
    </Svg>
  );
};

export const OtherIcon = ({ color }) => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M19.0766 20.4122L19.0776 20.4132C19.2207 20.5563 19.4968 20.6937 19.7763 20.6937C20.0634 20.6937 20.3385 20.5497 20.6006 20.2876C21.0165 19.8718 21.0165 19.1805 20.6006 18.7646L16.5984 14.7624C17.7545 13.2557 18.3691 11.4419 18.3691 9.5408C18.3691 4.71182 14.4298 0.9 9.7283 0.9C4.90027 0.9 1.0875 4.71277 1.0875 9.5408C1.0875 14.3698 5.02683 18.1816 9.7283 18.1816C11.6804 18.1816 13.5366 17.4423 15.062 16.2829L19.0766 20.4122ZM3.2971 9.4152C3.2971 5.95238 6.14237 2.984 9.7283 2.984C13.313 2.984 16.1595 5.95113 16.1595 9.5408C16.1595 11.2745 15.5408 12.8813 14.3048 14.1173C13.0688 15.3533 11.462 15.972 9.7283 15.972C6.26916 15.972 3.2971 12.8792 3.2971 9.4152Z"
        fill={color || "#A0C460"}
        stroke={color || "#A0C460"}
        strokeWidth="0.2"
      />
    </Svg>
  );
};
