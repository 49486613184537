import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { DropDown, Input } from "../../components/Inputs";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../components/Buttons";
import Spinner from "../../components/Spinner";
import { colors } from "../../styles/GlobalStyle";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { AlertDialog } from "native-base";
import DimensionsHook from "../../hooks/DimensionsHook";
import { Ionicons } from "@expo/vector-icons";
import { H5 } from "../../components/TextsComponents";
import { useSelector } from "react-redux";

const AddPost = ({ trail_code, seasons, setShowAddPost }) => {
  const { t, i18n } = useTranslation();
  const { isDesktop } = DimensionsHook();
  const [title, setTitle] = useState("");
  const [errorTitle, setErrorTitle] = useState(false);
  const [text, setText] = useState("");
  const [errorText, setErrorText] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showSeason, setShowSeason] = useState(false);
  const [currentSeason, setCurrentSeason] = useState(seasons[0]);
  const [showSuccessAddPost, setShowSuccessAddPost] = useState(false);
  const { pseudo } = useSelector((state) => state.userData);
  const submitHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.COMMUNITY_POST, {
          access_token: TOKEN,
          title,
          text,
          trail_code,
          language: i18n.language,
          season: currentSeason,
          pseudo,
        })
        .then(() => {
          setSubmitLoader(false);
          setTitle("");
          setCurrentSeason(seasons[0]);
          setText("");
          setShowSuccessAddPost(true);
        })
        .catch((err) => {
          setSubmitLoader(false);
        });
    }
  };
  return (
    <View>
      {/** title */}
      <Input
        placeholder={t("placeholders.community_title")}
        value={title}
        error={errorTitle}
        onChangeText={(val) => {
          // CHECK IF VALUE IS VALID
          setTitle(val);
          const isValid = val.length < 6 ? false : true;
          setErrorTitle(!isValid);
        }}
        style={{ marginTop: 10 }}
      />
      <View style={{ marginTop: 15, zIndex: 100 }}>
        <DropDown
          height={58}
          placeholder=""
          show={showSeason}
          setShow={() => setShowSeason(!showSeason)}
          value={currentSeason}
          setValue={setCurrentSeason}
          options={seasons}
        />
      </View>
      {/** text */}
      <Input
        multiline
        numberOfLines={6}
        placeholder={t("placeholders.community_text")}
        value={text}
        error={errorText}
        onChangeText={(val) => {
          // CHECK IF VALUE IS VALID
          setText(val);
          const isValid = val.length < 20 ? false : true;
          setErrorText(!isValid);
        }}
        style={{ marginTop: 10 }}
      />
      <PrimaryButton
        disabled={
          errorText || errorTitle || text.length == 0 || title.length == 0
        }
        style={{ marginTop: 30, marginHorizontal: 15 }}
        onPress={submitHandler}
      >
        {submitLoader ? (
          <Spinner color={colors.white} />
        ) : (
          t("placeholders.community_publish")
        )}
      </PrimaryButton>
      {/** success add post modal */}
      <AlertDialog isOpen={showSuccessAddPost}>
        <AlertDialog.Content
          style={{
            width: isDesktop ? 400 : "95%",
            paddingVertical: 40,
            paddingHorizontal: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Ionicons name="shield-checkmark" size={100} color={colors.green2} />
          <H5 style={{ textAlign: "center", marginTop: 20 }}>
            {t("community.add_post")}
          </H5>
          <PrimaryButton
            onPress={() => {
              setShowSuccessAddPost(false);
              setShowAddPost(false);
            }}
            style={{ marginTop: 20 }}
          >
            {t("back")}
          </PrimaryButton>
        </AlertDialog.Content>
      </AlertDialog>
    </View>
  );
};

export default AddPost;

const styles = StyleSheet.create({});
