import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import HeaderComponent from "../../components/HeaderComponent";
import NavigationMenu from "../../components/myaccount/NavigationMenu";
import { H3 } from "../../components/TextsComponents";
import SettingsScreen from "../../components/myaccount/SettingsScreen";
import { useTranslation } from "react-i18next";

const AccountSettings = ({ navigation }) => {
  const { isDesktop, height } = DimensionsHook();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web"}>
          {isDesktop ? (
            <HeaderComponent myaccount={true} navigation={navigation} />
          ) : (
            <BackHeader
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              navigation={navigation}
              myaccount={true}
              active="AccountSettings"
            />
          )}
          <View style={styles.contentContainer}>
            {isDesktop && (
              <H3 style={{ marginVertical: 10, marginLeft: 10 }}>
                {t("my_account")}
              </H3>
            )}
            <View
              style={{
                flexDirection: "row",

                alignItems: "flex-start",
                width: "100%",
              }}
            >
              {isDesktop ? (
                <View style={{ width: 300 }}>
                  <NavigationMenu
                    navigation={navigation}
                    active="AccountSettings"
                  />
                </View>
              ) : (
                <></>
              )}
              {/** settings screen */}
              <View
                style={[
                  styles.screenContainer,
                  {
                    backgroundColor: isDesktop ? colors.white : "transparent",
                    paddingHorizontal: isDesktop ? 20 : 10,
                    marginHorizontal: isDesktop ? 20 : 0,
                    paddingTop: isDesktop ? 20 : 0,
                    paddingBottom: isDesktop ? 10 : 0,
                    minHeight: isDesktop ? "auto" : height - 95,
                  },
                ]}
              >
                <SettingsScreen navigation={navigation} />
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default AccountSettings;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  contentContainer: { marginHorizontal: "2%", marginTop: 10 },
  screenContainer: {
    flex: 1,
    borderRadius: 20,
  },
});
