import React, { useRef } from "react";
import { colors } from "../../styles/GlobalStyle";
import { useTranslation } from "react-i18next";
import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import DimensionsHook from "../../hooks/DimensionsHook";
import { H3, H6 } from "../../components/TextsComponents";
import BackHeader from "../../components/BackHeader";
import AddPost from "./AddPost";
import Posts from "./Posts";

const Community = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { isDesktop } = DimensionsHook();

  const trail = route.params;
  const seasonsFromTrail = trail.seasons;
  const seasons = seasonsFromTrail.map((s, i) => {
    return { value: i + 1, code: s, label: `${t("trail.season")} ${i + 1}` };
  });

  const scrollRef = useRef();
  const scrollToTop = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <BackHeader
          isOpen={false}
          setIsOpen={() => {}}
          navigation={navigation}
        />
        <ScrollView
          ref={scrollRef}
          showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          <View
            style={{
              paddingTop: 10,
              paddingBottom: 30,
              marginHorizontal: isDesktop ? "2.5%" : 10,
            }}
          >
            <H3
              style={{
                marginTop: 10,
                marginBottom: 20,
                fontSize: isDesktop ? 30 : 24,
              }}
            >
              {t("community.title")} - {trail.title}
            </H3>
            {/** screen container */}
            <View style={{ flex: 1 }}>
              {/** form container */}
              {/* <View
                style={{
                  padding: 15,
                  flex: 1,
                  backgroundColor: "#ffffff95",
                  borderRadius: 20,
                }}
              >
                <H6>{t("community.new_post")}</H6>
                <AddPost trail_code={trail.trail_code} seasons={seasons} />
              </View>*/}
              {/** posts container */}
              <View
                style={{
                  paddingVertical: 20,
                  paddingHorizontal: isDesktop ? 15 : 5,
                  width: isDesktop ? "90%" : "100%",
                  backgroundColor: "#ffffff95",
                  marginRight: isDesktop ? 20 : 0,
                  marginTop: isDesktop ? 0 : 20,
                  borderRadius: 20,
                  minHeight: 200,
                  alignSelf: "center",
                }}
              >
                <Posts
                  seasons={seasons}
                  scrollToTop={scrollToTop}
                  trail_code={trail.trail_code}
                  trail_title={trail.title}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default Community;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
});
