import {
  Pressable,
  StyleSheet,
  View,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useRef } from "react";
import { Modal } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { ArrowBack } from "../../assets/svg/Icons";
import { LinearGradient } from "expo-linear-gradient";
import Constants from "expo-constants";
import { LislUp } from "../../assets/svg/Logo";
import { Surface } from "react-native-paper";
import WebView from "react-native-webview";

const VideoMovement = ({ isOpen, setIsOpen, data }) => {
  const { isMobile, width, height } = DimensionsHook();
  const vidRef = useRef(null);
  const url = data.link.replace("playlist.m3u8", "");

  // close  video modal
  const closeHandler = () => {
    setIsOpen(false);
    if (Platform.OS == "web") {
      vidRef.current.pause();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content
        style={[
          styles.modalContainer,
          {
            backgroundColor: isMobile ? colors.beige : colors.white,
            height: isMobile ? "100%" : "auto",
            paddingTop: Platform.OS == "ios" ? Constants.statusBarHeight : 0,
            borderRadius: isMobile ? 0 : 20,
            width: isMobile ? "100%" : "95%",
          },
        ]}
      >
        <ImageBackground
          source={require("../../assets/background.png")}
          style={{ flex: 1 }}
        >
          {isMobile ? (
            <View style={styles.backContainer}>
              <Pressable onPress={closeHandler} style={styles.backButton}>
                <View onPress={closeHandler} style={{ width: 24, height: 24 }}>
                  <ArrowBack />
                </View>
              </Pressable>
            </View>
          ) : (
            <Modal.CloseButton focusable={false} style={{ marginRight: 10 }} />
          )}
          <Modal.Body
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? height - 70 : "auto",
              marginTop: Platform.OS === "ios" ? -Constants.statusBarHeight : 0,
            }}
          >
            <View
              style={{
                width: isMobile ? width : "90%",
                borderRadius: !isMobile ? 20 : 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                overflow: "hidden",
                flex: 1,
                alignItems: "center",
                marginTop: isMobile ? 0 : 40,
                justifyContent: "center",
              }}
            >
              {Platform.OS == "web" ? (
                <Surface
                  elevation={15}
                  style={{
                    borderRadius: 20,
                    elevation: 15,
                    overflow: "hidden",
                    backgroundColor: colors.blue3,
                    cursor: "pointer",
                    width: "100%",
                    marginBottom: 30,
                  }}
                >
                  <video
                    ref={vidRef}
                    autoPlay={false}
                    src={url + "play_480p.mp4"}
                    poster={url + "/thumbnail.jpg"}
                    onEnded={closeHandler}
                    preload="true"
                    crossOrigin="anonymous"
                    controls
                    controlsList="nodownload noremoteplayback"
                    width="100%"
                  >
                    {data.srt.map((srt, i) => {
                      return (
                        <track
                          key={srt.value}
                          default={i == 0 ? true : false}
                          kind="captions"
                          label={srt.label}
                          src={url + `captions/${srt.value.toUpperCase()}.vtt`}
                          srcLang={srt.value.toLowerCase()}
                        ></track>
                      );
                    })}
                  </video>
                  <LinearGradient
                    // Background Linear Gradient
                    colors={["rgba(0,0,0,0.8)", "rgba(0,0,0,0.2)"]}
                    style={{
                      position: "absolute",
                      width: 80,
                      height: 80,
                      top: -2,
                      right: -2,
                      padding: 10,
                      borderBottomLeftRadius: 20,
                    }}
                  >
                    <LislUp color={colors.white} />
                  </LinearGradient>
                </Surface>
              ) : (
                /** ANDROID || IOS CASES */
                <View style={{ width: "100%", flex: 1 }}>
                  <WebView
                    onMessage={(event) => {
                      const d = event.nativeEvent.data;
                      if (d == "ended") {
                        setIsOpen(false);
                      }
                    }}
                    containerStyle={{
                      margin: 0,
                      padding: 0,
                    }}
                    allowsFullscreenVideo={true}
                    scalesPageToFit={false}
                    setBuiltInZoomControls={false}
                    useWebView2={true}
                    source={{
                      html: `
              <body style="margin:0 !important; padding:0 !important; background-color:#FBF6F3; ">
              <div style="margin:0px; padding:0px; width:100%; height:100%; display:flex; align-items:center; justify-content:center;  ">
                <div style="border-radius:10px; overflow:hidden;  margin:0 ; padding:0 ; margin-top:-20px; width:98%; ">
                <video
                preload="true"
                onended="onEnded()"
                poster="${url + "thumbnail.jpg"}"
                crossorigin="anonymous"
                src="${url + "play_480p.mp4"}"
                controls
                allowfullscreen
                controlsList="nodownload"
                width="100%" 
              > 
              ${data.srt.map(
                (srt) => `"<track
              key="${srt.value}"
              kind="captions"
              label="${srt.label}"
              src="${url + `captions/${srt.value.toUpperCase()}.vtt`}"
              srcLang="${srt.value.toLowerCase()}"
            ></track>"`
              )}
             
              </video>
              </div>
              </div>
              <script>
            function onEnded() {
                  window.ReactNativeWebView.postMessage("ended")
            }
           
            </script>
              </body>
              `,
                    }}
                  />
                </View>
              )}
            </View>
          </Modal.Body>
        </ImageBackground>
      </Modal.Content>
    </Modal>
  );
};

export default VideoMovement;

const styles = StyleSheet.create({
  backContainer: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.beige,
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
  backButton: {
    height: 70,
    width: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B496515",
  },

  modalContainer: {
    maxWidth: 800,
    maxHeight: 5000,
  },
});
