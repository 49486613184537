import { AlertDialog, View } from "native-base";
import React from "react";
import { PrimaryButton } from "../Buttons";
import { BoldTxt, Txt } from "../TextsComponents";

const EulaModal = ({ isOpen, setIsOpen }) => {
  const onClose = () => setIsOpen(false);

  return (
    <AlertDialog isOpen={isOpen}>
      <AlertDialog.Content style={{ width: "95%", maxWidth: 1000 }}>
        <AlertDialog.Header>
          <BoldTxt style={{ fontSize: 15 }}>End User License Agreement</BoldTxt>
        </AlertDialog.Header>
        <AlertDialog.Body>
          <View style={{ paddingHorizontal: 15 }}>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}>
              LISL UP app is licensed to You (End-User) by MASSA TRAILS, located
              and registered at 47 rue des Batignolles, 75017 PARIS, FRANCE
              ("Licensor"), for use only under the terms of this License
              Agreement.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              By downloading the Licensed Application from Apple's software
              distribution platform ("App Store") and Google's software
              distribution platform ("Play Store"), and any update thereto (as
              permitted by this License Agreement), You indicate that You agree
              to be bound by all of the terms and conditions of this License
              Agreement, and that You accept this License Agreement. App Store
              and Play Store are referred to in this License Agreement as
              "Services."
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              LISL UP app (“Licensed Application”) is licensed, not sold, to
              You. Your license is subject to your prior acceptance of the
              present end user license agreement (“EULA”) between You and MASSA
              TRAILS. Your license under this EULA is granted by MASSA TRAILS.
              LISL UP app is referred to herein as the “Licensed Application.”
              MASSA TRAILS (“Licensor”) reserves all rights in and to the
              Licensed Application not expressly granted to you under this EULA.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              Licensed Application is to be used on devices that operate with
              Apple's operating systems ("iOS" and "Mac OS") or Google's
              operating system ("Android").
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              1. Scope of License
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {" "}
              a. Licensor grants to you a non transferable license to use the
              Licensed Application on any Apple-branded products or Android
              products that you own or control and as permitted by the Usage
              Rules.{" "}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {" "}
              b. The terms of this EULA will govern any content, materials, or
              services accessible from or purchased within the Licensed
              Application as well as upgrades provided by Licensor that replace
              or supplement the original Licensed Application, unless such
              upgrade is accompanied by a Custom EULA.{" "}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {" "}
              c. Except as provided in the Usage Rules, you may not distribute
              or make the Licensed Application available over a network where it
              could be used by multiple devices at the same time. You may not
              transfer, redistribute or sublicense the Licensed Application and,
              if you sell your Apple Device or Android Device to a third party,
              you must remove the Licensed Application from the Apple Device or
              Android Device before doing so.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {" "}
              d. You may not copy (except as permitted by this license and the
              Usage Rules), reverse-engineer, disassemble, attempt to derive the
              source code of, modify, or create derivative works of the Licensed
              Application, any updates, or any part thereof (except as and only
              to the extent that any foregoing restriction is prohibited by
              applicable law or to the extent as may be permitted by the
              licensing terms governing use of any open-sourced components
              included with the Licensed Application). If so, You may create and
              store copies only on devices that You own or control for backup
              keeping under the terms of this license, the Usage Rules, and any
              other terms and conditions that apply to the device or software
              used. You may not remove any intellectual property notices. You
              acknowledge that no unauthorized third parties may gain access to
              these copies at any time.
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              2. Consent to Use of Data
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              You agree that Licensor may collect and use technical data and
              related information—including but not limited to technical
              information about your device, system and application software,
              and peripherals—that is gathered periodically to facilitate the
              provision of software updates, product support, and other services
              to you (if any) related to the Licensed Application. Licensor may
              use this information, as long as it is in a form that does not
              personally identify you, to improve its products or to provide
              services or technologies to you.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              You acknowledge that Licensor will be able to access and adjust
              Your downloaded Licensed Application content and Your personal
              information, and that Licensor's use of such material and
              information is subject to Your legal agreements with Licensor and
              Licensor's privacy policy.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              The Licensed Application does not offer users the ability to
              submit or publish content. We may give you the opportunity to
              submit, post, in the community forum, to us or in the Licensed
              Application, exclusively text, comments, suggestions or personal
              information (collectively, "Comments"). Comments may only be
              visible to other users of the Licensed Application. As such, any
              Comments you transmit may be treated in accordance with the
              Licensed Application's Privacy Policy. When you create or make
              available Comments, you represent and warrant that:
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {" "}
              1. The creation, transmission, public display, and the accessing,
              downloading, or copying of your Comments do not and will not
              infringe the proprietary rights, including but not limited to the
              copyright, patent, trademark, trade secret, or moral rights of any
              third party.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              2. You are the creator and owner of or have the necessary
              licenses, rights, consents, releases, and permissions to use and
              to authorize us, the Licensed Application, and other users of the
              Licensed Application to use your Comments in any manner
              contemplated by the Licensed Application and this License
              Agreement.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              3. You have the written consent, release, and/or permission of
              each and every identifiable individual person in your Comments to
              use the name or likeness or each and every such identifiable
              individual person to enable inclusion and use of your Comments in
              any manner contemplated by the Licensed Application and this
              License Agreement.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              4. Your Comments are not false, inaccurate, or misleading.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              5. Your Comments are not unsolicited or unauthorized advertising,
              promotional materials, pyramid schemes, chain letters, spam, mass
              mailings, or other forms of solicitation.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              6. Your Comments are not obscene, lewd, lascivious, filthy,
              violent, harassing, libelous, slanderous, or otherwise
              objectionable (as determined by us).
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              7. Your Comments do not ridicule, mock, disparage, intimidate, or
              abuse anyone.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              8. Your Comments are not used to harass or threaten (in the legal
              sense of those terms) any other person and to promote violence
              against a specific person or class of people.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              9. Your Comments do not violate any applicable law, regulation, or
              rule.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              10. Your Comments do not violate the privacy or publicity rights
              of any third party.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              11. Your Comments do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health or
              well-being of minors.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              12. Your Comments do not include any offensive comments that are
              connected to race, national origin, gender, sexual preference, or
              physical handicap.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              13. Your Commentss do not otherwise violate, or link to material
              that violates, any provision of this License Agreement, or any
              applicable law or regulation.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {" "}
              14. Any use of the Licensed Application in violation of the
              foregoing violates this License Agreement and may result in, among
              other things, termination or suspension of your rights to use the
              Licensed Application.
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              3. Termination
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              The license is valid until terminated by MASSA TRAILS or by You.
              Your rights under this license will terminate automatically and
              without notice from MASSA TRAILS if You fail to comply with any
              term(s) of this license. Upon License termination, You shall stop
              all use of the Licensed Application, and destroy all copies, full
              or partial, of the Licensed Application.
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              4. External Services
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              The Licensed Application may enable access to Licensor’s and/or
              third-party services and websites (collectively and individually,
              "External Services"). You agree to use the External Services at
              your sole risk. Licensor is not responsible for examining or
              evaluating the content or accuracy of any third-party External
              Services, and shall not be liable for any such third-party
              External Services. Data displayed by any Licensed Application or
              External Service, including but not limited to financial, medical
              and location information, is for general informational purposes
              only and is not guaranteed by Licensor or its agents. You will not
              use the External Services in any manner that is inconsistent with
              the terms of this Standard EULA or that infringes the intellectual
              property rights of Licensor or any third party. You agree not to
              use the External Services to harass, abuse, stalk, threaten or
              defame any person or entity, and that Licensor is not responsible
              for any such use. External Services may not be available in all
              languages or in your Home Country, and may not be appropriate or
              available for use in any particular location. To the extent you
              choose to use such External Services, you are solely responsible
              for compliance with any applicable laws. Licensor reserves the
              right to change, suspend, remove, disable or impose access
              restrictions or limits on any External Services at any time
              without notice or liability to you.
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              5. NO WARRANTY
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED
              APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED
              BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES
              PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED "AS
              IS" AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF
              ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND
              CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY
              SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT
              NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF
              MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A
              PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NON
              INFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION
              OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL
              CREATE A WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES
              PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY
              SERVICING, REPAIR, OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW
              THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE
              STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND
              LIMITATIONS MAY NOT APPLY TO YOU.
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              6. Limitation of Liability
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE
              LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT,
              OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT
              LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS
              INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING
              OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED
              APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY
              (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO
              NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT
              APPLY TO YOU. In no event shall Licensor’s total liability to you
              for all damages (other than as may be required by applicable law
              in cases involving personal injury) exceed the amount of fifty
              dollars ($50.00). The foregoing limitations will apply even if the
              above stated remedy fails of its essential purpose.
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              7. Legal Compliance
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              You may not use or otherwise export or re-export the Licensed
              Application except as authorized by United States law and the laws
              of the jurisdiction in which the Licensed Application was
              obtained. In particular, but without limitation, the Licensed
              Application may not be exported or re-exported (a) into any
              U.S.-embargoed countries or (b) to anyone on the U.S. Treasury
              Department's Specially Designated Nationals List or the U.S.
              Department of Commerce Denied Persons List or Entity List. By
              using the Licensed Application, you represent and warrant that you
              are not located in any such country or on any such list. You also
              agree that you will not use these products for any purposes
              prohibited by United States law, including, without limitation,
              the development, design, manufacture, or production of nuclear,
              missile, or chemical or biological weapons.
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              8. Commercial items
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              The Licensed Application and related documentation are "Commercial
              Items", as that term is defined at 48 C.F.R. §2.101, consisting of
              "Commercial Computer Software" and "Commercial Computer Software
              Documentation", as such terms are used in 48 C.F.R. §12.212 or 48
              C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212
              or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the
              Commercial Computer Software and Commercial Computer Software
              Documentation are being licensed to U.S. Government end users (i)
              only as Commercial Items and (ii) with only those rights as are
              granted to all other end users pursuant to the terms and
              conditions herein. Unpublished-rights reserved under the copyright
              laws of the United States.
            </Txt>
            <BoldTxt
              style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}
            >
              9. Miscellaneous
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              Except to the extent expressly provided in the following
              paragraph, this Agreement and the relationship between You and
              Apple shall be governed by the laws of the State of California,
              excluding its conflicts of law provisions. You and Apple agree to
              submit to the personal and exclusive jurisdiction of the courts
              located within the county of Santa Clara, California, to resolve
              any dispute or claim arising from this Agreement. If (a) you are
              not a U.S. citizen; (b) you do not reside in the U.S.; (c) you are
              not accessing the Service from the U.S.; and (d) you are a citizen
              of one of the countries identified below, you hereby agree that
              any dispute or claim arising from this Agreement shall be governed
              by the applicable law set forth below, without regard to any
              conflict of law provisions, and you hereby irrevocably submit to
              the non-exclusive jurisdiction of the courts located in the state,
              province or country identified below whose law governs:
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              If you are a citizen of any European Union country or Switzerland,
              Norway or Iceland, the governing law and forum shall be the laws
              and courts of your usual place of residence.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              Specifically excluded from application to this Agreement is that
              law known as the United Nations Convention on the International
              Sale of Goods.
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              If any of the terms of this agreement should be or become invalid,
              the validity of the remaining provisions shall not be affected.
              Invalid terms will be replaced by valid ones formulated in a way
              that will achieve the primary purpose.
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 15,
              }}
            >
              Collateral agreements, changes and amendments are only valid if
              laid down in writing. The preceding clause can only be waived in
              writing.
            </Txt>
          </View>
          <PrimaryButton
            onPress={onClose}
            style={{ marginVertical: 30, width: 250, alignSelf: "center" }}
          >
            Accept
          </PrimaryButton>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default EulaModal;
