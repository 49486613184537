import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import HeaderComponent from "../../components/HeaderComponent";
import NavigationMenu from "../../components/myaccount/NavigationMenu";
import { H3 } from "../../components/TextsComponents";
import PaymentScreen from "../../components/myaccount/PaymentScreen";
import { useTranslation } from "react-i18next";
import AlertBar from "../../components/AlertBar";

const AccountPayment = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { isDesktop } = DimensionsHook();
  const [isOpen, setIsOpen] = useState(false);
  const successPayTrail = route.params ? route.params.success_pay_trail : false;
  const [showSuccessPayment, setShowSuccessPayment] = useState(successPayTrail);
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web"}>
          {isDesktop ? (
            <HeaderComponent myaccount={true} navigation={navigation} />
          ) : (
            <BackHeader
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              navigation={navigation}
              myaccount={true}
              active="AccountPayment"
            />
          )}
          <View style={styles.contentContainer}>
            {isDesktop && (
              <H3 style={{ marginVertical: 10, marginLeft: 10 }}>
                {t("my_account")}
              </H3>
            )}

            <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
              {isDesktop ? (
                <View style={{ width: 300 }}>
                  <NavigationMenu
                    navigation={navigation}
                    active="AccountPayment"
                  />
                </View>
              ) : (
                <></>
              )}
              {/** payment screen */}
              <View
                style={[
                  styles.screenContainer,
                  {
                    backgroundColor: isDesktop ? colors.white : "transparent",
                    paddingHorizontal: isDesktop ? 20 : 10,
                    marginHorizontal: isDesktop ? 20 : 0,
                    paddingVertical: isDesktop ? 20 : 0,
                  },
                ]}
              >
                <PaymentScreen navigation={navigation} />
              </View>
            </View>
            <View style={{ height: 30 }}></View>
          </View>
        </ScrollView>
        <AlertBar
          isOpen={showSuccessPayment}
          setIsOpen={setShowSuccessPayment}
          text={`${t("buy_trails.ios_success")}`}
        />
      </ImageBackground>
    </View>
  );
};

export default AccountPayment;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  contentContainer: { marginHorizontal: "2%", marginTop: 10 },
  screenContainer: {
    flex: 1,
    borderRadius: 20,
  },
});
