import {
  ImageBackground,
  StyleSheet,
  ScrollView,
  View,
  Platform,
} from "react-native";
import React, { useEffect, useState } from "react";
import { BoldTxt, H2, Txt } from "../components/TextsComponents";
import { colors } from "../styles/GlobalStyle";
import DimensionsHook from "../hooks/DimensionsHook";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch, useSelector } from "react-redux";
import LifeTimeCard from "./Plans/LifeTimeCard";
import TrialCard from "./Plans/TrialCard";
import SubscriptionCard from "./Plans/SubscriptionCard";
import { resetUser, setUserData } from "../redux/Actions";
import { PrimaryButton } from "../components/Buttons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";
import axios from "axios";
import EulaModal from "../components/modals/EulaModal";

const Plans = ({ navigation }) => {
  const { t } = useTranslation();
  const { height, width } = DimensionsHook();
  const userData = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [lifetimeData, setLifetimeData] = useState({});
  const [showEula, setShowEula] = useState(true);
  const [userGift, setUserGift] = useState(null);
  useEffect(() => {
    const interval = setInterval(async () => {
      dispatch(setUserData());
      if (
        (userData.subscription && userData.subscription_status == "active") ||
        userData.lifetime_subscription
      ) {
        navigation.replace("TabNavigation");
      }
      return clearInterval(interval);
    }, 2000);
  }, [userData]);

  const logoutHandler = async () => {
    await AsyncStorage.removeItem("access_token");
    dispatch(resetUser());

    navigation.reset({
      index: 0,
      routes: [{ name: "Loader" }],
    });
  };

  useEffect(() => {
    const giftUrl = process.env.GIFT_DATA;
    const url = process.env.SUBSCRIPTIONS_LIST;
    setLoader(true);
    axios
      .get(`${giftUrl}?email=${userData.email}`)
      .then((res) => {
        if (res.data == null) {
          setUserGift(null);
        } else {
          setUserGift(res.data);
        }
        axios
          .get(url)
          .then((res) => {
            const subs = res.data.filter(
              (product) => product.lifetime == false
            );
            const lifetime = res.data.find(
              (product) => product.lifetime == true
            );
            setLoader(false);
            setSubscriptions(subs);
            setLifetimeData(lifetime);
          })
          .catch((err) => {
            navigation.replace("NoConnection");
          });
      })
      .catch((err) => {
        navigation.replace("NoConnection");
      });
  }, []);

  if (loader) {
    return (
      <View style={styles.container}>
        <ImageBackground
          source={require("../assets/background.png")}
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <Spinner size="large" />
        </ImageBackground>
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView
          style={{ flex: 1 }}
          showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          <View style={[styles.plansContainer, { minHeight: height }]}>
            <View style={styles.logoutContainer}>
              <PrimaryButton onPress={logoutHandler}>
                <MaterialCommunityIcons
                  name="logout"
                  size={24}
                  color={colors.white}
                />
              </PrimaryButton>
            </View>
            <View>
              <H2
                style={{
                  textAlign: "center",
                  marginVertical: 20,
                  marginTop: 30,
                  marginHorizontal: 20,
                  fontSize: 24,
                  lineHeight: 30,
                }}
              >
                {t("plans.choose_plan")}
              </H2>
              {/** stripe break point to add code */}

              {Platform.OS != "ios" && width < 992 && (
                <Txt style={{ textAlign: "center", marginHorizontal: 15 }}>
                  {t("plans.code_text")}
                </Txt>
              )}
              {/** case is parrained show message */}
              {userData.parrained && (
                <Txt
                  style={{
                    textAlign: "center",
                    marginBottom: 15,
                  }}
                >
                  <BoldTxt>
                    {userData.parrainedBy && userData.parrainedBy}{" "}
                  </BoldTxt>
                  {t("parrainage.text2")}
                </Txt>
              )}
            </View>

            {/** cards container */}
            <View style={styles.cardsContainer}>
              <TrialCard userGift={userGift} navigation={navigation} />

              {subscriptions.map((plan) => {
                return (
                  <SubscriptionCard
                    navigation={navigation}
                    key={plan._id}
                    plan={plan}
                  />
                );
              })}
              <LifeTimeCard navigation={navigation} plan={lifetimeData} />
            </View>
            <View style={{ height: 20 }} />
          </View>
        </ScrollView>
        <EulaModal
          isOpen={showEula && Platform.OS != "web"}
          setIsOpen={setShowEula}
        />
      </ImageBackground>
    </View>
  );
};

export default Plans;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  cardsContainer: {
    width: "95%",
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  logoutContainer: {
    alignSelf: "flex-end",
    paddingTop: 30,
    paddingRight: 30,
  },
});
