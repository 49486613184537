import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useState, useEffect } from "react";
import BackHeader from "../components/BackHeader";
import { colors } from "../styles/GlobalStyle";
import { SearchComponent } from "../components/Inputs";
import DimensionsHook from "../hooks/DimensionsHook";
import { H3, H4 } from "../components/TextsComponents";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import axios from "axios";
import ResponsiveItem from "../components/seeall/ResponsiveItem";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { Entypo } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";

const FavoriteScreen = ({ navigation, route }) => {
  const { type } = route.params;
  const { isDesktop, isTablet, isBigScreen, width, height } = DimensionsHook();
  const { t, i18n } = useTranslation();
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  const getData = async () => {
    setLoader(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const endpoint =
      type == "Trail"
        ? process.env.USER_FAVOURITE_TRAILS
        : process.env.USER_FAVOURITE_WORKSHOPS;
    const Response = await axios.get(
      endpoint + `?language=${i18n.language}`,
      config
    );

    setData(Response.data);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const itemContainer = {
    backgroundColor: colors.grayBackground,
    marginTop: 15,
    height: 240,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    overflow: "hidden",
    width: isBigScreen
      ? (width * 0.95 - 15) * 0.25 - 20
      : isDesktop
      ? (width * 0.95 - 15) * 0.33 - 20
      : isTablet
      ? (width * 0.95 - 15) * 0.5 - 20
      : (width * 0.95 - 20) * 0.5 - 15,
    marginLeft: 15,
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView
          style={{ flex: 1 }}
          showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          <BackHeader
            isOpen={false}
            setIsOpen={() => {}}
            navigation={navigation}
          />

          {/** trails list */}
          <View style={[styles.trailsContainer, { minHeight: height - 130 }]}>
            <View
              style={{ flexDirection: "row", alignItems: "center", margin: 15 }}
            >
              <View style={{ marginTop: -5, marginRight: 7 }}>
                <AntDesign name="heart" size={30} color={colors.green2} />
              </View>
              <H4>
                {type == "Trail"
                  ? t("my_space.title2_trail")
                  : t("my_space.title2_workshop")}
              </H4>
            </View>
            {/** list of trails in rows  */}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 15,
                flexWrap: "wrap",
              }}
            >
              {loader ? (
                Array.from(Array(2)).map((_, i) => {
                  return (
                    <View key={i} style={itemContainer}>
                      <Spinner />
                    </View>
                  );
                })
              ) : Data.length === 0 ? (
                <View
                  style={{
                    width: "100%",
                    marginTop: 30,
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <Entypo
                    name="notifications-off"
                    size={40}
                    color={colors.grayLabel}
                  />
                  <H4
                    style={{ textAlign: "center", marginTop: 10 }}
                    color={colors.grayLabel}
                  >
                    {t("my_space.no_favorite")}
                  </H4>
                </View>
              ) : (
                Data.map((item) => {
                  return (
                    <ResponsiveItem
                      key={
                        type == "Trail" ? item.trail_code : item.workshop_code
                      }
                      item={item}
                      type={type}
                      navigation={navigation}
                    />
                  );
                })
              )}
            </View>
          </View>
          {isDesktop && <Footer />}
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default FavoriteScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  searchBarContainer: {
    flexDirection: "row",
    alignItems: "center",

    marginTop: 10,
  },
  trailsContainer: {
    minHeight: 500,
    width: "95%",
    alignSelf: "center",
    backgroundColor: colors.white,
    marginVertical: 20,
    borderRadius: 15,
    paddingVertical: 15,
  },
});
