import { StyleSheet, View } from "react-native";
import React from "react";
import { BoldTxt, H1, H7 } from "../../TextsComponents";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { colors } from "../../../styles/GlobalStyle";
import { PrimaryButton } from "../../Buttons";
import { useTranslation } from "react-i18next";

const Categories = ({ setState, categorieList }) => {
  const { isDesktop, width } = DimensionsHook();
  const isBig = width > 1100;
  const { t } = useTranslation();

  categorieList.shift();

  return (
    <View style={{ marginTop: isDesktop ? 0 : 20 }}>
      <H1>{t("account_navigation.help")}</H1>
      <BoldTxt style={{ marginVertical: 20 }}>
        {t("help_screen.select")}
      </BoldTxt>
      {/** cards */}
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: isBig ? "flex-start" : "space-between",
        }}
      >
        {categorieList.map((cat) => {
          return (
            <View
              key={cat.value}
              style={[
                styles.card,
                {
                  backgroundColor: isDesktop ? colors.beige : colors.white,
                  width: isBig ? "30%" : "48%",
                  marginRight: isBig ? 20 : 0,
                },
              ]}
            >
              <View style={{ height: 80 }} />
              <View style={styles.circle}>
                <View style={{ width: 30, height: 30 }}>
                  {cat.icon(colors.green3)}
                </View>
              </View>
              <H7
                style={{
                  textAlign: "center",
                  marginHorizontal: isBig ? 40 : 10,
                }}
              >
                {cat.label}
              </H7>

              <PrimaryButton
                onPress={() => setState(cat)}
                style={{ marginBottom: 20 }}
              >
                {t("help_screen.button")}
              </PrimaryButton>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default Categories;

const styles = StyleSheet.create({
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginVertical: 20,
  },
  circle: {
    width: 150,
    height: 150,
    paddingBottom: 30,
    borderRadius: 75,
    backgroundColor: "#C9DCC525",
    position: "absolute",
    top: -75,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  card: {
    height: 250,
    borderRadius: 20,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 20,
    overflow: "hidden",
  },
});
