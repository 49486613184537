import { Platform, StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { H6, H7, H1, Txt } from "../../TextsComponents";
import { PrimaryButton } from "../../Buttons";
import { ScoreBook, ScoreShake, ScoreGift } from "../../../assets/svg/Icons";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { colors } from "../../../styles/GlobalStyle";
import SoonModal from "../../modals/SoonModal";
import ParrainageModal from "../../modals/ParrainageModal";
import axios from "axios";
import GiftEmailModal from "./modals/GiftEmailModal";
import Spinner from "../../Spinner";
import GiftPlansModal from "./modals/GiftPlansModal";

const LowerSection = ({ navigation, scrollToTop }) => {
  const { t } = useTranslation();
  const { isDesktop, width, isMobile } = DimensionsHook();
  const cardStyle = {
    backgroundColor: isDesktop ? colors.beige : colors.white,
    width: isMobile ? "100%" : width > 1250 ? "22.5%" : "46.25%",
    height: isMobile ? 220 : 280,
    marginLeft: isMobile ? 0 : "2.5%",
    paddingBottom: 20,
    paddingHorizontal: 8,
  };

  const [showSoonModal, setShowSoonModal] = useState(false);
  const [showParrainage, setShowParrainage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [showGiftEmailModal, setShowGiftEmailModal] = useState(false);
  const [showGiftPlansModal, setShowGiftPlansModal] = useState(false);
  const [gifts, setGifts] = useState([]);

  const giftPlanHandler = () => {
    if (Platform.OS == "ios") {
      setShowSoonModal(true);
    } else {
      setLoader(true);
      axios
        .get(process.env.GIFTS_LIST)
        .then((res) => {
          setGifts(res.data);
          setLoader(false);
          setShowGiftEmailModal(true);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  return (
    <View>
      <H1>{t("buy_screen.lower.title")}</H1>

      <Txt style={{ marginBottom: 20 }}>{t("buy_screen.lower.text1")}</Txt>
      {/** cards */}
      <View style={styles.cardsContainer}>
        {/** card 1 - gift subscription  */}
        <View style={[styles.card, cardStyle]}>
          <View style={{ height: 80 }} />
          <View style={styles.circle}>
            <ScoreGift />
          </View>
          <H7 style={{ textAlign: "center" }}>{t("buy_screen.lower.text2")}</H7>

          <PrimaryButton onPress={giftPlanHandler} style={styles.button}>
            {loader ? <Spinner color="white" /> : t("buy_screen.lower.button")}
          </PrimaryButton>
        </View>
        {/** card 2 - gift ebook */}
        {Platform.OS != "ios" && (
          <View style={[styles.card, cardStyle]}>
            <View style={styles.circle}>
              <ScoreBook />
            </View>
            <View style={{ height: 80 }} />
            <H7 style={{ textAlign: "center" }}>
              {t("buy_screen.lower.text3")}
            </H7>

            <PrimaryButton
              onPress={() => navigation.navigate("Books", { gift: true })}
              style={styles.button}
            >
              {t("buy_screen.lower.button")}
            </PrimaryButton>
          </View>
        )}
        {/** card 3 - gift flash coaching */}
        <View style={[styles.card, cardStyle]}>
          <View style={styles.circle}>
            <ScoreShake />
          </View>
          <View style={{ height: 80 }} />
          <H7 style={{ textAlign: "center" }}>{t("buy_screen.lower.text4")}</H7>

          <PrimaryButton
            onPress={() => navigation.navigate("Appointment", { gift: true })}
            style={styles.button}
          >
            {t("buy_screen.lower.button")}
          </PrimaryButton>
        </View>
      </View>
      <View style={styles.divider} />

      {/** parrainage  */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: isDesktop ? 0 : 30,
        }}
      >
        <H6>{t("buy_screen.lower.parrain")}</H6>
        <PrimaryButton onPress={() => setShowParrainage(true)}>
          {t("buy_screen.lower.button1")}
        </PrimaryButton>
      </View>
      {/** soon modal */}
      <SoonModal
        don={true}
        isOpen={showSoonModal}
        setIsOpen={setShowSoonModal}
      />
      {/** parrainage modal */}
      <ParrainageModal
        scrollToTop={scrollToTop}
        isOpen={showParrainage}
        setIsOpen={setShowParrainage}
      />
      {/** email to gift modal */}
      <GiftEmailModal
        isOpen={showGiftEmailModal}
        setIsOpen={setShowGiftEmailModal}
        email={email}
        setEmail={setEmail}
        setShowGiftPlansModal={setShowGiftPlansModal}
      />
      {/** gifts plan modal */}
      <GiftPlansModal
        isOpen={showGiftPlansModal}
        setIsOpen={setShowGiftPlansModal}
        email={email}
        gifts={gifts}
        setEmail={setEmail}
      />
    </View>
  );
};

export default LowerSection;

const styles = StyleSheet.create({
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginVertical: 20,
  },
  circle: {
    width: 150,
    height: 150,
    paddingBottom: 30,
    borderRadius: 75,
    backgroundColor: "#C9DCC525",
    position: "absolute",
    top: -75,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  card: {
    borderRadius: 20,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 20,
    overflow: "hidden",
  },
  cardsContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  button: {
    marginBottom: 20,
  },
});
