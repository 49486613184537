import { ImageBackground, Image, StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { H2, SmallBoldTxt, SmallTxt, Txt } from "../components/TextsComponents";
import { colors } from "../styles/GlobalStyle";
import { Input } from "../components/Inputs";
import { PrimaryButton } from "../components/Buttons";
import BackHeader from "../components/BackHeader";
import Spinner from "../components/Spinner";
import DimensionsHook from "../hooks/DimensionsHook";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ResetPassword = ({ navigation }) => {
  const { width } = DimensionsHook();
  const [email, setEmail] = useState("");
  const { isDesktop } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const { t } = useTranslation();

  const setAsyncTimeout = (cb, timeout = 0) =>
    new Promise((resolve) => {
      setTimeout(() => {
        cb();
        resolve();
      }, timeout);
    });

  const submitHandler = async () => {
    try {
      setSubmitLoader(true);
      setErrorMessage("");
      if (email.length < 3) {
        setSubmitLoader(false);
        setErrorMessage(t("reset_pass_screen.error_email"));
        return;
      }
      const payload = { email: email.trim().toLocaleLowerCase() };
      await setAsyncTimeout(() => {
        // Do stuff
      }, 1000);
      const Reponse = await axios.post(
        process.env.USER_SEND_FORGOT_PASSWORD,
        payload
      );

      navigation.replace("ChangePassword", email);
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 404) {
        setErrorMessage(t("reset_pass_screen.error_email"));
      } else {
        setErrorMessage(t("reset_pass_screen.error_connexion"));
      }
    }

    setSubmitLoader(false);
  };

  return (
    <View style={styles.container}>
      <BackHeader isOpen={false} setIsOpen={() => {}} navigation={navigation} />
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: -20,
          }}
        >
          <H2 style={{ textAlign: "center" }}>{t("signin.forgot_password")}</H2>
          <SmallBoldTxt
            style={{
              color: colors.red1,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            {errorMessage.length > 0 ? errorMessage : " "}
          </SmallBoldTxt>
          <View style={{ width: "95%", maxWidth: 500, marginVertical: 20 }}>
            <Txt style={{ marginBottom: 10 }}>
              {t("reset_pass_screen.text1")}
            </Txt>
            <Input
              placeholder={t("placeholders.email")}
              value={email}
              onChangeText={(val) => setEmail(val)}
            />
            <SmallTxt style={{ textAlign: "justify", marginTop: 30 }}>
              {t("reset_pass_screen.text2") + " "}
              <SmallBoldTxt>{t("reset_pass_screen.button")}</SmallBoldTxt>,{" "}
              {t("reset_pass_screen.text3") + " "}
            </SmallTxt>
            <PrimaryButton
              onPress={submitHandler}
              style={{ marginVertical: 30 }}
            >
              {submitLoader ? (
                <Spinner
                  color={submitLoader ? colors.white : colors.blue3}
                  size="small"
                />
              ) : (
                t("reset_pass_screen.button")
              )}
            </PrimaryButton>
          </View>
        </View>
      </ImageBackground>
      {width > 1000 && (
        <Image
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
          }}
          style={styles.image}
        />
      )}
    </View>
  );
};

export default ResetPassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  image: {
    position: "absolute",
    bottom: 0,
    right: 20,
    height: 230,
    width: 140,
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    borderRadius: 20,
  },
});
