import {
  StyleSheet,
  View,
  ScrollView,
  ImageBackground,
  Image,
} from "react-native";
import React from "react";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import { CoachingCalender, CoachingQuestion } from "../../assets/svg/space";
import CoachingTitle from "./Components/CoachingTitle";
import CoachingCards from "./Components/CardCoaching";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";

const Coaching = ({ navigation }) => {
  const { isDesktop, isBigScreen } = DimensionsHook();
  const { t } = useTranslation();
  const Container = {
    width: "100%",
    alignItems: "center",
  };

  const desktopContent = {
    width: "100%",
    flexDirection: !isDesktop ? "column" : "row",
    justifyContent: "center",
    alignItems: !isDesktop ? "center" : "stretch",
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView>
          <BackHeader
            isOpen={false}
            setIsOpen={() => {}}
            navigation={navigation}
          />
          <View style={Container}>
            {/* Top Rendez-vous  */}
            <CoachingTitle />

            {/* Body Cards */}
            <View style={desktopContent}>
              <CoachingCards
                TitleCard={t("coaching.expert_title")}
                Imge={CoachingCalender}
                BodyText={t("coaching.expert_text")}
                ButtonText={t("coaching.expert_button")}
                navigation={navigation}
              />
              <CoachingCards
                Imge={CoachingQuestion}
                TitleCard={t("coaching.quiz_title")}
                BodyText={t("coaching.quiz_text")}
                ButtonText={t("coaching.quiz_button")}
                navigation={navigation}
                soon={true}
              />
            </View>
          </View>
        </ScrollView>

        {isBigScreen && (
          <Image
            source={{
              uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
            }}
            style={styles.image}
          />
        )}
      </ImageBackground>
    </View>
  );
};

export default Coaching;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },

  image: {
    position: "absolute",
    bottom: 0,
    right: 20,
    height: 230,
    width: 140,
  },
});
