const data = [
  {
    _id: "626955c55d4814054a9e073c",
    question: "Titre de la question sur 2 lignes possible  ",
    possible_answers: [
      "lorem ipsum 1",
      "lorem ipsum 2",
      "lorem ipsum 3",
      "lorem ipsum 4",
    ],
  },
  {
    _id: "626955c55d486654a9e073e",
    question: "Titre de la question sur 4 lignes possible  ",
    possible_answers: [
      "lorem ipsum 1",
      "lorem ipsum 2",
      "lorem ipsum 3",
      "lorem ipsum 4",
      "lorem ipsum 5",
    ],
  },
  {
    _id: "626955c55d4810954a9e073f",
    question: "Titre de la question sur 3 lignes possible",
    possible_answers: ["lorem ipsum 1", "lorem ipsum 2", "lorem ipsum 3"],
  },
  {
    _id: "626955clld4814054a9e073g",
    question: "Titre de la question lignes possible",
    possible_answers: ["lorem ipsum 1", "lorem ipsum 2"],
  },
  {
    _id: "6269ttc55d4814054a9e073h",
    question: "Titre de la question lignes possible",
    possible_answers: [
      "lorem ipsum 1",
      "lorem ipsum 2",
      "lorem ipsum 3",
      "lorem ipsum 4",
    ],
  },
];
export default data;
