import { StyleSheet, View, Image, TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import { Actionsheet } from "native-base";
import { CloseIcon, LislUp } from "../../assets/svg/Logo";
import { PlayIcon, LikeIcon, FavoriteIcon } from "../../assets/svg/Icons";
import { BoldTxt, H6, H5, Txt, SmallTxt } from "../TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";

import { LinearGradient } from "expo-linear-gradient";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setUserActivity } from "../../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  BoiteIcon,
  CoachingIcon,
  ConscienceIcon,
  CorpsIcon,
  PlaisirIcon,
  RituelsIcon,
  ScienceIcon,
  TemoignageIcon,
} from "../../assets/svg/WorkshopBadgeIcons";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const RolloverSmall = ({ item, isOpen, onClose, type, navigation }) => {
  const { width } = DimensionsHook();
  const { t } = useTranslation();
  // liked and favorite user lists
  let code = type == "Trail" ? item.trail_code : item.workshop_code;
  const userActivity = useSelector((state) => state.userActivity);
  const liked =
    type == "Trail" ? userActivity.liked_trails : userActivity.liked_workshops;
  const favourite =
    type == "Trail"
      ? userActivity.favourite_trails
      : userActivity.favourite_workshops;

  // like favorite states
  // like favorite states
  const [isLiked, setIsLiked] = useState(liked.includes(code));
  const [isFavorite, setIsFavorite] = useState(favourite.includes(code));
  // PRESS LIKE & FAVOURITE FUNCTIONS
  const dispatch = useDispatch();
  const likePressHandler = async () => {
    setIsLiked(!isLiked);
    const TOKEN = await AsyncStorage.getItem("access_token");
    if (type === "Trail") {
      axios
        .post(process.env.USER_LIKE_TRAILS, {
          access_token: TOKEN,
          trail_code: item.trail_code,
        })
        .then(() => dispatch(setUserActivity()));
    } else {
      axios
        .post(process.env.USER_LIKE_WORKSHOPS, {
          access_token: TOKEN,
          workshop_code: item.workshop_code,
        })
        .then(() => dispatch(setUserActivity()));
    }
  };
  const favoritePressHandler = async () => {
    setIsFavorite(!isFavorite);
    const TOKEN = await AsyncStorage.getItem("access_token");
    if (type === "Trail") {
      axios
        .post(process.env.USER_FAVOURITE_TRAILS, {
          access_token: TOKEN,
          trail_code: item.trail_code,
        })
        .then(() => dispatch(setUserActivity()));
    } else {
      axios
        .post(process.env.USER_FAVOURITE_WORKSHOPS, {
          access_token: TOKEN,
          workshop_code: item.workshop_code,
        })
        .then(() => dispatch(setUserActivity()));
    }
  };

  const Categories = [
    {
      label: t("workshop_types.label"),
      value: "",
    },
    {
      label: t("workshop_types.conscience"),
      value: "conscience",
      textColor: colors.blue3,
      backgroundColor: colors.green2,
      icon: () => <ConscienceIcon />,
    },
    {
      label: t("workshop_types.corps"),
      value: "body",
      textColor: colors.blue3,
      backgroundColor: colors.yellow,
      icon: () => <CorpsIcon />,
    },
    {
      label: t("workshop_types.plaisir"),
      value: "fun",
      textColor: colors.blue3,
      backgroundColor: colors.green3,
      icon: () => <PlaisirIcon />,
    },
    {
      label: t("workshop_types.rituels"),
      value: "rituals",
      textColor: colors.white,
      backgroundColor: colors.red1,
      icon: () => <RituelsIcon color="white" />,
    },
    {
      label: t("workshop_types.science"),
      value: "science",
      textColor: colors.white,
      backgroundColor: colors.blue3,
      icon: () => <ScienceIcon color="white" />,
    },
    {
      label: t("workshop_types.temoignages"),
      value: "testimony",
      textColor: colors.blue3,
      backgroundColor: colors.blue0,
      icon: () => <TemoignageIcon />,
    },
    {
      label: t("workshop_types.outils"),
      value: "tools",
      textColor: colors.blue3,
      backgroundColor: colors.blue2,
      icon: () => <BoiteIcon />,
    },
    {
      label: t("workshop_types.coaching"),
      value: "coaching",
      textColor: colors.blue3,
      backgroundColor: colors.red0,
      icon: () => <CoachingIcon />,
    },
  ];
  const category = Categories.find((cat) => cat.value === item.type) || null;

  const playHandler = () => {
    if (type === "Trail") {
      navigation.navigate("Trail", item);
      onClose();
    } else {
      navigation.navigate("Workshop", item);
      onClose();
    }
  };

  useEffect(() => {
    // check if in liked | favorite
    if (liked.includes(code)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
    if (favourite.includes(code)) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [userActivity]);

  return (
    <Actionsheet
      backgroundColor="#00000098"
      hideDragIndicator
      isOpen={isOpen}
      onClose={onClose}
    >
      <View style={styles.container}>
        <View>
          <Image
            source={{ uri: item.poster_link }}
            style={{
              width,
              height: width * 0.7,
              resizeMode: "cover",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            }}
          />
          <LinearGradient
            colors={["#1B496500", "#1B496566"]}
            style={{
              width,
              height: width * 0.7,
              position: "absolute",
              borderTopRightRadius: 20,
              borderTopLeftRadius: 20,
            }}
          />
          <View style={[styles.logoCloseCotnainer, { width }]}>
            <View style={styles.logoContainer}>
              <LislUp color={colors.white} />
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {moment().diff(item.createdAt, "days") < 90 ? (
                <View style={styles.newButton}>
                  <Txt fontSize={13}>{t("trail.new")}</Txt>
                </View>
              ) : (
                <></>
              )}
              <TouchableOpacity onPress={onClose} style={styles.closeButton}>
                <CloseIcon />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.textsContainer}>
            <BoldTxt color={colors.white}>{type}</BoldTxt>
            <H6 style={{ textAlign: "center" }} color={colors.white}>
              {item.title}
            </H6>
            {type === "Atelier" && (
              <>
                {category && (
                  <View
                    style={[
                      styles.categoryBadge,
                      {
                        backgroundColor: category.backgroundColor,
                        opacity: 0.9,
                      },
                    ]}
                  >
                    <View style={{ width: 16, height: 16, marginRight: 8 }}>
                      {category.icon()}
                    </View>

                    <SmallTxt color={category.textColor}>
                      {category.label}
                    </SmallTxt>
                  </View>
                )}
              </>
            )}
          </View>
        </View>
        <View style={{ padding: 16 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            {/** play button */}
            <TouchableOpacity onPress={playHandler} style={styles.playButton}>
              <PlayIcon />
            </TouchableOpacity>
            {/** like button */}
            <TouchableOpacity
              onPress={likePressHandler}
              style={[
                styles.likeButton,
                {
                  borderColor: isLiked ? "transparent" : colors.grayBorder,
                  backgroundColor: isLiked ? colors.blue2 : "transparent",
                },
              ]}
            >
              <LikeIcon color={isLiked ? colors.white : colors.grayBorder} />
            </TouchableOpacity>
            {/** favorite button */}
            <TouchableOpacity
              onPress={favoritePressHandler}
              style={[
                styles.favoriteButton,
                {
                  borderColor: isFavorite ? "transparent" : colors.grayBorder,
                  backgroundColor: isFavorite ? colors.red1 : "transparent",
                },
              ]}
            >
              <FavoriteIcon
                color={isFavorite ? colors.white : colors.grayBorder}
              />
            </TouchableOpacity>
          </View>

          <View>
            <H5>{item.title}</H5>
            <Txt
              numberOfLines={3}
              style={{ textAlign: "justify", marginTop: 10 }}
            >
              {item.description}
            </Txt>
          </View>
        </View>
        {type == "Atelier" && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: 16,
            }}
          >
            <MaterialCommunityIcons
              name="clock-fast"
              size={20}
              color={colors.blue3}
            />
            <BoldTxt
              style={{ fontSize: 15, marginLeft: 5 }}
              color={colors.blue3}
            >{`${item.duration} Min`}</BoldTxt>
          </View>
        )}
        {/** seasons and go button */}
        {type === "Trail" && (
          <View style={styles.bottomRow}>
            {item.seasons.length > 0 ? (
              <BoldTxt fontSize={12}>
                {item.seasons.length +
                  ` saison${item.seasons.length > 1 ? "s" : ""}`}
              </BoldTxt>
            ) : (
              <BoldTxt></BoldTxt>
            )}
          </View>
        )}
        <View style={{ marginBottom: 10 }} />
      </View>
    </Actionsheet>
  );
};

export default RolloverSmall;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  logoCloseCotnainer: {
    position: "absolute",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    paddingVertical: 15,
  },
  logoContainer: {
    width: 40,
    height: 40,
  },

  newButton: {
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    borderRadius: 100,
  },
  textsContainer: {
    alignItems: "center",
    width: "100%",
    position: "absolute",
    bottom: 20,
  },
  closeButton: {
    width: 30,
    height: 30,
    marginHorizontal: 12,
    padding: 8,
  },
  playButton: {
    width: 50,
    height: 50,
    backgroundColor: colors.green2,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 12,
  },
  likeButton: {
    width: 50,
    height: 50,
    borderWidth: 1,

    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    marginLeft: 12,
  },
  favoriteButton: {
    width: 50,
    height: 50,
    borderWidth: 1,

    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    marginLeft: 12,
  },
  bottomRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    paddingHorizontal: 16,
  },
  goButton: { width: 18, height: 18 },
  badge: {
    paddingHorizontal: 12,
    borderRadius: 100,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  categoryBadge: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 30,
    width: "100%",
  },
});
