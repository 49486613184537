import {
  Pressable,
  StyleSheet,
  View,
  Platform,
  Linking,
  ImageBackground,
} from "react-native";
import React, { useState } from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../../../styles/GlobalStyle";
import {
  BoldTxt,
  H2,
  SmallBoldTxt,
  SmallTxt,
  Txt,
} from "../../../TextsComponents";
import { PrimaryButton } from "../../../Buttons";
import { useTranslation } from "react-i18next";
import DimensionsHook from "../../../../hooks/DimensionsHook";
import { AntDesign } from "@expo/vector-icons";
import Spinner from "../../../Spinner";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Purchases from "react-native-purchases";
import { setUserData } from "../../../../redux/Actions";

const BuyTrailsModal = ({ isOpen, setIsOpen, trailProduct, navigation }) => {
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = DimensionsHook();
  const { t, i18n } = useTranslation();
  const { customer } = useSelector((state) => state.userData);
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [submitLoaderPoints, setSubmitLoaderPoints] = useState(false);
  const closeHandler = async () => {
    setIsOpen(false);
    setQuantity(1);
    setSubmitLoader(false);
  };

  const addToQty = () => quantity < 3 && setQuantity(quantity + 1);
  const removeFromQty = () => quantity > 1 && setQuantity(quantity - 1);

  const trailInAppPurchaseHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      const price =
        quantity == 1
          ? trailProduct.ios_iap
          : quantity == 2
          ? trailProduct.ios_iap2
          : trailProduct.ios_iap3;
      try {
        await Purchases.purchaseProduct(price);
        await dispatch(setUserData());
        setSubmitLoader(false);
        closeHandler();
        navigation.navigate("AccountPayment", { success_pay_trail: true });
      } catch (error) {
        console.log(error);
        setSubmitLoader(false);
      }
    }
  };

  const createBuyTrailsSession = async (withPoints) => {
    const toggleLoader = () => {
      if (withPoints) {
        setSubmitLoaderPoints(false);
      } else {
        setSubmitLoader(false);
      }
    };
    if (!submitLoader && !submitLoaderPoints) {
      if (withPoints) {
        setSubmitLoaderPoints(true);
      } else {
        setSubmitLoader(true);
      }
      setErrorMessage(null);
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.BUY_TRAILS, {
          access_token: TOKEN,
          quantity,
          language: i18n.language,
          customer,
          points: trailProduct.points,
          price: withPoints
            ? trailProduct.stripe_points
            : quantity == 1
            ? trailProduct.stripe
            : quantity == 2
            ? trailProduct.stripe2
            : trailProduct.stripe3,
          withPoints,
        })
        .then((res) => {
          closeHandler();
          toggleLoader();
          const url = res.data.session.url;
          if (Platform.OS == "web") {
            window.open(url, "_blank");
          } else {
            Linking.openURL(url);
          }
        })
        .catch((err) => {
          toggleLoader();

          if (err.response.status === 409) {
            setErrorMessage(t("buy_trails.error_max_reached"));
          } else if (err.response.status === 403) {
            setErrorMessage(t("buy_trails.error_points"));
          } else {
            setSubmitLoader(false);
            setErrorMessage(t("buy_trails.error_connexion"));
          }
        });
    }
  };

  const preventSelect = Platform.OS == "web" ? { userSelect: "none" } : {};

  return (
    <Modal size="full" focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              style={{
                marginTop: isMobile ? 50 : 40,
                marginHorizontal: isMobile ? 10 : 30,
                marginBottom: 20,
              }}
            >
              <H2 style={{ textAlign: "center" }}>{t("buy_trails.title")}</H2>
              <SmallBoldTxt
                style={{
                  color: colors.red1,
                  textAlign: "center",

                  marginTop: 20,
                }}
              >
                {errorMessage ? errorMessage : " "}
              </SmallBoldTxt>
              <Txt style={{ marginTop: 20, textAlign: "center" }}>
                {t("buy_trails.text2")}
              </Txt>
              <Txt
                style={{ marginTop: 20, marginBottom: 30, textAlign: "center" }}
              >
                {t("buy_trails.text1")}
              </Txt>
              {/** qty section */}
              <View style={styles.qtyContent}>
                {/** minus button */}
                <Pressable
                  focusable={false}
                  onPress={removeFromQty}
                  style={[styles.button, preventSelect]}
                  disabled={quantity == 1}
                >
                  <AntDesign
                    name="minuscircle"
                    size={30}
                    color={quantity == 1 ? colors.grayBorder : colors.green2}
                  />
                </Pressable>
                {/** qty */}
                <View style={styles.quantityContainer}>
                  <Txt>{quantity}</Txt>
                </View>
                {/** plus button */}
                <Pressable
                  focusable={false}
                  onPress={addToQty}
                  style={[styles.button, preventSelect]}
                  disabled={quantity == 3}
                >
                  <AntDesign
                    name="pluscircle"
                    size={30}
                    color={quantity == 3 ? colors.grayBorder : colors.green2}
                  />
                </Pressable>
              </View>
              {/** total  */}
              <View
                style={[
                  styles.totalContainer,
                  { width: isDesktop ? "80%" : "100%" },
                ]}
              >
                <BoldTxt style={{ marginBottom: 15, marginTop: 10 }}>
                  {quantity} × {t("buy_trails.title1")}
                </BoldTxt>
                {/** pay eur */}
                <View style={styles.priceContainer}>
                  <View>
                    <SmallTxt
                      style={{
                        backgroundColor:
                          quantity == 1 ? "transparent" : colors.blue3,
                        paddingHorizontal: 10,
                        paddingVertical: 4,
                      }}
                      color={colors.white}
                    >
                      {/*quantity > 1 && (
                        <SmallTxt
                          color={colors.white}
                          style={{
                            textDecorationLine: "line-through",
                            textDecorationStyle: "solid",
                          }}
                        >
                          ({quantity * Number(trailProduct.price)}€){" "}
                        </SmallTxt>
                        )*/}
                      {quantity == 1
                        ? ""
                        : quantity == 2
                        ? "15% " + t("buy_trails.discount")
                        : "20% " + t("buy_trails.discount")}
                    </SmallTxt>

                    <BoldTxt style={{ marginVertical: 10, fontSize: 15 }}>
                      {(quantity == 1
                        ? Number(
                            Platform.OS == "ios"
                              ? trailProduct.ios_price
                              : trailProduct.price
                          )
                        : quantity == 2
                        ? Number(
                            Platform.OS == "ios"
                              ? trailProduct.ios_price2
                              : trailProduct.price2
                          )
                        : Number(
                            Platform.OS == "ios"
                              ? trailProduct.ios_price3
                              : trailProduct.price3
                          )
                      ).toFixed(2)}
                      € {t("tax")}
                    </BoldTxt>
                  </View>
                  <PrimaryButton
                    width={90}
                    onPress={() => {
                      if (Platform.OS == "ios") {
                        trailInAppPurchaseHandler();
                      } else {
                        createBuyTrailsSession(false);
                      }
                    }}
                  >
                    {submitLoader ? (
                      <Spinner color={colors.white} />
                    ) : (
                      t("buy_trails.pay_button")
                    )}
                  </PrimaryButton>
                </View>
                <View style={styles.divider} />
                {/** pay eur + points */}
                {Platform.OS != "ios" && (
                  <View style={[styles.priceContainer, { marginBottom: 10 }]}>
                    <BoldTxt style={{ marginVertical: 10, fontSize: 15 }}>
                      {(quantity * Number(trailProduct.price_points)).toFixed(
                        2
                      )}
                      €{t("tax")}+ {trailProduct.points * quantity}{" "}
                      {t("score_screen.points")}
                    </BoldTxt>

                    <PrimaryButton
                      width={90}
                      onPress={() => createBuyTrailsSession(true)}
                    >
                      {submitLoaderPoints ? (
                        <Spinner color={colors.white} />
                      ) : (
                        t("buy_trails.pay_button")
                      )}
                    </PrimaryButton>
                  </View>
                )}
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default BuyTrailsModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 900,
    maxHeight: 5000,
  },
  button: {
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    borderRadius: 25,
  },
  quantityContainer: {
    width: 75,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    backgroundColor: colors.grayBackground,
    marginHorizontal: 10,
  },
  totalContainer: {
    backgroundColor: colors.green0,
    paddingVertical: 10,

    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    borderRadius: 20,
  },
  buttonContainer: {
    width: 80,
    height: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  qtyContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  priceContainer: {
    width: "95%",
    paddingHorizontal: 10,
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: 10,
  },
  divider: {
    height: 1,
    width: "95%",
    alignSelf: "center",
    backgroundColor: colors.grayLabel,
    marginVertical: 10,
  },
});
