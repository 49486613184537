import { StyleSheet, Keyboard } from "react-native";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "native-base";
import { BoldTxt, SmallBoldTxt, Txt } from "../../../TextsComponents";
import DimensionsHook from "../../../../hooks/DimensionsHook";
import { Input } from "../../../Inputs";
import { PrimaryButton } from "../../../Buttons";
import axios from "axios";
import Spinner from "../../../Spinner";
import { colors } from "../../../../styles/GlobalStyle";

const GiftEmailModal = ({
  isOpen,
  setIsOpen,
  email,
  setEmail,
  setShowGiftPlansModal,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = DimensionsHook();
  const [errorEmail, setErrorEmail] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(" ");

  const submitHandler = () => {
    if (!loader) {
      setLoader(true);
      setErrorMessage(" ");
      axios
        .post(process.env.GIFT_CHECK_EMAIL, {
          email: email.toLowerCase().trim(),
        })
        .then((res) => {
          setLoader(false);
          setShowGiftPlansModal(true);
          setIsOpen(false);
          console.log(res.data);
        })
        .catch((err) => {
          setLoader(false);
          if (err.response.status === 409) {
            setErrorMessage(t("plans_gift.error_email"));
          } else {
            setErrorEmail("parrainage.error_connexion");
          }
        });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        avoidKeyboard
        justifyContent="flex-end"
        bottom="2"
        size={isDesktop ? "lg" : "full"}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>
            <BoldTxt style={{ fontSize: 16 }}>
              {t("buy_screen.lower.text2")}
            </BoldTxt>
          </Modal.Header>
          <Modal.Body p="5">
            <Txt style={{ fontSize: 14, textAlign: "justify" }}>
              {t("plans_gift.text1")}
            </Txt>
            <SmallBoldTxt
              style={{
                color: colors.red1,
                textAlign: "center",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              {errorMessage.length > 0 ? errorMessage : " "}
            </SmallBoldTxt>
            <BoldTxt style={{ fontSize: 14, marginTop: 20, marginBottom: 5 }}>
              {t("placeholders.email_destinataire")}
            </BoldTxt>
            <Input
              textContentType="emailAddress"
              kayboardType="email-address"
              placeholder={t("placeholders.email")}
              value={email}
              error={errorEmail}
              onChangeText={(val) => {
                // CHECK IF THE EMAIL IS VALID
                setEmail(val);
                const re = /\S+@\S+\.\S+/;
                const isValid = re.test(val);
                setErrorEmail(!isValid);
              }}
            />
            <PrimaryButton
              onPress={submitHandler}
              disabled={errorEmail || email.length == 0}
              style={{ marginVertical: 20 }}
            >
              {loader ? <Spinner color="white" /> : t("plans_gift.button1")}
            </PrimaryButton>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default GiftEmailModal;

const styles = StyleSheet.create({});
