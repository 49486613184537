import { View, StyleSheet } from "react-native";
import React, { useState } from "react";
import { colors } from "../../../../styles/GlobalStyle";
import { H7, LightTxt } from "../../../../components/TextsComponents";
import { PrimaryButton } from "../../../../components/Buttons";
import { DemiCircl, DemiCirclMob } from "../../../../assets/svg/space";
import DimensionsHook from "../../../../hooks/DimensionsHook";
import SoonModal from "../../../../components/modals/SoonModal";

const CoachingCards = ({
  Imge,
  TitleCard,
  BodyText,
  ButtonText,
  navigation,
  soon,
}) => {
  const { width, isDesktop, isBigScreen } = DimensionsHook();
  const [showGreenModal, setShowGreenModal] = useState(false);
  const card = {
    width: isBigScreen ? "30%" : isDesktop ? "45%" : "90%",
    marginHorizontal: 20,
    borderRadius: 20,
    paddingBottom: 20,
    backgroundColor: colors.white,
    marginBottom: 20,
    position: "relative",
    overflow: "hidden",
  };
  const BoxButton = {
    width: "100%",
    alignItems: !isDesktop ? "center" : "flex-start",
    marginLeft: !isDesktop ? 0 : 20,
    marginTop: 20,
  };
  const topCard = {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: !isDesktop ? 120 : "",
  };

  return (
    <View style={card}>
      <View style={topCard}>
        {width <= 1000 ? <DemiCirclMob /> : <DemiCircl />}
        <View style={styles.image}>
          <Imge />
        </View>
      </View>

      <View style={styles.Body}>
        <View style={styles.BoxTitle}>
          <H7>{TitleCard}</H7>
        </View>

        <View
          style={{
            textAlign: "justify",
          }}
        >
          <LightTxt style={{ paddingHorizontal: 25 }}>{BodyText}</LightTxt>
        </View>

        <View style={BoxButton}>
          <PrimaryButton
            onPress={() => {
              soon
                ? setShowGreenModal(true)
                : navigation.navigate("Appointment", { gift: false });
            }}
          >
            {ButtonText}
          </PrimaryButton>
        </View>
      </View>
      <SoonModal isOpen={showGreenModal} setIsOpen={setShowGreenModal} />
    </View>
  );
};

export default CoachingCards;
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },

  image: {
    width: 53,
    height: 53,
    position: "absolute",
    bottom: 45,
  },
  Body: {},
  BoxTitle: {
    marginVertical: 15,
    paddingLeft: 20,
  },
});
