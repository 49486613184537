import { StyleSheet, View } from "react-native";
import React from "react";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
import { useTranslation } from "react-i18next";
import { Box } from "native-base";
import { BoldTxt, Txt } from "../../components/TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { LislUp } from "../../assets/svg/Logo";

const PostResponse = ({ answer, createdAt }) => {
  const { i18n, t } = useTranslation();
  const { isDesktop } = DimensionsHook();
  if (i18n.language == "fr") {
    moment.locale(i18n.language);
  }
  return (
    <Box
      mt="3"
      p={isDesktop ? "4" : "2"}
      space={8}
      cursor="pointer"
      bg="#ffffff60"
      _dark={{
        borderColor: "coolGray.500",
      }}
      _light={{
        borderColor: "coolGray.200",
      }}
      style={styles.container}
    >
      <View style={styles.topContainer}>
        <View style={styles.row}>
          <View
            style={[
              styles.image,
              {
                width: isDesktop ? 40 : 30,
                height: isDesktop ? 40 : 30,
              },
            ]}
          >
            <LislUp />
          </View>

          {/** posted by */}
          <View style={styles.postedBy}>
            <BoldTxt style={{ fontSize: 15 }} color={colors.green2}>
              LISL UP - {t("community.moderator")}
            </BoldTxt>
            <Txt style={{ fontSize: 13 }} color={colors.grayLabel}>
              {moment(createdAt).fromNow()}
            </Txt>
          </View>
        </View>
      </View>

      <Txt
        style={{
          marginTop: 10,
          fontSize: 14,
          marginLeft: isDesktop ? 48 : 38,
        }}
      >
        {answer}
      </Txt>
    </Box>
  );
};

export default PostResponse;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignSelf: "center",
    borderWidth: 1,
    borderRadius: 5,
  },
  topContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: { flexDirection: "row", alignItems: "center" },
  image: {
    borderRadius: 50,

    backgroundColor: colors.white,
  },
  postedBy: {
    marginRight: 8,
    marginLeft: 8,
  },
  textsContainer: {
    width: "100%",
    justifyContent: "center",
    marginTop: 20,
  },
});
