import { StyleSheet, View, ImageBackground } from "react-native";
import React, { useState } from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import { H6, Txt } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { PrimaryButton } from "../Buttons";
import { useTranslation } from "react-i18next";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner";
import axios from "axios";
import { setUserActivity } from "../../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";

const ChooseVideoModal = ({
  isOpen,
  setIsOpen,
  setShowTrailVideoModal,
  setShowMovementVideoModal,
  selectedEpisode,
  setIsOpenBuyTrailModal,
  setIssOpenNeedSubscriptionModal,
  setIsOpenMaxEpisodesModal,
  setSelectedWorkshop,
  setShowNoAccessModal,
}) => {
  const { t, i18n } = useTranslation();
  const { isDesktop, isMobile, isTablet, height } = DimensionsHook();
  const { trails } = useSelector((state) => state.userActivity);
  const watchedEpisodes = useSelector((state) => state.watchedEpisodes);
  const { number_trails, lifetime_subscription, subscription, company } =
    useSelector((state) => state.userData);

  const dispatch = useDispatch();
  const [unlockLoader, setUnlockLoader] = useState(false);
  const [movementLoader, setMovementLoader] = useState(false);

  const [checkWatchedEpisodeLoader, setCheckWatchedEpisodeLoader] =
    useState(false);

  const episodeNumber = selectedEpisode.episode_code
    .split("-")[3]
    .replace("E", "");
  const seasonNumber = selectedEpisode.episode_code
    .split("-")[2]
    .replace("S", "");

  // TRAIL CODE EX E000-T001
  // SEASON CODE EX E000-T001-S1
  // EPISODE CODE EX E000-T001-S1-E1

  const isSecondEpisode = selectedEpisode.episode_code.includes("S1-E2");
  const IsFirstSeason = selectedEpisode.episode_code.includes("S1");
  const isWatched = watchedEpisodes.includes(selectedEpisode.episode_code);
  const isInUserTrails = trails.includes(selectedEpisode.trail_code);
  const isFreeTrail = selectedEpisode.trail_free;
  const isToUnlockTrail =
    isSecondEpisode && !isInUserTrails && !isWatched && !isFreeTrail;
  //console.log("isSecondEpisode", isSecondEpisode);
  //console.log("isWatched", isWatched);
  //console.log("isInUserTrails", isInUserTrails);
  //console.log("isFreeTrail", isFreeTrail);
  //console.log("isToUnlockTrail", isToUnlockTrail);

  const closeHandler = () => {
    setIsOpen(false);
    setMovementLoader(false);
  };

  const movementButtonHandler = async () => {
    if (!movementLoader) {
      setSelectedWorkshop(null);
      setMovementLoader(true);
      const url = process.env.WORKSHOPS_ENDPOINT;
      const TOKEN = await AsyncStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(
          `${url}/workshop?workshop_code=${selectedEpisode.workshop_code}&language=${i18n.language}`,
          config
        )
        .then((res) => {
          setSelectedWorkshop(res.data);
          console.log(res.data);
          closeHandler();
          setShowMovementVideoModal(true);
        })
        .catch((err) => {
          setMovementLoader(false);
          console.log(err);
        });
    }
  };

  const pressEpisodeButtonHandler = async () => {
    // if to unlock trail
    if (isToUnlockTrail) {
      // check if allowed to unlock trail if not show BuyTrailModal
      if (trails.length < number_trails) {
        if (!unlockLoader) {
          setUnlockLoader(true);
          // CASE 1 HAVE PERMISSION TO ADD NEW TRAIL
          // example : number_trails = 3 , trails.length = 2
          // ADD TRAIL CODE TO trails [] => isInUserTrails become true => we unlock it

          const TOKEN = await AsyncStorage.getItem("access_token");
          setTimeout(() => {
            axios
              .post(process.env.UNLOCK_TRAIL, {
                access_token: TOKEN,
                trail_code: selectedEpisode.trail_code,
              })
              .then(() => {
                dispatch(setUserActivity());
              })
              .catch((err) => {
                setUnlockLoader(false);
              });
          }, 1500);
        }
      } else {
        // CASE 2 HAVE NOT PERMISSION TO ADD NEW TRAIL TO HIS ACCOUNT -> BuyTrailModal
        // example : number_trails = 2 , trails.length = 2

        // CASE USER FREEMIUM
        if (!subscription && !lifetime_subscription && !company) {
          setIssOpenNeedSubscriptionModal(true);
          closeHandler();
        } else {
          // CASE USER IS NOT FREEMIUM  FREEMIUM
          closeHandler();
          setIsOpenBuyTrailModal(true);
        }
      }
    } else {
      // IF USER HAS ALREADY WATCHED THEN ACCESS OK
      if (isWatched) {
        // show ressource
        closeHandler();
        setShowTrailVideoModal(true);
        // IF TRAIL DAILY LIMIT == false
      } else if (!selectedEpisode.daily_limit) {
        closeHandler();
        setShowTrailVideoModal(true);
      } else {
        // check if user is freemium and want to watch other season than 1.
        // CASE USER FREEMIUM -> NO SEASON 2
        if (
          !IsFirstSeason &&
          !(subscription || lifetime_subscription || company)
        ) {
          closeHandler();
          setShowNoAccessModal(true);
        } else {
          // CHECK USER IF WATCHED 2 EPISODES TODAY OR NOT ( NOT IN DEVELOPMENT MODE)
          if (process.env.ENV_NAME == "production") {
            setCheckWatchedEpisodeLoader(true);
            const TOKEN = await AsyncStorage.getItem("access_token");
            const config = {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
              },
            };
            setTimeout(() => {
              axios
                .get(process.env.TODAY_EPISODES, config)
                .then((res) => {
                  // if watched episodes < 2 -> allowed to watch an episode
                  // if (res.data < 2) {

                  if (res.data < 2) {
                    setCheckWatchedEpisodeLoader(false);
                    closeHandler();
                    setShowTrailVideoModal(true);
                  } else {
                    setCheckWatchedEpisodeLoader(false);
                    closeHandler();
                    setIsOpenMaxEpisodesModal(true);
                  }
                })
                .catch((err) => {
                  setCheckWatchedEpisodeLoader(false);
                  //  console.log(err);
                });
            }, 600);
          } else {
            setCheckWatchedEpisodeLoader(false);
            closeHandler();
            setShowTrailVideoModal(true);
          }
        }
      }
    }
  };

  const numberOfTrailsRemaining = number_trails - trails.length;

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={[styles.container, { maxHeight: height * 0.9 }]}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />

            <VStack
              justifyContent="center"
              alignItems="center"
              style={{ marginVertical: !isMobile ? 30 : 20 }}
            >
              {isToUnlockTrail && (
                <View
                  style={{
                    width: isDesktop ? "80%" : isTablet ? "90%" : "95%",
                    marginBottom: -20,
                    marginLeft: 10,
                    marginTop: 20,
                  }}
                >
                  <Txt>{t("trail.access1")}</Txt>
                  <Txt style={{ marginTop: 10 }}>
                    {!(subscription || lifetime_subscription || company)
                      ? t("trail.freemium_access")
                      : t("trail.access2") +
                        " " +
                        numberOfTrailsRemaining +
                        " " +
                        t("tab_navigation.trails")}
                  </Txt>
                </View>
              )}

              {/** episode */}
              <View
                style={[
                  styles.sectionContainer,
                  {
                    backgroundColor: isMobile ? colors.white : colors.beige,
                    width: isDesktop ? "80%" : isTablet ? "90%" : "100%",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    justifyContent: isMobile ? "flex-start" : "space-between",
                  },
                ]}
              >
                <H6 style={{ paddingBottom: isMobile ? 12 : 0 }}>
                  {selectedEpisode.title
                    ? selectedEpisode.title
                    : `S${seasonNumber} - Ep${episodeNumber}`}
                  {selectedEpisode.duration && (
                    <Txt style={{ fontSize: 19 }} color={colors.blue3}>
                      {" | "}
                      <MaterialCommunityIcons
                        name="clock-fast"
                        size={19}
                        color={colors.blue3}
                      />

                      {` ${selectedEpisode.duration} min`}
                    </Txt>
                  )}
                </H6>
                <View
                  style={{
                    width: 200,
                    paddingTop: 0,
                  }}
                >
                  <PrimaryButton
                    whiteText={isToUnlockTrail}
                    style={
                      isToUnlockTrail
                        ? {
                            backgroundColor: colors.blue3,
                          }
                        : {}
                    }
                    onPress={pressEpisodeButtonHandler}
                  >
                    {isToUnlockTrail && unlockLoader ? (
                      <Spinner color={colors.white} />
                    ) : isToUnlockTrail && !unlockLoader ? (
                      t("trail.unlock")
                    ) : (
                      t("trail.button1")
                    )}
                  </PrimaryButton>
                </View>
              </View>
              {/** movement */}
              {selectedEpisode.workshop_code.length > 0 && (
                <View
                  style={[
                    styles.sectionContainer,
                    {
                      backgroundColor: isMobile ? colors.white : colors.beige,
                      width: isDesktop ? "80%" : isTablet ? "90%" : "100%",
                      flexDirection: isMobile ? "column" : "row",
                      marginTop: isMobile ? 10 : 30,
                      alignItems: isMobile ? "flex-start" : "center",
                      justifyContent: isMobile ? "flex-start" : "space-between",
                    },
                  ]}
                >
                  <H6 style={{ paddingBottom: isMobile ? 12 : 0 }}>
                    {t("trail.movement")}
                  </H6>
                  <View
                    style={{
                      width: 200,
                      paddingTop: 0,
                    }}
                  >
                    <PrimaryButton
                      disabled={!isWatched}
                      onPress={movementButtonHandler}
                    >
                      {movementLoader ? (
                        <Spinner color={colors.white} />
                      ) : (
                        t("trail.button")
                      )}
                    </PrimaryButton>
                  </View>
                </View>
              )}
              <View
                style={{
                  width: isDesktop ? "80%" : isTablet ? "90%" : "95%",
                  marginTop: 20,
                  marginBottom: isDesktop ? 0 : 20,
                }}
              >
                <Txt style={{ textAlign: "justify" }}>
                  {t("trail.first_visit_text")}
                </Txt>
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default ChooseVideoModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    maxWidth: 1000,
    maxHeight: 5000,
    width: "95%",
  },
  sectionContainer: {
    marginTop: 40,
    padding: 16,
    borderRadius: 16,
    alignSelf: "center",
  },
  backContainer: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.beige,
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
  backButton: {
    height: 70,
    width: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B496515",
  },
});
