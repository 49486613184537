import { Pressable, StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { SmallBoldTxt, SmallTxt, Txt } from "../../TextsComponents";
import { DropDown, Input, PhoneInput } from "../../Inputs";
import { TextInput } from "react-native-paper";
import { colors } from "../../../styles/GlobalStyle";
import { PrimaryButton } from "../../Buttons";
import Spinner from "../../Spinner";
import { MaterialIcons } from "@expo/vector-icons";
import { CheckBox } from "react-native-elements";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/Actions";
import { setAsyncTimeout } from "../../../hooks/Helpers";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { TouchableWithoutFeedback } from "react-native";
import DimensionsHook from "../../../hooks/DimensionsHook";
import * as WebBrowser from "expo-web-browser";

const Individiual = ({ navigation, scrollToTop }) => {
  const { t } = useTranslation();
  const { isMobile } = DimensionsHook();
  /* const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-.])(?=.{8,})"
  );*/

  const Language = [
    { label: t("placeholders.language"), value: "" },
    { label: t("language.fr"), value: "fr" },
    { label: t("language.en"), value: "en" },
  ];

  const genderOptions = [
    { value: "", label: t("placeholders.gender") },
    { value: "H", label: t("gender.male") },
    { value: "F", label: t("gender.female") },
    { value: "NP", label: t("gender.np") },
  ];
  const ageOptions = [
    { value: "", label: t("placeholders.age") },
    { value: "NP", label: t("gender.np") },
    { value: "18-25", label: t("age.option1") },
    { value: "26-35", label: t("age.option2") },
    { value: "36-45", label: t("age.option3") },
    { value: "46-55", label: t("age.option4") },
    { value: ">56", label: t("age.option5") },
  ];

  const [name, setName] = useState("");
  const [surname, setsurname] = useState("");
  const [email, setEmail] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [phoneCode, setPhoneCode] = useState("33");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [isSecure, setIsSecure] = useState(true);
  const [isConfirmSecure, setIsConfirmSecure] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [gender, setGender] = useState(genderOptions[0]);
  const [showLanguage, setShowLanguage] = useState(false);
  const [language, setLanguage] = useState(Language[0]);
  const [age, setAge] = useState(ageOptions[0]);
  const [showGender, setShowGender] = useState(false);
  const [showAge, setShowAge] = useState(false);
  const [companyUser, setCompanyUser] = useState(false);
  const [companyCode, setCompanyCode] = useState("");
  // list error data states
  const [errorName, setErrorName] = useState(false);
  const [errorSurname, setErrorSurname] = useState(false);
  const [errorPseudo, setErrorPseudo] = useState(false);
  const [errorPhoneCode, setErrorPhoneCode] = useState(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorCompanyCode, setErrorCompanyCode] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const toggleSecure = () => setIsSecure(!isSecure);
  const toggleConfirmSecure = () => setIsConfirmSecure(!isConfirmSecure);
  const dispatch = useDispatch();
  const submitHandler = async () => {
    // sign up user and navigate to validation code  screen
    setErrorMessage("");
    setSubmitLoader(true);

    if (!acceptTerms) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_terms"));
      scrollToTop();
      return;
    }

    if (surname.length < 3) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_surname"));
      scrollToTop();
      return;
    }
    if (name.length < 3) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_name"));
      scrollToTop();
      return;
    }
    if (pseudo.length < 3) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_pseudo"));
      scrollToTop();
      return;
    }
    if (gender.value === "") {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_gender"));
      scrollToTop();
      return;
    }
    if (age.value === "") {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_age"));
      scrollToTop();
      return;
    }
    if (language.value === "") {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_language"));
      scrollToTop();
      return;
    }
    if (email.length < 2) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_email"));
      scrollToTop();
      return;
    }

    if (isNaN(phoneCode) && phoneCode.length > 0) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_phone_code"));
      scrollToTop();
      return;
    }
    if (isNaN(phoneNumber) && phoneNumber.length > 0) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_phone"));
      scrollToTop();
      return;
    }

    const passwordTest = password.length >= 8;

    if (passwordTest === false) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_password"));
      scrollToTop();
      return;
    }
    if (confirmPassword != password) {
      setSubmitLoader(false);
      setErrorMessage(t("signup.error_confirm"));
      scrollToTop();
      return;
    }
    if (companyUser) {
      if (companyCode.length < 6) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_company_code"));
        scrollToTop();
        return;
      }
    }

    const payload = {
      lastname: surname,
      firstname: name,
      genre: gender.value,
      age: age.value,
      language: language.value,
      pseudo: pseudo.toLowerCase().trim(),
      email: email.toLowerCase().trim(),
      phoneCode: "+" + phoneCode,
      phoneNumber,
      password,
      companyCode: companyUser ? companyCode : null,
    };
    await setAsyncTimeout(() => {}, 1000);
    axios
      .post(process.env.USER_SIGNUP, payload)
      .then(async (res) => {
        const token = res.data.access_token;
        await AsyncStorage.setItem("access_token", token);
        await dispatch(setUserData());

        navigation.replace("Validation");
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setErrorMessage(t("signup.error_email_exist") + " " + email);
          scrollToTop();
          setSubmitLoader(false);
        } else if (err.response.status === 401) {
          setErrorMessage(t("signup.error_email"));
          scrollToTop();
          setSubmitLoader(false);
        } else if (err.response.status === 403) {
          setErrorMessage(t("signup.error_pseudo_exist"));
          scrollToTop();
          setSubmitLoader(false);
        } else if (err.response.status === 404) {
          setErrorMessage(t("signup.error_no_company"));
          scrollToTop();
          setSubmitLoader(false);
        } else if (err.response.status === 406) {
          setErrorMessage(t("signup.error_company_max_users"));
          scrollToTop();
          setSubmitLoader(false);
        } else if (err.response.status === 410) {
          setErrorMessage(t("signup.error_company_inactive"));
          scrollToTop();
          setSubmitLoader(false);
        } else if (err.response.status === 412) {
          setErrorMessage(t("signup.error_company_code"));
          scrollToTop();
          setSubmitLoader(false);
        } else if (err.response.status === 413) {
          setErrorMessage(t("signup.error_phone_exist"));
          scrollToTop();
          setSubmitLoader(false);
        } else {
          setErrorMessage(t("signup.error_connexion"));
          setSubmitLoader(false);
          scrollToTop();
        }
      });
  };

  const onFocusPseudo = () => {
    setErrorPseudo(false);
  };

  const onBlurPseudo = () => {
    setErrorPseudo(false);
    axios
      .get(
        `${process.env.VERIFY_PSEUDO}?pseudo=${pseudo
          .toLocaleLowerCase()
          .trim()}`
      )
      .then((res) => {
        setErrorPseudo(false);
        setErrorMessage("");
      })
      .catch((err) => {
        setErrorPseudo(true);
        setErrorMessage(t("signup.error_pseudo_exist"));
        scrollToTop();
      });
  };

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setShowAge(false);
        setShowGender(false);
        setShowLanguage(false);
      }}
    >
      <View>
        <SmallBoldTxt
          style={{
            color: colors.red1,
            textAlign: "center",
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          {errorMessage.length > 0 ? errorMessage : " "}
        </SmallBoldTxt>

        {/** surname */}
        <Input
          placeholder={t("placeholders.surname")}
          value={surname}
          error={errorSurname}
          onChangeText={(val) => {
            // CHECK IF THE PASSWORD IS VALID
            setsurname(val);
            const isValid = val.length < 3 ? false : true;
            setErrorSurname(!isValid);
          }}
        />
        {/** name */}
        <Input
          placeholder={t("placeholders.name")}
          value={name}
          error={errorName}
          onChangeText={(val) => {
            // CHECK IF THE PASSWORD IS VALID
            setName(val);
            const isValid = val.length < 3 ? false : true;
            setErrorName(!isValid);
          }}
          style={{ marginTop: 10 }}
        />
        {/** pseudo */}
        <Input
          onBlur={onBlurPseudo}
          onFocus={onFocusPseudo}
          placeholder="Pseudo"
          right={
            errorPseudo ? (
              <TextInput.Icon
                name={"account-alert"}
                size={24}
                color={colors.red1}
              />
            ) : (
              <></>
            )
          }
          value={pseudo}
          error={errorPseudo}
          onChangeText={(val) => {
            // CHECK IF THE PASSWORD IS VALID
            setPseudo(val);
            const isValid = val.length < 3 ? false : true;
            setErrorPseudo(!isValid);
          }}
          style={{ marginTop: 10 }}
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            zIndex: 15,
          }}
        >
          {/** gender */}
          <View style={{ marginTop: 15, width: "48%" }}>
            <DropDown
              height={58}
              placeholder=""
              show={showGender}
              setShow={() => setShowGender(!showGender)}
              value={gender}
              disableFirstOption={true}
              setValue={setGender}
              options={genderOptions}
            />
          </View>
          {/** age */}
          <View style={{ marginTop: 15, width: "48%" }}>
            <DropDown
              height={58}
              placeholder=""
              show={showAge}
              setShow={() => setShowAge(!showAge)}
              value={age}
              disableFirstOption={true}
              setValue={setAge}
              options={ageOptions}
            />
          </View>
        </View>
        {/** language  */}
        <View
          style={{
            zIndex: 5,
            marginTop: 15,
          }}
        >
          <DropDown
            height={64}
            placeholder={t("placeholders.language")}
            show={showLanguage}
            setShow={() => setShowLanguage(!showLanguage)}
            value={language}
            setValue={setLanguage}
            options={Language}
            disableFirstOption={true}
            navigation={navigation}
          />
        </View>
        {/** email */}
        <Input
          textContentType="emailAddress"
          kayboardType="email-address"
          placeholder={t("placeholders.email")}
          value={email}
          error={errorEmail}
          onChangeText={(val) => {
            // CHECK IF THE EMAIL IS VALID
            setEmail(val);
            const re = /\S+@\S+\.\S+/;
            const isValid = re.test(val);
            setErrorEmail(!isValid);
          }}
          style={{ marginTop: 10 }}
        />
        {/** phoneNumber */}
        <View style={{ marginTop: 15 }}>
          <PhoneInput
            disabled={false}
            codePlaceholder="33"
            placeholder={`12 34 56 78 90 (${t("placeholders.optional")})`}
            phoneCode={phoneCode}
            setPhoneCode={setPhoneCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </View>

        {/** password */}
        <Input
          mode="outlined"
          style={{ marginTop: 10 }}
          placeholder={t("placeholders.password")}
          secureTextEntry={isSecure}
          value={password}
          error={errorPassword}
          onChangeText={(val) => {
            // CHECK IF THE PASSWORD IS VALID
            setPassword(val);
            const isValid = val.length >= 8;
            setErrorPassword(!isValid);
          }}
          right={
            isSecure ? (
              <TextInput.Icon
                forceTextInputFocus={false}
                onPress={toggleSecure}
                name={"eye"}
                size={24}
                color={colors.blue3}
              />
            ) : (
              <TextInput.Icon
                forceTextInputFocus={false}
                onPress={toggleSecure}
                name="eye-off"
                size={24}
                color={colors.blue3}
              />
            )
          }
        />
        <SmallTxt
          style={{ marginRight: 5, marginTop: 5, marginLeft: 3, fontSize: 12 }}
        >
          {t("signup.error_password")}
        </SmallTxt>
        {/** confirm password */}
        <Input
          mode="outlined"
          style={{ marginTop: 10 }}
          placeholder={t("placeholders.confirm_password")}
          secureTextEntry={isConfirmSecure}
          value={confirmPassword}
          error={errorConfirmPassword}
          onChangeText={(val) => {
            // CHECK IF THE PASSWORD IS VALID
            setConfirmPassword(val);
            const isValid = password === val ? true : false;

            setErrorConfirmPassword(!isValid);
          }}
          right={
            isConfirmSecure ? (
              <TextInput.Icon
                forceTextInputFocus={false}
                onPress={toggleConfirmSecure}
                name={"eye"}
                size={24}
                color={colors.blue3}
              />
            ) : (
              <TextInput.Icon
                forceTextInputFocus={false}
                onPress={toggleConfirmSecure}
                name="eye-off"
                size={24}
                color={colors.blue3}
              />
            )
          }
        />
        {/** Company User ? */}
        <Pressable
          style={styles.companyCheck}
          onPress={() => setCompanyUser(!companyUser)}
        >
          <CheckBox
            containerStyle={{
              margin: 0,
              padding: 0,
              backgroundColor: "transparent",
              borderWidth: 0,
            }}
            onPress={() => setCompanyUser(!companyUser)}
            checked={companyUser}
            title={<></>}
            checkedIcon={
              <MaterialIcons name="check-box" size={24} color={colors.green2} />
            }
            uncheckedIcon={
              <MaterialIcons
                name="check-box-outline-blank"
                size={24}
                color={colors.grayBorder}
              />
            }
          />
          <View style={{ flex: 1 }}>
            <SmallTxt on color={companyUser ? colors.blue3 : colors.grayLabel}>
              {t("signup.company_text")}
            </SmallTxt>
          </View>
        </Pressable>
        {companyUser && (
          <Input
            placeholder={t("placeholders.company_code")}
            value={companyCode}
            error={errorCompanyCode}
            onChangeText={(val) => {
              // CHECK IF THE PASSWORD IS VALID
              setCompanyCode(val);
              const isValid = val.length < 6 ? false : true;
              setErrorCompanyCode(!isValid);
            }}
            style={{ marginTop: 10 }}
          />
        )}
        {/** accept terms */}
        <Pressable
          onPress={() => setAcceptTerms(!acceptTerms)}
          style={styles.acceptTermsContainer}
        >
          <CheckBox
            containerStyle={{
              margin: 0,
              padding: 0,
              backgroundColor: "transparent",
              borderWidth: 0,
            }}
            onPress={() => setAcceptTerms(!acceptTerms)}
            checked={acceptTerms}
            title={<></>}
            checkedIcon={
              <MaterialIcons name="check-box" size={24} color={colors.green2} />
            }
            uncheckedIcon={
              <MaterialIcons
                name="check-box-outline-blank"
                size={24}
                color={colors.grayBorder}
              />
            }
          />
          <View style={{ flex: 1 }}>
            <SmallTxt style={{ letterSpacing: 0.5 }}>
              {t("signup.text2")}{" "}
              <SmallBoldTxt
                onPress={() => {
                  WebBrowser.openBrowserAsync(
                    "https://www.lislup.com/conditions-generales-dutilisation/"
                  );
                }}
              >
                {t("signup.text3")}
              </SmallBoldTxt>{" "}
              {t("signup.text4")}{" "}
              <SmallBoldTxt
                onPress={() => {
                  WebBrowser.openBrowserAsync(
                    "https://www.lislup.com/politique-de-confidentialite/"
                  );
                }}
              >
                {t("signup.text5")}
              </SmallBoldTxt>
            </SmallTxt>
          </View>
        </Pressable>
        {/** submit button */}
        <PrimaryButton
          onPress={submitHandler}
          style={{ marginTop: 20, marginBottom: isMobile ? 300 : 50 }}
        >
          {submitLoader ? (
            <Spinner
              color={submitLoader ? colors.white : colors.blue3}
              size="small"
            />
          ) : (
            t("signup.submit")
          )}
        </PrimaryButton>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default Individiual;

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginVertical: 20,
  },
  acceptTermsContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 20,
    marginRight: 10,
  },
  companyCheck: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginTop: 20,
    marginRight: 10,
  },
});
