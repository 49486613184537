import {
  StyleSheet,
  View,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import React, { useState } from "react";
import {
  GeneralIcon,
  PaymentIcon,
  GiftIcon,
  SettingsIcon,
  HelpIcon,
  MentionsIcon,
  ContactIcon,
  LogoutIcon,
  ScoreIcon,
  AccountIcon,
} from "../../assets/svg/MyAccountIcons";
import { H3, Txt } from "../TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import GreenModal from "../modals/GreenModal";
import { Octicons } from "@expo/vector-icons";
import * as WebBrowser from "expo-web-browser";

const NavigationMenu = ({ navigation, active }) => {
  const [isOpenGreenModal, setIsOpenGreenModal] = useState(false);
  const { t } = useTranslation();
  const logoutHandler = async () => {
    setIsOpenGreenModal(true);
  };
  const { isDesktop } = DimensionsHook();
  const routing = [
    {
      name: t("account_navigation.general"),
      link: "AccountGeneral",
      icon: () => <GeneralIcon />,
    },
    {
      name: t("account_navigation.account"),
      link: "AccountDetails",
      icon: () => <AccountIcon />,
    },
    {
      name: t("account_navigation.payment"),
      link: "AccountPayment",
      icon: () => <PaymentIcon />,
    },
    {
      name: t("account_navigation.score"),
      link: "AccountScore",
      icon: () => <ScoreIcon />,
    },
    {
      name: t("account_navigation.buy"),
      link: "AccountBuy",
      icon: () => <GiftIcon />,
    },
    {
      name: t("account_navigation.settings"),
      link: "AccountSettings",
      icon: () => <SettingsIcon />,
    },
    {
      name: t("account_navigation.help"),
      link: "AccountHelp",
      icon: () => <HelpIcon />,
    },
    /*{
      name: t("account_navigation.mentions"),
      link: "AccountMentions",
      icon: () => <MentionsIcon />,
    },*/
    {
      name: t("account_navigation.contact"),
      link: "AccountContact",
      icon: () => <ContactIcon />,
    },
    {
      name: t("privacy.title"),
      link: "AccountPolitiques",
      icon: () => <MentionsIcon />,
    },
    {
      name: t("terms.title"),
      link: "AccountConditions",
      icon: () => <Octicons name="law" size={20} color={colors.blue3} />,
    },
  ];

  return (
    <View showsVerticalScrollIndicator={false}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        {!isDesktop && (
          <H3 style={{ marginVertical: 15 }}>{t("my_account")}</H3>
        )}
        {routing.map((r) => {
          const isActive = r.link === active;
          return (
            <TouchableOpacity
              key={r.link}
              onPress={() =>
                r.link === "AccountConditions"
                  ? WebBrowser.openBrowserAsync(
                      "https://www.lislup.com/conditions-generales-dutilisation/"
                    )
                  : r.link === "AccountPolitiques"
                  ? WebBrowser.openBrowserAsync(
                      "https://www.lislup.com/politique-de-confidentialite/"
                    )
                  : navigation.replace(r.link)
              }
              style={[
                styles.linkContainer,
                {
                  borderColor: isActive ? "transparent" : colors.grayBorder,
                  backgroundColor: !isActive ? "transparent" : colors.green2,
                },
              ]}
            >
              <View style={{ paddingLeft: 15, paddingRight: 10 }}>
                {r.icon()}
              </View>
              <View style={{ flex: 1, marginRight: 5 }}>
                <Txt numberOfLines={2} style={{ fontSize: 14, lineHeight: 18 }}>
                  {r.name}
                </Txt>
              </View>
            </TouchableOpacity>
          );
        })}

        {/** logout */}
        <TouchableOpacity
          onPress={logoutHandler}
          style={[
            styles.linkContainer,
            {
              borderColor: colors.grayBorder,
            },
          ]}
        >
          <View style={{ paddingHorizontal: 15 }}>
            <LogoutIcon />
          </View>
          <Txt style={{ fontSize: 14, lineHeight: 18 }}>
            {t("account_navigation.logout")}
          </Txt>
        </TouchableOpacity>
        {/** modal if logout */}
        <GreenModal
          logout={true}
          navigation={navigation}
          isOpen={isOpenGreenModal}
          setIsOpen={setIsOpenGreenModal}
          title={t("modals.title2")}
          text={t("modals.text4")}
        />
      </ImageBackground>
    </View>
  );
};

export default NavigationMenu;

const styles = StyleSheet.create({
  linkContainer: {
    width: "100%",
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    borderRadius: 21,
    height: 50,
  },
});
