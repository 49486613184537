import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "../styles/GlobalStyle";
import BackHeader from "../components/BackHeader";
import axios from "axios";
import Spinner from "../components/Spinner";
import UpperSection from "../components/trail/UpperSection";
import LowerSection from "../components/trail/LowerSection";
import Footer from "../components/Footer";
import DimensionsHook from "../hooks/DimensionsHook";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import PrealableVideo from "../components/videos/PrealableVideo";
import { Fab, Icon, Text } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { Txt } from "../components/TextsComponents";

const Trail = ({ navigation, route }) => {
  const { t, i18n } = useTranslation();
  const item = route.params;

  const dispatch = useDispatch();
  const { current_trails } = useSelector((state) => state.userActivity);
  const watched_episodes = useSelector((state) => state.watchedEpisodes);

  const { isDesktop } = DimensionsHook();
  const [loader, setLoader] = useState(true);
  const [selectedSeason, setSelectedSeason] = useState(0);
  const [episodesLoader, setEpisodesLoader] = useState(true);
  const [prealable, setPrealable] = useState(null);
  const [isOpenFirstVisit, setIsOpenFirstVisit] = useState(false);
  const [showPrealableVideoModal, setShowPrealableVideoModal] = useState(false);
  const [lastWatchedEpisode, setLastWatchedEpisode] = useState(0);
  const [seasons, setSeasons] = useState([]);
  const [sequenceEpisodes, setSequenceEpisodes] = useState([]);

  useState(null);

  // get seasons + check user progression and accesibility
  const getData = async () => {
    setLoader(true);

    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    // get prealable

    const url = process.env.TRAILS_ENDPOINT;

    axios
      .get(`${url}/prealable?language=${i18n.language}`, config)
      .then((prealableRes) => {
        setPrealable(prealableRes.data);
        // get Seasons
        axios
          .get(
            `${process.env.SEASONS_ENDPOINT}/${item.trail_code}?language=${i18n.language}`,
            config
          )
          .then(async (res) => {
            const seasonsData = res.data;

            setSeasons(seasonsData);
            const allEpisodes = [];
            await seasonsData.forEach((season) => {
              allEpisodes.push(...season.episodes);
            });
            setSequenceEpisodes(allEpisodes);

            // is First time ?
            const isFirstVisit = !current_trails.includes(item.trail_code);
            if (isFirstVisit) {
              const season = seasonsData.find(
                (season) => season.season_code == item.trail_code + "-S1"
              );
              setSelectedSeason(season);
              setLoader(false);
            } else {
              const watchedEpisodes = watched_episodes.filter((ep) =>
                ep.includes(item.trail_code)
              );
              const lastWatchedEpisode =
                watchedEpisodes[watchedEpisodes.length - 1];

              const lastEpisodeCodeSplitted = lastWatchedEpisode.split("-");

              const lastWatchedSeason =
                item.trail_code + "-" + lastEpisodeCodeSplitted[2];
              setLoader(false);
              const season = seasonsData.find(
                (season) => season.season_code == lastWatchedSeason
              );
              setLastWatchedEpisode(lastWatchedEpisode);
              setSelectedSeason(season);
              setLoader(false);
            }
          });
      });
  };

  const scrollRef = useRef();

  const scrollToEnd = () => {
    scrollRef.current?.scrollTo({
      y: 1000,
      animated: true,
      duration: 1500,
    });
  };

  useEffect(() => {
    getData();
    dispatch(setWatchedEpisodes());
    dispatch(setUserActivity());
    dispatch(setUserData());
  }, []);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <BackHeader
          isOpen={false}
          setIsOpen={() => {}}
          navigation={navigation}
        />

        {loader ? (
          <View style={styles.loadingContainer}>
            <Spinner size="large" />
          </View>
        ) : (
          <ScrollView
            style={{ flex: 1 }}
            showsVerticalScrollIndicator={Platform.OS === "web"}
            ref={scrollRef}
          >
            <UpperSection
              navigation={navigation}
              lastWatchedEpisode={lastWatchedEpisode}
              episodesLoader={episodesLoader}
              setEpisodesLoader={setEpisodesLoader}
              item={item}
              sequenceEpisodes={sequenceEpisodes}
              selectedSeason={selectedSeason}
              setIsOpenFirstVisit={setIsOpenFirstVisit}
              isOpenFirstVisit={isOpenFirstVisit}
              setShowPrealableVideoModal={setShowPrealableVideoModal}
            />
            <LowerSection
              episodesLoader={episodesLoader}
              setEpisodesLoader={setEpisodesLoader}
              item={item}
              navigation={navigation}
              seasons={seasons}
              selectedSeason={selectedSeason}
              setSelectedSeason={setSelectedSeason}
            />
            {isDesktop && <Footer />}
          </ScrollView>
        )}

        {!loader && (
          <Fab
            colorScheme="success"
            focusable={false}
            isHovered={false}
            onPress={scrollToEnd}
            mr="3"
            placement="bottom-right"
            bg={colors.blue3}
            _hover={{ backgroundColor: colors.green2 }}
            icon={
              <Icon color="white" as={<AntDesign name="down" />} size={4} />
            }
            label={
              <Txt style={{ color: "white", fontSize: 13 }}>
                {t("trails.suggested_workshops")}
              </Txt>
            }
          />
        )}
      </ImageBackground>

      {/** prealable video */}
      {prealable && showPrealableVideoModal && (
        <PrealableVideo
          isOpen={showPrealableVideoModal}
          setIsOpen={setShowPrealableVideoModal}
          setIsOpenFirstVisit={setIsOpenFirstVisit}
          data={prealable}
        />
      )}
    </View>
  );
};

export default Trail;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  loadingContainer: {
    width: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
