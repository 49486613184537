import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { AlertDialog } from "native-base";
import { BoldTxt, Txt } from "../TextsComponents";
import { PrimaryButton } from "../Buttons";
import { colors } from "../../styles/GlobalStyle";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch } from "react-redux";
import { signCommunityConditions } from "../../redux/Actions";
import Spinner from "../Spinner";
import { useTranslation } from "react-i18next";
import DimensionsHook from "../../hooks/DimensionsHook";

const CommunityConditionsModal = ({
  isOpen,
  setIsOpen,
  navigation,
  getCurrentTrails,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = DimensionsHook();
  const dispatch = useDispatch();
  const [conditionsLoader, setConditionsLoader] = useState(false);
  const onClose = () => {
    setIsOpen(false);
    navigation.navigate("Home");
  };
  const onAcceptConditions = async () => {
    if (!conditionsLoader) {
      setConditionsLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .put(process.env.USER_UPDATE, {
          access_token: TOKEN,
          community_visit: true,
        })
        .then((res) => {
          dispatch(signCommunityConditions());
          setConditionsLoader(false);
          setIsOpen(false);
          getCurrentTrails();
        })
        .catch((err) => {
          console.log(err);
          setConditionsLoader(false);
        });
    }
  };
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialog.Content
        style={{ width: "95%", maxWidth: 1300, height: "95%", maxHeight: 720 }}
      >
        <AlertDialog.CloseButton />

        <AlertDialog.Header>
          <BoldTxt style={{ fontSize: 16, marginTop: isDesktop ? 0 : 30 }}>
            {t("community_charter.title")}
          </BoldTxt>
        </AlertDialog.Header>
        <AlertDialog.Body>
          <View style={{ paddingHorizontal: 15 }}>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title1")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text1_1")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title2")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text2_1")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_2")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_3")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_4")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_5")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_6")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_7")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_8")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_9")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_10")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_11")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text2_12")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title3")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text3_1")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title4")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_1")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_2")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_3")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_4")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_5")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_6")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_7")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_8")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_9")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title5")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_1")}{" "}
              <BoldTxt
                color={colors.blue2}
                style={{ fontSize: 14, textDecoration: "underline" }}
                onPress={() => {
                  setIsOpen(false);
                  navigation.navigate("PolitiquesScreen");
                }}
              >
                {t("community_charter.text5_2")}
              </BoldTxt>
            </Txt>
          </View>
          <PrimaryButton
            onPress={onAcceptConditions}
            style={{ marginVertical: 30, width: 250, alignSelf: "center" }}
          >
            {conditionsLoader ? <Spinner color={colors.white} /> : "Accept"}
          </PrimaryButton>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default CommunityConditionsModal;

const styles = StyleSheet.create({});
