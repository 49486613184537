import { StyleSheet, View, Pressable } from "react-native";
import React, { useState, useRef } from "react";
import { BoldTxt, Txt, H1, LightTxt } from "../TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { FavoriteIcon, LikeIcon } from "../../assets/svg/Icons";
import { DropDown } from "../Inputs";
import { useHover } from "react-native-web-hooks";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setUserActivity } from "../../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import RecommandedSwiper from "./RecommandedSwiper";

const LowerSection = ({
  seasons,
  item,
  selectedSeason,
  setSelectedSeason,
  navigation,
}) => {
  const { isBigScreen, isTablet, isDesktop } = DimensionsHook();
  const { t, i18n } = useTranslation();
  const likeRef = useRef(null);
  const favoriteRef = useRef(null);
  const [showSeason, setShowSeason] = useState(false);
  const [seasonOptions, setSeasonOptions] = useState([
    {
      label: `${t("trail.season")} 1`,
      value: 0,
    },
  ]);

  const [currentSeason, setCurrentSeason] = useState({
    label: `${t("trail.season")}`,
    value: " ",
  });
  const [details, setDetails] = useState({});
  const [detailsLoader, setDetailsLoader] = useState(true);

  const getDetails = async () => {
    setDetailsLoader(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.TRAILS_ENDPOINT}/details?trail_code=${item.trail_code}&language=${i18n.language}`;
    try {
      const Response = await axios.get(url, config);

      setDetails(Response.data);

      setDetailsLoader(false);
    } catch (error) {
      setDetails({});
      setDetailsLoader(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    let s = [];
    Array.from(Array(seasons.length).keys()).forEach((i) => {
      s.push({ label: `${t("trail.season")} ${i + 1}`, value: i });
    });
    setSeasonOptions(s);
    const fromSeason = seasons.indexOf(selectedSeason);
    if (fromSeason > -1) {
      setCurrentSeason(s[fromSeason]);
    }
  }, [seasons, selectedSeason]);

  const likeHover = useHover(likeRef);
  const favoriteHover = useHover(favoriteRef);

  const container = {
    flex: 1,
    width: isBigScreen ? "75%" : isDesktop ? "80%" : isTablet ? "90%" : "95%",
    alignSelf: "center",
    paddingBottom: 10,
    marginBottom: 30,
  };

  // liked and favorite user lists
  const userActivity = useSelector((state) => state.userActivity);
  const liked = userActivity.liked_trails;
  const favourite = userActivity.favourite_trails;

  // like favorite states
  const [isLiked, setIsLiked] = useState(liked.includes(item.trail_code));
  const [isFavorite, setIsFavorite] = useState(
    favourite.includes(item.trail_code)
  );
  // PRESS LIKE & FAVOURITE FUNCTIONS
  const dispatch = useDispatch();
  const likePressHandler = async () => {
    setIsLiked(!isLiked);
    const TOKEN = await AsyncStorage.getItem("access_token");

    axios
      .post(process.env.USER_LIKE_TRAILS, {
        access_token: TOKEN,
        trail_code: item.trail_code,
      })
      .then(() => dispatch(setUserActivity()));
  };
  const favoritePressHandler = async () => {
    setIsFavorite(!isFavorite);
    const TOKEN = await AsyncStorage.getItem("access_token");
    axios
      .post(process.env.USER_FAVOURITE_TRAILS, {
        access_token: TOKEN,
        trail_code: item.trail_code,
      })
      .then(() => dispatch(setUserActivity()));
  };

  console.log("DETAILS ", details);

  const renderDetail = (array, label) => {
    console.log(label, array);
    if (array.length > 0) {
      const values = array.map((element) => {
        if (i18n.language == "fr") {
          return element.code.length > 0
            ? { title: element.code }
            : {
                name: `${element.firstname} ${element.lastname}`,
                title: element.title_fr,
              };
        } else {
          return element.code_en.length > 0
            ? { title: element.code_en }
            : {
                name: `${element.firstname} ${element.lastname}`,
                title: element.title_en,
              };
        }
      });

      return (
        <View
          style={{
            width: "100%",
            marginTop: 3,
          }}
        >
          <BoldTxt style={{ fontSize: 13, marginBottom: 3 }}>{label}</BoldTxt>
          {values.map((el, i) => {
            return (
              <View key={i.toString()}>
                {el.name ? (
                  <BoldTxt
                    style={{
                      fontSize: 12,
                      color: "#8a8686",
                    }}
                  >
                    • {el.name} -{" "}
                    <LightTxt style={{ fontSize: 12, color: "#8a8686" }}>
                      {el.title}.
                    </LightTxt>
                  </BoldTxt>
                ) : (
                  <Txt style={{ fontSize: 12, color: "#8a8686" }}>
                    • {el.title}.
                  </Txt>
                )}
              </View>
            );
          })}
        </View>
      );
    } else {
      return <></>;
    }
  };

  return (
    <View style={container}>
      <BoldTxt style={{ marginVertical: 20, fontSize: 22 }}>
        {item.title}
      </BoldTxt>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/** like button */}
          <Pressable
            onPress={likePressHandler}
            ref={likeRef}
            style={[
              styles.likeButton,
              {
                borderWidth: isLiked ? 0 : likeHover ? 0 : 1,
                backgroundColor: isLiked
                  ? colors.blue2
                  : likeHover
                  ? colors.blue0
                  : "white",
              },
            ]}
          >
            <LikeIcon
              color={
                isLiked
                  ? colors.white
                  : likeHover
                  ? colors.white
                  : colors.grayBorder
              }
            />
          </Pressable>
          {/** favorite button */}
          <Pressable
            onPress={favoritePressHandler}
            ref={favoriteRef}
            style={[
              styles.favoriteButton,
              {
                borderWidth: isFavorite ? 0 : favoriteHover ? 0 : 1,
                backgroundColor: isFavorite
                  ? colors.red1
                  : favoriteHover
                  ? colors.red0
                  : "white",
              },
            ]}
          >
            <FavoriteIcon
              color={
                isFavorite
                  ? colors.white
                  : favoriteHover
                  ? colors.white
                  : colors.grayBorder
              }
            />
          </Pressable>
        </View>
        {/** DROPDOWN TO CHANGE SEASON */}
        <View
          onPress={() => setShowSeason(!setShowSeason)}
          style={{
            width: isDesktop ? 200 : isTablet ? 150 : 160,
          }}
        >
          <DropDown
            height={50}
            placeholder=""
            show={showSeason}
            setShow={() => setShowSeason(!showSeason)}
            value={currentSeason}
            seasons={seasons}
            setSelectedSeason={setSelectedSeason}
            setValue={setCurrentSeason}
            options={seasonOptions}
            seasonDropDown={true}
          />
        </View>
      </View>
      <View style={{ zIndex: -1 }}>
        {/** season title */}
        <H1>{selectedSeason.title}</H1>
        {/** season description */}
        <Txt style={{ marginTop: 20, marginBottom: 7 }}>
          {selectedSeason.description}
        </Txt>
        {/** details */}
        {/** infos container : author, voice, scriptwriter ... */}

        <View
          style={{
            flexDirection: isDesktop ? "row" : "column",
            justifyContent: isDesktop ? "space-evenly" : "flex-start",
            backgroundColor: colors.beige,
            paddingVertical: isDesktop ? 10 : 5,
            borderRadius: isDesktop ? 10 : 5,
          }}
        >
          <View
            style={{
              width: isDesktop ? "45%" : "95%",
              alignSelf: isDesktop ? "flex-start" : "center",
            }}
          >
            {details.author && renderDetail(details.author, t("trail.author"))}
            {/** source */}
            {item.source && (
              <View
                style={{
                  width: "100%",
                  marginTop: 3,
                }}
              >
                <BoldTxt style={{ fontSize: 13, marginBottom: 3 }}>
                  Sources
                </BoldTxt>

                <Txt style={{ fontSize: 12, color: "#8a8686" }}>
                  {item.source}
                </Txt>
              </View>
            )}
            {details.scriptwriter &&
              renderDetail(details.scriptwriter, t("trail.scriptwriter"))}
          </View>
          {isDesktop && (
            <View
              style={{
                height: "100%",
                width: 2,
                backgroundColor: colors.grayBorder,
              }}
            ></View>
          )}
          <View
            style={{ width: isDesktop ? "45%" : "95%", alignSelf: "center" }}
          >
            {details.voice && renderDetail(details.voice, t("trail.voice"))}
            {details.audio_studio &&
              renderDetail(details.audio_studio, t("trail.studio_audio"))}
            {details.image_studio &&
              renderDetail(details.image_studio, t("trail.studio_image"))}
            {details.translation_agency &&
              renderDetail(
                details.translation_agency,
                t("trail.translation_agency")
              )}
          </View>
        </View>

        {selectedSeason.season_code && (
          <RecommandedSwiper
            navigation={navigation}
            season_code={selectedSeason.season_code}
          />
        )}
      </View>
    </View>
  );
};

export default LowerSection;

const styles = StyleSheet.create({
  divider: {
    width: "90%",
    alignSelf: "center",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginVertical: 20,
  },
  likeButton: {
    width: 50,
    height: 50,
    borderWidth: 1,
    borderColor: colors.grayBorder,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    marginLeft: 0,
  },
  favoriteButton: {
    width: 50,
    height: 50,
    borderWidth: 1,
    borderColor: colors.grayBorder,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    marginLeft: 8,
  },
});
