import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { Center, HStack, Modal, View } from "native-base";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet } from "react-native";
import { Check } from "../../assets/svg/Coaching";
import { EnLogo, FrLogo } from "../../assets/svg/Icons";
import { colors } from "../../styles/GlobalStyle";
import { Txt } from "../TextsComponents";

const LanguageModal = ({ showModal, setShowModal }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const Languages = [
    { label: "Français", value: "fr", icon: () => <FrLogo /> },
    { label: "English", value: "en", icon: () => <EnLogo /> },
  ];

  const navigation = useNavigation();

  const changeLanguageHandler = async (lng) => {
    i18n.changeLanguage(lng);
    await AsyncStorage.setItem("lng", lng);

    navigation.reset({
      index: 0,
      routes: [{ name: "TabNavigation" }],
    });
  };

  return (
    <Center>
      <Modal size="lg" isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header
            _text={{
              color: colors.blue3,
              fontFamily: "OxygenBold",
              marginLeft: 2,
            }}
          >
            {t("choose_language.title")}
          </Modal.Header>
          <Modal.Body>
            <View w="full">
              {Languages.map((lng) => {
                return (
                  <Pressable
                    onPress={() => changeLanguageHandler(lng.value)}
                    key={lng.value}
                    style={styles.languageButton}
                  >
                    <HStack alignItems="center">
                      <View style={styles.iconContainer}>{lng.icon()}</View>
                      <Txt>{lng.label}</Txt>
                    </HStack>
                    <View style={{ width: 30, height: 30 }}>
                      {currentLanguage === lng.value ? <Check /> : <></>}
                    </View>
                  </Pressable>
                );
              })}
            </View>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Center>
  );
};

export default LanguageModal;

const styles = StyleSheet.create({
  languageButton: {
    backgroundColor: colors.beige,
    marginBottom: 10,
    height: 60,
    padding: 4,
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "90%",
  },
  iconContainer: {
    width: 40,
    height: 30,
    marginRight: 8,
    borderRadius: 5,
    overflow: "hidden",
  },
});
