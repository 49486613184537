import { StyleSheet, View, ScrollView, ImageBackground } from "react-native";
import React, { useRef } from "react";
import { colors } from "../styles/GlobalStyle";
import DimensionsHook from "../hooks/DimensionsHook";
import BackHeader from "../components/BackHeader";
import Company from "../components/login/signup/Company";
import Individiual from "../components/login/signup/Individual";

const SignUp = ({ navigation, route }) => {
  const type = route.params;
  const { isMobile, isTablet } = DimensionsHook();
  const scrollRef = useRef();

  const scrollToTop = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <BackHeader
          isOpen={false}
          setIsOpen={() => {}}
          navigation={navigation}
        />
        <ScrollView
          ref={scrollRef}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              alignSelf: "center",
              width: isMobile ? "90%" : isTablet ? "80%" : 600,
            }}
          >
            {type === "individual" ? (
              <Individiual scrollToTop={scrollToTop} navigation={navigation} />
            ) : (
              <Company scrollToTop={scrollToTop} navigation={navigation} />
            )}
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default SignUp;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  linksButton: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 20,
  },
  divider: {
    backgroundColor: colors.grayBorder,
    width: 2,
    height: "80%",
    marginHorizontal: 10,
  },
});
