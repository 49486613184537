import { Tooltip } from "native-base";
import React from "react";
import { Platform } from "react-native";

export const CustomTooltip = ({ label, bg, placement, color, children }) => {
  if (Platform.OS == "web") {
    return (
      <Tooltip
        label={label}
        fontFamily="heading"
        openDelay={0}
        placement={placement || "bottom"}
        bg={bg}
        px="4"
        _text={{
          color: color,
          fontWeight: "600",
        }}
      >
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};
