import { AlertDialog, Button } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import Spinner from "../Spinner";
import { BoldTxt, SmallBoldTxt, Txt } from "../TextsComponents";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { DropDown, Input } from "../Inputs";
import { View } from "react-native";
import DimensionsHook from "../../hooks/DimensionsHook";

const DeleteAccountModal = ({ isOpen, setIsOpen, navigation }) => {
  const { t } = useTranslation();
  const reasonsOptions = [
    { label: t("delete_account_modal.placeholder_reason"), value: "" },
    {
      label: t("unsubscribe.reasons.will_back"),
      value: "will_back",
    },
    { label: t("unsubscribe.reasons.no_interest"), value: "no_interest" },
    { label: t("unsubscribe.reasons.other"), value: "other" },
  ];

  const [loader, setLoader] = useState(false);
  const { isMobile } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState(reasonsOptions[0]);
  const [message, setMessage] = useState("");

  const cancelRef = React.useRef(null);

  const onClose = () => {
    setLoader(false);
    setIsOpen(false);
    setReason(reasonsOptions[0]);
    setMessage("");
    setErrorMessage(null);
  };

  const deleteAccountHandler = async () => {
    if (!loader) {
      const TOKEN = await AsyncStorage.getItem("access_token");
      setLoader(true);
      setErrorMessage(null);
      if (reason.value == "") {
        setErrorMessage(t("unsubscribe.error_reason"));
        setLoader(false);
      } else {
        const url = process.env.DELETE_ACCOUNT;
        axios
          .post(url, { access_token: TOKEN, reason, message })
          .then(() => {
            setTimeout(async () => {
              setIsOpen(false);
              await AsyncStorage.removeItem("access_token");
              navigation.reset({
                index: 0,
                routes: [{ name: "Loader" }],
              });

              setLoader(false);
            }, 1000);
          })
          .catch((err) => {
            setErrorMessage(t("unsubscribe.error_connexion"));
            setLoader(false);
          });
      }
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialog.Content
        style={{
          width: "95%",
          maxWidth: 800,
        }}
      >
        <AlertDialog.CloseButton focusable={false} />

        <AlertDialog.Header>
          <BoldTxt style={{ fontSize: 16 }}>
            {t("delete_account_modal.title")}
          </BoldTxt>
        </AlertDialog.Header>
        <AlertDialog.Body style={{ padding: isMobile ? 5 : 30 }}>
          <Txt style={{ fontSize: 14, marginTop: 10 }}>
            {t("delete_account_modal.text")}
          </Txt>
          <BoldTxt style={{ marginTop: 10, marginBottom: 10, fontSize: 14 }}>
            {t("unsubscribe.text1")}
          </BoldTxt>
          <Txt style={{ fontSize: 14 }}>{t("unsubscribe.text2")}</Txt>
          {/** reason */}
          <View style={{ width: "100%", marginTop: 20, zIndex: 5 }}>
            <DropDown
              height={58}
              placeholder=""
              show={showReason}
              setShow={() => setShowReason(!showReason)}
              value={reason}
              disableFirstOption={true}
              setValue={setReason}
              options={reasonsOptions}
            />
          </View>
          {/** message input */}
          <View style={{ marginVertical: 10 }}>
            <Input
              placeholder={t("unsubscribe.your_message")}
              multiline={true}
              numberOfLines={6}
              value={message}
              onChangeText={(val) => setMessage(val)}
            />
          </View>
          <SmallBoldTxt
            style={{
              color: colors.red1,
              fontSize: 12,
              textAlign: "center",
              marginBottom: 10,
            }}
          >
            {errorMessage ? errorMessage : " "}
          </SmallBoldTxt>
          <Button.Group alignSelf="center" space={2}>
            <Button
              focusable={false}
              style={{
                backgroundColor: colors.green2,
                color: colors.white,
                height: 40,
              }}
              onPress={onClose}
              ref={cancelRef}
            >
              <Txt style={{ fontSize: 14, color: colors.white }}>
                {t("delete_account_modal.cancel")}
              </Txt>
            </Button>
            <Button
              focusable={false}
              style={{
                backgroundColor: colors.red1,
                color: colors.white,
                width: 140,
                height: 40,
              }}
              onPress={deleteAccountHandler}
            >
              {loader ? (
                <Spinner color={colors.white} />
              ) : (
                <Txt
                  style={{
                    fontSize: 14,
                    color: colors.white,
                  }}
                >
                  {t("delete_account_modal.delete")}
                </Txt>
              )}
            </Button>
          </Button.Group>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default DeleteAccountModal;
