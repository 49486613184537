import {
  View,
  StyleSheet,
  ScrollView,
  Image,
  ImageBackground,
  Platform,
} from "react-native";
import React from "react";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import Questions from "./Components/Questions";
import DimensionsHook from "../../hooks/DimensionsHook";

const Quiz = ({ navigation }) => {
  const { isBigScreen } = DimensionsHook();

  const navigateTo = () => {
    navigation.navigate("Message");
  };
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web"}>
          <BackHeader
            isOpen={false}
            setIsOpen={() => {}}
            navigation={navigation}
          />

          <Questions navigateTo={navigateTo} navigation={navigation} />
        </ScrollView>

        {isBigScreen && (
          <Image
            source={{
              uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
            }}
            style={styles.image}
          />
        )}
      </ImageBackground>
    </View>
  );
};

export default Quiz;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },

  image: {
    position: "absolute",
    bottom: 0,
    right: 20,
    height: 230,
    width: 140,
  },
});
