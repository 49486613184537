import { LinearGradient } from "expo-linear-gradient";
import { AlertDialog } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Platform, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { LislUp } from "../../assets/svg/Logo";
import DimensionsHook from "../../hooks/DimensionsHook";
import { H3, H5 } from "../TextsComponents";
import * as Animatable from "react-native-animatable";
import AsyncStorage from "@react-native-async-storage/async-storage";

const MonthlyMessageModal = ({ isOpen, setIsOpen, fromHome, setShowTuto }) => {
  const onClose = async () => {
    if (fromHome) {
      await AsyncStorage.setItem("message_seen", "true");
      const isEnded = await AsyncStorage.getItem("tuto_end");
      if (isEnded) {
        setShowTuto(false);
      } else {
        setShowTuto(true);
      }
    }
    setIsOpen(false);
  };
  const monthlyMessage = useSelector((state) => state.monthlyMessage);
  const { i18n, t } = useTranslation();
  const { isDesktop, isTablet } = DimensionsHook();

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialog.Content
        style={{ width: "95%", maxWidth: 1000, aspectRatio: 9 / 16 }}
      >
        <ImageBackground
          source={{ uri: monthlyMessage.picture }}
          style={StyleSheet.absoluteFill}
        >
          <LinearGradient
            // Background Linear Gradient
            colors={["rgba(0,0,0,0.9)", "rgba(0,0,0,0.2)", "rgba(0,0,0,0.9)"]}
            style={styles.linearContainer}
          />
          <View
            style={[
              styles.container,
              { marginTop: 20, marginBottom: isDesktop ? 40 : 20 },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isDesktop && (
                <View style={{ width: 60, height: 60 }}>
                  <LislUp color="#ffffff" />
                </View>
              )}
              <H3
                style={{
                  textAlign: "center",
                  marginHorizontal: isDesktop ? 10 : 25,
                  fontSize: isDesktop ? 35 : 22,
                }}
                color="#ffffff"
              >
                {t("monthly_message.title")}
              </H3>
            </View>
            {isOpen && (
              <Animatable.View
                animation="fadeInDownBig"
                duration={1500}
                delay={0}
                useNativeDriver={Platform.OS != "web"}
              >
                <H5
                  style={{
                    textAlign: "center",
                    marginHorizontal: 25,
                    lineHeight: isDesktop ? 38 : 25,
                    fontSize: isDesktop ? 30 : 18,
                    marginBottom: isDesktop || isTablet ? 20 : 0,
                  }}
                  color="#ffffff"
                >
                  {i18n.language == "fr"
                    ? monthlyMessage.text_fr
                    : monthlyMessage.text_en}
                </H5>
              </Animatable.View>
            )}
          </View>
          <AlertDialog.CloseButton color="white" _icon={{ color: "white" }} />
        </ImageBackground>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default MonthlyMessageModal;

const styles = StyleSheet.create({
  linearContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
