import { AlertDialog, Button, HStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import { BoldTxt, SmallBoldTxt, Txt } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const ChatEnterModal = ({ isOpen, setIsOpen, navigation, times }) => {
  const { t } = useTranslation();

  const { isMobile } = DimensionsHook();

  const cancelRef = React.useRef(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const openTimes = times.filter((el) => el.open === true);

  const enterChat = () => {
    setIsOpen(false);
    navigation.navigate("Chat");
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      const today = new Date();
      const todayDay = today.getDay();
      const todayTime = openTimes.find((el) => el.day == todayDay);
      if (todayTime) {
        // case today is open  check time
        const startSplitted = todayTime.start.split(":");
        const endSplitted = todayTime.end.split(":");

        const startTime = new Date();
        startTime.setHours(startSplitted[0]);
        startTime.setMinutes(startSplitted[1]);
        startTime.setSeconds(0);
        const endTime = new Date();
        endTime.setHours(endSplitted[0]);
        endTime.setMinutes(endSplitted[1]);
        endTime.setSeconds(0);

        const startTimeStamp = startTime.getTime();
        const endTimeStamp = endTime.getTime();
        const todayStamp = today.getTime();

        if (todayStamp > startTimeStamp && todayStamp < endTimeStamp) {
          // case today ok &&  NOW is  between start time and end time
          setIsDisabled(false);
        } else {
          // case today ok &&  NOW is  not between start time and end time
          setIsDisabled(true);
        }
      } else {
        // case today is not open
        setIsDisabled(true);
      }
    }
  }, [isOpen]);

  return (
    <AlertDialog
      style={{ backgroundColor: "#00000050" }}
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialog.Content
        style={{
          width: "95%",
          maxWidth: 400,
        }}
      >
        <AlertDialog.Header>
          <AlertDialog.CloseButton focusable={false} />

          <HStack alignItems="center">
            <BoldTxt style={{ fontSize: 16 }}>{t("chat.title")}</BoldTxt>
          </HStack>
        </AlertDialog.Header>
        <AlertDialog.Body style={{ padding: isMobile ? 5 : 30 }}>
          {openTimes.map((time) => {
            const d = t(`days.${time.day}`);
            return (
              <HStack
                mt="4"
                key={time._id}
                alignItems="center"
                justifyContent="space-between"
                style={{
                  borderBottomWidth: 1,
                  paddingBottom: 5,
                  borderColor: colors.grayBorder,
                }}
              >
                <HStack alignItems="flex-end">
                  <MaterialCommunityIcons
                    name="account-clock"
                    size={24}
                    color={colors.blue3}
                  />
                  <Txt style={{ marginLeft: 4 }}>{" • " + d}</Txt>
                </HStack>
                <HStack alignItems="center">
                  <SmallBoldTxt
                    style={{ fontSize: 13.5 }}
                  >{`${time.start}H →`}</SmallBoldTxt>
                  <SmallBoldTxt
                    style={{ fontSize: 13.5 }}
                  >{` ${time.end}H`}</SmallBoldTxt>
                </HStack>
              </HStack>
            );
          })}

          <Button.Group mt="8" mb="2" alignSelf="center" space={2}>
            <Button
              focusable={false}
              style={{
                backgroundColor: "transparent",
                borderWidth: 1,
                borderColor: colors.blue3,
                width: 140,
                height: 40,
              }}
              onPress={onClose}
              ref={cancelRef}
            >
              <Txt style={{ fontSize: 14, color: colors.blue3 }}>
                {t("delete_account_modal.cancel")}
              </Txt>
            </Button>
            <Button
              disabled={isDisabled}
              focusable={false}
              style={{
                backgroundColor: colors.green2,
                color: colors.white,
                height: 40,
                width: 140,
                cursor: isDisabled ? "not-allowed" : "pointer",
              }}
              onPress={enterChat}
            >
              <Txt
                style={{
                  fontSize: 14,
                  color: colors.white,
                }}
              >
                {t("chat.button")}
              </Txt>
            </Button>
          </Button.Group>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default ChatEnterModal;
