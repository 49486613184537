import { ImageBackground, StyleSheet, View } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../../../styles/GlobalStyle";
import { BoldTxt, H3, Txt } from "../../../TextsComponents";
import { useTranslation } from "react-i18next";
import DimensionsHook from "../../../../hooks/DimensionsHook";
import Plan from "./Plan";

const GiftPlansModal = ({ isOpen, setIsOpen, email, gifts, setEmail }) => {
  const { isMobile, isDesktop, height } = DimensionsHook();
  const { t } = useTranslation();

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={[styles.container, { maxHeight: height - 45 }]}>
        <Modal.Body>
          <ImageBackground
            source={require("../../../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ position: "absolute", right: 0, top: 20 }}
            />

            <VStack
              style={{
                marginTop: isMobile ? 20 : 30,
                marginHorizontal: isDesktop ? 20 : 0,
                marginBottom: 20,
              }}
            >
              <H3
                style={{
                  marginBottom: 0,
                  marginTop: isMobile ? 50 : 10,
                  marginHorizontal: 10,
                  textAlign: "center",
                  fontSize: 22,
                  lineHeight: 30,
                }}
              >
                {t("plans_gift.text2")} {t("plans_gift.to")} {email}
              </H3>

              <Txt
                style={{
                  marginLeft: "2.5%",
                  marginTop: 30,
                  color: "#8a8686",
                }}
              >
                {t("plans_gift.text3")}
              </Txt>
              <View style={styles.cardsContainer}>
                {gifts.map((plan) => {
                  return (
                    <Plan
                      key={plan._id}
                      plan={plan}
                      closeHandler={closeHandler}
                      email={email}
                      setEmail={setEmail}
                    />
                  );
                })}
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default GiftPlansModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "99%",
    maxWidth: 1200,
  },
  buttonsContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  badgeContainer: {
    width: "100%",
    padding: 15,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: colors.green0,
  },
  rowInputs: {
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardsContainer: {
    width: "100%",
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: 20,
  },
});
