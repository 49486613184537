import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import {
  BoldTxt,
  H6,
  SmallBoldTxt,
  SmallTxt,
  Txt,
} from "../../TextsComponents";
import { useTranslation } from "react-i18next";
import { PhoneIcon } from "../../../assets/svg/MyAccountIcons";
import { PhoneInput } from "../../Inputs";
import { useDispatch, useSelector } from "react-redux";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { PrimaryButton, SecondaryButton } from "../../Buttons";
import Spinner from "../../Spinner";
import { colors } from "../../../styles/GlobalStyle";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { CloseIcon } from "../../../assets/svg/Appointment";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useToast } from "native-base";
import { setUserData } from "../../../redux/Actions";
import ValidatePhone from "./ValidatePhone";
import { Check } from "../../../assets/svg/Coaching";

const ChangePhone = ({ scrollToTop, setErrorMessage }) => {
  const { phoneNumber, phoneCode, verify_number } = useSelector(
    (state) => state.userData
  );
  const code = phoneCode.replace("+", "");
  const { t } = useTranslation();
  const { width } = DimensionsHook();
  const toast = useToast();
  const dispatch = useDispatch();

  const isDesktop = width > 900;

  const [phoneNumberValue, setphoneNumberValue] = useState(phoneNumber);
  const [showWarningMessage, setShowWarningMessage] = useState(true);
  const [phoneCodeValue, setphoneCodeValue] = useState(code);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showValidatePhone, setShowValidatePhone] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [submitVerifLoder, setSubmitVerifLoader] = useState(false);
  // UPDATE PHONE NUMBER HANDLER
  const submitHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      setErrorMessage("");
      if (isNaN(phoneCodeValue) && phoneCodeValue.length > 0) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_phone_code"));
        scrollToTop();
        return;
      }
      if (isNaN(phoneNumberValue) && phoneNumberValue.length > 0) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_phone"));
        scrollToTop();
        return;
      }

      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .put(process.env.USER_UPDATE, {
          access_token: TOKEN,
          phoneCode: "+" + phoneCodeValue,
          phoneNumber: phoneNumberValue,
          verify_number: false,
        })
        .then(async (res) => {
          dispatch(setUserData());

          setTimeout(() => {
            setSubmitLoader(false);
            scrollToTop();
            toast.show({
              placement: "top",
              render: () => {
                return (
                  <View
                    style={[
                      styles.toastContainer,
                      { marginTop: isDesktop ? 30 : 20 },
                    ]}
                  >
                    <Ionicons
                      name="checkmark-circle"
                      size={24}
                      color={colors.white}
                      style={{ marginRight: 5 }}
                    />
                    <Txt color={colors.white}>
                      {t("general_screen.success_update")}
                    </Txt>
                    <TouchableOpacity
                      style={{ marginLeft: 20 }}
                      onPress={() => toast.closeAll()}
                    >
                      <AntDesign name="close" size={24} color={colors.white} />
                    </TouchableOpacity>
                  </View>
                );
              },
            });
          }, 1000);
        })
        .catch((err) => {
          // console.log(err);
          setSubmitLoader(false);
        });
    }
  };

  const verifHandler = async () => {
    if (!submitVerifLoder) {
      setErrorMessage("");
      setSubmitVerifLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.USER_SEND_VALIDATE_SMS, {
          access_token: TOKEN,
        })
        .then((res) => {
          setSubmitVerifLoader(false);
          setShowValidatePhone(true);
          //  console.log(res);
        })
        .catch((err) => {
          setSubmitVerifLoader(false);
          setErrorMessage(t("signin.error_connexion"));
          scrollToTop();
        });
    }
  };

  useEffect(() => {
    setShowWarningMessage(true);
    setDisabled(false);

    if (phoneCodeValue == code && phoneNumber == phoneNumberValue) {
      setDisabled(true);
    }
  }, [phoneCodeValue, phoneNumberValue]);

  return (
    <View style={{ marginBottom: 20 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <PhoneIcon />
          <H6 style={{ marginLeft: 5 }}>{t("account_details_screen.phone")}</H6>
          {verify_number && (
            <View
              style={{ width: 14, height: 14, marginLeft: 2, marginTop: -15 }}
            >
              <Check />
            </View>
          )}
        </View>
      </View>
      {/** message appear if phone number is not verified */}
      {!verify_number &&
        showWarningMessage &&
        phoneCode.length > 0 &&
        phoneNumber.length > 0 && (
          <View
            style={{
              padding: 10,
              backgroundColor: colors.red1,
              borderRadius: 10,
              marginVertical: 10,
            }}
          >
            <TouchableOpacity
              style={{
                width: 25,
                height: 25,
                alignSelf: "flex-end",
                padding: 5,
              }}
              onPress={() => setShowWarningMessage(false)}
            >
              <CloseIcon color={colors.white} />
            </TouchableOpacity>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Ionicons name="ios-warning-outline" size={20} color="white" />
              <View style={{ marginLeft: 4 }}>
                <SmallBoldTxt color={colors.white}>
                  {t("account_details_screen.text3")} |{" "}
                  {phoneCode + phoneNumber}
                </SmallBoldTxt>
              </View>
            </View>
            <SmallTxt style={{ marginTop: 5 }} color={colors.white}>
              {t("account_details_screen.text4")}
            </SmallTxt>
            {/** verification number button */}
            <SecondaryButton
              width={200}
              onPress={verifHandler}
              isWhite={true}
              style={{ alignSelf: "flex-end", marginTop: 15 }}
            >
              {submitVerifLoder ? (
                <Spinner color="white" size="small" />
              ) : (
                t("account_details_screen.verif")
              )}
            </SecondaryButton>
          </View>
        )}
      {/** show message number is valid */}

      <View
        style={{
          flexDirection: isDesktop ? "row" : "column",
          alignItems: isDesktop ? "center" : "stretch",
          justifyContent: isDesktop ? "space-between" : "flex-start",
        }}
      >
        <View style={{ flex: 1, marginRight: isDesktop ? 30 : 0 }}>
          <View style={{ marginTop: 15 }}>
            <PhoneInput
              codePlaceholder="33"
              placeholder="01 23 45 67 89"
              phoneCode={phoneCodeValue}
              setPhoneCode={setphoneCodeValue}
              phoneNumber={phoneNumberValue}
              setPhoneNumber={setphoneNumberValue}
            />
          </View>
        </View>

        <PrimaryButton
          disabled={disabled}
          style={{ alignSelf: "center", marginTop: isDesktop ? 10 : 20 }}
          width={200}
          onPress={submitHandler}
        >
          {submitLoader ? (
            <Spinner
              color={submitLoader ? colors.white : colors.blue3}
              size="small"
            />
          ) : (
            t("account_details_screen.change_email")
          )}
        </PrimaryButton>
      </View>
      {/** pop up to validate phone number with code */}
      <ValidatePhone
        isOpen={showValidatePhone}
        setIsOpen={setShowValidatePhone}
      />
    </View>
  );
};

export default ChangePhone;

const styles = StyleSheet.create({
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    borderRadius: 20,
    paddingHorizontal: 15,
    marginTop: 30,
  },
});
