import { Alert, Linking, Platform, StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { colors } from "../styles/GlobalStyle";
import * as Animatable from "react-native-animatable";
import {
  EyeLeft,
  EyeRight,
  LislUp,
  Loader1,
  LoaderTextEn,
  LoaderTextFr,
  Smile,
} from "../assets/svg/Logo";
import { useDispatch } from "react-redux";
import {
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import NetInfo from "@react-native-community/netinfo";
import Purchases from "react-native-purchases";
import { useTranslation } from "react-i18next";
import Constants from "expo-constants";
import Spinner from "../components/Spinner";
import moment from "moment";

const Loader = ({ navigation }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [showLoader, setShowLoader] = useState(true);
  const getCurrentUrl = async () => {
    const url = await Linking.getInitialURL();
    return url;
  };

  const checkUser = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");

    // Clean the monthly message seen if first of month
    const d = new Date();
    const dayOfTheMonth = d.getDate();
    if (dayOfTheMonth == 1) {
      await AsyncStorage.removeItem("message_seen");
    }

    if (!TOKEN) {
      navigation.replace("Login");
    } else {
      // CHECK CONNECTION
      NetInfo.fetch().then(async (state) => {
        if (state.isConnected) {
          try {
            // GET USER DATA
            const response = await axios.get(process.env.USER_DATA, {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
              },
            });
            const userData = response.data;
            if (!userData) {
              await AsyncStorage.removeItem("access_token");
              navigation.replace("Login");
            }
            await dispatch(setUserData());
            await dispatch(setWatchedEpisodes());
            await dispatch(setUserActivity());
            if (Platform.OS == "ios") {
              Purchases.setDebugLogsEnabled(true);
              Purchases.configure({
                apiKey: "appl_qsunokDQSTJoiKMlhDkpJNeQDjY",
                appUserID: userData.customer,
              });
            }
            // CHECK IF ACCOUNT VERIFIED : status == pending ? => go validation screen
            const { status, verify_number } = userData;
            if (status === "pending" && !verify_number) {
              navigation.replace("Validation");
            } else {
              // USER BELONG TO COMPANY
              if (userData.company) {
                // company active
                if (userData.company_status == "active") {
                  navigation.replace("TabNavigation");
                }
                // company not active
                else {
                  await AsyncStorage.removeItem("access_token");
                  navigation.replace("Login");
                }
              }
              // USER HAS LIFETIME SUBSCRIPTION ?
              else if (userData.lifetime_subscription) {
                navigation.replace("TabNavigation");
              } else if (userData.subscription) {
                if (userData.subscription.endsWith("_gift")) {
                  const endDate = moment(userData.gift_end);
                  // Get the current date as a Moment.js object
                  const currentDate = moment();

                  // Calculate the difference between the MongoDB date and today's date in days
                  const diffInDays = endDate.diff(currentDate, "days");
                  console.log(diffInDays);
                  if (diffInDays > 0) {
                    navigation.replace("TabNavigation");
                  } else {
                    navigation.replace("Plans");
                  }
                } else if (userData.subscription_status == "active") {
                  navigation.replace("TabNavigation");
                } else {
                  navigation.replace("Plans");
                }

                // USER WITH FREEMIUM PLAN
              } else if (userData.trial_used) {
                navigation.replace("TabNavigation");
              } else {
                // NO PLANS AND NO TRIAL
                navigation.replace("Plans");
              }
            }
          } catch (error) {
            // IF TOKEN IS EXPIRED => REMOVED FROM STORAGE AND GO LOGIN SCREEN
            await AsyncStorage.removeItem("access_token");
            navigation.replace("Login");
          }
        } else {
          navigation.replace("NoConnection");
        }
      });
    }
  };

  const checkVersion = async () => {
    if (Platform.OS == "android") {
      // CURRENT APP VERSION
      const version = Constants.manifest.version;
      // GET VERSION FROM DB
      await axios.get(process.env.GET_APP_VERSION).then(async (res) => {
        const androidVersionFromDB = res.data.android;
        // CHECK VERSION
        if (version < androidVersionFromDB) {
          Alert.alert(
            "LISL UP needs an update",
            "To use this app download the latest version",
            [
              {
                text: "Update",
                onPress: () => {
                  Linking.openURL(
                    "https://play.google.com/store/apps/details?id=com.lislup.com"
                  );
                },
              },
            ]
          );
        } else {
          await checkUser();
        }
      });
    } else if (Platform.OS == "ios") {
      // CURRENT APP VERSION
      const version = Constants.manifest.version;
      // GET VERSION FROM DB
      await axios.get(process.env.GET_APP_VERSION).then(async (res) => {
        const iosVersionFromDB = res.data.ios;
        // CHECK VERSION
        if (version < iosVersionFromDB) {
          Alert.alert(
            "LISL UP needs an update",
            "To use this app download the latest version",
            [
              {
                text: "Update",
                onPress: () => {
                  Linking.openURL(
                    "https://apps.apple.com/fr/app/lisl-up/id6443772429"
                  );
                },
              },
            ]
          );
        } else {
          await checkUser();
        }
      });
    } else {
      await checkUser();
    }
  };

  useEffect(() => {
    const startApp = async () => {
      const currentUrl = await getCurrentUrl();
      if (Platform.OS == "web" && currentUrl.includes("access_token=")) {
        setShowLoader(false);
        // USER IS SIGNED IN WITH GOOGLE !
        const url = new URL(currentUrl);
        const hashFragment = url.hash.substr(1);
        const params = new URLSearchParams(hashFragment);
        const accessToken = params.get("access_token");

        // info provided by google
        let userInfoResponse = await fetch(
          "https://www.googleapis.com/userinfo/v2/me",
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        userInfoResponse.json().then(async (data) => {
          // sign up user = get user data and generated token

          axios
            .post(process.env.USER_SIGNIN_WITH_PROVIDER, {
              email: data.email,
              firstname: data.given_name,
              lastname: data.family_name,
              language: i18n.language,
            })
            .then(async (serverResponse) => {
              const token = serverResponse.data.access_token;

              await AsyncStorage.setItem("access_token", token);
              console.log(token);
              //window.location.href = "/";
              const link = currentUrl.split("#");
              window.opener.location.href = link[0];
              self.close();
            });
        });
      } else {
        setTimeout(() => {
          checkVersion();
        }, 5300);
      }
      //5300
    };
    startApp();
  }, []);

  const animation1 = {
    0: {
      rotate: "0deg",
      opacity: 1,
    },
    0.9: {
      rotate: "972deg",
      opacity: 1,
    },
    1: {
      rotate: "1080deg",
      opacity: 0,
    },
  };
  const animation2 = {
    0: {
      rotate: "-180deg",
      opacity: 0,
    },
    0.1: {
      rotate: "-110deg",
      opacity: 1,
    },
    0.8: {
      rotate: "390deg",
      opacity: 1,
    },
    1: {
      rotate: "360deg",
      opacity: 1,
    },
  };

  if (!showLoader) {
    return (
      <View style={styles.container}>
        <Spinner />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View
        style={{
          width: 180,
          height: 180,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Animatable.View
          useNativeDriver={Platform.OS === "web" ? false : true}
          animation={animation1}
          iterationCount={1}
          iterationDelay={0}
          direction="normal"
          easing="ease-in"
          duration={500}
          delay={500}
          style={styles.logoContainer}
        >
          <LislUp />
        </Animatable.View>
        <Animatable.View
          useNativeDriver={Platform.OS === "web" ? false : true}
          animation={animation2}
          iterationCount={1}
          iterationDelay={0}
          direction="normal"
          easing="ease-out"
          duration={800}
          delay={900}
          style={styles.vectorContainer}
        >
          <Loader1 />
        </Animatable.View>
        <Animatable.View
          useNativeDriver={Platform.OS === "web" ? false : true}
          animation="flipInX"
          duration={1500}
          iterationCount={1}
          iterationDelay={0}
          easing="ease-in"
          delay={1700}
          style={styles.leftEye}
        >
          <EyeLeft />
        </Animatable.View>
        <Animatable.View
          useNativeDriver={Platform.OS === "web" ? false : true}
          animation="flipInX"
          duration={1500}
          iterationCount={1}
          iterationDelay={0}
          easing="ease-in"
          delay={1700}
          style={styles.rightEye}
        >
          <EyeRight />
        </Animatable.View>
        <Animatable.View
          useNativeDriver={Platform.OS === "web" ? false : true}
          animation="flipInX"
          duration={2500}
          iterationCount={1}
          iterationDelay={0}
          easing="ease-out"
          delay={2500}
          style={styles.smile}
        >
          <Smile />
        </Animatable.View>
      </View>
      <Animatable.View
        useNativeDriver={Platform.OS === "web" ? false : true}
        animation="fadeIn"
        iterationCount={1}
        duration={1000}
        delay={2500}
        direction="alternate"
        easing="ease-in-out"
        style={styles.text}
      >
        {i18n.language == "fr" ? <LoaderTextFr /> : <LoaderTextEn />}
      </Animatable.View>
    </View>
  );
};

export default Loader;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  logoContainer: { position: "absolute", width: 200, height: 200 },
  vectorContainer: { position: "absolute", width: 180, height: 180 },
  leftEye: {
    position: "absolute",
    width: 53,
    height: 32,
    top: 85,
    left: 30,
  },
  rightEye: {
    position: "absolute",
    width: 53,
    height: 32,
    top: 85,
    right: 30,
  },
  smile: {
    position: "absolute",
    width: 60,
    height: 30,
    bottom: 20,
    alignSelf: "center",
  },
  text: {
    width: "85%",
    height: 300,
    paddingTop: 260,
    maxWidth: 380,
    position: "absolute",
  },
});
