import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AppIcon,
  OtherIcon,
  PaymentIcon,
  TrailIcon,
  WorkshopIcon,
} from "../../assets/svg/Help";
import Categories from "./HelpScreens/Categories";

import HelpList from "./HelpScreens/HelpList";

const HelpScreen = ({ navigation }) => {
  const { t } = useTranslation();
  const categorieList = [
    {
      label: t("help_screen.categories.placeholder"),
      value: "categories",
    },
    {
      label: t("help_screen.categories.facturation"),
      value: "facturation",
      icon: (color) => <PaymentIcon color={color} />,
    },
    {
      label: t("help_screen.categories.technique"),
      value: "technique",
      icon: (color) => <AppIcon color={color} />,
    },
    {
      label: t("help_screen.categories.trail"),
      value: "trail",
      icon: (color) => <TrailIcon color={color} />,
    },
    {
      label: t("help_screen.categories.workshop"),
      value: "workshop",
      icon: (color) => <WorkshopIcon color={color} />,
    },
    {
      label: t("help_screen.categories.autres"),
      value: "autres",
      icon: (color) => <OtherIcon color={color} />,
    },
  ];
  const [state, setState] = useState(categorieList[0]);

  if (state.value == "categories") {
    return <Categories categorieList={categorieList} setState={setState} />;
  }

  return (
    <HelpList
      navigation={navigation}
      setState={setState}
      category={state}
      categorieList={categorieList}
    />
  );
};

export default HelpScreen;
