import { StyleSheet, useWindowDimensions, View } from "react-native";
import React from "react";
import { H3, Txt } from "../../../../components/TextsComponents";
import { useTranslation } from "react-i18next";

const CoachingTitle = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const StyleText = {
    textAlign: "center",
    padding: width <= 1000 ? 10 : 0,
  };
  return (
    <View style={styles.Conatiner}>
      <View style={styles.TitleCoaching}>
        <H3 style={styles.textJustify}>{t("coaching.title")}</H3>
      </View>
      <View style={styles.Title}>
        <Txt style={StyleText}>{t("coaching.text1")}</Txt>
      </View>
    </View>
  );
};

export default CoachingTitle;

const styles = StyleSheet.create({
  Conatiner: {
    alignItems: "center",
    marginVertical: 25,
  },
  TitleCoaching: {
    paddingVertical: 8,
  },
  Title: {
    paddingVertical: 8,
  },
  textJustify: {},
});
