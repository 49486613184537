import { StyleSheet, View, ImageBackground } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import { H5, Txt } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { PrimaryButton } from "../Buttons";

import { useTranslation } from "react-i18next";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const FirstVisitModal = ({
  isOpen,
  setIsOpen,
  selectedEpisode,
  setShowFirstVideo,
  setShowPrealableVideo,
}) => {
  const { isDesktop, isMobile, isTablet } = DimensionsHook();

  const closeHandler = () => {
    setIsOpen(false);
  };

  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={[styles.container]}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              justifyContent="center"
              alignItems="center"
              style={{ marginVertical: !isMobile ? 30 : 0 }}
            >
              <View
                style={[
                  styles.sectionContainer,
                  {
                    backgroundColor: isMobile ? colors.white : colors.beige,
                    width: isDesktop ? "80%" : isTablet ? "90%" : "95%",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    justifyContent: isMobile ? "flex-start" : "space-between",
                  },
                ]}
              >
                <H5 style={{ paddingBottom: isMobile ? 12 : 0 }}>
                  {t("trail.prealable")}
                </H5>
                <View
                  style={{
                    width: 200,

                    paddingTop: 0,
                  }}
                >
                  <PrimaryButton
                    onPress={() => {
                      closeHandler();
                      setShowPrealableVideo(true);
                    }}
                  >
                    {t("trail.button")}
                  </PrimaryButton>
                </View>
              </View>
              {/*<View
                style={{
                  width: isDesktop ? "80%" : isTablet ? "90%" : "95%",
                  marginTop: 20,
                }}
              > 
                <Txt style={{ textAlign: "justify" }}>
                  {t("trail.first_visit_text")}
                </Txt>
              </View>*/}

              <View
                style={[
                  styles.sectionContainer,
                  {
                    backgroundColor: isMobile ? colors.white : colors.beige,
                    width: isDesktop ? "80%" : isTablet ? "90%" : "95%",
                    flexDirection: isMobile ? "column" : "row",
                    marginTop: isMobile ? 10 : 30,
                    alignItems: isMobile ? "flex-start" : "center",
                    justifyContent: isMobile ? "flex-start" : "space-between",
                  },
                ]}
              >
                <H5 style={{ paddingBottom: isMobile ? 12 : 0 }}>
                  {selectedEpisode.title
                    ? selectedEpisode.title
                    : t("trail.first_episode")}{" "}
                  {selectedEpisode.duration && (
                    <Txt style={{ fontSize: 19 }} color={colors.blue3}>
                      {"\n"}
                      <MaterialCommunityIcons
                        name="clock-fast"
                        size={19}
                        color={colors.blue3}
                      />

                      {` ${selectedEpisode.duration} min`}
                    </Txt>
                  )}
                </H5>

                <View
                  style={{
                    width: 200,
                    paddingTop: 0,
                    marginLeft: 10,
                  }}
                >
                  <PrimaryButton
                    onPress={() => {
                      closeHandler();
                      setShowFirstVideo(true);
                    }}
                  >
                    {t("trail.button1")}
                  </PrimaryButton>
                </View>
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default FirstVisitModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    maxWidth: 1000,
    width: "95%",
    maxHeight: 5000,
  },
  sectionContainer: {
    marginTop: 40,
    padding: 16,
    borderRadius: 16,
    alignSelf: "center",
  },
  backContainer: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.beige,
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
  backButton: {
    height: 70,
    width: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B496515",
  },
});
