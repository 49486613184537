import {
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { Modal, VStack } from "native-base";
import { BoldTxt, H2, H5 } from "../TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { Surface } from "react-native-paper";
import { EmojiHappy, EmojiLove, EmojiSad } from "../../assets/svg/Emojis";
import { PrimaryButton } from "../Buttons";
import Spinner from "../Spinner";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useTranslation } from "react-i18next";

const FinishEpisodeModal = ({ isOpen, setIsOpen, item }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = DimensionsHook();
  const feedbackOptions = [
    { id: 1, value: "bad" },
    { id: 2, value: "good" },
    { id: 3, value: "excellent" },
  ];
  const [selectedFeedback, setSelectedFeedback] = useState({
    id: 0,
    value: "",
  });
  const [submitLoader, setSubmitLoader] = useState(false);

  const episodeNumber = item.episode_code.split("-")[3].replace("E", "");
  const seasonNumber = item.episode_code.split("-")[2].replace("S", "");

  const sendFeedbackHandler = async () => {
    if (
      selectedFeedback.value == "bad" ||
      selectedFeedback.value == "good" ||
      selectedFeedback.value == "excellent"
    ) {
      setSubmitLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const endpoint = process.env.FEEDBACK_EPISODE;
      axios
        .post(endpoint, {
          access_token: TOKEN,
          feedback: selectedFeedback.value,
          episode_code: item.episode_code,
        })
        .then(() => {
          setTimeout(() => {
            setSubmitLoader(false);
            closeHandler();
          }, 500);
        });
    }
  };

  const closeHandler = () => {
    setIsOpen(false);
    setSelectedFeedback({ id: 0, name: "" });
  };
  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              justifyContent="center"
              alignItems="center"
              style={{
                width: isDesktop ? 600 : "100%",
                alignSelf: "center",
                marginTop: 50,
                marginBottom: isDesktop ? 20 : 0,
              }}
            >
              <H2 style={{ textAlign: "center" }}>
                {t("trail.finish_episode.title")}
              </H2>
              <BoldTxt style={{ marginTop: 10, textAlign: "center" }}>
                {t("trail.finish_episode.text1") +
                  " " +
                  episodeNumber +
                  " - " +
                  t("trail.season") +
                  " " +
                  seasonNumber}
              </BoldTxt>
              <H5 style={{ marginTop: 30, textAlign: "center" }}>
                {t("trail.finish_episode.question")}
              </H5>
              {/** feedback  */}
              <View style={styles.rowButtons}>
                {feedbackOptions.map((option) => {
                  return (
                    <Surface
                      style={[
                        styles.feedbackButton,
                        {
                          borderColor:
                            selectedFeedback.id === option.id
                              ? colors.green2
                              : colors.grayBorder,
                        },
                      ]}
                      key={option.id}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setSelectedFeedback(option);
                        }}
                        style={styles.feedbutton}
                      >
                        <View style={{ width: 40, height: 40 }}>
                          {option.id === 1 ? (
                            <EmojiSad />
                          ) : option.id === 2 ? (
                            <EmojiHappy />
                          ) : (
                            <EmojiLove />
                          )}
                        </View>
                      </TouchableOpacity>
                    </Surface>
                  );
                })}
              </View>
              <H5 style={{ marginTop: 30, marginBottom: 20 }}>
                {t("trail.finish_episode.points")}
              </H5>
              <View style={{ width: 200, marginVertical: 20 }}>
                <PrimaryButton onPress={sendFeedbackHandler}>
                  {submitLoader ? (
                    <Spinner color={colors.white} size="small" />
                  ) : (
                    t("trail.finish_episode.button")
                  )}
                </PrimaryButton>
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default FinishEpisodeModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 1000,
    maxHeight: 5000,
  },
  rowButtons: {
    width: "100%",
    paddingVertical: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  feedbackButton: {
    width: "30%",
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    elevation: 3,
    borderWidth: 3,
  },
  feedbutton: {
    paddingVertical: 15,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});
