import React, { useEffect, useState } from "react";
import { colors } from "../../styles/GlobalStyle";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Row, Skeleton, VStack } from "native-base";
import AddComment from "./AddComment";
import Comment from "./Comment";
import axios from "axios";
import { H6 } from "../../components/TextsComponents";
import { SecondaryButton } from "../../components/Buttons";

const Comments = ({ post }) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastResult, setLastResult] = useState(false);
  const [fetchingMoreLoader, setFetchingMoreLoader] = useState(false);

  const getData = async (loadingMore, toFirstTime) => {
    setLastResult(false);
    if (!toFirstTime) {
      if (loadingMore) {
        setFetchingMoreLoader(true);
      } else {
        setLoader(true);
      }
    }
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(
        `${process.env.COMMUNITY_COMMENTS}?pageNumber=${
          toFirstTime ? 1 : currentPage
        }&post_id=${post._id}`,
        config
      )
      .then((res) => {
        setTimeout(() => {
          setComments(
            toFirstTime
              ? res.data.comments
              : [...comments, ...res.data.comments]
          );

          if (res.data.page == res.data.pages) {
            setLastResult(true);
          } else {
            setCurrentPage(toFirstTime ? 2 : currentPage + 1);
          }
          if (!toFirstTime) {
            if (loadingMore) {
              setFetchingMoreLoader(false);
            } else {
              setLoader(false);
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const CommentLoader = () => {
    return (
      <VStack
        style={{ width: "100%", alignSelf: "center" }}
        mt="5"
        p="4"
        borderWidth="1"
        space={4}
        overflow="hidden"
        rounded="md"
        _dark={{
          borderColor: "coolGray.500",
        }}
        _light={{
          borderColor: "coolGray.200",
        }}
      >
        <Row alignItems="center" flex={1}>
          <Skeleton style={{ width: 50, height: 50, borderRadius: 25 }} />
          <View style={{ flex: 1 }}>
            <Skeleton style={styles.skelton1} />
            <Skeleton style={styles.skelton2} />
          </View>
        </Row>

        <Skeleton.Text lines={2} />
      </VStack>
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ width: "100%" }}>
        <View style={styles.screenContainer}>
          {/** add comment section */}
          {post.closed ? null : (
            <AddComment
              getData={getData}
              post_id={post._id}
              comment={comment}
              setComment={setComment}
            />
          )}

          {/** COMMENTS SECTIONS */}
          {loader ? (
            /** case loader */
            <View>
              <CommentLoader />
              <CommentLoader />
              <CommentLoader />
            </View>
          ) : comments.length == 0 ? (
            <View
              style={{
                marginTop: 10,
              }}
            >
              <H6
                style={{ fontSize: 15, marginLeft: 3 }}
                color={colors.grayLabel}
              >
                {t("community.no_comments")}
              </H6>
            </View>
          ) : (
            <View>
              {comments.map((c) => {
                return (
                  <View mt="3" key={c._id} style={{ width: "100%" }}>
                    <Comment getData={getData} comment={c} />
                  </View>
                );
              })}
              {/** loading More */}
              {!lastResult && !fetchingMoreLoader && comments.length > 7 && (
                <SecondaryButton
                  onPress={() => getData(true)}
                  style={{
                    width: "100%",
                    marginVertical: 20,
                    maxWidth: 400,
                    alignSelf: "center",
                  }}
                >
                  {t("community.load_more")}
                </SecondaryButton>
              )}
              {fetchingMoreLoader && (
                <>
                  <CommentLoader />
                  <CommentLoader />
                  <CommentLoader />
                </>
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default Comments;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  skelton1: {
    width: "75%",
    marginLeft: 8,
    height: 12,
    maxWidth: 300,
    borderRadius: 10,
  },
  skelton2: {
    width: "50%",
    marginLeft: 8,
    height: 10,
    marginTop: 3,
    maxWidth: 200,
    borderRadius: 10,
  },
  screenContainer: {
    width: "100%",
  },
});
