import { View } from "react-native";
import React from "react";
import { BoldTxt, Txt } from "../../TextsComponents";
import { PrimaryButton } from "../../Buttons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const NumberOfTrails = ({ navigation }) => {
  const { t } = useTranslation();
  const { number_trails } = useSelector((state) => state.userData);
  return (
    <View>
      <Txt style={{ marginVertical: 20 }}>
        {t("plans.num_trails")}{" "}
        <BoldTxt>
          {number_trails} {number_trails > 1 ? "Trails" : "Trail"}.
        </BoldTxt>
      </Txt>
      <PrimaryButton
        style={{ alignSelf: "flex-start" }}
        onPress={() => navigation.navigate("AccountBuy")}
      >
        {t("plans.add_trail")}
      </PrimaryButton>
    </View>
  );
};

export default NumberOfTrails;
