import { StyleSheet, Image, View, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
import { useTranslation } from "react-i18next";
import { Box } from "native-base";
import { BoldTxt, Txt } from "../../components/TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import DeleteCommentModal from "../../components/modals/DeleteCommentModal";
import ReportCommentModal from "../../components/modals/ReportCommentModal";
import { Avatar } from "../../assets/svg/Icons";
import { CustomTooltip } from "../../components/Tooltips";
import PostResponse from "./PostResponse";
const Comment = ({ comment, getData }) => {
  const { i18n, t } = useTranslation();
  const { isDesktop } = DimensionsHook();
  const { _id } = useSelector((state) => state.userData);
  if (i18n.language == "fr") {
    moment.locale(i18n.language);
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  if (comment.moderator) {
    return (
      <PostResponse answer={comment.comment} createdAt={comment.createdAt} />
    );
  }

  return (
    <Box
      mt="3"
      p={isDesktop ? "4" : "2"}
      space={8}
      cursor="pointer"
      bg="#ffffff60"
      _dark={{
        borderColor: "coolGray.500",
      }}
      _light={{
        borderColor: "coolGray.200",
      }}
      style={{
        width: "100%",
        alignSelf: "center",
        borderWidth: 1,
        borderRadius: 5,
      }}
    >
      <View style={styles.topContainer}>
        <View style={styles.row}>
          {comment.user_data[0].image ? (
            <View
              style={[
                styles.image,
                {
                  width: isDesktop ? 40 : 30,
                  height: isDesktop ? 40 : 30,
                },
              ]}
            >
              <Image
                source={{
                  uri: process.env.BASE_URL + "/" + comment.user_data[0].image,
                }}
                style={{ ...StyleSheet.absoluteFillObject }}
              />
            </View>
          ) : (
            <View
              style={[
                styles.image,
                {
                  width: isDesktop ? 40 : 30,
                  height: isDesktop ? 40 : 30,
                  padding: 10,
                },
              ]}
            >
              <Avatar />
            </View>
          )}

          {/** posted by */}
          <View style={styles.postedBy}>
            <Txt style={{ fontSize: 15 }}>
              <BoldTxt style={{ fontSize: 15 }}>
                {comment.user_data[0].pseudo}
              </BoldTxt>
            </Txt>
            <Txt style={{ fontSize: 13 }} color={colors.grayLabel}>
              {moment(comment.createdAt).fromNow()}
            </Txt>
          </View>
        </View>
        {/** delete button if actual user comment */}
        {comment.user_data[0]._id == _id ? (
          <CustomTooltip
            placement="left"
            bg={colors.grayLabel}
            color={colors.white}
            label={t("community.delete")}
          >
            <TouchableOpacity onPress={() => setShowDeleteModal(true)}>
              <Ionicons
                name="trash"
                size={isDesktop ? 18 : 15}
                color={colors.grayLabel}
              />
            </TouchableOpacity>
          </CustomTooltip>
        ) : (
          <CustomTooltip
            placement="left"
            bg={colors.grayLabel}
            color={colors.white}
            label={t("community.report")}
          >
            <TouchableOpacity onPress={() => setShowReportModal(true)}>
              <MaterialCommunityIcons
                name="flag-variant"
                size={isDesktop ? 18 : 15}
                color={colors.grayLabel}
              />
            </TouchableOpacity>
          </CustomTooltip>
        )}
      </View>
      <Txt
        style={{
          marginTop: 10,
          fontSize: 14,
          marginLeft: isDesktop ? 48 : 38,
        }}
      >
        {comment.comment}
      </Txt>
      {/** delete comment modal */}
      <DeleteCommentModal
        getData={getData}
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        comment_id={comment._id}
      />
      {/** delete comment modal */}
      <ReportCommentModal
        isOpen={showReportModal}
        setIsOpen={setShowReportModal}
        comment_id={comment._id}
      />
    </Box>
  );
};

export default Comment;

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: { flexDirection: "row", alignItems: "center" },
  image: {
    borderRadius: 50,
    overflow: "hidden",
    backgroundColor: colors.grayBackground,
  },
  postedBy: {
    marginRight: 8,
    marginLeft: 8,
  },
  textsContainer: {
    width: "100%",
    justifyContent: "center",
    marginTop: 20,
  },
});
