import { View } from "react-native";
import React, { useState } from "react";
import UpperSection from "./BuyScreen/UpperSection";
import LowerSection from "./BuyScreen/LowerSection";

const BuyScreen = ({ navigation, scrollToTop, showByTrail }) => {
  return (
    <View>
      <View>
        <UpperSection showByTrail={showByTrail} navigation={navigation} />
      </View>
      <View>
        <LowerSection scrollToTop={scrollToTop} navigation={navigation} />
      </View>
    </View>
  );
};

export default BuyScreen;
