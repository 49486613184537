import {
  StyleSheet,
  View,
  Image,
  Pressable,
  Animated,
  Easing,
} from "react-native";
import React, { useEffect, useRef } from "react";
import { BoldTxt, H6, Txt } from "../TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { LinearGradient } from "expo-linear-gradient";
import { useDisclose } from "native-base";
import RolloverSmall from "../rollover/RolloverSmall";
import { useSelector } from "react-redux";
import moment from "moment";
import { LislUp } from "../../assets/svg/Logo";

const SwiperItemMySpace = ({
  item,
  type,
  navigation,
  showStateBar,
  SwiperItem,
}) => {
  const { isMobile, isDesktop } = DimensionsHook();
  const { isOpen, onOpen, onClose } = useDisclose();

  const watchedEpisodes = useSelector((state) => state.watchedEpisodes);

  // progress bar ref
  const barWidth = useRef(new Animated.Value(0)).current;

  const navigationHandler = () => {
    if (isMobile) {
      onOpen();
    } else {
      if (type === "Trail") {
        navigation.navigate("Trail", item);
      } else {
        navigation.navigate("Workshop", item);
      }
    }
  };

  useEffect(() => {
    if (type === "Trail") {
      // calculate number of watched episodes / total number of episodes
      const totalEpisodes = item.episodes_number;

      const numberOfWatchedEpisodes = watchedEpisodes.filter((ep) =>
        ep.startsWith(item.trail_code)
      ).length;
      // calculate progress
      const progress = (numberOfWatchedEpisodes / totalEpisodes) * 240 || 0;
      Animated.timing(barWidth, {
        toValue: progress,
        duration: 1200,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start();
    }
  }, [watchedEpisodes]);

  return (
    <View
      style={{
        width: SwiperItem ? "49%" : isDesktop ? 369 : 270,
        alignItems: "center",
        marginHorizontal: 5,
      }}
    >
      <Pressable
        onPress={navigationHandler}
        style={[styles.container, { width: "99%" }]}
      >
        {item.type === "Favouris" ? (
          <Image source={{ uri: item.poster_link }} style={styles.image} />
        ) : (
          <Image source={{ uri: item.poster_link }} style={styles.image} />
        )}

        <LinearGradient
          colors={["#1B496500", "#1B496566"]}
          style={styles.image}
        />

        <View style={styles.row}>
          <View style={styles.logoContainer}>
            <LislUp color={colors.white} />
          </View>
          {moment().diff(item.createdAt, "days") < 90 ? (
            <View style={styles.newButton}>
              <Txt fontSize={13}>Nouveau</Txt>
            </View>
          ) : (
            <></>
          )}
        </View>
        <View style={styles.textsContainer}>
          <BoldTxt color={colors.white}>{type}</BoldTxt>
          <H6 style={{ textAlign: "center" }} color={colors.white}>
            {item.title}
          </H6>
        </View>

        {isMobile && isOpen && (
          <RolloverSmall
            type={type}
            item={item}
            navigation={navigation}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}

        {showStateBar && (
          <View
            style={{
              width: 240,
              alignSelf: "center",
              height: 3,
              borderRadius: 20,
              overflow: "hidden",
              justifyContent: "center",
              backgroundColor: colors.grayBorder,
              position: "absolute",
              bottom: 15,
            }}
          >
            <Animated.View
              style={{
                height: 3,
                backgroundColor: colors.green2,

                width: barWidth,
              }}
            ></Animated.View>
          </View>
        )}
      </Pressable>
      {/** progress bar for trails */}
    </View>
  );
};

export default SwiperItemMySpace;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.grayBackground,
    height: 240,
    borderRadius: 20,
    marginRight: 15,
    marginBottom: 20,
    overflow: "hidden",
  },
  logoContainer: {
    width: 35,
    height: 35,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
  },
  newButton: {
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    borderRadius: 100,
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    position: "absolute",
  },
  textsContainer: {
    alignItems: "center",
    width: "100%",
    position: "absolute",
    bottom: 20,
  },
});
