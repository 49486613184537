import { ImageBackground, StyleSheet, View } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { BoldTxt, H2, Txt } from "../TextsComponents";
import { SecondaryButton } from "../Buttons";
import { useTranslation } from "react-i18next";

const UnsubscribeDoneModal = ({ isOpen, setIsOpen }) => {
  const { isMobile } = DimensionsHook();
  const closeHandler = async () => {
    setIsOpen(false);
  };
  const { t } = useTranslation();
  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              style={{
                marginTop: isMobile ? 40 : 60,
                marginHorizontal: isMobile ? 0 : 30,
              }}
            >
              <H2>{t("unsubscribe.success_unsubscribe.title")}</H2>
              <BoldTxt style={{ marginTop: 20, marginBottom: 30 }}>
                {t("unsubscribe.success_unsubscribe.text1")}
              </BoldTxt>

              <Txt style={{ marginTop: 10 }}>
                {t("unsubscribe.success_unsubscribe.text2")}
              </Txt>
              <Txt style={{ marginTop: 20 }}>
                {t("unsubscribe.success_unsubscribe.text3")}
              </Txt>
              <View style={{ alignSelf: "center", marginVertical: 20 }}>
                <SecondaryButton onPress={closeHandler}>
                  {t("unsubscribe.success_unsubscribe.quit")}
                </SecondaryButton>
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default UnsubscribeDoneModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 700,
  },
});
