import { Path, Svg } from "react-native-svg";

export const EmojiSad = () => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="#FFE17D"
      />
      <Path
        d="M28.629 46.371C14.8219 46.371 3.629 35.1781 3.629 21.371C3.629 15.3918 5.73066 9.9051 9.23213 5.60315C3.5999 10.1871 0 17.1721 0 25C0 38.8071 11.1929 50 25 50C32.828 50 39.813 46.4 44.3971 40.7678C40.095 44.2693 34.6083 46.371 28.629 46.371Z"
        fill="#FFD164"
      />
      <Path
        d="M10.4901 17.9428C10.2081 17.9428 9.92699 17.825 9.72778 17.5952C9.36391 17.175 9.40805 16.5395 9.82778 16.1748C9.96957 16.0516 13.356 13.1448 18.3128 11.9244C18.8563 11.7929 19.3997 12.1221 19.5328 12.6623C19.6659 13.203 19.3351 13.7492 18.7949 13.8823C14.3287 14.9817 11.1808 17.6704 11.1494 17.6976C10.9587 17.8621 10.7241 17.9428 10.4901 17.9428Z"
        fill="#AA7346"
      />
      <Path
        d="M25.0002 38.4871C27.6525 38.4871 30.2042 38.6935 32.5897 39.0746C34.5259 39.3838 35.9537 37.2892 34.9709 35.5925C32.9783 32.1529 29.2623 29.8386 25.0002 29.8386C20.7381 29.8386 17.022 32.1529 15.0295 35.5925C14.0466 37.2892 15.4744 39.3838 17.4106 39.0746C19.7962 38.6935 22.3478 38.4871 25.0002 38.4871Z"
        fill="#9C6846"
      />
      <Path
        d="M35.2726 36.9968C35.321 36.5361 35.2361 36.0502 34.9709 35.5925C32.9783 32.1529 29.2623 29.8386 25.0002 29.8386C20.7381 29.8386 17.0221 32.1529 15.0295 35.5925C14.7643 36.0501 14.6794 36.5358 14.7277 36.9964C17.9426 36.0773 21.3981 35.5773 24.9981 35.5773C28.5994 35.5772 32.0565 36.0772 35.2726 36.9968Z"
        fill="#7D5046"
      />
      <Path
        d="M39.5154 17.9428C39.2815 17.9428 39.0468 17.862 38.8562 17.6975C38.8247 17.6703 35.6769 14.9817 31.2107 13.8821C30.6705 13.749 30.3397 13.2028 30.4728 12.6622C30.6067 12.122 31.1485 11.7928 31.6927 11.9243C36.6496 13.1446 40.036 16.0515 40.1778 16.1747C40.5975 16.5394 40.6417 17.1749 40.2778 17.595C40.0785 17.825 39.7973 17.9428 39.5154 17.9428Z"
        fill="#AA7346"
      />
      <Path
        d="M16.5322 25.8065C15.4187 25.8065 14.5161 24.9039 14.5161 23.7904V22.1775C14.5161 21.064 15.4187 20.1614 16.5322 20.1614C17.6457 20.1614 18.5483 21.064 18.5483 22.1775V23.7904C18.5483 24.9039 17.6457 25.8065 16.5322 25.8065Z"
        fill="#7D5046"
      />
      <Path
        d="M16.5321 20.1614C16.394 20.1614 16.2593 20.1755 16.1289 20.202V22.9839C16.1289 23.652 16.6705 24.1936 17.3386 24.1936C18.0066 24.1936 18.5482 23.652 18.5482 22.9839V22.1775C18.5482 21.064 17.6457 20.1614 16.5321 20.1614Z"
        fill="#9C6846"
      />
      <Path
        d="M33.4678 25.8065C32.3543 25.8065 31.4517 24.9039 31.4517 23.7904V22.1775C31.4517 21.064 32.3543 20.1614 33.4678 20.1614C34.5812 20.1614 35.4839 21.064 35.4839 22.1775V23.7904C35.4839 24.9039 34.5812 25.8065 33.4678 25.8065Z"
        fill="#7D5046"
      />
      <Path
        d="M33.4677 20.1614C33.3296 20.1614 33.1948 20.1755 33.0645 20.202V22.9839C33.0645 23.652 33.6061 24.1936 34.2741 24.1936C34.9422 24.1936 35.4838 23.652 35.4838 22.9839V22.1775C35.4838 21.064 34.5812 20.1614 33.4677 20.1614Z"
        fill="#9C6846"
      />
      <Path
        d="M15.8911 34.3138C18.6488 33.5131 21.7366 33.0645 24.9989 33.0645C28.2622 33.0645 31.3512 33.5131 34.1097 34.3142C32.0036 31.5924 28.7094 29.8386 25.0003 29.8386C21.2913 29.8386 17.9971 31.5923 15.8911 34.3138Z"
        fill="white"
      />
    </Svg>
  );
};

export const EmojiHappy = () => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="#FFE17D"
      />
      <Path
        d="M28.629 46.371C14.8219 46.371 3.629 35.1781 3.629 21.371C3.629 15.3918 5.73066 9.9051 9.23213 5.60315C3.5999 10.1871 0 17.1721 0 25C0 38.8071 11.1929 50 25 50C32.828 50 39.813 46.4 44.3971 40.7678C40.095 44.2693 34.6083 46.371 28.629 46.371Z"
        fill="#FFD164"
      />
      <Path
        d="M25 29.815C20.1833 29.815 15.5854 29.2557 11.3674 28.2448C10.821 28.1138 10.3501 28.6846 10.5186 29.2795C12.4639 36.1471 18.2059 41.1291 25.0001 41.1291C31.7942 41.1291 37.5361 36.1471 39.4815 29.2795C39.6501 28.6846 39.1792 28.1138 38.6328 28.2448C34.4145 29.2557 29.8167 29.815 25 29.815Z"
        fill="#9C6846"
      />
      <Path
        d="M38.6327 28.2446C34.4145 29.2556 29.8168 29.8148 25 29.8148C20.1832 29.8148 15.5854 29.2557 11.3674 28.2446C10.821 28.1137 10.3501 28.6845 10.5186 29.2794C11.0278 31.0771 11.8026 32.7406 12.7829 34.2244C16.7907 34.9858 20.868 35.4046 25.0017 35.4046C29.1341 35.4046 33.2098 34.986 37.2165 34.2253C38.197 32.7413 38.9721 31.0775 39.4814 29.2795C39.6499 28.6844 39.1791 28.1137 38.6327 28.2446Z"
        fill="#7D5046"
      />
      <Path
        d="M16.5322 22.6212C15.4187 22.6212 14.5161 21.7186 14.5161 20.6051V17.3386C14.5161 16.2251 15.4187 15.3225 16.5322 15.3225C17.6457 15.3225 18.5483 16.2251 18.5483 17.3386V20.6051C18.5483 21.7186 17.6457 22.6212 16.5322 22.6212Z"
        fill="#7D5046"
      />
      <Path
        d="M16.5321 15.3225C16.394 15.3225 16.2593 15.3367 16.1289 15.3631V19.7986C16.1289 20.4667 16.6705 21.0083 17.3386 21.0083C18.0066 21.0083 18.5482 20.4667 18.5482 19.7986V17.3385C18.5482 16.2251 17.6457 15.3225 16.5321 15.3225Z"
        fill="#9C6846"
      />
      <Path
        d="M33.4678 22.6212C32.3543 22.6212 31.4517 21.7186 31.4517 20.6051V17.3386C31.4517 16.2251 32.3543 15.3225 33.4678 15.3225C34.5812 15.3225 35.4839 16.2251 35.4839 17.3386V20.6051C35.4839 21.7186 34.5812 22.6212 33.4678 22.6212Z"
        fill="#7D5046"
      />
      <Path
        d="M33.4677 15.3225C33.3296 15.3225 33.1948 15.3367 33.0645 15.3631V19.7986C33.0645 20.4667 33.6061 21.0083 34.2741 21.0083C34.9422 21.0083 35.4838 20.4667 35.4838 19.7986V17.3385C35.4838 16.2251 34.5812 15.3225 33.4677 15.3225Z"
        fill="#9C6846"
      />
      <Path
        d="M35.8443 30.9989C36.2803 30.2519 36.645 29.4515 36.9509 28.6183C33.2024 29.3918 29.1841 29.8149 24.9998 29.8149C20.813 29.8149 16.7924 29.3915 13.042 28.6169C13.3494 29.4542 13.7201 30.256 14.159 31.0061C14.6663 31.8733 15.5252 32.428 16.4596 32.5417C19.2573 32.882 22.1087 33.0645 24.9998 33.0645C27.8903 33.0645 30.741 32.8821 33.5382 32.5418C34.4757 32.4279 35.3362 31.8696 35.8443 30.9989Z"
        fill="white"
      />
    </Svg>
  );
};

export const EmojiLove = () => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="#FFE17D"
      />
      <Path
        d="M28.629 46.371C14.8219 46.371 3.629 35.1781 3.629 21.371C3.629 15.3918 5.73066 9.9051 9.23213 5.60315C3.5999 10.1871 0 17.1721 0 25C0 38.8071 11.1929 50 25 50C32.828 50 39.813 46.4 44.3971 40.7678C40.095 44.2693 34.6083 46.371 28.629 46.371Z"
        fill="#FFD164"
      />
      <Path
        d="M24.9943 31.2295C20.4432 31.2295 16.0991 30.7401 12.1136 29.8552C11.5973 29.7405 11.1524 30.2402 11.3116 30.7608C13.1496 36.7714 18.5749 41.1317 24.9942 41.1317C31.4136 41.1317 36.8388 36.7714 38.6768 30.7608C38.836 30.2401 38.3912 29.7405 37.8749 29.8552C33.8895 30.7401 29.5454 31.2295 24.9943 31.2295Z"
        fill="#9C6846"
      />
      <Path
        d="M25.0019 34.6775C29.2245 34.6775 33.3897 34.278 37.4884 33.5627C37.9702 32.679 38.376 31.745 38.6769 30.761C38.8362 30.2403 38.3913 29.7407 37.875 29.8553C33.8895 30.7401 29.5454 31.2296 24.9943 31.2296C20.4432 31.2296 16.099 30.7402 12.1136 29.8553C11.5973 29.7407 11.1524 30.2403 11.3116 30.761C11.6122 31.744 12.0176 32.6771 12.4987 33.56C16.6027 34.2771 20.7736 34.6775 25.0019 34.6775Z"
        fill="#7D5046"
      />
      <Path
        d="M17.6461 24.1711C19.0169 22.4477 22.2624 17.8208 20.4114 14.6755C19.6959 13.4597 18.2879 12.766 16.8862 12.9261C15.7478 13.0562 14.8345 13.6543 14.2614 14.4771C14.0265 14.8144 13.6009 14.9538 13.212 14.8209C12.2632 14.4966 11.1729 14.5548 10.1782 15.1235C8.95341 15.8237 8.2288 17.216 8.37128 18.6195C8.73984 22.2502 14.0939 24.0604 16.2187 24.6388C16.7444 24.7818 17.3069 24.5976 17.6461 24.1711Z"
        fill="#E6646E"
      />
      <Path
        d="M32.354 24.1711C30.9832 22.4477 27.7377 17.8208 29.5887 14.6755C30.3042 13.4597 31.7122 12.766 33.1139 12.9261C34.2523 13.0562 35.1657 13.6543 35.7387 14.4771C35.9737 14.8144 36.3993 14.9538 36.7881 14.8209C37.737 14.4966 38.8272 14.5548 39.8219 15.1235C41.0467 15.8237 41.7713 17.216 41.6289 18.6195C41.2603 22.2502 35.9062 24.0604 33.7814 24.6388C33.2557 24.7818 32.6932 24.5976 32.354 24.1711Z"
        fill="#E6646E"
      />
      <Path
        d="M18.3493 22.5328C16.2245 21.9544 10.8704 20.1442 10.5019 16.5135C10.4443 15.947 10.534 15.3848 10.7349 14.8667C10.5473 14.9375 10.3608 15.0189 10.1782 15.1234C8.95341 15.8235 8.2288 17.2159 8.37128 18.6194C8.73984 22.2502 14.0939 24.0604 16.2187 24.6388C16.7444 24.7818 17.3069 24.5977 17.6461 24.1712C17.9613 23.7749 18.3766 23.22 18.8054 22.5698C18.6541 22.5809 18.5008 22.574 18.3493 22.5328Z"
        fill="#DC4655"
      />
      <Path
        d="M32.354 24.1711C30.9832 22.4477 27.7377 17.8208 29.5887 14.6755C30.3042 13.4597 31.7122 12.766 33.1139 12.9261C34.2523 13.0562 35.1657 13.6543 35.7387 14.4771C35.9737 14.8144 36.3993 14.9538 36.7881 14.8209C37.737 14.4966 38.8272 14.5548 39.8219 15.1235C41.0467 15.8237 41.7713 17.216 41.6289 18.6195C41.2603 22.2502 35.9062 24.0604 33.7814 24.6388C33.2557 24.7818 32.6932 24.5976 32.354 24.1711Z"
        fill="#E6646E"
      />
      <Path
        d="M35.0622 23.6782C33.6914 21.9547 30.4459 17.3277 32.2969 14.1826C32.583 13.6963 32.9829 13.2986 33.4459 13.0005C33.3341 12.9777 33.2306 12.9394 33.1139 12.9261C31.7122 12.766 30.3042 13.4597 29.5887 14.6755C27.7377 17.8208 30.9832 22.4478 32.354 24.1711C32.6932 24.5975 33.2557 24.7818 33.7814 24.6387C34.2515 24.5108 34.8837 24.3185 35.581 24.0636C35.3842 23.9761 35.2017 23.8536 35.0622 23.6782Z"
        fill="#DC4655"
      />
    </Svg>
  );
};
