import { StyleSheet, ImageBackground, View } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { H6, Txt } from "../../../components/TextsComponents";
import { useTranslation } from "react-i18next";
import { colors } from "../../../styles/GlobalStyle";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { Ionicons } from "@expo/vector-icons";

const HelpModal = ({ isOpen, setIsOpen, text, title }) => {
  const { t } = useTranslation();
  const { width, isMobile } = DimensionsHook();
  const isDesktop = width > 1000;
  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.CloseButton
          focusable={false}
          style={{ marginRight: isMobile ? 0 : 10, marginTop: 10 }}
        />
        <Modal.Body>
          <ImageBackground
            source={require("../../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <View
              style={{
                marginTop: 30,
                marginHorizontal: isDesktop ? 20 : 10,
                marginBottom: isDesktop ? 30 : 20,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginBottom: 10,
                }}
              >
                <Ionicons
                  name="help-circle-outline"
                  size={25}
                  color={colors.blue3}
                />
                <H6 style={{ marginLeft: 3 }}>{title}</H6>
              </View>
              <Txt
                style={{
                  fontSize: isDesktop ? 16 : 14,
                }}
              >
                {text}
              </Txt>
            </View>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default HelpModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 800,
    maxHeight: 5000,
  },
});
