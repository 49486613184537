import React, { Suspense, useState, useEffect } from "react";
import "./i18n";
import { StatusBar } from "expo-status-bar";

import {
  SafeAreaView,
  View,
  StatusBar as Sbar,
  ImageBackground,
  Platform,
  StyleSheet,
  Linking,
} from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { colors } from "./styles/GlobalStyle";
import { LogBox } from "react-native";
import UseFonts from "./hooks/UseFonts";
import AppLoading from "expo-app-loading";
import { NavigationContainer } from "@react-navigation/native";
import Loader from "./screens/Loader";
import { Provider } from "react-redux";
import store from "./redux/store";
import TabNavigation from "./utils/TabNavigation";
import Login from "./screens/Login";
import Workshop from "./screens/Workshop";
import Trail from "./screens/Trail";
import Coaching from "./screens/Coaching/index";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { NativeBaseProvider } from "native-base";
import Quiz from "./screens/Quiz/index";
import Message from "./screens/Message";
import Appointment from "./screens/Appointment";
import Spinner from "./components/Spinner";
import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from "./i18n";
import Plans from "./screens/Plans";
import Validation from "./screens/Validation/Validation";
import ResetPassword from "./screens/ResetPassword";
import SignUp from "./screens/SignUp";
import NoConnection from "./screens/NoConnection";
import ChangePassword from "./screens/ChangePassword";
import { useTranslation } from "react-i18next";
import Books from "./screens/Books/index";
import { activateKeepAwake } from "expo-keep-awake";
import AccountDetails from "./screens/MyAccount/AccountDetails";
import AccountGeneral from "./screens/MyAccount/AccountGeneral";
import AccountBuy from "./screens/MyAccount/AccountBuy";
import AccountPayment from "./screens/MyAccount/AccountPayment";
import AccountScore from "./screens/MyAccount/AccountScore";
import AccountSettings from "./screens/MyAccount/AccountSettings";
import AccountHelp from "./screens/MyAccount/AccountHelp";
import AccountContact from "./screens/MyAccount/AccountContact";
import FavoriteScreen from "./screens/FavoriteScreen";
import Community from "./screens/Community/Community";
import { LinearGradient } from "expo-linear-gradient";
import { EventProvider } from "react-native-outside-press";
import Chat from "./screens/Chat";
import GiftScreen from "./screens/GiftScreen";

LogBox.ignoreAllLogs(true);

//console.log(process.env.BASE_URL);

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    error: colors.red1,
  },
  fontFamily: {
    ...(DefaultTheme.fonts.regular.fontFamily = "OxygenRegular"),
  },
};

export default function App() {
  const { t } = useTranslation();

  // NATIVE STACK NAVIGATOR
  const Stack = createNativeStackNavigator();
  // SET APP IS READY
  const [IsReady, SetIsReady] = useState(false);

  // LOADFONTS
  const LoadFonts = async () => {
    if (Platform.OS != "web") {
      activateKeepAwake();
    }
    await UseFonts();
    // check if language is selected
    const language = await AsyncStorage.getItem("lng");
    if (language) {
      i18n.changeLanguage(language);
    } else {
      await AsyncStorage.setItem("lng", "fr");
      i18n.changeLanguage("fr");
    }
  };

  function handelRightClick(event) {
    event.preventDefault();
  }

  const checkAccessCode = () => {
    let code = window.prompt("Code d'accés sur mode test");
    if (!code) {
      checkAccessCode();
    } else {
      if (code.toLowerCase().trim() != process.env.ACCESS_CODE) {
        checkAccessCode();
      } else {
        SetIsReady(true);
      }
    }
  };

  useEffect(() => {
    if (Platform.OS == "web" && process.env.IS_PROD == "1") {
      document.addEventListener("contextmenu", handelRightClick);
    }
  }, []);

  const getCurrentUrl = async () => {
    const url = await Linking.getInitialURL();
    return url;
  };

  if (!IsReady) {
    return (
      <AppLoading
        startAsync={LoadFonts}
        onFinish={async () => {
          const currentUrl = await getCurrentUrl();
          if (
            Platform.OS == "web" &&
            process.env.IS_PROD != "1" &&
            !currentUrl.includes("access_token=")
          ) {
            checkAccessCode();
            // SetIsReady(true);
          } else {
            SetIsReady(true);
          }
        }}
        onError={(err) => {
          console.log(err);
        }}
      />
    );
  }

  const config = {
    dependencies: {
      "linear-gradient": LinearGradient,
    },
  };

  return (
    <Provider store={store}>
      <NativeBaseProvider config={config}>
        <PaperProvider theme={theme}>
          <Suspense
            fallback={
              <View style={styles.loaderContainer}>
                <ImageBackground
                  style={[
                    styles.loaderContainer,
                    { alignItems: "center", justifyContent: "center" },
                  ]}
                >
                  <Spinner size="large" />
                </ImageBackground>
              </View>
            }
          >
            {Platform.OS == "android" ? (
              <Sbar backgroundColor={colors.green2} />
            ) : (
              <StatusBar style="dark" backgroundColor={colors.green2} />
            )}
            <EventProvider style={{ flex: 1 }}>
              <View style={{ flex: 1, backgroundColor: colors.beige }}>
                <SafeAreaView style={{ flex: 1 }}>
                  <NavigationContainer>
                    <Stack.Navigator screenOptions={{ headerShown: false }}>
                      <Stack.Screen
                        name="Loader"
                        component={Loader}
                        options={{ title: "LISL UP" }}
                      />
                      <Stack.Screen
                        name="Favoris"
                        component={FavoriteScreen}
                        options={{ title: "LISL UP" }}
                      />
                      <Stack.Screen
                        name="NoConnection"
                        component={NoConnection}
                        options={{ title: "LISL UP" }}
                      />
                      <Stack.Screen
                        name="Login"
                        component={Login}
                        options={{ title: "LISL UP" }}
                      />
                      <Stack.Screen
                        name="SignUp"
                        component={SignUp}
                        options={{ title: "LISL UP" }}
                      />
                      <Stack.Screen
                        name="ResetPassword"
                        component={ResetPassword}
                        options={{
                          animation: "slide_from_right",
                          title: "LISL UP",
                        }}
                      />
                      <Stack.Screen
                        name="ChangePassword"
                        component={ChangePassword}
                        options={{
                          animation: "slide_from_right",
                          title: "LISL UP",
                        }}
                      />
                      <Stack.Screen
                        name="Chat"
                        component={Chat}
                        options={{ title: "LISL UP Chat" }}
                      />
                      <Stack.Screen
                        name="Books"
                        component={Books}
                        options={{ title: "Books" }}
                      />
                      <Stack.Screen
                        name="TabNavigation"
                        component={TabNavigation}
                        options={{ title: "LISL UP" }}
                      />
                      <Stack.Screen
                        name="GiftScreen"
                        component={GiftScreen}
                        options={{ title: "LISL UP" }}
                      />
                      <Stack.Screen
                        name="Plans"
                        component={Plans}
                        options={{ animation: "slide_from_right" }}
                      />
                      <Stack.Screen
                        name="Community"
                        component={Community}
                        options={{ animation: "slide_from_right" }}
                      />
                      {/*<Stack.Screen
                        name="Payment"
                        component={Payment}
                        options={{ animation: "slide_from_right" }}
                      />*/}
                      <Stack.Screen name="Coaching" component={Coaching} />
                      <Stack.Screen name="Quiz" component={Quiz} />

                      <Stack.Screen
                        name="Validation"
                        component={Validation}
                        options={{ animation: "slide_from_right" }}
                      />
                      <Stack.Screen name="Trail" component={Trail} />
                      <Stack.Screen name="Workshop" component={Workshop} />

                      <Stack.Screen name="Message" component={Message} />
                      <Stack.Screen
                        name="Appointment"
                        component={Appointment}
                      />
                      <Stack.Screen
                        name="AccountDetails"
                        component={AccountDetails}
                        options={{ title: t("account_navigation.account") }}
                      />
                      <Stack.Screen
                        name="AccountGeneral"
                        component={AccountGeneral}
                        options={{ title: t("account_navigation.general") }}
                      />
                      <Stack.Screen
                        name="AccountBuy"
                        component={AccountBuy}
                        options={{ title: t("account_navigation.buy") }}
                      />
                      <Stack.Screen
                        name="AccountPayment"
                        component={AccountPayment}
                        options={{ title: t("account_navigation.payment") }}
                      />
                      <Stack.Screen
                        name="AccountScore"
                        component={AccountScore}
                        options={{ title: t("account_navigation.score") }}
                      />

                      <Stack.Screen
                        name="AccountSettings"
                        component={AccountSettings}
                        options={{ title: t("account_navigation.settings") }}
                      />
                      <Stack.Screen
                        name="AccountHelp"
                        component={AccountHelp}
                        options={{ title: t("account_navigation.help") }}
                      />
                      <Stack.Screen
                        name="AccountContact"
                        component={AccountContact}
                        options={{ title: t("account_navigation.contact") }}
                      />
                    </Stack.Navigator>
                  </NavigationContainer>
                </SafeAreaView>
              </View>
            </EventProvider>
          </Suspense>
        </PaperProvider>
      </NativeBaseProvider>
    </Provider>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    backgroundColor: colors.beige,
  },
});
