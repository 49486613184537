import { Svg, Path, G } from "react-native-svg";
import { Platform } from "react-native";
export const EpisodesBorder = () => {
  return (
    <Svg
      width="100%"
      heigh="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 120"
      preserveAspectRatio="none"
    >
      <G>
        <Path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="5"
          d="M-11.86,60c0,0,28.28-18.38,71.86,0"
        />
        <Path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="5"
          d="M131.86,60c0,0-28.28,18.38-71.86,0"
        />
      </G>
    </Svg>
  );
};

export const PlayIcon = () => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M20.3938 10.064L3.89375 0.309337C2.55313 -0.48285 0.5 0.2859 0.5 2.24528V21.75C0.5 23.5078 2.40781 24.5672 3.89375 23.6859L20.3938 13.9359C21.8656 13.0687 21.8703 10.9312 20.3938 10.064Z"
        fill="white"
      />
    </Svg>
  );
};

export const PauseIcon = () => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14V0H2V14H0ZM8 14V0H10V14H8Z"
        fill="white"
      />
    </Svg>
  );
};
export const RightPlay = () => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M0.72406 0.935959V24.0686L18.8997 12.8327L0.72406 0.935959Z"
        fill="white"
      />
      <Path
        d="M23.8567 0.935959V24.0686H27.1613V0.935959H23.8567Z"
        fill="white"
      />
    </Svg>
  );
};
export const LeftPlay = () => {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M26.9283 0.935959V24.0686L8.75266 12.8327L26.9283 0.935959Z"
        fill="white"
      />
      <Path
        d="M3.79567 0.935959V24.0686H0.491007V0.935959H3.79567Z"
        fill="white"
      />
    </Svg>
  );
};

export const FullScreenIcon = () => {
  return (
    <Svg
      width="25"
      height="25"
      viewBox="0 0 34 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M0.90686 9.66142V1.65795C0.90686 0.799509 1.59748 0.108887 2.45592 0.108887H10.4594C10.8854 0.108887 11.2339 0.457425 11.2339 0.883416V2.43248C11.2339 2.85847 10.8854 3.20701 10.4594 3.20701H4.00498V9.66142C4.00498 10.0874 3.65644 10.4359 3.23045 10.4359H1.68139C1.2554 10.4359 0.90686 10.0874 0.90686 9.66142ZM23.6264 0.883416V2.43248C23.6264 2.85847 23.9749 3.20701 24.4009 3.20701H30.8553V9.66142C30.8553 10.0874 31.2039 10.4359 31.6299 10.4359H33.1789C33.6049 10.4359 33.9535 10.0874 33.9535 9.66142V1.65795C33.9535 0.799509 33.2628 0.108887 32.4044 0.108887H24.4009C23.9749 0.108887 23.6264 0.457425 23.6264 0.883416ZM33.1789 14.5668H31.6299C31.2039 14.5668 30.8553 14.9153 30.8553 15.3413V21.7957H24.4009C23.9749 21.7957 23.6264 22.1443 23.6264 22.5702V24.1193C23.6264 24.5453 23.9749 24.8938 24.4009 24.8938H32.4044C33.2628 24.8938 33.9535 24.2032 33.9535 23.3448V15.3413C33.9535 14.9153 33.6049 14.5668 33.1789 14.5668ZM11.2339 24.1193V22.5702C11.2339 22.1443 10.8854 21.7957 10.4594 21.7957H4.00498V15.3413C4.00498 14.9153 3.65644 14.5668 3.23045 14.5668H1.68139C1.2554 14.5668 0.90686 14.9153 0.90686 15.3413V23.3448C0.90686 24.2032 1.59748 24.8938 2.45592 24.8938H10.4594C10.8854 24.8938 11.2339 24.5453 11.2339 24.1193Z"
        fill="white"
      />
    </Svg>
  );
};
