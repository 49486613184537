import { StyleSheet, Image, View } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import DimensionsHook from "../../hooks/DimensionsHook";
import { colors } from "../../styles/GlobalStyle";
import {
  FestivalUpgrade,
  FestivalUpgradeSmall,
} from "../../assets/svg/MyAccountIcons";
import { H6, H2 } from "../TextsComponents";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PointsUpgradeModal = ({ isOpen, setIsOpen }) => {
  const { isMobile, isDesktop } = DimensionsHook();
  const { t } = useTranslation();
  const { total_points } = useSelector((state) => state.userData);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Content style={styles.container}>
        {isDesktop ? (
          <View style={[styles.festivalContainer, { top: -30 }]}>
            <FestivalUpgrade />
          </View>
        ) : (
          <View
            style={[
              styles.festivalContainerSmall,
              { width: "100%", height: "100%", bottom: 0 },
            ]}
          >
            <FestivalUpgradeSmall />
          </View>
        )}

        <Modal.Body
          style={[
            styles.body,
            {
              marginTop: isDesktop ? 50 : 20,
              marginBottom: isDesktop ? 30 : 0,
            },
          ]}
        >
          <Modal.CloseButton
            focusable={false}
            style={{ marginRight: isMobile ? 0 : 10 }}
          />
          <VStack justifyContent="center" alignItems="center">
            <View
              style={[
                styles.modalWrapper,
                {
                  marginTop: isMobile ? 15 : 0,
                  flexDirection: isDesktop ? "row-reverse" : "column",
                },
              ]}
            >
              <View
                style={{
                  flex: 1,
                  marginHorizontal: isDesktop ? 30 : 0,
                }}
              >
                <H2 style={{ textAlign: "center", marginBottom: 10 }}>
                  {t("score_screen.title1")}
                </H2>
                <H6 style={{ textAlign: "center" }}>
                  {`${t("score_screen.num_points1")} ${total_points} ${t(
                    "score_screen.points"
                  )}. ${t("score_screen.num_points2")}\n${t(
                    "score_screen.num_points3"
                  )}`}
                </H6>
              </View>
              <View
                style={{ marginVertical: 20, marginLeft: isDesktop ? 20 : 0 }}
              >
                <Image
                  source={{
                    uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                  }}
                  style={{
                    width: 100,
                    height: 165,
                  }}
                />
              </View>
            </View>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default PointsUpgradeModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.green0,
    width: "95%",

    maxWidth: 840,
    maxHeight: 5000,
  },
  festivalContainer: {
    position: "absolute",
    width: "120%",
    height: "120%",
    alignSelf: "center",
  },
  festivalContainerSmall: {
    position: "absolute",

    alignSelf: "center",
  },
  body: {
    overflow: "hidden",
    marginHorizontal: 20,
  },
  modalWrapper: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
