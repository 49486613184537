import { StyleSheet, Platform, Linking, View } from "react-native";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DimensionsHook from "../../../hooks/DimensionsHook";
import {
  BoldTxt,
  LightTxt,
  SmallBoldTxt,
  SmallLightTxt,
  Txt,
} from "../../TextsComponents";
import { SecondaryButton } from "../../Buttons";
import { useTranslation } from "react-i18next";
import { colors } from "../../../styles/GlobalStyle";
import { Check } from "../../../assets/svg/Coaching";

const InvoiceItem = ({ invoice }) => {
  const { isDesktop } = DimensionsHook();
  const { t } = useTranslation();

  const types = [
    { label: "", value: "" },
    { label: t("plans.invoice_lifetime"), value: "Lifetime Plan" },
    { label: t("plans.invoice_trails"), value: "Buy Trails" },
    { label: t("plans.invoice_subscribtion"), value: "Subscription" },
    { label: t("plans.invoice_ebook"), value: "Buy Ebook" },
    { label: t("plans.invoice_coaching"), value: "Buy Flash Coaching" },
    { label: t("plans.invoice_gift"), value: "Gift Plan" },
  ];

  const [type, setType] = useState(types[0]);
  const downloadInvoiceHandler = (link) => {
    if (Platform.OS == "web") {
      window.open(link, "_blank");
    } else {
      Linking.openURL(link);
    }
  };

  useEffect(() => {
    const t = types.find((type) => type.value == invoice.source);
    setType(t);
  }, []);

  return (
    <View
      style={[
        styles.invoiceContainer,
        {
          marginHorizontal: isDesktop ? 40 : 10,
          backgroundColor: isDesktop ? colors.beige : colors.white,
          flexDirection: isDesktop ? "row" : "column",
          alignItems: isDesktop ? "flex-start" : "flex-end",
          justifyContent: isDesktop ? "space-between" : "space-between",
        },
      ]}
      key={invoice.id}
    >
      <View style={{ marginTop: 10, alignSelf: "flex-start" }}>
        <BoldTxt style={{ fontSize: 14 }}>{type.label}</BoldTxt>
        {invoice.period_start && (
          <SmallLightTxt style={{ fontSize: 13 }}>
            {t("plans.period")} |{" "}
            {moment.unix(invoice.period_start).format("DD/MM/YYYY")}-{" "}
            {moment.unix(invoice.period_end).format("DD/MM/YYYY")}
          </SmallLightTxt>
        )}
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <View style={{ width: 16, height: 16, marginRight: 3 }}>
            <Check />
          </View>
          <SmallBoldTxt color={colors.green2}>
            {t("plans.pay_at")}{" "}
            {moment(invoice.createdAt).format("DD/MM/YYYY - HH:mm")}{" "}
          </SmallBoldTxt>
        </View>
        <SmallBoldTxt
          style={{ marginTop: 10, fontSize: 13 }}
          color={colors.grayLabel}
        >
          {t("plans.sub_total")} | {(invoice.subtotal / 100).toFixed(2)}
          {t("plans.eur")}
        </SmallBoldTxt>
        <SmallBoldTxt color={colors.grayLabel} style={{ fontSize: 13 }}>
          {t("plans.tax")} | {(invoice.tax / 100).toFixed(2)}
          {t("plans.eur")}
        </SmallBoldTxt>
        {invoice.discount > 0 && (
          <SmallBoldTxt color={colors.grayLabel} style={{ fontSize: 13 }}>
            {t("plans.discount")} | {(invoice.discount / 100).toFixed(2)}
            {t("plans.eur")}
          </SmallBoldTxt>
        )}
        <SmallBoldTxt
          style={{ marginVertical: 10, fontSize: 14 }}
          color={colors.blue3}
        >
          {t("plans.total")} | {(invoice.total / 100).toFixed(2)}
          {t("plans.eur")}
        </SmallBoldTxt>
      </View>

      <SecondaryButton
        style={{ marginTop: 10, alignSelf: "flex-end" }}
        width={200}
        onPress={() => downloadInvoiceHandler(invoice.invoice_link)}
      >
        {t("plans.download_invoice")}
      </SecondaryButton>
    </View>
  );
};

export default InvoiceItem;

const styles = StyleSheet.create({
  invoiceContainer: { marginTop: 15, padding: 15, borderRadius: 15 },
});
