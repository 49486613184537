import {
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import DimensionsHook from "../../../hooks/DimensionsHook";
import {
  BoldTxt,
  H1,
  H2,
  SmallBoldTxt,
  SmallTxt,
  Txt,
} from "../../TextsComponents";
import { colors } from "../../../styles/GlobalStyle";
import { PrimaryButton, SecondaryButton } from "../../Buttons";
import { useSelector } from "react-redux";
import axios from "axios";
import Spinner from "../../Spinner";
import moment from "moment";
import UnsubscribeModal from "../../modals/UnsubscribeModal";
import UnsubscribeDoneModal from "../../modals/UnsubscribeDoneModal";
import { Check } from "../../../assets/svg/Coaching";
import { useToast } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import NumberOfTrails from "./NumberOfTrails";
import InvoiceItem from "./InvoiceItem";

const SubscriptionPlan = ({ navigation }) => {
  const toast = useToast();
  const { width } = DimensionsHook();
  const isDesktop = width > 1000;
  const { t, i18n } = useTranslation();

  const [loader, setLoader] = useState(true);
  // data = invoices
  const [data, setData] = useState([]);
  const [isIosSubscription, setIsIosSubscription] = useState(false);
  const [plan, setPlan] = useState(null);
  const [sub, setSub] = useState(null);
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);
  const [unsubscribeDoneModal, setUnSubsribeDoneModal] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(true);
  const [activationLoader, setActivationLoader] = useState(false);
  const [changeLoader, setChangeLoader] = useState(false);

  const { customer, subscription } = useSelector((state) => state.userData);

  useEffect(() => {
    setLoader(true);
    const invoiceUrl = process.env.USER_INVOICES;
    //get invoices
    axios.get(`${invoiceUrl}?customerId=${customer}`).then((InvoicesRes) => {
      setData(InvoicesRes.data);
      /*   console.log("invoices :", res.data);*/
      // check if ios payment in app purchase
      if (subscription == "sub_annual" || subscription == "sub_three_months") {
        setIsIosSubscription(true);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } else {
        const url = process.env.SUBSCRIPTIONS_LIST;
        const subUrl = process.env.SUBSCRIPTION_USER;

        // get  subscription oject
        axios.get(url).then((res) => {
          axios
            .get(`${subUrl}?subscriptionId=${subscription}`)
            .then((subRes) => {
              setSub(subRes.data);
              const currentProduct = subRes.data.items.data[0].plan.product;
              const pl = res.data.find((p) => p.product == currentProduct);
              setPlan(pl);
              setLoader(false);
            });
        });
      }
    });
  }, [subscription]);

  const activateSubscription = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    const urlActivation = process.env.ACTIVATE_SUBSCRIBTION;
    const urlSubscribtion = process.env.SUBSCRIPTION_USER;
    if (!activationLoader) {
      setActivationLoader(true);
      setTimeout(async () => {
        axios
          .post(urlActivation, { subscription, access_token: TOKEN })
          .then(async () => {
            axios
              .get(`${urlSubscribtion}?subscriptionId=${subscription}`)
              .then(async (res) => {
                setSub(res.data);
                await toast.show({
                  placement: "top",
                  render: () => {
                    return (
                      <View style={styles.toastContainer}>
                        <Txt
                          style={{ paddingLeft: isDesktop ? 5 : 0 }}
                          color={colors.white}
                        >
                          {t("plans.activate")}
                        </Txt>
                        <TouchableOpacity
                          style={{ paddingHorizontal: 5 }}
                          onPress={() => toast.closeAll()}
                        >
                          <AntDesign
                            name="close"
                            size={16}
                            color={colors.white}
                          />
                        </TouchableOpacity>
                      </View>
                    );
                  },
                });
                setActivationLoader(false);
              })
              .catch((err) => {
                setActivationLoader(false);
              });
          })
          .catch((err) => {
            setActivationLoader(false);
          });
      }, 1500);
    }
  };

  const changePaymentMethod = async () => {
    if (!changeLoader) {
      setChangeLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const url = process.env.CHANGE_METHOD_SESSION;

      axios
        .post(url, {
          access_token: TOKEN,
          customer,
          subscription,
        })
        .then((res) => {
          const url = res.data.session.url;
          if (Platform.OS == "web") {
            setChangeLoader(false);
            window.open(url, "_blank");
          } else {
            setChangeLoader(false);
            Linking.openURL(url);
          }
        })
        .catch((err) => {
          //   console.log(err);
          setChangeLoader(false);
        });
    }
  };

  if (loader) {
    return (
      <View style={[styles.loadingContainer, { minHeight: 250 }]}>
        <Spinner size="large" />
      </View>
    );
  }
  // CASE IOS SUBSCRIPTION
  if (isIosSubscription) {
    return (
      <View style={{ marginBottom: 20 }}>
        <View
          style={{
            marginTop: isDesktop ? 0 : 20,
          }}
        >
          <H1>
            {t("plans.your_subscription")} -{" "}
            {subscription == "sub_annual"
              ? t("plans.annual")
              : t("plans.three_months")}
          </H1>

          <View style={styles.statusBar}>
            <View style={{ width: 18, height: 18, marginRight: 4 }}>
              <Check />
            </View>
            <BoldTxt color={colors.green2}>
              {t("plans.subscription_active")}
            </BoldTxt>
          </View>

          <View
            style={{
              marginBottom: 20,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Txt
              style={{ marginVertical: 20, textAlign: "justify", fontSize: 16 }}
            >
              {t("plans.ios_text")}
            </Txt>
            {/** button link to subscription management in ios (apple store subscriptions)  */}
            <PrimaryButton
              onPress={() =>
                Linking.openURL("https://apps.apple.com/account/subscriptions")
              }
            >
              {t("plans.ios_button")}
            </PrimaryButton>
          </View>
          <View style={styles.divider} />
          {/** number of allowed trails */}
          <NumberOfTrails navigation={navigation} />

          {/** historique factures */}
          {data.length > 0 && (
            <>
              <View style={styles.divider} />
              <View style={{ marginTop: 20 }}>
                <H2>{t("plans.invoices")}</H2>
                {data.map((invoice) => {
                  return <InvoiceItem key={invoice._id} invoice={invoice} />;
                })}
              </View>
            </>
          )}
        </View>
      </View>
    );
  }

  if (plan == null || sub == null) {
    return (
      <View style={[styles.loadingContainer, { minHeight: 250 }]}>
        <Spinner size="large" />
      </View>
    );
  }

  return (
    <View style={{ marginBottom: 20 }}>
      <View
        style={{
          marginTop: isDesktop ? 0 : 20,
        }}
      >
        <H1>
          {t("plans.your_subscription")} -{" "}
          {i18n.language == "fr" ? plan.title_fr : plan.title_en}
        </H1>

        <View style={styles.statusBar}>
          <View style={{ width: 18, height: 18, marginRight: 4 }}>
            <Check />
          </View>
          <BoldTxt color={colors.green2}>
            {t("plans.subscription_active")}
          </BoldTxt>
        </View>
      </View>
      {sub.cancel_at_period_end && (
        <View style={styles.cancelBadge}>
          <SmallBoldTxt
            color={colors.white}
            style={{ marginTop: isDesktop ? 20 : 0 }}
          >
            {t("plans.subscription_end1")}{" "}
            {moment.unix(sub.cancel_at).format("DD/MM/YYYY [à] HH:mm")}.
          </SmallBoldTxt>
          <SmallTxt color={colors.white} style={{ marginTop: 10 }}>
            {t("plans.subscription_end2")}
          </SmallTxt>
          <SecondaryButton
            width={160}
            onPress={activateSubscription}
            isWhite={true}
            style={{ alignSelf: "flex-end", marginTop: 15 }}
          >
            {activationLoader ? (
              <Spinner color={colors.white} />
            ) : (
              t("plans.subscription_reactivate")
            )}
          </SecondaryButton>
        </View>
      )}
      {/** next paiement */}
      <Txt style={{ textAlign: "justify", marginTop: 20 }}>
        {i18n.language == "fr" ? plan.text_fr : plan.text_en}
      </Txt>

      {
        <View style={{ marginTop: 20 }}>
          {!sub.cancel_at_period_end && (
            <BoldTxt style={{ marginTop: 5 }}>
              {t("plans.subscription_next_billing")}{" "}
              {moment.unix(sub.current_period_end).format("DD/MM/YYYY")}.
            </BoldTxt>
          )}
        </View>
      }

      {/** buttons */}
      {!sub.cancel_at_period_end && (
        <View
          style={{
            width: "100%",
            marginTop: isDesktop ? 25 : 20,
            flexDirection: isDesktop ? "row-reverse" : "column",
            alignItems: isDesktop ? "center" : "flex-end",
            justifyContent: isDesktop ? "flex-start" : "center",
          }}
        >
          <PrimaryButton
            onPress={changePaymentMethod}
            width={isDesktop ? 340 : "100%"}
            style={{
              marginLeft: isDesktop ? 15 : 0,
            }}
          >
            {changeLoader ? (
              <Spinner color={colors.white} />
            ) : (
              t("plans.change_payment_method")
            )}
          </PrimaryButton>

          <SecondaryButton
            onPress={() => setUnsubscribeModal(true)}
            style={{ marginTop: isDesktop ? 0 : 15 }}
          >
            {t("plans.unsubscribe")}
          </SecondaryButton>
        </View>
      )}
      <View style={styles.divider} />
      {/** number of allowed trails */}
      <NumberOfTrails navigation={navigation} />
      <View style={styles.divider} />
      {/** historique factures */}
      {data.length > 0 && (
        <View style={{ marginTop: 20 }}>
          <H2>{t("plans.invoices")}</H2>
          {data.map((invoice) => {
            return <InvoiceItem key={invoice._id} invoice={invoice} />;
          })}
        </View>
      )}
      {/** modals */}
      {/** unsubscribe modal */}
      <UnsubscribeModal
        isOpen={unsubscribeModal}
        setIsOpen={setUnsubscribeModal}
        setSub={setSub}
        setUnSubsribeDoneModal={setUnSubsribeDoneModal}
      />
      {/** Unsubscribe done */}
      <UnsubscribeDoneModal
        isOpen={unsubscribeDoneModal}
        setIsOpen={setUnSubsribeDoneModal}
      />
    </View>
  );
};

export default SubscriptionPlan;

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  badge: {
    backgroundColor: colors.green2,
    paddingHorizontal: 15,
    borderRadius: 100,
    paddingVertical: 5,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginTop: 20,
  },
  statusBar: {
    flexDirection: "row",
    alignItems: "center",
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    borderRadius: 20,
    marginTop: 30,
  },
  cancelBadge: {
    backgroundColor: colors.red1,
    padding: 15,
    paddingTop: 20,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: -30,
  },
  closeButton: {
    width: 25,
    height: 25,
    alignSelf: "flex-end",
    padding: 2,
    marginBottom: 10,
  },
});
