import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import PlansModal from "../modals/PlansModal";
import { useSelector } from "react-redux";
import LifePlan from "./PaymentScreen/LifePlan";
import SubscriptionPlan from "./PaymentScreen/SubscriptionPlan";
import TrialPlan from "./PaymentScreen/TrialPlan";
import Spinner from "../Spinner";
import axios from "axios";
import CompanyUser from "./PaymentScreen/CompanyUser";
import GiftPlan from "./PaymentScreen/GiftPlan";

const PaymentScreen = ({ navigation }) => {
  const [plansModal, setPlansModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [lifetimeData, setLifetimeData] = useState({});
  const [loader, setLoader] = useState(false);

  const userData = useSelector((state) => state.userData);

  useEffect(() => {
    const url = process.env.SUBSCRIPTIONS_LIST;
    setLoader(true);

    axios.get(url).then((res) => {
      const subs = res.data.filter((product) => product.lifetime == false);
      const lifetime = res.data.find((product) => product.lifetime == true);

      setSubscriptions(subs);
      setLifetimeData(lifetime);
      setLoader(false);
    });
  }, []);

  if (loader || !subscriptions) {
    return (
      <View style={[styles.loadingContainer, { minHeight: 250 }]}>
        <Spinner size="large" />
      </View>
    );
  }
  return (
    <View>
      {/** show depend if user in trial, in subscription or in lifetime plan */}
      {userData.company ? (
        <CompanyUser navigation={navigation} />
      ) : userData.lifetime_subscription ? (
        <LifePlan lifetimePlan={lifetimeData} navigation={navigation} />
      ) : userData.subscription && userData.subscription.endsWith("_gift") ? (
        <GiftPlan />
      ) : userData.subscription ? (
        <SubscriptionPlan navigation={navigation} />
      ) : (
        <TrialPlan setPlansModal={setPlansModal} />
      )}
      {/** payment modal */}
      <PlansModal
        subscriptions={subscriptions}
        lifetimePlan={lifetimeData}
        navigation={navigation}
        isOpen={plansModal}
        setIsOpen={setPlansModal}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default PaymentScreen;
