import axios from "axios";
import * as AppleAuthentication from "expo-apple-authentication";
import { View, StyleSheet } from "react-native";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch } from "react-redux";
import {
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../../redux/Actions";
import { useNavigation } from "@react-navigation/native";
import Purchases from "react-native-purchases";

export default function AppleSignIn() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  return (
    <AppleAuthentication.AppleAuthenticationButton
      buttonType={AppleAuthentication.AppleAuthenticationButtonType.CONTINUE}
      buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
      cornerRadius={30}
      style={{ width: "100%", height: 45 }} // You must choose default size
      onPress={async () => {
        try {
          const credential = await AppleAuthentication.signInAsync({
            requestedScopes: [
              AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
              AppleAuthentication.AppleAuthenticationScope.EMAIL,
            ],
          });

          // signed in

          const { email } = jwt_decode(credential.identityToken);

          axios
            .post(process.env.USER_APPLE_SIGNIN, {
              email,
              firstname: credential.fullName.givenName
                ? credential.fullName.givenName
                : " ",
              lastname: credential.fullName.familyName
                ? credential.fullName.familyName
                : " ",
              language: i18n.language,
            })
            .then(async (serverResponse) => {
              const token = serverResponse.data.access_token;

              await AsyncStorage.setItem("access_token", token);
              await dispatch(setUserData());
              await dispatch(setUserActivity());
              await dispatch(setWatchedEpisodes());
              console.log("la réponse du serveur est :  ", serverResponse.data);
              if (Platform.OS == "ios") {
                Purchases.setDebugLogsEnabled(true);
                Purchases.configure({
                  apiKey: "appl_qsunokDQSTJoiKMlhDkpJNeQDjY",
                  appUserID: serverResponse.data.customer,
                });
              }

              if (serverResponse.data.lifetime_subscription) {
                navigation.replace("TabNavigation");
              } else if (serverResponse.data.subscription) {
                if (serverResponse.data.subscription_status == "active") {
                  navigation.replace("TabNavigation");
                } else {
                  navigation.replace("Plans");
                }
              } else if (serverResponse.data.trial_used) {
                navigation.replace("TabNavigation");
              } else {
                // NO PLANS AND NO TRIAL
                navigation.replace("Plans");
              }
            });
        } catch (e) {
          if (e.code === "ERR_REQUEST_CANCELED") {
            // handle that the user canceled the sign-in flow
          } else {
            console.log(e);

            // handle other errors
          }
        }
      }}
    />
  );
}

const styles = StyleSheet.create({});
