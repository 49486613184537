import { Platform, StyleSheet, View } from "react-native";
import React, { useState } from "react";
import WebView from "react-native-webview";
import BackHeader from "../components/BackHeader";
import { colors } from "../styles/GlobalStyle";
import Spinner from "../components/Spinner";

const Chat = ({ navigation }) => {
  if (Platform.OS == "web") {
    const [isLoading, setIsLoading] = useState(true);
    return (
      <View style={{ flex: 1, backgroundColor: colors.beige }}>
        <BackHeader navigation={navigation} />
        <View
          style={{
            flex: 1,
            zIndex: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <iframe
            frameBorder="0"
            onLoad={() => setIsLoading(false)}
            width="100%"
            height="100%"
            src="https://secure.livechatinc.com/customer/action/open_chat?license_id=15144921"
          ></iframe>
          {isLoading && (
            <View style={{ position: "absolute" }}>
              <Spinner size="large" />
            </View>
          )}
        </View>
      </View>
    );
  }

  return (
    <WebView
      style={{ flex: 1 }}
      scalesPageToFit
      startInLoadingState
      onError={() => navigation.goBack()}
      source={{
        uri: "https://secure.livechatinc.com/customer/action/open_chat?license_id=15144921",
      }}
    />
  );
};

export default Chat;

const styles = StyleSheet.create({});
