import {
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import React, { useState } from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import { H2, Txt, H3, H6 } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { EmojiHappy, EmojiLove, EmojiSad } from "../../assets/svg/Emojis";
import { Surface } from "react-native-paper";
import { useTranslation } from "react-i18next";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Spinner from "../Spinner";

const ChallengeModal = ({
  isOpen,
  setIsOpen,
  setIsOpenChooseModal,
  selectedEpisode,
  sequenceEpisodes,
}) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = DimensionsHook();
  const [challengeCompleted, setChallengeCompleted] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState({ id: 0, name: "" });
  const [submitLoader, setSubmitLoader] = useState(false);
  const feedbackOptions = [
    { id: 1, name: t("trail.challenge.not_easy"), value: "not_easy" },
    { id: 2, name: t("trail.challenge.easy"), value: "easy" },
    { id: 3, name: t("trail.challenge.very_easy"), value: "very_easy" },
  ];

  const indexOfPressedEpisode = sequenceEpisodes.indexOf(
    selectedEpisode.episode_code
  );
  const challenge_episode_code = sequenceEpisodes[indexOfPressedEpisode - 1];
  const episodeNumber = challenge_episode_code.split("-")[3].replace("E", "");
  const seasonNumber = challenge_episode_code.split("-")[2].replace("S", "");

  const closeHandler = () => {
    setIsOpen(false);
    setChallengeCompleted("");
    setSelectedFeedback({ id: 0, name: "" });
  };

  const sendFeedbackHandler = async () => {
    if (
      selectedFeedback.value == "not_easy" ||
      selectedFeedback.value == "easy" ||
      selectedFeedback.value == "very_easy"
    ) {
      setSubmitLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const endpoint = process.env.FEEDBACK_CHALLENGE;
      axios
        .post(endpoint, {
          access_token: TOKEN,
          feedback: selectedFeedback.value,
          episode_code: selectedEpisode,
        })
        .then((res) => {
          setTimeout(() => {
            setSubmitLoader(false);
            closeHandler();
            setIsOpenChooseModal(true);
          }, 1000);
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        closeHandler();
        setIsOpenChooseModal(true);
      }}
    >
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 20 }}
            >
              <View style={{ marginTop: isMobile ? 15 : 0 }}>
                <H2
                  style={{
                    textAlign: "center",
                    marginTop: 15,
                  }}
                >
                  {t("challenge_modal.title")}
                </H2>
                <H6
                  color={colors.grayLabel}
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  {t("challenge_modal.movement")}
                  {` | Saison ${seasonNumber} - Episode ${episodeNumber}`}
                </H6>
              </View>
              <View style={styles.buttonsContainer}>
                <View style={{ width: 130 }}>
                  <TouchableOpacity
                    onPress={() => setChallengeCompleted(false)}
                    style={[
                      styles.button,
                      {
                        backgroundColor:
                          challengeCompleted === false
                            ? colors.green2
                            : "transparent",
                        borderColor:
                          challengeCompleted === false
                            ? "transparent"
                            : colors.grayBorder,
                      },
                    ]}
                  >
                    <Txt>{t("no")}</Txt>
                  </TouchableOpacity>
                </View>
                <View style={{ width: 130 }}>
                  <TouchableOpacity
                    onPress={() => setChallengeCompleted(true)}
                    style={[
                      styles.button,
                      {
                        backgroundColor:
                          challengeCompleted === true
                            ? colors.green2
                            : "transparent",
                        borderColor:
                          challengeCompleted === true
                            ? "transparent"
                            : colors.grayBorder,
                      },
                    ]}
                  >
                    <Txt>{t("yes")}</Txt>
                  </TouchableOpacity>
                </View>
              </View>

              {/** check if user select yes || no  */}
              {challengeCompleted === "" ? (
                <></>
              ) : challengeCompleted === true ? (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    width: isDesktop ? 600 : "100%",
                    marginTop: 30,
                    marginBottom: isDesktop ? 70 : 0,
                  }}
                >
                  <H2 style={{ textAlign: "center" }}>
                    {t("challenge_modal.text1")}
                  </H2>
                  {/** feedback  */}
                  <View style={styles.rowButtons}>
                    {feedbackOptions.map((option) => {
                      return (
                        <Surface
                          style={[
                            styles.feedbackButton,
                            {
                              borderColor:
                                selectedFeedback.id === option.id
                                  ? colors.green2
                                  : colors.grayBorder,
                            },
                          ]}
                          key={option.id}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              setSelectedFeedback(option);
                            }}
                            style={styles.feedbutton}
                          >
                            <View style={{ width: 40, height: 40 }}>
                              {option.id === 1 ? (
                                <EmojiSad />
                              ) : option.id === 2 ? (
                                <EmojiHappy />
                              ) : (
                                <EmojiLove />
                              )}
                            </View>
                            <Txt style={{ marginTop: 20 }}>{option.name}</Txt>
                          </TouchableOpacity>
                        </Surface>
                      );
                    })}
                  </View>
                  {/** submit feedback button */}
                  <View style={{ width: 200, marginBottom: 30, marginTop: 20 }}>
                    <PrimaryButton onPress={sendFeedbackHandler}>
                      {submitLoader ? (
                        <Spinner color={colors.white} size="small" />
                      ) : (
                        t("challenge_modal.valid")
                      )}
                    </PrimaryButton>
                  </View>
                </VStack>
              ) : (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  style={{ width: isDesktop ? 600 : "100%", marginTop: 30 }}
                >
                  <Txt style={{ textAlign: "center", marginBottom: 20 }}>
                    {t("challenge_modal.text2")}
                  </Txt>
                  <Txt style={{ textAlign: "center", marginBottom: 20 }}>
                    {t("challenge_modal.text3")}
                  </Txt>
                  <Txt style={{ textAlign: "center", marginBottom: 20 }}>
                    {t("challenge_modal.text4")}
                  </Txt>
                  <H3 style={{ textAlign: "center", marginBottom: 20 }}>
                    {t("challenge_modal.text5")}
                  </H3>
                  <View style={[styles.buttonsContainer, { width: 320 }]}>
                    <View style={{ width: 150 }}>
                      <SecondaryButton onPress={closeHandler}>
                        {t("challenge_modal.back")}
                      </SecondaryButton>
                    </View>
                    <View style={{ width: 150 }}>
                      <PrimaryButton
                        onPress={() => {
                          closeHandler();
                          setIsOpenChooseModal(true);
                        }}
                      >
                        {t("challenge_modal.continue")}
                      </PrimaryButton>
                    </View>
                  </View>
                </VStack>
              )}
            </VStack>
            {isDesktop && (
              <Image
                source={{
                  uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                }}
                style={{
                  width: challengeCompleted === "" ? 100 : 120,
                  height: challengeCompleted === "" ? 162 : 200,
                  position: "absolute",
                  bottom: 0,
                  right: challengeCompleted === "" ? 40 : 20,
                }}
              />
            )}
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default ChallengeModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 1000,
    maxHeight: 5000,
  },
  button: {
    height: 57,
    width: "100%",
    borderRadius: 5000,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: 280,
    marginVertical: 20,
  },
  rowButtons: {
    width: "100%",
    paddingVertical: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  feedbackButton: {
    width: "30%",

    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    elevation: 3,
    borderWidth: 2,
  },
  feedbutton: {
    paddingVertical: 15,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});
