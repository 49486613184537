import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnglishTranslation from "./i18n/en.json";
import FrenchTranslation from "./i18n/fr.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          ...EnglishTranslation,
        },
      },
      fr: {
        translation: {
          ...FrenchTranslation,
        },
      },
    },
    compatibilityJSON: "v3",
    fallbackLng: "fr",
    lng: "fr",
    debug: false,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
