import {
  View,
  useWindowDimensions,
  StyleSheet,
  TouchableOpacity,
  Pressable,
} from "react-native";
import React, { useState } from "react";
import { H6 } from "../../../../components/TextsComponents";
import { colors } from "../../../../styles/GlobalStyle";
import {
  CrownLogo,
  Cup,
  Medal,
  MedalWhite,
  WhiteCup,
} from "../../../../assets/svg/space";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Ionicons } from "@expo/vector-icons";
import HelpModal from "../HelpModal";

const Status = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const CustHeight = width <= 790 ? 200 : 236;
  const [showStatusHelpModal, setShowStatusHelpModal] = useState(false);
  const MarginBottom = width <= 790 ? 20 : 0;
  const SMWidth = width <= 790 ? "100%" : width <= 1300 ? "49%" : "49%";
  const Mr = width >= 1300 ? 5 : 0;
  const userData = useSelector((state) => state.userData);

  return (
    <Pressable
      onPress={() => setShowStatusHelpModal(true)}
      style={[
        styles.status,
        {
          marginBottom: MarginBottom,
          width: SMWidth,
          marginRight: Mr,
          height: CustHeight,
        },
      ]}
    >
      <H6 style={{ color: colors.beige }}>{t("my_space.title5")}</H6>

      <View style={styles.statusItem}>
        <View style={styles.item}>
          {userData.total_points < 300 ? (
            <>
              <View style={styles.itemImage}>
                <Medal />
              </View>
              <View style={styles.itemImage}>
                <MedalWhite />
              </View>
              <View style={styles.itemImage}>
                <MedalWhite />
              </View>
            </>
          ) : userData.total_points < 600 && userData.total_points >= 300 ? (
            <>
              <View style={styles.itemImage}>
                <Medal />
              </View>
              <View style={styles.itemImage}>
                <Medal />
              </View>
              <View style={styles.itemImage}>
                <MedalWhite />
              </View>
            </>
          ) : userData.total_points < 1000 && userData.total_points >= 600 ? (
            <>
              <View style={styles.itemImage}>
                <Medal />
              </View>
              <View style={styles.itemImage}>
                <Medal />
              </View>
              <View style={styles.itemImage}>
                <Medal />
              </View>
            </>
          ) : userData.total_points < 1300 && userData.total_points >= 1000 ? (
            <>
              <View style={styles.itemImage}>
                <Cup />
              </View>
              <View style={styles.itemImage}>
                <WhiteCup />
              </View>
              <View style={styles.itemImage}>
                <WhiteCup />
              </View>
            </>
          ) : userData.total_points < 1600 && userData.total_points >= 1300 ? (
            <>
              <View style={styles.itemImage}>
                <Cup />
              </View>
              <View style={styles.itemImage}>
                <Cup />
              </View>
              <View style={styles.itemImage}>
                <WhiteCup />
              </View>
            </>
          ) : userData.total_points < 2000 && userData.total_points >= 1600 ? (
            <>
              <View style={styles.itemImage}>
                <Cup />
              </View>
              <View style={styles.itemImage}>
                <Cup />
              </View>
              <View style={styles.itemImage}>
                <Cup />
              </View>
            </>
          ) : (
            <>
              <View style={styles.CrownContainer}>
                <CrownLogo />
              </View>
            </>
          )}
        </View>
      </View>
      <H6 style={{ color: colors.beige }}>
        {userData.total_points < 1000
          ? t("ranks.rank1")
          : userData.total_points < 2000 && userData.total_points >= 1000
          ? t("ranks.rank2")
          : t("ranks.rank3")}
      </H6>
      {/** help button -> SHOW POP UP */}
      <TouchableOpacity
        onPress={() => setShowStatusHelpModal(true)}
        style={styles.helpButton}
      >
        <Ionicons name="help-circle-outline" size={24} color={colors.white} />
      </TouchableOpacity>
      {/** help modal */}
      <HelpModal
        isOpen={showStatusHelpModal}
        setIsOpen={setShowStatusHelpModal}
        text={t("my_space.text1")}
        title={t("my_space.title5")}
      />
    </Pressable>
  );
};

export default Status;

const styles = StyleSheet.create({
  status: {
    position: "relative",
    height: 236,
    width: 343,
    borderRadius: 20,
    backgroundColor: colors.red1,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  statusItem: {
    flexDirection: "row",
  },
  item: {
    marginHorizontal: 10,
    flexDirection: "row",
  },
  itemImage: {
    width: 50,
    height: 50,
    marginRight: 5,
  },

  CrownContainer: {
    width: 60,
    height: 60,
  },
  helpButton: {
    position: "absolute",
    padding: 5,
    top: 10,
    right: 10,
  },
});
