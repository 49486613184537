import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  Animated,
  Easing,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import DimensionsHook from "../hooks/DimensionsHook";
import { useHover } from "react-native-web-hooks";
import { Avatar, LanguageIcon } from "../assets/svg/Icons";
import { colors } from "../styles/GlobalStyle";
import { LislUp } from "../assets/svg/Logo";
import { BoldTxt, Txt } from "./TextsComponents";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  GeneralIcon,
  HelpIcon,
  LogoutIcon,
} from "../assets/svg/MyAccountIcons";
import { AntDesign, SimpleLineIcons } from "@expo/vector-icons";
import GreenModal from "./modals/GreenModal";
import { CustomTooltip } from "./Tooltips";
import OutsidePressHandler from "react-native-outside-press";
import MonthlyMessageModal from "./modals/MonthlyMessageModal";
import { Fontisto } from "@expo/vector-icons";
import LanguageModal from "./modals/LanguageModals";

const HeaderComponent = ({ navigation, myaccount, name }) => {
  const { isDesktop } = DimensionsHook();
  const userData = useSelector((state) => state.userData);
  const [isOpen, setIsOpen] = useState(false);
  const [showMonthlyMessage, setShowMonthlyMessage] = useState(false);
  const [isOpenGreenModal, setIsOpenGreenModal] = useState(false);
  const menuHeight = useRef(new Animated.Value(0)).current;
  const { t } = useTranslation();
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const helpRef = useRef(null);
  const isHelpHovered = useHover(helpRef);
  const messageRef = useRef(null);
  const isMessageHovered = useHover(messageRef);
  const accountRef = useRef(null);
  const isAccountHovered = useHover(accountRef);
  const logoutRef = useRef(null);
  const isLogoutHovered = useHover(logoutRef);
  const closeRef = useRef(null);
  const isCloseHovered = useHover(closeRef);
  const languageRef = useRef(null);
  const isLanguageHovered = useHover(languageRef);

  const routing = [
    {
      name: t("tab_navigation.home"),
      link: "Home",
      tooltip: t("tooltips.home"),
    },
    {
      name: t("tab_navigation.my_space"),
      link: "Espace",
      tooltip: t("tooltips.my_space"),
    },
    {
      name: t("tab_navigation.trails"),
      link: "Trails",
      tooltip: t("tooltips.trails"),
    },
    {
      name: t("tab_navigation.workshops"),
      link: "Workshops",
      tooltip: t("tooltips.workshops"),
    },
    {
      name: t("tab_navigation.community"),
      link: "Communaute",
      tooltip: t("tooltips.community"),
    },
  ];

  const openMenuHandler = () => {
    Animated.timing(menuHeight, {
      toValue: 250,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  };

  const closeMenuHandler = () => {
    Animated.timing(menuHeight, {
      toValue: 0,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  };

  const clickHandler = () => {
    //navigation.navigate("MyAccount");
    if (isOpen) {
      closeMenuHandler();
    } else {
      openMenuHandler();
    }
  };

  useEffect(() => {
    navigation.addListener("focus", () => {
      // do something
      closeMenuHandler();
    });
  }, [navigation]);

  return (
    <>
      <View style={{ backgroundColor: colors.beige }}>
        <View
          style={[
            styles.header,
            {
              width: isDesktop ? "85%" : "95%",
              alignSelf: "center",
            },
          ]}
        >
          <View style={styles.row}>
            <View style={styles.logoContainer}>
              <LislUp />
            </View>
            {isDesktop && (
              <View style={styles.linksContainer}>
                {routing.map((r) => {
                  let isActive = r.name === name;
                  return (
                    <CustomTooltip
                      key={r.name}
                      bg={colors.green2}
                      color={colors.white}
                      label={r.tooltip}
                    >
                      <TouchableOpacity
                        key={r.name}
                        onPress={() => navigation.navigate(r.link)}
                        style={styles.linkButton}
                      >
                        {isActive ? (
                          <BoldTxt>{r.name}</BoldTxt>
                        ) : (
                          <Txt>{r.name}</Txt>
                        )}

                        {isActive && <View style={styles.activeDot}></View>}
                      </TouchableOpacity>
                    </CustomTooltip>
                  );
                })}
              </View>
            )}
          </View>
          <View style={styles.row}>
            <TouchableOpacity
              ref={languageRef}
              style={[
                styles.openLanguageModalButton,
                {
                  backgroundColor: isLanguageHovered
                    ? colors.blue3
                    : colors.beige,
                },
              ]}
              onPress={() => setShowLanguageModal(true)}
            >
              <View style={{ width: 20, height: 20 }}>
                <LanguageIcon
                  color={isLanguageHovered ? colors.white : colors.blue3}
                />
              </View>
            </TouchableOpacity>
            {userData.image ? (
              <OutsidePressHandler onOutsidePress={closeMenuHandler}>
                <TouchableOpacity ref={hoverRef} onPress={clickHandler}>
                  <Image
                    style={{ width: 50, height: 50, borderRadius: 50 }}
                    source={{
                      uri: `${process.env.BASE_URL}/${userData.image}`,
                    }}
                  />
                </TouchableOpacity>
              </OutsidePressHandler>
            ) : (
              <OutsidePressHandler onOutsidePress={closeMenuHandler}>
                <TouchableOpacity
                  ref={hoverRef}
                  style={[
                    styles.avatar,
                    {
                      backgroundColor: isHovered
                        ? colors.blue3
                        : myaccount
                        ? colors.green2
                        : "transparent",
                      borderWidth: 2,
                      borderColor: isHovered
                        ? colors.blue3
                        : myaccount
                        ? colors.green2
                        : colors.grayLabel,
                    },
                  ]}
                  onPress={clickHandler}
                >
                  <Avatar
                    color={
                      isHovered
                        ? colors.white
                        : myaccount
                        ? colors.white
                        : colors.grayLabel
                    }
                  />
                </TouchableOpacity>
              </OutsidePressHandler>
            )}
          </View>
        </View>
      </View>
      {/** opened menu */}
      <Animated.View
        style={[
          styles.menu,
          {
            right: isDesktop ? "7.5%" : "2.5%",
            height: menuHeight,
            borderBottomLeftRadius: 20,
          },
        ]}
      >
        <View style={styles.divider} />
        <TouchableOpacity
          ref={closeRef}
          style={{ alignSelf: "flex-end", margin: 10 }}
          onPress={closeMenuHandler}
        >
          <AntDesign
            name="closesquare"
            size={24}
            color={isCloseHovered ? colors.red1 : colors.grayBorder}
          />
        </TouchableOpacity>
        {/** message du jour button */}
        <TouchableOpacity
          ref={messageRef}
          onPress={() => {
            closeMenuHandler();
            setShowMonthlyMessage(true);
          }}
          style={[
            styles.menuButton,
            {
              backgroundColor: isMessageHovered ? colors.blue3 : colors.green2,
              borderColor: isMessageHovered ? colors.blue3 : colors.green2,
            },
          ]}
        >
          <View style={{ paddingHorizontal: 10 }}>
            <SimpleLineIcons
              name="envelope-letter"
              size={16}
              color={colors.white}
            />
          </View>
          <Txt
            color={isMessageHovered ? colors.white : colors.white}
            fontSize={16}
          >
            {t("monthly_message.title")}
          </Txt>
        </TouchableOpacity>
        {/** help button */}
        <TouchableOpacity
          ref={helpRef}
          onPress={() => {
            navigation.navigate("AccountHelp");
          }}
          style={[
            styles.menuButton,
            {
              backgroundColor: isHelpHovered ? colors.blue3 : "transparent",
              borderColor: isHelpHovered ? colors.blue3 : colors.grayBorder,
            },
          ]}
        >
          <View style={{ paddingHorizontal: 10 }}>
            <HelpIcon color={isHelpHovered ? colors.white : colors.blue3} />
          </View>
          <Txt
            color={isHelpHovered ? colors.white : colors.blue3}
            fontSize={16}
          >
            {t("account_navigation.help")}
          </Txt>
        </TouchableOpacity>
        {/** my account button */}
        <TouchableOpacity
          ref={accountRef}
          style={[
            styles.menuButton,
            {
              backgroundColor: isAccountHovered ? colors.blue3 : "transparent",
              borderColor: isAccountHovered ? colors.blue3 : colors.grayBorder,
            },
          ]}
          onPress={() => {
            navigation.navigate("AccountGeneral", { show: true });
          }}
        >
          <View style={{ paddingHorizontal: 10 }}>
            <GeneralIcon
              color={isAccountHovered ? colors.white : colors.blue3}
            />
          </View>
          <Txt
            color={isAccountHovered ? colors.white : colors.blue3}
            fontSize={16}
          >
            {t("my_account")}
          </Txt>
        </TouchableOpacity>
        {/** logout button  */}
        <TouchableOpacity
          ref={logoutRef}
          onPress={() => {
            setIsOpenGreenModal(true);
          }}
          style={[
            styles.menuButton,
            {
              backgroundColor: isLogoutHovered ? colors.blue3 : "transparent",
              borderColor: isLogoutHovered ? colors.blue3 : colors.grayBorder,
            },
          ]}
        >
          <View style={{ paddingHorizontal: 10 }}>
            <LogoutIcon color={isLogoutHovered ? colors.white : colors.blue3} />
          </View>
          <Txt
            color={isLogoutHovered ? colors.white : colors.blue3}
            fontSize={16}
          >
            {t("account_navigation.logout")}
          </Txt>
        </TouchableOpacity>
      </Animated.View>
      {/** logout menu */}
      <GreenModal
        logout={true}
        navigation={navigation}
        isOpen={isOpenGreenModal}
        setIsOpen={setIsOpenGreenModal}
        title={t("modals.title2")}
        text={t("modals.text4")}
      />
      {/** monthly message  */}
      <MonthlyMessageModal
        isOpen={showMonthlyMessage}
        setIsOpen={setShowMonthlyMessage}
      />
      {/** language modal */}
      <LanguageModal
        showModal={showLanguageModal}
        setShowModal={setShowLanguageModal}
      />
    </>
  );
};

export default HeaderComponent;

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.beige,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 70,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBorder,
  },

  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    width: 55,
    height: 55,
  },
  avatar: {
    width: 48,
    padding: 10,
    height: 48,
    borderRadius: 50,
  },
  activeDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: colors.green1,
    alignSelf: "center",
    position: "absolute",
    bottom: 0,
  },
  linksContainer: {
    flexDirection: "row",
    alignItems: "center",

    marginLeft: 15,
  },
  linkButton: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginLeft: 10,
  },
  menu: {
    position: "absolute",
    zIndex: 999,
    backgroundColor: colors.beige,
    top: 69,
    width: 250,
    overflow: "hidden",
  },
  menuButton: {
    width: "90%",
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    paddingVertical: 8,

    borderRadius: 100,
    marginBottom: 10,
  },
  divider: {
    height: 1,
    width: "100%",
    alignSelf: "center",
    backgroundColor: colors.grayBorder,
  },
  openLanguageModalButton: {
    width: 40,
    height: 40,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,

    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 0.5 * 4 },
    shadowOpacity: 0.25,
    shadowRadius: 0.8 * 4,
  },
});
